import React from "react";
import { getLogger, getCompanySettings } from "lib/util";
import { isPermissionsAllowed } from "lib/util/Permissions";
import {Panel, Button, Label } from "lib/components";
import { PATHS } from "core/config/urls";
import { useHistory } from "react-router-dom";

const log = getLogger("lme.ar.CustomerPortal.LegacyButton");

export function LegacyButton(props) {
  const settings = getCompanySettings();
  const history = useHistory();
	let items = [];
	let tlOrder = false, ltlOrder = false, tlQuote = false, ltlQuote = false;
	if (isPermissionsAllowed("InternetModule", "InternetModulePickupEntry")) {
		if (settings.is_asset_ltl)
			ltlOrder = true;
	}
	if (isPermissionsAllowed("InternetModule", "InternetModuleQuoteEntry")) {
		if (settings.is_asset_ltl || settings.is_ltl)
			ltlQuote = true;
	}
	if (isPermissionsAllowed("InternetModule", "InternetModuleOrderEntry")) {
		tlOrder = true;
    	tlQuote = true;
	}

	if (tlOrder && ltlOrder) {
		items.push({ caption: "Add TL Order", dropDownText: "Order", image: "FtlDryVan",  page: "portal/customer/ftl-order/FtlOrder" });
		items.push({ caption: "Add LTL Order", dropDownText: "Order", image: "LtlPallet", page: "portal/customer/ltl-order/LtlOrder" });
	}
	else if (tlOrder)
	 	items.push({ caption: "Add Order", dropDownText: "Order", image: "FtlDryVan", page: "portal/customer/ftl-order/FtlOrder" });
	else if (ltlOrder)
		items.push({ caption: "Add Order", dropDownText: "Order", image: "LtlPallet", page: "portal/customer/ltl-order/LtlOrder" });
	if (tlQuote && ltlQuote) {
		//items.push({ caption: "Add TL quote", dropDownText: "Quote", image: "FtlDryVan", page: PATHS.ROOT });
		if (settings.is_asset_ltl)
			items.push({ caption: "Add LTL quote", dropDownText: "Quote", image: "LtlPallet", page: "portal/customer/asset-ltl-quote/AssetLtlQuote" });
		else
			items.push({ caption: "Add LTL quote", dropDownText: "Quote", image: "LtlPallet", page: "portal/customer/brokerage-ltl-quote/BrokerageLtlQuote" });
	}
	// else if (tlQuote)
	// 	items.push({ caption: "Add quote", dropDownText: "Quote", image: "FtlDryVan", page: PATHS.ROOT });
	else if (ltlQuote){
		if (settings.is_asset_ltl)
			items.push({ caption: "Add LTL quote", dropDownText: "Quote", image: "LtlPallet", page: "portal/customer/asset-ltl-quote/AssetLtlQuote" });
		else
			items.push({ caption: "Add LTL quote", dropDownText: "Quote", image: "LtlPallet", page: "portal/customer/brokerage-ltl-quote/BrokerageLtlQuote" });
	}
	log.debug("Legacy settings %o items %o", settings, items);
	if (items.length === 0)
		return null;
	let dropDownButton;
	if (items.length > 1) {
		let dropDown = [];
		for (let i = 0; i < items.length; i++) {
      const image = { image: items[i].image, height:40, width:151, marginRight: 32, ...items[i].imgProps };
			dropDown.push(
				<Panel onClick={() => history.push(items[i].page)}>
					<Label caption={items[i].dropDownText} image={image} look="secondary5" />
				</Panel>
			);
    }
		dropDownButton = (
			<Button
        image={{image:"Chevron", width:16}}
				buttonStyle={{padding:"4px 8px 4px 8px", borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
        borderOnlyOnHover={false}
				marginLeft={-9}
				height={48}
				dropDown={dropDown}
        dropDownAlign="right"
        look="secondary4"
				{...props}
				noRowBreak
			/>
		);
	}
	return (
		<Panel width={240}>
			<Button
				caption={items[0].caption}
				buttonStyle={{borderBottomRightRadius: 0, borderTopRightRadius: 0}}
				height={48}
        fillRow
        noRowBreak
        look="secondary4"
				onClick={() => history.push(items[0].page)}
			/>
			{dropDownButton}
    </Panel>
  );
}
