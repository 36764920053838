import React from "react";
import { Panel, Button, Label } from "lib/components";
import { getThemeClassNames } from "lib/util/Theme";
import { AuthType } from "lib/util/AuthType";

export function ThemeDemo() {
  const buttons = [], labels = [];
  const theme = getThemeClassNames();
  for (let prop in theme)
    if (prop.startsWith("button"))
      buttons.push(<Button onClick={(event, context, setLoading) => showSpinner(event, context, setLoading)} look={prop.substring(6)} caption={prop.substring(6)}  padding={16}/>);
    else if (!prop.startsWith("background") && !prop.startsWith("header"))
      labels.push(<Label look={prop} caption={prop} padding={16}/>);
  return (
    <Panel>
      <Panel paddingRight={64} noRowBreak>
        {labels}
      </Panel>
      <Panel noRowBreak>
        {buttons}
      </Panel>
    </Panel>
  );
}

function showSpinner(event, context, setLoading)
{ setLoading(true); }

export function getPageOptions() {
  return {auth: AuthType.UNAUTH, title: "Theme List"}
}
