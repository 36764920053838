import React from "react";
import { useComponentContext } from "./Context";
import { CheckBox, TextBox } from ".";
import { addMetaDataItems, getMetaData } from "lib/util/ModelDefaultProps";

export function Field({ ...props }) {
  let componentContext = useComponentContext();
  if (props.metaData == null)
    props.metaData = getMetaData(componentContext, props.field);
  addMetaDataItems(props);
  let componentType = getComponentType(props.metaData);
  if (componentType === "checkbox")
    return <CheckBox {...props} />
  else
    return <TextBox {...props} />
}

function getComponentType(metaData) {
  if (metaData != null && metaData.dataType === "bool")
    return "checkbox";
  else
    return "textbox";
}
