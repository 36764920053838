import { getLogger } from "./Logger";

const log = getLogger("lib.util.ModelValues");

/**
 * This function collects all the data for a given context and returns it as an object that it suitable
 * for submitting to the server.
 *
 * @param {*} context the context for which we want to get the user's input
 * @param {*} forSearch whether or not we are trying to get the searchValues or the values intended to be posted
 */
export function getModelValues(context, forSearch, modifyDataMethod) {
  const data = context.data;
  let result = getValuesFromContextData(data, forSearch);
  if (modifyDataMethod != null) {
    result = modifyDataMethod(result);
  }
  return result;
}

function getValuesFromContextData(data, forSearch) {
  log.debug("getValuesFromContextData entry %o", data);
  const dataItems = getRelevantData(data, forSearch);
  let result = [];
  for (let i = 0; i < dataItems.length; i++) {
    const dataItem = dataItems[i].modelData;
    let resultItem = { ...data.fixedData };
    result.push(resultItem);
    log.debug("getValuesFromContextData with dataItem %o", dataItem);
    for (let key in dataItem) {
      let value = dataItem[key];
      if (value != null) {
        //First check if there are any call back props, if so use those instead of the origianl props
        if((data.componentPropsCallback || data.componentProps != null) && typeof value === "object"){
            const callBackProps = data.componentPropsCallback && data.componentPropsCallback(data.list[i], i, data.componentProps);
            if(callBackProps){
              if (callBackProps[key] != null && callBackProps[key].lookupModelResultField != null) {
               value = value[callBackProps[key].lookupModelResultField];
              }
            }
            else if(data.componentProps) {
              if(data.componentProps[key] != null && data.componentProps[key].lookupModelResultField !=null) {
                value = value[data.componentProps[key].lookupModelResultField];
              }
            }
        }
        resultItem[key] = value;
      }
    }
    addChildData(resultItem, data.childData, forSearch);
  }
  if (forSearch === true)
    result = result[0];
  log.debug("getValuesFromContextData result %o", result);
  return result;
}

function getRelevantData(data, forSearch) {
  if (forSearch === true) {
    if (data.searchValues == null)
      return null;
    return [data.searchValues];
  }
  else {
    if (data.list == null)
      return data;
    else
      return data.list;
  }
}

function addChildData(targetData, childData, forSearch) {
  if (childData != null) {
    log.debug("Adding child data %o", childData);
    for (let field in childData)
      targetData[field] = getValuesFromContextData(childData[field], forSearch);
  }
}
