import React, { useState } from "react";
import { Tabset, Panel, Label } from "lib/components";
import { DataModes } from "lib/util/ModelUtil";
import { PersonalInfo } from "./PersonalInfo";
import { Certifications } from "./Certifications";
import { Records } from "./Records";
import { Experience } from "./Experience";
import { PastJobs } from "./PastJobs";
import { Confirmation } from "./Confirmation";
import { Congrats } from "./Congrats";
import { AuthType } from "lib/util/AuthType";

export function DriverApplication() {
  const [currentStep, setCurrentStep] = useState();
  const [agree, setAgree] = useState(false);
  return (
      <Panel dataMode={DataModes.ADD} modelName="portal/driver/driver-application" fillHeight fillRow>
          <Label look="default6" caption="Driver Application" />
          <Tabset variant="wizard" keepMounted={true} selectedIndex={currentStep}
            setCurrentStep={setCurrentStep} agreed={agree} successPage={Congrats()} fillRow fillHeight >
              {PersonalInfo()}
              {Certifications()}
              {Records()}
              {Experience()}
              {PastJobs()}
              {Confirmation(agree, setAgree)}
          </Tabset>
      </Panel>
  )
}

export function getPageOptions() {
    return {
      auth: AuthType.UNAUTH,
      title: "Driver Application",
    };
}
