import * as React from "react";

function BoxArrowUp(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <path
        d="M9.475.225a.772.772 0 011.094 0l3.478 3.48a.772.772 0 01-1.09 1.092l-2.162-2.163V15.53a.773.773 0 01-1.545 0V2.634L7.087 4.797a.77.77 0 01-1.09 0 .774.774 0 01-.001-1.092l3.48-3.48zm9.753 9.784a.773.773 0 00-.773.772V18a.46.46 0 01-.46.46H2.003a.46.46 0 01-.46-.46v-7.221a.772.772 0 10-1.542 0v7.833c0 .765.623 1.386 1.386 1.386h17.228c.765 0 1.386-.623 1.386-1.386v-7.833a.772.772 0 00-.772-.77z"
        fillRule="nonzero"
      />
    </svg>
  );
}

const SVGBoxArrowUp = React.memo(BoxArrowUp);
export { SVGBoxArrowUp };