import React from "react";
import SplitterLayout from 'react-splitter-layout';
import {Component} from "lib/components";

export function Splitter({vertical, percentage, primaryIndex,
    primaryMinSize, secondaryMinSize, children, ...props }) {
    return (
        <Component {...props} >
            <SplitterLayout vertical={vertical} percentage={percentage} primaryIndex={primaryIndex}
                primaryMinSize={primaryMinSize} secondaryMinSize={secondaryMinSize} >
                {children}
            </SplitterLayout>
        </Component>
    );
}