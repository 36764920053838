import React from "react";
import { Form, Icon, Input, Button } from "antd";
import { SESSION_STORAGE_LOGIN_TOKEN } from "../../../lib/util/api";
import queryString from "query-string";
import "./login.css";
import { fetch, resolveArrayResponse } from "lib/util/fetch";
import { buildAPIUrl } from "core/utils/urls";
import { Logo } from "common/Logo";
import { withStyles } from "@material-ui/core";

const images = require.context("../../../images", true);
const API_URL_SETUP = "users/setup";
const API_URL_REQUIREMENTS = "users/password/requirements";
const API_URL_SETUP_VALIDATE = "users/setup/validate";
const styles = (theme) => ({
  logo: {
    width: 174,
    height: 67
  },
  logoContainer: {
    padding: theme.spacing(4),
    backgroundColor: theme.branding.colors.headerBackground || "transparent"
  }
})

class UnstyledSetupAccount extends React.Component {
  state = {
    confirmDirty: false,
    loading: false
  };

  static defaultProps = {
    headerMessage: <p className="login-welcome-text">Setup Account</p>,
    referrer: "/",
    tokenValidationMessage: (
      <div>
        <p className="login-error-text">This link to complete your account setup has expired.</p>
        <p className="login-error-text-secondary">
          To complete setting up your account, please request again from your
          administrator.
        </p>
      </div>
    ),
    isDisabled: true
  };

  constructor(props, state) {
    super(props, state);
    this.state = { ...props };
  }

  async fetchSetupValidation() {
    const url = buildAPIUrl({ path: API_URL_SETUP_VALIDATE });
    const response = await fetch({ url });
    return resolveArrayResponse(response);
  }

  componentDidMount = () => {
    let resetToken = this.getTokenFromQueryString();
    if (resetToken) {
      sessionStorage.setItem(SESSION_STORAGE_LOGIN_TOKEN, resetToken);
      this.fetchSetupValidation()
        .then(setupValidationResponse => {
          console.log(
            "Result from setup validation call %o",
            setupValidationResponse
          );
          if (setupValidationResponse != null) {
            if (setupValidationResponse.validation.valid === true) {
              this.setState({
                tokenValidationMessage: null,
                isDisabled: false
              });
            } else if (
              setupValidationResponse.success === false &&
              setupValidationResponse.validation.valid === false &&
              setupValidationResponse.validation.message.includes("enabled")
            ) {
              window.location.href = "/login?setupEnabled";
              return;
            }

            this.setState({
              resetResponse: setupValidationResponse
            });
          }
        })
        .catch(() => this.setState({ error: true }));
    }
  };

  async fetchSetup(values) {
    const response = await fetch({
      url: buildAPIUrl(API_URL_SETUP),
      method: "post",
      data: { password: values.password }
    })
    return resolveArrayResponse(response);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        //let payload = { password: values.password };
        let resetToken = this.getTokenFromQueryString();
        if (resetToken)
          sessionStorage.setItem(SESSION_STORAGE_LOGIN_TOKEN, resetToken);
        this.fetchSetup(values)
          .then(resetResponse => {
            console.log("Result from account setup request %o", resetResponse);
            if (resetResponse != null) {
              if (
                resetResponse.success === true &&
                resetResponse.validation.valid === true
              ) {
                window.location.href = "/login?setupSuccess";
                return;
              } else if (
                resetResponse.success === false &&
                resetResponse.validation.valid === false &&
                resetResponse.validation.message.includes("enabled")
              ) {
                window.location.href = "/login?setupEnabled";
                return;
              }
            }
            this.setState({
              resetResponse: resetResponse,
              loading: false
            });
          })
          .catch(() => this.setState({ error: true, loading: false }));
      }
    });
  };

  async fetchPasswordRequirements() {
    const url = buildAPIUrl({ path: API_URL_REQUIREMENTS });
    const response = await fetch({ url });
    return resolveArrayResponse(response);
  }

  showPasswordRequirements = () => {
    if (!this.state.passwordRequirements) {
      this.fetchPasswordRequirements()
        .then(requirementsResponse => {
          console.log(
            "Result from password requirements call %o",
            requirementsResponse
          );
          if (requirementsResponse) {
            this.setState({
              passwordRequirements: requirementsResponse.requirements
            });
          }
        })
        .catch(() => this.setState({ error: true }));
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("The passwords you entered do not match!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  getTokenFromQueryString() {
    let token = null;
    if (this.props.location !== undefined) {
      var result = queryString.parse(this.props.location.search);
      token = result["token"];
    }
    return token;
  }

  getAdminContactRender(adminContact) {
    return (
      <div>
        <span>
          <b>For assistance, please contact:</b>
        </span>
        <br />
        <span>{adminContact.displayName}</span>
        <br />
        <span>Phone number: {adminContact.phone}</span>
        <br />
        <span>Cell phone number: {adminContact.cellPhone}</span>
        <br />
        <span>Email: {adminContact.email}</span>
      </div>
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    };
    let statusMessageRender = null;
    let adminContactMessageRender = null;
    if (this.state.resetResponse) {
      let resetResponse = this.state.resetResponse;
      let validationResponse = this.state.resetResponse.validation;
      let adminContact = this.state.resetResponse.adminContact;
      let statusClassName = "reset-status-message-text";
      let statusMessage = "";
      if (resetResponse) {
        if (
          resetResponse.success === false &&
          validationResponse.valid === false
        ) {
          statusClassName += " validation-failed";
          statusMessage = validationResponse.message;
          if (adminContact) {
            adminContactMessageRender = this.getAdminContactRender(
              adminContact
            );
          }
        } else if (
          resetResponse.success === true &&
          validationResponse.valid === true
        ) {
          statusClassName += " validation-passed";
          statusMessage = <span>{resetResponse.message}</span>;
        }
        statusMessageRender = (
          <div>
            <p className={statusClassName}>{statusMessage}</p>
            {adminContactMessageRender}
          </div>
        );
      }
    }

    let passwordRequirementsRender = null;
    if (this.state.passwordRequirements) {
      let requirementsList = [];
      for (let i = 0; i < this.state.passwordRequirements.length; i++) {
        requirementsList.push(<li>{this.state.passwordRequirements[i]}</li>);
      }
      passwordRequirementsRender = <ul>{requirementsList}</ul>;
    }

    return (
      <div className="reset-form-container">
        <div className={this.props.classes.logoContainer}>
          <Logo className={this.props.classes.logo} variant="dark" />
        </div>
        <div className="reset-form-controls">
          {this.state.tokenValidationMessage}
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item
              label="New Password"
              hasFeedback
              className="input-confirm-password"
            >
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input your password!"
                  },
                  {
                    validator: this.validateToNextPassword
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                  className="login-input"
                  disabled={this.state.isDisabled}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              hasFeedback
              className="input-confirm-password"
            >
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password!"
                  },
                  {
                    validator: this.compareToFirstPassword
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Confirm Password"
                  className="login-input"
                  onBlur={this.handleConfirmBlur}
                  disabled={this.state.isDisabled}
                />
              )}
            </Form.Item>
            <div>
              <p>
                Password Criteria&nbsp;&nbsp;
                <img
                  src={images("./png/question-circle-o.png")}
                  alt="question-circle"
                  onClick={this.showPasswordRequirements}
                  style={{ cursor: "pointer" }}
                />
              </p>
              {passwordRequirementsRender}
            </div>
            {statusMessageRender}
            <Form.Item className="button-reset">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={this.state.isDisabled}
                loading={this.state.loading}
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item className="button-reset">
              <Button
                type="default"
                href="/login"
                className="login-form-button"
                disabled={this.state.isDisabled}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
export const SetupAccount = withStyles(styles)(UnstyledSetupAccount);
