import { makeStyles } from "lib/util";

let styles;

export function useButtonStyles() {
  if (styles == null)
    styles = makeStyles("btn", {
      button: {
        width: "100%",
        height: "100%",
        minWidth: "unset",
        "& .MuiButton-root": {
          padding: "0px",
          borderRadius: "6px"
        },
        "& .MuiButton-text": {
          padding: "0px"
        },
      },
      caption: {
        //paddingTop: 5
      },
      progress: {
        marginBottom: "-8px",
        marginLeft: "-12px"
      },
      image: {
        margin: "0"
      }
    });
  return styles;
}
