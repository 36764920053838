import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function MagnifyingGlassWithLetter(props) {
  return (
    <SVGIcon viewBox="0 0 15 15" {...props} >
      <path
        d="M14.805 13.863l-3.417-3.412A6.41 6.41 0 006.413 0 6.41 6.41 0 000 6.407a6.41 6.41 0 006.413 6.407c1.527 0 2.93-.533 4.03-1.425l3.418 3.415a.667.667 0 00.944-.942zM8.44 11.207a5.175 5.175 0 01-2.028.408A5.205 5.205 0 011.2 6.408a5.194 5.194 0 011.526-3.683A5.192 5.192 0 016.413 1.2a5.205 5.205 0 015.211 5.208 5.194 5.194 0 01-1.526 3.683 5.238 5.238 0 01-1.657 1.116zM6.4 10.016a2.867 2.867 0 00.77-.098l.127-.036V8.76a2.644 2.644 0 01-.436.036c-.096 0-.179-.008-.25-.023a.384.384 0 01-.176-.087.39.39 0 01-.108-.177.794.794 0 01-.032-.178l-.004-.11V5.452h.95V4.364h-.95V3H4.81v1.364H4v1.087h.81V8.38c0 .294.038.545.113.754.075.208.182.377.32.507.14.13.306.225.5.285.196.06.414.09.657.09zM8 9.198l2.099-2.099L8 5v4.198z"
      />
    </SVGIcon>
  );
}

const SVGMagnifyingGlassWithLetter = React.memo(MagnifyingGlassWithLetter);
export { SVGMagnifyingGlassWithLetter };
