import { makeStyles } from "lib/util";
import { styleDisableUserSelect } from "lib/util/StyleUtil";
import { getTheme, getThemeColor } from "lib/util/Theme";

let styles;

export function useTextBoxStyles() {
  if (styles == null)
    styles = makeStyles("txt", {
      labelValue: { padding: "4px 8px" },
      content: {
        padding: "1px",
        display: "flex",
        border: "1px solid " + getThemeColor("subtle.light"),
        borderRadius: "4px",
        position: "relative",
        height: "40px",
        "&:focus-within": {
          borderColor: getThemeColor("primary")
        },
        backgroundColor: getTheme().background,
      },
      error: {
        padding: "1px",
        display: "flex",
        border: "1px solid " + getThemeColor("warning"),
        borderRadius: "4px",
        position: "relative",
        height: "40px",
        backgroundColor: getTheme().background,
      },
      contentFullHeight: {
        height: "100%"
      },
      fullHeight: {
      },
      contentNoCaption: {
        height: "100%"
      },
      input: {
        width: "99%",
        border: "none",
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "8px",
        paddingRight: "2px",
        height: "34px",
        fontFamily: "Roboto",
        fontWeight: "400",
        outline: "none",
        "&:disabled": {
          backgroundColor: "unset"
        },
        "&::placeholder": {
          color: "#9E9E9E",
          fontSize: "12px"
        }
      },
      textarea: {
        resize: "none",
        border: "none",
        outline: "none",
        width: "100%",
        height: "100%",
        borderRadius: "4px",
        "&:disabled": {
          backgroundColor: "unset"
        }
      },
      button: {
        width: "24px",
        padding: "3px",
        marginBottom: "1px",
        borderRadius: "2px",
        ":active": {
          marginTop: "1px"
        }
      },
      buttonAddLocation: {
        width: "150px"
      },
      noMatching: {
        fontWeight: "200",
        fontStyle: "italic",
        paddingTop: "12px",
        paddingBottom: "12px",
        textAlign: "center"
      },
      addLocation: {
        fontWeight: "200",
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "12px",
        paddingBottom: "12px",
        alignItems: "center"
      },
      listbox: {
        position: "fixed",
        zIndex: "20",
        listStyle: "none",
        backgroundColor: "white",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "200px",
        marginLeft: "-2px",
        border: "1px solid rgba(0,0,0,.25)",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        borderTopRightRadius: "8px",
      },
      listboxLocation: {
        maxHeight: "450px"
      },
      dropDownTable: {
        width: "100%",
        "& td": {
          paddingRight: "16px"
        }
      },
      dropDownRow: {
        cursor: "pointer",
        padding: "4px",
        width: "100%",
        ...styleDisableUserSelect,
      },
      dropDownRowSticky: {
        cursor: "pointer",
        padding: "4px",
        width: "100%",
        ...styleDisableUserSelect,
        position: "-webkit-sticky",
        position: "sticky",
        bottom: "0px",
        backgroundColor: "#FFFFFF"
      },
      dropDownCell: {
        paddingTop: "6px",
        paddingBottom: "6px"
      },
      dropDownDivider: {
        backgroundColor: getTheme().background,
      },
      productBookHeader: {
        ...styleDisableUserSelect,
      },
      selectedItem: {
        backgroundColor: "#F0F0F0",
      },
      leadingAdornment: {
        alignSelf: "center",
        paddingRight: "4px",
        color: getThemeColor("primary"),
        cursor: "pointer",
        ...styleDisableUserSelect
      },
      trailingAdornment: {
        paddingRight: "3px",
        paddingLeft: "2px",
      },
      uom: {
        paddingRight: "2px"
      },
      chevron: {
        color: "#AAA"
      },
      combo: {
        caretColor: "transparent",
      },
      comboContainer: {
        backgroundColor: "#F0F0F0",
        "& input": {
          backgroundColor: "#F0F0F0",
        }
      },
      contentReadMore: {
        padding: "1px",
        display: "flex",
        border: "none",
        borderRadius: "4px",
        position: "relative",
        whiteSpace: "pre-wrap",
        backgroundColor: getTheme().background
      }
    });
  return styles;
}
