import React from "react";
import { DefaultPageContainer } from "pages/containers";
import { AuthenticatedPage } from "lib/util/AuthenticatedPage";
import { getLogger } from "./Logger";
import { PageNotFound } from "./PageNotFound";
import { evaluateProp } from "lib/components/ComponentUtil";
import { getUserSettings } from "./MainPageContext";
import { setPageTitle } from "./PageTitle";
import { AuthType } from "./AuthType";
import { getHistory } from "./Navigation";

const { parseFunctionIdentifier, getModuleForParsedIdentifier } = require("./DynamicLoader");

const log = getLogger("lib.util.DynamicRouter");

export function getFunctionIdentifierForPathName(pathname) {
  let path = pathname.substring(1);
  path = path.replaceAll("/", ".");
  return parseFunctionIdentifier(path, true);
}

export function getPageOptionsForPathName(pathname) {
  const functionId = getFunctionIdentifierForPathName(pathname);
  if (functionId == null)
    return null;
  const module = getModuleForParsedIdentifier(functionId);
  if (module == null) {
    log.debug("getPageOptionsForPathName module %s not found", functionId);
    return null;
  }
  let pageOptions = evaluateProp(null, module.getPageOptions);
  if (pageOptions == null)
    log.debug("getPageOptionsForPathName pageOptions %s not found", module);
  log.debug("getPageOptionsForPathName %s   %o", pathname, pageOptions);
  return pageOptions;
}

export function DynamicRouter() {
  const functionId = getFunctionIdentifierForPathName(window.location.pathname);
  const module = getModuleForParsedIdentifier(functionId);
  const history = getHistory();
  if (module == null) {
    log.debug("Module %s not found", functionId);
    return wrapPageNotFound();
  }
  const func = module[functionId.functionName];
  let pageOptions = evaluateProp(null, module.getPageOptions);
  if (pageOptions == null) {
    log.debug("getPageOptions() not implemented in %o", module);
    return wrapPageNotFound();
  }
  log.debug("Page options %o", pageOptions);
  if (pageOptions.auth === AuthType.UNAUTH) {
    setPageTitle(evaluateProp(null, pageOptions.title));
    return unauthenticatedWrapper(func, history.location.state);
  }
  return authenticatedWrapper(func, pageOptions, history.location.state);
}

function wrapPageNotFound() {
  if (getUserSettings() == null)
    return unauthenticatedWrapper(PageNotFound);
  else
    return authenticatedWrapper(PageNotFound);
}

function unauthenticatedWrapper(comp, props) {
  return (
    <DefaultPageContainer>
      {React.createElement(comp, props, null)}
    </DefaultPageContainer>
  )
}

function authenticatedWrapper(comp, pageOptions, props) {
    return (
      <DefaultPageContainer>
        <AuthenticatedPage childFunc={comp} pageOptions={pageOptions} childProps={props}/>
      </DefaultPageContainer>
    );
}
