import React, { useState } from "react";
import { Button, Column, Label, Panel, showSnackbar, Switch, Table, TextBox, useComponentContext } from "lib/components";
import { DataModes } from "lib/util/ModelUtil";
import { callApi } from "lib/util/api";
import { copyTextToClipboard } from "lib/util/Clipboard";
import { downloadText } from "lib/util/DownloadUtil";
import { JsonView } from "lib/components/JsonView";
import { AuthType } from "lib/util/AuthType";

export function ApiDoc(props) {
  let [jsonView, setJsonView] = useState(false);
  return (
    <Panel modelName="lib/api-doc" autoSearch >
      <Label caption="vNext API documentation" look="defaultBold4" noRowBreak />
      <Panel fillRow noRowBreak />
      <Button caption="Download" look="secondary" onClick={(event, context) => downloadDoc(context.data.list)} noRowBreak paddingTop={16} marginRight={16}/>
      <Switch caption="View as" leftLabel="Table" rightLabel="JSON" value={jsonView} onChange={setJsonView}/>
      <Table allowExport={false} expandComponent={ModelDetail} expandProps={{jsonView}}>
        <Column field="endpoint" caption="Endpoint" look="defaultBold3" />
        <Column field="description" caption="Description" width={400}/>
        <Column field="type" caption="Type" />
        <Column sortField="class_name" caption="Source" >
          <SourceLinks />
        </Column>
        <Column field="user_types" caption="User types"/>
      </Table>
    </Panel>
  )
}

function ModelDetail({jsonView}) {
  let [response, setResponse] = useState();
  let responseText;
  if (response != null)
    responseText = JSON.stringify(response, undefined, 2);
  return (
    <Panel>
      <Panel dataMode={DataModes.SEARCH} paddingLeft={16} paddingRight={32} fillRow noRowBreak>
        <TableOrJson isInput={true} jsonView={jsonView} />
        <Panel fillRow noRowBreak />
        <Button caption="Search" look="primary" marginBottom={16} onClick={(event, context, setLoading) => searchClicked(context, setResponse, setLoading)} />
        <JsonView json={responseText} visible={response != null} height={400} />
      </Panel>
      <Panel paddingLeft={32} paddingRight={16} fillRow noRowBreak>
        <TableOrJson isInput={false} jsonView={jsonView} />
      </Panel>
    </Panel>
  );
}

function TableOrJson({jsonView, isInput}) {
  let context = useComponentContext();
  const data = context.getActiveData();
  let field = "output", heading = "Output fields";
  let tryColumn, requiredColumn;
  if (isInput) {
    field = "input";
    heading = "Input parameters";
    requiredColumn = <Column caption="Required" field="required" />;
    tryColumn = (
      <Column caption="Value">
        <TextBox hideFormControl field="search" usePrintableVersion={false} />
      </Column>
    );
  }
  let tableOrText;
  if (jsonView)
    tableOrText = <JsonView json={getJsonText(data[field])} height={300}/>
  else {
    tableOrText = (
      <Table field={field} emptyCaption={"No " + heading.toLowerCase() + " for this endpoint"} headerVisible={false} fillRow >
        <Column caption="Parameter name" field="param" />
        <Column caption="Caption" field="caption" />
        <Column caption="Type" field="type" />
        {requiredColumn}
        {tryColumn}
      </Table>
    );
  }
  return (
    <Panel fillRow >
      <Label caption={heading} look="defaultBold4" fillRow />
      {tableOrText}
    </Panel>
  );
}

function getJsonText(params) {
  const result = JSON.stringify(params, undefined, 2);
  console.log("Result %o", result);
  return result;
}

function searchClicked(context, setResponse, setLoading) {
  const modelInfo = context.getActiveData();
  let params = modelInfo.input;
  let searchValues = {};
  for (let i = 0; i < params.length; i++) {
    const value = params[i].search;
    if (value != null) {
      const field = params[i].param;
      searchValues[field] = value;
    }
  }
  setLoading(true);
  let prefix, method;
  prefix = "api/";
  method = "PATCH";
  callApi(prefix + modelInfo.endpoint, method, searchValues)
    .then(response => {
      setResponse(response);
      setLoading(false);
    })
    .catch(reason => {
      setResponse(reason);
      setLoading(false);
    });
}

function TextDisplay(props) {
  return <TextBox multiline backgroundColor="#DDD" hideFormControl marginBottom={16} usePrintableVersion={false} fillRow {...props} />
}

function downloadDoc(data) {
  let swag = {
    swagger: "2.0",
    host: "tms-baseapp.tmscorp.com:3190",
    basePath: "/api-main-portal",
  };
  swag.info = {
     description: "The vNext Portal API",
     title: "vNext API"
  }
  swag.paths = {};
  for (let i = 0; i < data.length; i++) {
    let pt = data[i].modelData;
    let path = {
      get: {
        summary: pt.summary,
        description: pt.description,
        produces: ["*/*"],
        deprecated: false,
        parameters: {
          in: "body",
          name: "requestBody",
          description: "Parameter format for " + pt.endpoint,
          required: true,
          schema: getSchema(pt.input)
        },
        responses: {
          "200": {
            description: "OK",
            schema: getSchema(pt.output)
          },
          "400": {
            description: "Something went wrong",
          },
          "403": {
            description: "Access is denied",
          },
          "500": {
            description: "Internal server error",
          },
        }
      }
    };
    swag.paths[pt.endpoint] = path;
  }
  const text = JSON.stringify(swag, undefined, 2);
  copyTextToClipboard(text);
  downloadText(text, "api-doc.json");
  showSnackbar("Downloaded API documentation and copied to clipboard.");
}

function getSchema(params) {
  let result = {};
  for (let i = 0; i < params.length; i++) {
    const param = params[i];
    result[param.param] = {
      type: param.type.toLowerCase(),
      description: param.caption,
      required: param.required
    }
  }
  return result;
}

function SourceLinks() {
  const context = useComponentContext();
  const links = context.getActiveData()["source_links"];
  let labels = [];
  addLinks(links, labels, (key) => key.indexOf(".model") < 0);
  addLinks(links, labels, (key) => key.indexOf(".model") >= 0);
  return <Panel>{labels}</Panel>
}

function addLinks(links, labels, shouldAddFunc) {
  for (let key in links)
    if (shouldAddFunc(key))
      labels.push(<Label caption={key} key={key} linkURL={links[key]} openLinkInNewTab />);
}

export function getPageOptions() {
  return { title: "API Test Utility", auth: AuthType.ANY};
}
