import { useState } from "react";
import { makeStyles, getCompanySettings } from "lib/util";
import { ThemeDefaults } from "./ThemeDefaults";

let currentThemeClassNames;
let themeElements;
let branding;

export let setThemeName;

export function ThemeProvider(props) {
  const [themeName, internalSetThemeName] = useState(getDefaultThemeName());
  setThemeName = internalSetThemeName;
  themeElements = getThemeElements(themeName);
  makeThemeStyles();
  return props.children;
}

function getThemeElements(themeName) {
  let themes = getCompanySettings().themes;
  if (themes == null || themes[themeName] == null)
    return ThemeDefaults;
  else
    return themes[themeName]
}

function getDefaultThemeName() {
  return localStorage.getItem("themeName");
}

export function getThemeColor(paletteName, shade = "base") {
  if (paletteName == null)
    return paletteName;
  if (paletteName.length === 0)
    return null;
  const dotPos = paletteName.indexOf(".");
  if (dotPos >= 0) {
    shade = paletteName.substring(dotPos + 1);
    paletteName = paletteName.substring(0, dotPos);
  }
  if (paletteName === "branding" && branding != null)
    return branding[shade];
  const theme = getTheme();
  if (theme != null) {
    const palette = theme.palette[paletteName];
    if (palette != null)
      return palette[shade];
  }
  return paletteName;
}

export function getTheme() {
  return themeElements;
}

export function getThemeClassNames() {
  return currentThemeClassNames;
}

function makeThemeStyles() {
  let styles = {
    background: {
      backgroundColor: themeElements.background
    },
    headerBackground: {
      backgroundColor: themeElements.headerBackground
    }
  };
  for (let colorName in themeElements.palette) {
    let color = themeElements.palette[colorName];
    addShades(themeElements, styles, color, colorName.charAt(0) + colorName.substring(1));
  }
  currentThemeClassNames = makeStyles("", styles);
}

function addShades(themeElements, styles, colorDef, colorName) {
  const shades = ["Base", "Light", "Dark"];
  for (let shadeIndex = 0; shadeIndex < shades.length; shadeIndex++) {
    const color = colorDef[shades[shadeIndex].toLowerCase()];
    let prefix = colorName;
    if (shadeIndex > 0)
      prefix += shades[shadeIndex];
    addColor(themeElements, styles, color, colorDef, prefix);
  }
}

function addColor(themeElements, styles, color, colorDef, prefix) {
  const fontSizes = themeElements.fontSizes;
  for (let size = 0; size < fontSizes.length; size++)
    makeElement(themeElements, styles, color, colorDef, prefix, size, fontSizes[size]);
  makeElement(themeElements, styles, color, colorDef, prefix, "", themeElements.defaultFontSize);
}

function makeElement(themeElements, styles, color, colorDef, prefix, fontIndex, fontSize) {
  fontSize = fontSize + "px";
  styles["button" + prefix + fontIndex] = { fontSize, color, borderColor: color };
  styles["button" + prefix + "NoBorder" + fontIndex] = { fontSize, color, border: "none" };
  styles["button" + prefix + "Filled" + fontIndex] = { fontSize, color: colorDef.reverse, backgroundColor: color, "&:hover": { color } };
  styles["button" + prefix + "FilledHoverDarker" + fontIndex] = { fontSize, color: colorDef.reverse, backgroundColor: color, "&:hover": { color: colorDef.reverse, backgroundColor: colorDef.dark } };
  styles[prefix + fontIndex] = { color, fontSize };
  styles[prefix + "Bold" + fontIndex] = { color, fontSize, fontWeight: themeElements.boldWeight };
}

export function setBranding(value) {
  branding = value;
}
