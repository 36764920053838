import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function Trash(props) {
  return (
    <SVGIcon viewBox="0 0 24 24" {...props} >
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z"></path>
    </SVGIcon>
  );
}

const SVGTrash = React.memo(Trash);
export { SVGTrash };
