import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { routes } from "core/config/routes";
import { AUTH_TYPE_AUTHENTICATED, AUTH_TYPE_GUEST } from "core/store/states/UserState";
import { ProtectedRoute } from "./ProtectedRoute";
import { LoginRoute } from "./LoginRoute";
import { DynamicRouter } from "lib/util/DynamicRouter";

export function Router() {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map(({ page, key, authType, userType, ...otherProps }) => {
          function renderRoute(renderProps) {
            const { Component, props = {} } = page;
            // NOTE: This is done to support some olders components that
            // rely on the router's props to be passed into the component.
            const routerProps = props.passRouterProps ? renderProps : {};
            return <Component {...props} {...routerProps} />;
          }
          if (authType === AUTH_TYPE_GUEST && key === "login") {
            return (
              <LoginRoute
                key={key}
                routeAuthType={authType}
                routeUserType={userType}
                {...otherProps}
                render={renderRoute}
              />
            );
          }
          if (authType === AUTH_TYPE_AUTHENTICATED) {
            return (
              <ProtectedRoute
                key={key}
                routeAuthType={authType}
                routeUserType={userType}
                {...otherProps}
                render={renderRoute}
              />
            );
          }
          return <Route key={key} {...otherProps} render={renderRoute} />;
        })}
        <Route key="something" path="/" render={DynamicRouter} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}


