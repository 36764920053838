import { DisplayTypes, isDisplayTypeNumeric } from "lib/enum/DisplayTypes";
import { getCompanySettings } from "lib/util";
import { formatDate, formatDateTime, formatTime, parseDateWithKeywords, parseTime } from "lib/util/Date";
import { getDataFromContext } from "lib/util/ModelUtil";
import { getDataContextValue } from "../DataContainer";
import { isAutoComplete, isCombo } from "./TextBoxUtil";

export function validateTextBox(context, props, value, event) {
  if (props.items != null) {
    for (let i = 0; i < props.items.length; i++)
      if (props.items[i].caption === value) // might need to support props.items that contain stuff other than "caption" and "value." Add support fot that when we need it
        value = props.items[i].value;
  }
  let values;
  if (props.allowMultipleValues === true)
    values = value.split(",");  // might want a prop to allow different separators, but let's keep it simple until needed
  else
    values = [value];
  let result;
  values.forEach((singleValue) => {
    let thisResult;
    if (props.validateEmptyOnly !== true) {
      thisResult = validateSingleValue(context, props, singleValue, event);
    }
    else {
      thisResult = validateSingleValueEmpty(context, props, singleValue, event);
    }
    if (result == null)
      result = thisResult;
    else {
      result.value = appendValue(result.value, thisResult.value);
      result.contextValue = appendValue(result.contextValue, thisResult.contextValue);
      if (thisResult.error != null)
        result.error = thisResult.error;
    }
    if (result.error)
      return result;
  });
  return result;
}

function appendValue(value1, value2) {
  if (value1 == null)
    return value2;
  return value1 + ", " + value2;
}

function validateSingleValueEmpty(context, props, value, event) {
  if (value != null && value.trim) {
    value = value.trim();
  }
  let result = { value: null, contextValue: null };
  if (value == null || value.length === 0 || (props.includeBlankItem && value === "--")) {
    result = validateEmpty(props.required);
  }
  return result;
}

function validateSingleValue(context, props, value, event) {
  if (value != null && value.trim)
    value = value.trim();
  let result;
  if (value == null || value.length === 0 || (props.includeBlankItem && value === "--"))
    result = validateEmpty(props.required);
  else if (props.onValidate != null)
    result = props.onValidate(context, props, value);
  else if (props.lookupModel != null)
    result = validateLookupModel(context, props, value, event);
  else if (props.displayType != null) {
    if (props.displayType === DisplayTypes.INTEGER)
      result = validateInteger(value);
    else if (isDisplayTypeNumeric(props.displayType))
      result = validateNumeric(value, props.metaData.precision, props.metaData.scale);
    else if (props.displayType === DisplayTypes.DATE || props.displayType === DisplayTypes.DATETIME) {
      result = validateDate(value, props.displayType);
      if (props.displayType === DisplayTypes.DATE && result != null)
        result.hasTime = false;
    }
    else if (props.displayType === DisplayTypes.TIME)
      result = validateTime(context, value, props.field);
    else if (props.displayType === DisplayTypes.EMAIL)
      result = validateEmail(value);
    else if (props.displayType === DisplayTypes.PHONE)
      result = validatePhone(value);
  }
  if (!isCombo(props) && !isAutoComplete(props) && props.maxLength != null && value.length > props.maxLength)
    result = { value: value, contextValue: null, error: "The maximum length allowed is " + props.maxLength + " characters." };
  if (result == null)
    result = { value: value, contextValue: value };
  if (props.items != null) {
    for (let i = 0; i < props.items.length; i++)
      if (props.items[i].value === result.value)
        result.value = props.items[i].caption;
  }
  return result;
}

function validateEmpty(required) {
  if (required)
    return { value: null, contextValue: null, error: "Required" };
  return { value: null, contextValue: null };
}

function validateLookupModel(context, props, value, event) {
  const dataContextValue = getDataContextValue(context, props.field);
  if ((dataContextValue == null || typeof dataContextValue !== "object") && (event === undefined || event.relatedTarget === null || (event.relatedTarget.id.indexOf("dropDownRow") < 0
    && event.relatedTarget.id.indexOf("addAddress") < 0))) {
    let invalidMessage = "Required"
    if (props.displayType === DisplayTypes.CITY)
      invalidMessage = "Please enter a valid city.";
    return { value: value, contextValue: null, error: invalidMessage };
  }
  else
    return { value: value, contextValue: dataContextValue };
}

function validateInteger(value) {
  const intValue = Number(value);
  if (!Number.isInteger(intValue))
    return { value: value, contextValue: null, error: "Please enter a whole number." };
  else
    return { value: value, contextValue: Number(value) };
}

function validateNumeric(value, precision, scale) {
  if (typeof value === "number")
    value = value.toString();
  const intValue = Number(value);
  if (isNaN(intValue)) {
    return { value: value, contextValue: null, error: "Please enter a number." };
  }
  if (precision != null && scale != null) {
    const adjPrecision = precision - scale;
    const regExWholeNumber = "^[-+]?[0-9]{1," + adjPrecision + "}$";
    const regExDecimal = "^[-+]?[0-9]{1," + adjPrecision + "}\\.[0-9]{1" + "," + scale + "}$";
    if (value.match(regExWholeNumber) || value.match(regExDecimal)) {
      return { value: value, contextValue: Number(value) };
    }
    else {
      let formatString = "";
      for (let x = 0; x < adjPrecision; x++) {
        formatString += "#";
      }
      if (scale > 0) {
        formatString += ".";
        for (let x = 0; x < scale; x++) {
          formatString += "#";
        }
      }
      return { value: value, contextValue: null, error: "Hover for Error", rolloverMessage: "Please enter in the format of " + formatString + "." };
    }
  }
  else {
    return { value: value, contextValue: intValue };
  }
}

function validateDate(value, displayType) {
  const parsed = parseDateWithKeywords(value, displayType === DisplayTypes.DATETIME);
  if (parsed == null || isNaN(parsed.getTime()))
    return { value: value, contextValue: null, error: "Please enter a valid date." };
  else if (displayType === DisplayTypes.DATE)
    return { value: formatDate(parsed), contextValue: parsed };
  else
    return { value: formatDateTime(parsed), contextValue: parsed };
}

function validateTime(context, value, field) {
  let origDate = getDataFromContext(context, field);
  const parsed = parseTime(value, origDate);
  if (parsed == null || isNaN(parsed.getTime())) {
    return { value: value, contextValue: null, error: "Please enter a valid time." };
  }
  return { value: formatTime(parsed), contextValue: parsed };
}

function validatePhone(value) {
  const settings = getCompanySettings();
  if (settings.enable_phone_format === false)
    return { value: value, contextValue: value };
  const repl = value.replace(new RegExp("([( )-])", 'g'), "");
  if (isNaN(repl))
    return { value: value, contextValue: null, error: "Phone numbers can only contain digits and formatting characters." };
  if (repl.length === 7)
    return { value: value, contextValue: null, error: "Please enter an area code." };
  if (repl.length === 10) {
    let formatted;
    if (settings.phone_format === "-")
      formatted = repl.substring(0, 3) + "-" + repl.substring(3, 6) + "-" + repl.substring(6);
    else if (settings.phone_format === ".")
      formatted = repl.substring(0, 3) + "." + repl.substring(3, 6) + "." + repl.substring(6);
    else
      formatted = "(" + repl.substring(0, 3) + ") " + repl.substring(3, 6) + "-" + repl.substring(6);
    return { value: formatted, contextValue: formatted };
  }
  else
    return { value: value, contextValue: null, error: "Please enter a ten-digit phone number.  Formatting characters can be included or omitted." };
}

function validateEmail(value) {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/.test(value))
    return { value: value, contextValue: value }
  else
    return { value: value, contextValue: null, error: "Please enter a valid email address." };
}
