import React from "react";
import { Label } from "lib/components";

export function renderHazmat(data, userText) {
  if (data.is_hazmat === "Y")
    return (
      <Label
        caption={data.hazmat_number}
        image={{image:"Danger", color:"red", marginRight: 8, marginTop: -4}}
        highlightText={userText}
        tooltip="This commodity is a hazardous material."
      />
    );
  else
    return null;
}

export function renderCommodity(data, userText) {
  const props = { highlightText: userText, highlightReverse: false, paddingTop: 0, paddingBottom: 0}
  if(data.commodity_id) {
    return (
        <Label caption={data.descr} look="secondary2" {...props} />
    );
  }
  else {
    return (
      <Label caption={data.descr} {...props} />
    );
  }
}
