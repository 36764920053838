import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function Calendar(props) {
  return (
    <SVGIcon viewBox="0 0 14 14" {...props} >
      <path
        d="M9.954 5.459H4.082a.489.489 0 100 .978h5.873a.489.489 0 10-.001-.978zm0 2.648H4.082a.489.489 0 100 .978h5.873a.489.489 0 10-.001-.978zM12.91.875h-1.806V.489a.489.489 0 10-.978 0v.386H3.909V.489a.489.489 0 10-.978 0v.386H1.125C.503.875 0 1.378 0 2v8.874c0 .622.503 1.125 1.125 1.125h11.788c.621 0 1.125-.503 1.125-1.125V2A1.128 1.128 0 0012.91.875zM1.125 2h1.807v.253a.489.489 0 10.979 0V2h6.218v.253a.489.489 0 10.978 0V2h1.807v1.532H1.124V2zm11.786 8.874H1.125V4.506h11.788v6.368h-.002z"
      />
    </SVGIcon>
  );
}

const SVGCalendar = React.memo(Calendar);
export { SVGCalendar };
