import { AuthType } from "lib/util/AuthType";
import React, { useState } from "react";

export function TestSimpleReactRaw() {
  const [caption, setCaption] = useState();
  return (
    <div>
      <div>
        {caption}
      </div>
      <button onClick={() => {
        setCaption("Clicked!");
      }} >
        Click me!
      </button>
    </div>
  )
}

export function getPageOptions() {
  return {
    title: "Simple React Test",
    auth: AuthType.UNAUTH
  };
}
