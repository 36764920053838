import { getLogger } from "lib/util";
import { showWarningSnackbar } from "../Snackbar";

let log;
function getLog() {
  if (log == null)
    log = getLogger("lib.components.Table.TableValidator");
  return log;
}

export function createTableValidator(field, rowProps, unfinishedEditWarning, minRowsRequiredWarning, columnTotals, columnTotalWarnings, autoSave, customValidator) {
  const result = {
    field: field,
    rowProps: rowProps,
    unfinishedEditWarning: unfinishedEditWarning,
    minRowsRequiredWarning: minRowsRequiredWarning,
    prevColumnTotals: {},
    columnTotals: columnTotals,
    columnTotalWarnings: columnTotalWarnings,
    autoSave: autoSave,
    customValidator: customValidator
  };
  result.validateTable = () => validateTable(result);
  return result;
}

export function validateTable(validator) {
  let result = true;
  getLog().debug("Validating table %o", validator)
  if (validator.unfinishedEditWarning != null) {
    result = warnOnUnfinishedEdits(validator.rowProps, validator.unfinishedEditWarning) && result;
  }
  if (validator.minRowsRequiredWarning != null) {
    result = warnOnMinRowCount(validator.rowProps, validator.minRowsRequiredWarning) && result;
  }
  if (validator.columnTotalWarnings != null) {
    result = warnOnColumnTotals(validator, true) && result;
  }
  if (validator.autoSave) {
    result = saveRows(validator.rowProps) && result;
  }
  if (validator.customValidator != null) {
    result = validator.customValidator() && result;
  }
  result = evaluateCustomRowValidators(validator.rowProps) && result;
  return result;
}

function evaluateCustomRowValidators(rowProps) {
  let result = true;
  for (let x = 0; x < rowProps.length; x++) {
    if (rowProps[x].customRowValidator != null) {
      result = rowProps[x].customRowValidator() && result;
    }
  }
  return result;
}

function saveRows(rowProps) {
  let result = true;
  for (let x = 0; x < rowProps.length; x++) {
    if (rowProps[x].editing === true) {
      result = rowProps[x].saveRowWithValidation() && result;
    }
  }
  return result;
}

function warnOnUnfinishedEdits(rowProps, unfinishedEditWarning) {
  for (let x = 0; x < rowProps.length; x++) {
    if (rowProps[x].editing === true) {
      showWarningSnackbar(unfinishedEditWarning, false);
      return false;
    }
  }
  return true;
}

function warnOnMinRowCount(rowProps, minRowsRequiredWarning) {


  if (minRowsRequiredWarning === null || minRowsRequiredWarning === undefined ||
    minRowsRequiredWarning.rowsRequired === null || minRowsRequiredWarning.rowsRequired === undefined || minRowsRequiredWarning.rowsRequired < 1 ||
    minRowsRequiredWarning.message === null || minRowsRequiredWarning.message === undefined) {
    return true;
  }

  let savedRowCount = 0;
  for (let x = 0; x < rowProps.length; x++) {
    if (rowProps[x].editing === false) {
      savedRowCount++;
    }
  }

  if (savedRowCount < minRowsRequiredWarning.rowsRequired) {
    showWarningSnackbar(minRowsRequiredWarning.message, false);
    return false;
  }

  return true;
}

export function setValidatorColumnTotals(validator, updatedColumnTotals) {
  validator.prevColumnTotals = { ...validator.columnTotals };
  validator.columnTotals = updatedColumnTotals;
  getLog().debug("setColumnTotals %o", validator);
}

export function warnOnColumnTotals(validator, skipPreviousValueTest = false) {
  if (validator.columnTotalWarnings === null || validator.columnTotalWarnings === undefined) { return; }

  getLog().debug("Warning on column totals for validator %o", validator);
  let result = true;
  for (let x in validator.columnTotalWarnings) {
    const columnTotalWarning = validator.columnTotalWarnings[x];
    let totalIsValid = true;
    const lastCheckedValue = validator.prevColumnTotals[x];

    getLog().debug("Warning on column totals: last checked value for %o is %o", x, lastCheckedValue);

    if (validator.columnTotals[x] > columnTotalWarning.maxValue) {
      totalIsValid = false;
      result = false;
      if (!skipPreviousValueTest) {
        //only show snackbar if total went from something less than or equal to the threshold to something higher than the threshold
        if (lastCheckedValue <= columnTotalWarning.maxValue) {
          showWarningSnackbar(columnTotalWarning.message, columnTotalWarning.persistSnack);
        }
      }
      else {
        //show regardless of the previous value
        showWarningSnackbar(columnTotalWarning.message, columnTotalWarning.persistSnack);
      }
    }

    if (columnTotalWarning.callbackFunction != null) {
      getLog().debug("Warning on column totals: invoking callback function for %o: %o with props %o", x, columnTotalWarning.callbackFunction, columnTotalWarning.callbackProps);
      columnTotalWarning.callbackFunction(totalIsValid, columnTotalWarning.callbackProps);
    }
  }
  return result;
}
