import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import { useField } from "formik";
import { useFieldMeta } from "core/hooks";
import { format as dateFnsFormat } from "date-fns";
import { SVGCalendar } from "images/SVGCalendar";

const mask = "__/__/____ __:__ _M";
const format = "MM/dd/yyyy hh:mm a";
const placeholder = "MM/DD/YYYY HH:MM";

/**
 * Returns a formatted date based off the default format used by the DateTimePicker
 *
 * @export
 * @param {date} date
 * @returns
 */
export function toDateTimeFormat(date) {
  return dateFnsFormat(date, format);
}

export function DateTimePicker(props) {
  const [field, meta, helpers] = useField(props);
  const [error, helperText] = useFieldMeta(meta);
  const { keyboardIcon } = props;

  function handleChange(_, formattedDate) {
    helpers.setValue(formattedDate);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        {...field}
        {...props}
        format={format}
        placeholder={placeholder}
        mask={mask}
        onChange={handleChange}
        ampm
        showTodayButton
        error={error}
        helperText={helperText}
        variant="dialog"
        inputVariant="outlined"
        keyboardIcon={keyboardIcon || <SVGCalendar />}
        KeyboardButtonProps={{
          disableRipple: true,
          disableFocusRipple: true,
          style: { backgroundColor: "transparent" }
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
