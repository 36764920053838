import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function DollarSignInCircle(props) {
  return (
    <SVGIcon viewBox="0 0 13 13" {...props}>
      <defs>
        <path
          d="M6.692 5.88c-1.115-.29-1.474-.59-1.474-1.056 0-.535.496-.908 1.326-.908.874 0 1.199.417 1.228 1.031h1.086c-.035-.845-.55-1.62-1.577-1.871V2H5.807v1.06c-.952.207-1.719.826-1.719 1.774 0 1.135.939 1.7 2.309 2.028 1.228.295 1.473.727 1.473 1.184 0 .34-.24.88-1.326.88-1.012 0-1.41-.453-1.463-1.032H4c.059 1.076.864 1.68 1.807 1.881v1.066h1.474V9.785C8.239 9.603 9 9.048 9 8.04c0-1.395-1.194-1.871-2.308-2.16z"
          id="prefix__a"
        />
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M6.5 0A6.5 6.5 0 000 6.5 6.5 6.5 0 006.5 13 6.5 6.5 0 0013 6.5 6.5 6.5 0 006.5 0z"
          fill="#E02020"
        />
        <use fill="#FFF" xlinkHref="#prefix__a" />
      </g>
    </SVGIcon>
  );
}

const SVGDollarSignInCircle = React.memo(DollarSignInCircle);
export { SVGDollarSignInCircle };
