import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function Arrow(props) {
  return (
    <SVGIcon viewBox="0 0 12 6" {...props} >
      <path d={"M1 0L6 5L11 0"} />
    </SVGIcon>
  );
}

const SVGArrow = React.memo(Arrow);
export { SVGArrow };
