import React from "react";
import { Form } from "antd";
import { AuthPageContainer } from "pages/containers";
import { ResetPassword } from "portal/general/login/ResetPassword";

export function ResetPasswordPresentation(props) {
  const ResetPasswordForm = Form.create({ name: "normal_reset_password" })(
    ResetPassword
  );
  return (
    <AuthPageContainer pageTitle={props.pageTitle}>
      <ResetPasswordForm {...props} />
    </AuthPageContainer>
  );
}
