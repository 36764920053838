const nonPixelStyles = ["font-weight", "z-index", "tabindex"];

export function makeStyles(prefix, styles) {
  if (prefix == null)
    prefix = "";
  if (prefix.length > 0)
    prefix += "-"
  const result = {};
  for (let key in styles) {
    createClass(prefix + key, styles[key]);
    result[key] = prefix + key;
  }
  return result;
}

export function createClass(className, style) {
  let styleElement = document.createElement("style");
  styleElement.type = "text/css";
  if (!className.startsWith("."))
    className = "." + className;
  if (typeof style === "object")
    styleElement.innerHTML = unnest(className, style);
  else
    styleElement.innerHTML += className + '{ ' + style + ' } \n';
  document.getElementsByTagName('head')[0].appendChild(styleElement);
};

function unnest(className, style) {
  let other = "";;
  const thisStyle = {};
  for (let key in style) {
    if (key.startsWith("&"))
      other += " " + unnest(className + key.substring(1), style[key]);
    else
      thisStyle[key] = style[key];
  }
  return className + " {" + convertToStyleString(thisStyle) + "}" + other;
}

function convertToStyleString(style) {
  let result = "";
  for (let prop in style) {
    const styleName = propNameToCSS(prop);
    let value = style[prop];
    if (typeof value === "number" && isPixelStyle(styleName))
      value = value + "px"
    result += styleName + ":" + value + ";";
  }
  return result;
}

function isPixelStyle(styleName) {
  return nonPixelStyles.indexOf(styleName) < 0;
}

export function propNameToCSS(propName) {
  return propName.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
}

export function setClassIncluded(component, className, included = true) {
  if (included)
    component.element.classList.add(className);
  else
    component.element.classList.remove(className);
}
