import { PropTypes as BasePropTypes } from "prop-types";
import { getLogger } from "lib/util";

const log = getLogger("lib.components.PropTypes.PropTypes")
export const PropTypes = {
  oneOfType: BasePropTypes.oneOfType,
  oneOf: BasePropTypes.oneOf,
  string: BasePropTypes.string,
  number: BasePropTypes.number,
  bool: BasePropTypes.bool,
  func: BasePropTypes.func,
  object: BasePropTypes.object
};

export function getBasicPropTypes(extPropTypes) {
  let result = {};
  for (let key in extPropTypes)
    result[key] = PropTypes.oneOfType([PropTypes.func, extPropTypes[key].type]);
  log.debug("Basic: %o  Ext %o", result, extPropTypes);
  return result;
}

const alignValues = ["right", "center", "left"];
const cursorValues = ["pointer"];

export function borderProp(suffix) {
  let result = {};
  result["border" + suffix + "Width"] = PropTypes.number;
  result["border" + suffix + "Color"] = colorProp;
  result["border" + suffix + "Style"] = PropTypes.oneOf(["solid", "dashed"]);
  result["border" + suffix + "Radius"] = PropTypes.number;
}
const colorValues = ["primary", "secondary"];
const displayTypeValues = ["string", "integer", "money", "email", "link", "date", "datetime", "time"];
export const colorProp = { type: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(colorValues)]), values: colorValues };
export const displayTypeProp = { type: PropTypes.oneOf(displayTypeValues), values: displayTypeValues };

export const ComponentPropTypes = {
  /**
   * This is an identifier for this Component that should be unique within its parent.  It is used when identifying the Component
   * for permissions.
   */
  name: { type: PropTypes.string },
  /**
   * This determines how this component should be horizontally aligned within its parent container.
   */
  align: { type: PropTypes.oneOf(alignValues), values: alignValues },
  /**
   * This defines the background color for this Component.  Any HTML color identifier is allowed as well as
   * a key name from the themes object.
   */
  backgroundColor: colorProp,
  /**
   * This defines the border for all sides of this Component with one property.
   */
  ...borderProp(""),
  /**
   * This defines the border for the bottom of this Component.
   */
  ...borderProp("Bottom"),
  /**
   * This defines the border for the top of this Component.
   */
  ...borderProp("Left"),

  /**
   * This defines the border for the left side of this Component.
   */
  ...borderProp("Right"),
  /**
    * This defines the border for the right side of this Component.
  */
  ...borderProp("Top"),
  /**
   * This defines the color of text inside this Component.
   */
  color: colorProp,

  /**
   * This defines the mouse pointer that is shown when hovering over this Component.
   */
  cursor: { type: PropTypes.oneOf(cursorValues), values: cursorValues },

  /**
   * This defines whether or not this Component accepts normal user input.
   */
  enabled: { type: PropTypes.bool, defaultValue: true },

  /**
   * This defines whether this Component should fill the height of its parent container.
  */
  fillHeight: { type: PropTypes.bool },
  /**
   * This defines whether this Component should fill the width of the current row of its parent container.
  */
  fillRow: { type: PropTypes.bool },
  /**
   * This defines whether the text within the Component should be bold.
  */
  fontBold: { type: PropTypes.bool },
  /**
   * This defines the size of the font of the text in the Component.
  */
  fontSize: { type: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["xsmall", "small", "medium", "large", "xlarge"])]) },
  /**
   * This defines a specific height (if an integer value) or a HTML size specifier (if a string, e.g. "50%") for this Component.
  */
  height: { type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) },

  /**
   * This defines which
   */

  look: { type: PropTypes.oneOf(getLookList) },

  /**
   * This defines the margin, in pixels, for all sides of the Component.
  */
  margin: { type: PropTypes.number },
  /**
   * This defines the margin, in pixels, for the bottom of the Component.
  */
  marginBottom: { type: PropTypes.number },
  /**
   * This defines the margin, in pixels, for the left side of the Component.
  */
  marginLeft: { type: PropTypes.number },
  /**
   * This defines the margin, in pixels, for the right side of the Component.
  */
  marginRight: { type: PropTypes.number },
  /**
   * This defines the margin, in pixels, for the top of the Component.
  */
  marginTop: { type: PropTypes.number },
  /**
   * This defines the minimum height, in pixels, for the Component.
  */
  minHeight: { type: PropTypes.number },
  /**
   * This defines the padding, in pixels, for all sides of the Component.
  */
  padding: { type: PropTypes.number, defaultValue: 4 },
  /**
   * This defines the padding, in pixels, for the bottom of the Component.
  */
  paddingBottom: { type: PropTypes.number },
  /**
   * This defines the padding, in pixels, for the left side of the Component.
  */
  paddingLeft: { type: PropTypes.number },
  /**
   * This defines the padding, in pixels, for the right side of the Component.
  */
  paddingRight: { type: PropTypes.number },
  /**
   * This defines the padding, in pixels, for the top of the Component.
  */
  paddingTop: { type: PropTypes.number },
  /**
   * This defines whether or not we should layout the next component on the next row of the Component's container.
  */
  rowBreak: { type: PropTypes.bool, defaultValue: true },
  /**
   * This defines text or a Component that should be displayed when hovering over the Component for an few seconds.
  */
  tooltip: { type: PropTypes.string },

  /**
   * This defines whether or not the Component should be shown on the screen.
  */
  visible: { type: PropTypes.bool, defaultValue: true },
  /**
   * This defines a specific pixel width (if an integer) or a HTML size specifer (if a string) for the Component
  */
  width: { type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) },
  /**
   * If set, this will cause the Component to fill the current row of its container with the given weight.  For example,
   * if this Component's widthFillWeight was 1 and there was another Component on the same row with a widthFillWeight of 2,
   * this Component would occupy 33% of the width of its container.
  */
  widthFillWeight: { type: PropTypes.number },

  /**
    Indicates whether this component is intended to be rendered as a printable (text-only) version.  This causes text boxes to
    be rendered as text, check boxes to be rendered as "Yes/No", etc.
  */
  usePrintableVersion: { type: PropTypes.bool },
};

function getLookList() {
  return ["primary0", "primary1", "primary2"];
}
