import React from "react";
import { Table, Label, TextBox, Column } from "lib/components";
import { PanelCustomerOrderDetail } from "./PanelCustomerOrderDetail";
import { useComponentContext } from "lib/components/Context";
import { getDataFromContext } from "lib/util/ModelUtil";
import { formatCityStateZip } from "lib/util/CityUtil";
import {getCompanySettings} from "../../lib/util";

export function TableCustomerOrders({ showETAColumn = true, showStatusColumn, description, ...props }) {
  const closeSpacing = { marginTop: -4, paddingTop: 0 };
  const displayBrkStatus = getCompanySettings().display_brk_status;
  let emptyCaption;
  if (description == null)
    emptyCaption = "No orders available for display";
  else
    emptyCaption = "No " + description + " orders available for display";
  return (
    <Table {...props} expandComponent={PanelCustomerOrderDetail} emptyCaption={emptyCaption}
      paginate
      defaultSort={[{ field: "shipper.sched_arrive_early", dataType: "datetime", ascending: false }]} exportName="Order Listing">
      <Column caption="BOL number" field="orders.blnum" minWidth={100} />
      <Column caption="Tracking numbers" sortfield="reference_number" minWidth={160}>
        <TextBox caption="Order number" field="orders.id" look="primary" />
        <TextBox caption="{reference_number_descr}" field="reference_number" nullDisplayValue="N/A" maxWidth={showETAColumn ? null :200} />
        <TextBox caption="Shipper PO number" field="po_number" nullDisplayValue="N/A" visible={isBRLTL} maxWidth={showETAColumn ? null :200} />
      </Column>
      <Column caption="Brokerage Status" field="brokerage_status" visible={displayBrkStatus} hideOnNoData minWidth={140} />
      <Column caption="Status" sortfield="status_descr" visible={showStatusColumn} minWidth={140}>
        <StatusLabel />
      </Column>
      <Column caption="ETA" visible={showETAColumn} sortfield="eta_date_time" sortDataType="datetime" minWidth={180}>
        <TextBox caption="{eta_label}" field="eta_date_time" displayType="date" format="long"/>
        <Label field="eta_date_time" export={false} displayType="time" format="long" style={closeSpacing} />
      </Column>
      <Column caption="Shipper" sortfield="shipper.sched_arrive_early" sortDataType="datetime" minWidth={180}>
        <Label caption={shipperLocation} look="primaryBold" displayLabel="Shipper" />
        <TextBox caption="Pickup" field="shipper.sched_arrive_early" displayLabel="Pickup date" displayType="date" format="long" style={closeSpacing} />
        <Label field="shipper.sched_arrive_early" export={false} displayType="time" format="long" style={closeSpacing} />
      </Column>
      <Column caption="Consignee" sortfield="consignee.sched_arrive_early" sortDataType="datetime" minWidth={180}>
        <Label caption={consigneeLocation} look="primaryBold" displayLabel="Consignee" />
        <TextBox caption="Delivery" field="consignee.sched_arrive_early" displayLabel="Delivery date" displayType="date" format="long" style={closeSpacing} />
        <Label field="consignee.sched_arrive_early" export={false} displayType="time" format="long" style={closeSpacing} />
      </Column>
    </Table>
  );
}

function shipperLocation(context) {
  return formatLocation(context, "shipper");
}

function consigneeLocation(context) {
  return formatLocation(context, "consignee");
}

function formatLocation(context, prefix) {
  const data = getDataFromContext(context);
  return formatCityStateZip(data[prefix + ".city_name"], data[prefix + ".state"], data[prefix + ".zip_code"]);
}

function isBRLTL(context) {
  const data = getDataFromContext(context);
  return data && data.is_br_ltl;
}

function StatusLabel() {
  let context = useComponentContext();
  let status = getDataFromContext(context)["orders.status"];
  let imageName;
  if (status === "D")
    imageName = "CircleCheck";
  else if (status === "P")
    imageName = "CircleFilled";
  else
    imageName = "CircleEmpty";
  return <Label field="status_descr" image={{image: imageName, width:20, height: 20, fill:"#137CDD", stroke: "#FFF", marginRight: 4 }} />
}
