import * as React from "react";

function LtlPackage({fill="#137CDD", ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path
        d="M9.097 0L0 1.544l.533 10.29L6.477 16l9.203-3.388L16 1.965l-3.004-.8-9.46 1.957.043 3.956-1.044-.484-.149-4.082L11.59.66 9.097 0zM7.212 4.942l8.032-1.908-.196 9.026-7.675 2.787-.161-9.905z"
        fillRule="evenodd"
      />
    </svg>
  );
}

const SVGLtlPackage = React.memo(LtlPackage);
export { SVGLtlPackage };