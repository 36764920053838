import { SESSION_STORAGE_USER_TYPE } from "core/utils/constCapsule";
import { BASE_API_URL } from "core/config/urls";
import { getLogger } from "./Logger";
import { isObject } from "./JSUtil";
import { getPageOptionsForPathName } from "./DynamicRouter";
import { AuthType } from "./AuthType";

export const SESSION_STORAGE_LOGIN_TOKEN = "login-token";
export const SESSION_STORAGE_TEMP_TOKEN = "temp-token";
export const LOGIN_PATH = "/login";
export const LOGIN_PATH_INVALID_TOKEN = "/login?invalidToken";
export const RESET_PATH = "/reset";
export const CALLIN_PATH = "/callin";
export const OFFER_PATH = "/offer";

const log = getLogger("lib.util.api");

export async function callApi(endpoint, method = "GET", body) {
  var fullURL = BASE_API_URL + endpoint;

  if (log.isDebugEnabled()) {
    let logMethod = method;
    if (logMethod === "PATCH")
      logMethod = "SEARCH";
    if (body == null)
      log.debug("%s API: %s", logMethod, fullURL)
    else
      log.debug("%s API: %s  Body %o", logMethod, fullURL, body);
  }
  if (isObject(body) || Array.isArray(body))
    body = JSON.stringify(body);
  let token = getAuthToken();
  let authHeader = null;
  if (token == null &&
    isAuthenticatedPage() &&
    window.location.pathname !== LOGIN_PATH &&
    window.location.pathname !== RESET_PATH &&
    window.location.pathname !== OFFER_PATH &&
    window.location.pathname !== CALLIN_PATH) {
    if (window.location.search == null || window.location.search.indexOf("no-login-redirect") < 0)
      window.location.href = LOGIN_PATH;
    return;
  } else if (token != null && window.location.pathname !== LOGIN_PATH)
    authHeader = "Bearer " + token;
  var headers = {
    Accept: "application/json",
    Authorization: authHeader,
    "Content-type": "application/json",
  };
  return fetch(fullURL, { headers: headers, method: method, body: body })
    .catch(reason => handleFetchError(reason))
    .then(response => handleFetchResponse(response))
}

async function handleFetchResponse(response) {
  if (!response.ok) {
    if (response.status === 403 && window.location.pathname !== LOGIN_PATH) {
      clearSessionTokenShowInvalid();
      return;
    }
    let text = await Promise.resolve(response.json());
    if (text.message.startsWith("{"))
      text = JSON.parse(text.message);
    log.debug("Error %o", text);

    return Promise.reject(text);
  }
  if (response.status === 204) {
    return;
  }
  var refreshToken = response.headers.get("refresh-token");
  if (refreshToken)
    sessionStorage.setItem(SESSION_STORAGE_LOGIN_TOKEN, refreshToken);
  if (response.headers.get("content-type") === "application/octet-stream")
    return Promise.resolve(getStream(response.body.getReader()));
  else
    return response.json();
}

function handleFetchError(reason) {
  return Promise.reject([
    "There was a problem connecting to the server.  Please check your network connection and try again.",
    "If the problem persists, the server may not be running or having networking issues."
  ]);
}

function isAuthenticatedPage() {
  const pageOptions = getPageOptionsForPathName(window.location.pathname);
  return pageOptions == null || pageOptions.auth !== AuthType.UNAUTH;
}

async function getStream(reader) {
  return await parseReader(reader);
}

function parseReader(reader) {
  let result = [];
  return new Promise(resolve => {
    reader.read().then(function process({ done, value }) {
      if (done) {
        resolve(result);
        return;
      }
      result = result.concat(Array.from(value));
      return reader.read().then(process);
    });
  });
}

export function clearSessionTokenShowInvalid() {
  sessionStorage.removeItem(SESSION_STORAGE_LOGIN_TOKEN);
  sessionStorage.removeItem(SESSION_STORAGE_TEMP_TOKEN);
  sessionStorage.removeItem(SESSION_STORAGE_USER_TYPE);
  if (window.location.search == null || window.location.search.indexOf("no-login-redirect") < 0)
    window.location.href = LOGIN_PATH_INVALID_TOKEN;
}

export function getAuthToken() {
  let token = sessionStorage.getItem(SESSION_STORAGE_LOGIN_TOKEN);
  if (token == null)
    token = sessionStorage.getItem(SESSION_STORAGE_TEMP_TOKEN);
  return token;
}

export function isAuthenticated() {
  return getAuthToken() != null;
}
