import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function ArrowUp(props) {
  return (
    <SVGIcon height="24" viewBox="5 0 24 24" width="24" >
     <path d={"M0 0h24v24H0z"} fill="none"/><path d={"M7 14l5-5 5 5z"}/>
    </SVGIcon>
  );
}

const SVGArrowUp = React.memo(ArrowUp);
export { SVGArrowUp };
