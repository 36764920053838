import { makeStyles } from "lib/util";

let styles;

export function useTreeStyles() {
  if (styles == null)
    styles = makeStyles("tree", {
      tree: {
        height: "100%",
        overflow: "auto"
      }
    });
  return styles;
}

