import * as React from "react";

function Dropdown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
      <path d="M10.5 15l7.5 7.5 7.5-7.5z" />
      <path d="M0 0h36v36H0z" fill="none" />
    </svg>
  );
}

const SVGDropdown = React.memo(Dropdown);
export { SVGDropdown };