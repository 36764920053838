import { makeStyles } from "lib/util";

let styles;

export function useButtonGroupStyles() {
  if (styles == null)
    styles = makeStyles("bgrp", {
      buttonContainer: {
        display: "flex",
        marginTop: "18px"
      },
      button: {
        padding: 0,
        maxHeight: 40,
        minWidth: 60,
        "&:not(:first-of-type)": {
          "& .MuiButton-root": {
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            marginLeft: "-1px"
          }
        },
        "&:not(:last-of-type)": {
          "& .MuiButton-root": {
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px"
          }
        }
      }
    });
  return styles;
}
