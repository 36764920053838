import React from "react";
import { Table, TableColumn } from "common";
import {
  OrderNumberTableCell,
  ExchangeeDetailsTableCell,
  CommodityDetailsTableCell
} from "common/TableCells";
import { connect } from "react-redux";
import { getActiveOrdersAndTypeByCategory } from "core/store/selectors/OrdersSelectors";
import { carrierFilterKeys } from "core/config/filterKeys";
import { ORDERS_CATEGORY_OFFER_HISTORY, ORDERS_TYPE_OFFERED } from "core/utils/constCapsule";
import { useMountEffect } from "lib/util";
import { fetchOrders } from "core/store/actions/OrdersActions";
import { OfferHistoryStatusTableCell } from "./OfferHistoryStatusTableCell";
import { OfferHistoryDetailsTableCell } from "./OfferHistoryDetailsTableCell";

export function OfferHistoryOrdersTablePresentation({ isFetching, orders, dispatchFetchOrders }) {
  useMountEffect(() => {
    dispatchFetchOrders(ORDERS_CATEGORY_OFFER_HISTORY, ORDERS_TYPE_OFFERED);
  });

  return (
    <Table
      loading={!orders && isFetching}
      data={orders}
      defaultSortOrder="desc"
      defaultSortKey="delivery.milli"
      stickyHeader
      emptyText="No Loads Available for Display"
      filterKeys={carrierFilterKeys}
    >
      <TableColumn
        label="Load Number"
        sortKey="id"
        minWidth={70}
        Component={<OrderNumberTableCell />}
      />
      <TableColumn
        label="Pickup"
        sortKey="pickup.milli"
        minWidth={140}
        Component={<ExchangeeDetailsTableCell dataKey="pickup" label="Date/Time" />}
      />
      <TableColumn
        label="Delivery"
        sortKey="delivery.milli"
        minWidth={140}
        Component={<ExchangeeDetailsTableCell dataKey="delivery" label="Date/Time" />}
      />
      <TableColumn
        label="Commodity"
        sortKey="commodity.name"
        minWidth={170}
        Component={<CommodityDetailsTableCell showLength={false} label="Equipment Required" />}
      />
      <TableColumn
        label="Status"
        minWidth={300}
        Component={<OfferHistoryStatusTableCell label="Status" />}
      />
      <TableColumn
        label="Extra Details"
        minWidth={300}
        Component={<OfferHistoryDetailsTableCell label="Extra Details" />}
      />
    </Table>
  );
}

const mapStateToProps = (state) => ({
  ...getActiveOrdersAndTypeByCategory(state, ORDERS_CATEGORY_OFFER_HISTORY)
});

const mapDispatchToProps = {
  dispatchFetchOrders: fetchOrders
};

export const OfferHistoryOrdersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferHistoryOrdersTablePresentation);
