import React, { useState } from "react";
import { Panel, TextBox, Switch } from "lib/components";
import { getCompanySettings } from "lib/util";

export function Records () {
  // context.data comes back as null since wizard tabset doesn't mount component
  // so this always returns true for now.  Skip null checks once we fix this.
//  let context = useComponentContext();
  // console.log("Context %o", context);
  //const showFelony = context == null || context.data == null || context.metadata.convict_felony != null;
  const showFelony = !getCompanySettings().suppress_felony;
  const [ felony, setFelony ] = useState(false);
  return (
    <Panel caption="Records"  >
      <Switch field="convict_felony" leftLabel="No" rightLabel="Yes" onChange={setFelony} visible={showFelony}/>
      <TextBox field="convict_felony_d" width={600} multiline disabled={!felony} visible={showFelony}/>
      <Switch field="can_travel_us" leftLabel="No" rightLabel="Yes"/>
      <TextBox field="num_tickets" />
      <TextBox field="ticket_descr" multiline width={600}/>
      <TextBox field="num_accidents" />
      <TextBox field="accident_descr" multiline width={600}/>
    </Panel>
  );
}
