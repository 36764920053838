import { getLogger } from "./Logger";

export const modules = {};
modules["lme"] = require.context("lme", true);
modules["lib"] = require.context("lib", true);
//modules["js"] = require.context("js", true);
modules["portal"] = require.context("portal", true);

const log = getLogger("lib.util.DynamicLoader");

/**
 * This accepts a function identifier in the format rootName.path.path.module or
 * rootName.path.path.module.function and parses it into the root, the path to the .jsx file,
 * and the function name.
 *
 * @param {*} id
 * @param {boolean} implicitFunctionName Whether or not the identifier contains a function name
 * or if it should use the module name as the function name.
 */
export function parseFunctionIdentifier(id, implicitFunctionName) {
  if (implicitFunctionName === true) {
    const path = id.replace(/\./g, "/");
    const slashPos = path.lastIndexOf("/");
    if (slashPos >= 0)
      id += "." + path.substring(slashPos + 1);
  }

  const index = id.indexOf(".");
  if (index < 0) {
    log.error("Invalid function identifier.  No dot was found. [%s]", id);
    return null;
  }
  const root = id.substring(0, index);
  let path = id.substring(index).replace(/\./g, "/");
  const slashPos = path.lastIndexOf("/");
  if (slashPos < 0) {
    log.error("Invaild function identifier.  No slash was found. [%s]", id);
    return null;
  }
  const functionName = path.substring(slashPos + 1);
  const fileName = "." + path.substring(0, slashPos) + ".jsx";
  return { root, fileName, functionName };
}

export function getModuleForIdentifier(id, implicitFunctionName) {
  const functionId = parseFunctionIdentifier(id, implicitFunctionName);
  if (functionId == null)
    return null;
  return getModuleForParsedIdentifier(functionId);
}

export function getFunctionForIdentifier(id, implicitFunctionName) {
  const functionId = parseFunctionIdentifier(id, implicitFunctionName);
  if (functionId == null)
    return null;
  const module = getModuleForParsedIdentifier(functionId, implicitFunctionName);
  if (module == null)
    return null;
  const result = module[functionId.functionName];
  if (result == null) {
    log.error("Function %s was not found in module %s.  Identifier [%s]", functionId.functionName, functionId.fileName, id);
    return null;
  }
  return result;
}

export function getModuleForParsedIdentifier(functionId) {
  if (functionId == null)
    return null;
  const root = modules[functionId.root];
  if (root == null) {
    log.error("Root element %s not found for function id %o", root, functionId);
    return null;
  }
  try {
    let result = root(functionId.fileName);
    log.debug("Component for %o is %o  Function name: %s", functionId, result, functionId.functionName);
    return result;
  }
  catch (err) {
    try {
      const jsName = functionId.fileName.replace(".jsx", ".js");
      return root(jsName);
    }
    catch (err2) {
      log.error("Could not find module %s.", functionId.fileName);
      log.debug("Module contents %o", root.keys());
      log.debug(err);
    }
  }
}
