import React, { useState, useEffect } from "react";
import {Table, Label, CheckBox, Panel, TextBox, Row, DisplayTypes, showDialog} from "lib/components";
import { getDataFromContext } from "lib/util/ModelUtil";
import { formatCityStateZip } from "lib/util/CityUtil";
import { setDataContextValue } from "lib/components/DataContainer";
import {getCompanySettings, getUserSettings} from "lib/util";
import { formatDateTime, getDateFormat, parseDateTime } from "lib/util/Date";
import { showWarningSnackbar } from "lib/components/Snackbar";
import { StopStatusPanel } from "./StopStatusPanel";
import { parseTime } from "../../lib/util/Date";
import { showPickupMinutesSnackbar, showPickupWindowSnackbar } from "portal/customer/StopSnackbars";
import { useComponentContext } from "../../lib/components";
import { callApi } from "../../lib/util/api";
import { getMainPageContext } from "../../lib/util/MainPageContext";
import { EmailLabel } from "../../common/EmailLabel";
import { showError } from "../../lib/components/Error";

export function QuoteStopDetailTable(props) {
	const currentStep = props.currentStep;
	const [isPickupMinutesValid, setPickupMinutesValid] = useState();
	const context = useComponentContext();
	const assetLtlQuote = props.parentType === "asset-ltl-quote";
	const parentType = props.parentType;
	useEffect(() => {
    let stops = getDataFromContext(context, "stops");
    if(props.initialData && stops === undefined){   //if from quote list, get data from initialData
      stops = props.initialData[0].stops;
    }

    let quoteId = getDataFromContext(context, "id");
    if (props.initialData && quoteId === undefined) {
      quoteId = props.initialData[0].id
    }

    if(stops)
    {
      if(currentStep !== 0 && assetLtlQuote){
        validatePickupMinutes(stops[0], setPickupMinutesValid);
        validateShipperLocation(stops, quoteId);
      } else if (currentStep === 2) {
        validateShipperLocation(stops, quoteId);
      }
    }
	}, [currentStep]);


	if(currentStep !=null && (currentStep === 0 || (parentType === "ltl-quote" && currentStep === 1))){
        return null;
    }
	return (
		<Panel rowBreak>
			<Table {...props}
				columnHeadingsVisible={false}
				headerVisible={false}
				showLinesBetweenRows={false}
				cellVerticalAlign="top"
				toolsInPanel
        		border={false}
        		rowBorder={false}
        		rowMarginBottom={"8px"}
				rowEditor={(props, rowData) => PanelStopDetails(props, rowData, currentStep, setPickupMinutesValid, parentType)}
				autoSave
				showDelete={() => {return false}}
        		customValidator={ (context, rowProps) => validateTable(context, rowProps, props.parentType, props != null ? props.addlValidator : null, isPickupMinutesValid, currentStep)}
				componentPropsCallback = {componentProps}
			/>
		</Panel>
	);
}

function componentProps(context, _, origComponentProps) {
	let result = {...origComponentProps};
	if (context && context.modelData) {
		let locationAddressRequired = false;
		if (context.modelData.stop_type === "PU") {
			locationAddressRequired = true;
		}
		result.location_name = {required: locationAddressRequired};
		result.address = {required: locationAddressRequired};
	}
	return result;
}

function format(inputDate) // auxiliary function to format Date object
{
    var hours = inputDate.getHours();
    var minutes = inputDate.getMinutes();
    //var ampm = hours < 12? "AM" : (hours=hours%12,"PM");
	//hours = hours == 0? 12 : hours < 10? ("0" + hours) : hours;
	hours = hours < 10? ("0" + hours) : hours;
    minutes = minutes < 10 ? ("0" + minutes) : minutes;
    return hours + ":" + minutes; // + " " + ampm;
}

function PanelStopDetails(props, rowData, currentStep, setPickupMinutesValid, parentType){
	const stopType = rowData.modelData["stop_type"];
	let pickupDeliveryTimes = null;
	const enforceShipperID = getCompanySettings().enforce_shipper_id;
	const [saveAddressVisible, setSaveAddressVisible] = useState(false);
	if ("PU" === stopType) {
		// create a drop down with early and late time
		const early = rowData.modelData["sched_arrive_early"];
		let earlyValue= early;
		if(typeof early ==="object"){
            earlyValue = earlyValue.value;
		}
		const earlyArrivalDate = new Date(earlyValue);
		var timeItems = [];
		var start = new Date(getCompanySettings().earliest_pickup);
		var end = new Date(getCompanySettings().ord_latest_pickup_time);
		if (isNaN(start.getTime())){
			start = new Date();
			start.setHours(8,0,0,0);
		}
		else{
			const temp = start;
			start = new Date();
			start.setHours(temp.getHours(), temp.getMinutes(), 0, 0);
		}
		if(isNaN(end.getTime())){
			end = new Date();
			end.setHours(17,0,0,0);
		}
		else{
			const temp = end;
			end = new Date();
			end.setHours(temp.getHours(), temp.getMinutes(), 0, 0);
		}
		for (var d = start; d <= end; d.setMinutes(d.getMinutes() + 30)) {
			let value = format(d);
			timeItems.push({ caption: value, value: value });
		}
		pickupDeliveryTimes =
			<Panel fillRow noRowBreak>
				<Label caption={formatPickupDate} variant="label" fontBold displayType="date" format="long" look="secondaryBold3" />
				<Row>
					<TextBox caption="Early Pickup" field="sched_arrive_early_time" includeBlankItem={false} displayType="time" items={timeItems} width={125}
						onItemChanged={(context, items, index) => timeChangeValidation(context, items, index)}
						onDropDownInvisible={(context) => { validatePickupMinutesFromContext(context, setPickupMinutesValid); validatePickupWindowFromContext(context);}}/>
					<TextBox caption="Late Pickup" field="sched_arrive_late_time" displayType="time" items={timeItems} width={125}
						onItemChanged={(context, items, index) => timeChangeValidation(context, items, index)}
            			onDropDownInvisible={(context) => validatePickupWindowFromContext(context)} />
				</Row>
				<CheckBox name="checkAppointmentRequired" caption="Appointment Required" field="appt_required" marginTop={8}/>
			</Panel>
	}
	else {
		let marginTop = 120;
		let deliveryDate;
		const serviceDate = rowData.modelData["service_date"];
		if(getCompanySettings().is_asset_ltl && serviceDate) {
			marginTop = 90;
			deliveryDate =<Label field="service_date" fontBold displayType="date" format="long" look="secondaryBold3" rowBreak/>;
		}
		pickupDeliveryTimes =
			<Panel fillRow noRowBreak>
				{deliveryDate}
        		<CheckBox name="checkAppointmentRequired" caption="Appointment Required" field="appt_required" marginTop={marginTop} marginRight={50}/>
			</Panel>
	}
	return(
		<Panel fillRow>
      <StopStatusPanel noRowBreak />
      <Panel borderType="card" fillRow noRowBreak>
        <Panel fillRow noRowBreak>
          <Label field="stop_type_descr" usePrintableVersion look="secondaryBold3" rowBreak/>
          <TextBox
            field="location_name"
			lookupModel={getLookupModel(stopType, enforceShipperID)}
            onBlur={(context, props, event)=>handleLocationBlur(context, props, event, setSaveAddressVisible, enforceShipperID, parentType)}
            onItemChanged={locationItemChanged}
            required={"PU" === stopType && ((getCompanySettings().is_asset_ltl && currentStep ===1) || (getCompanySettings().is_brokerage_ltl && currentStep ===2))}
			width={485}/>
          <TextBox
		  		field="address"
		  		required={"PU" === stopType && ((getCompanySettings().is_asset_ltl && currentStep ===1) || (getCompanySettings().is_brokerage_ltl && currentStep ===2))}
				onBlur={(context, props, event)=> handleAddressBlur(context, props, event, setSaveAddressVisible, enforceShipperID)}
				onChange={(value, context, props) => handleAddressChange(value, context, props, setSaveAddressVisible, enforceShipperID)}
		  />
          <Label caption="City, State, ZipCode" look="subtle1"/>
          <Label caption={formatCity} paddingTop={0} />
          <CheckBox caption="Save to address book" field="save_address" visible={saveAddressVisible}/>
        </Panel>
        {pickupDeliveryTimes}
        <Panel fillRow>
          <Label caption={null} nullDisplayValue="blankSpace" look="secondaryBold3" rowBreak/>
          <TextBox caption="Contact Name" field="contact_name"/>
          <TextBox caption="Contact Phone" field="phone"/>
        </Panel>
      </Panel>
    </Panel>
	);
}

function formatCity(context) {
	let data = getDataFromContext(context);
	return formatCityStateZip(data["city_name"], data["state"], data["zip_code"]);
}

function formatPickupDate(context) {
	let data = getDataFromContext(context);
	const pickupDate = data["sched_arrive_early"];
	if(typeof pickupDate === "string")
		return formatDateTime(parseDateTime(pickupDate), getDateFormat(DisplayTypes.DATE, "long"));
	else if(typeof pickupDate ==="object")
		return formatDateTime(parseDateTime(pickupDate.value), getDateFormat(DisplayTypes.DATE, "long"));
	else
		return "";
}

function handleLocationBlur(context, props, event, setSaveAddressVisible, enforceShipperID, parentType){
	const data = getDataFromContext(context);
	if(data != null && data.location_id != null){
		const payload = {id: data.location_id};
		let stopDateEarly = data.sched_arrive_early;
		if(typeof stopDateEarly ==="object" && stopDateEarly.value){
			stopDateEarly = stopDateEarly.value;
		}
		stopDateEarly = new Date(stopDateEarly);
		payload.day_of_week = stopDateEarly.getDay() + "";
		if (parentType === "ltl-order" || parentType === "brokerage-ltl-quote" || parentType === "asset-ltl-quote") {
		  payload.ltl = true;
		}
		callApi("api/dispatch/location-auto-fill", "PATCH", payload).then((response) => {
			const rowData = response.data[0];
			setDataContextValue(context, "appt_required", rowData.appt_required === "Y");
			setDataContextValue(context, "contact_name", rowData.name);
			setDataContextValue(context, "phone", rowData.phone);
			if (rowData.open_time != null) {
				stopDateEarly = parseTime(rowData.open_time, stopDateEarly);
				setDataContextValue(context, "sched_arrive_early", stopDateEarly.toString());
				setDataContextValue(context, "sched_arrive_early_time", rowData.open_time, true);
			  }
			if (rowData.close_time != null) {
				let stopDateLate = data.sched_arrive_late;
				if(typeof stopDateLate ==="object" && stopDateLate.value){
					stopDateLate = stopDateLate.value;
				}
				stopDateLate = parseTime(rowData.close_time, stopDateLate);
				setDataContextValue(context, "sched_arrive_late", stopDateLate.toString());
				setDataContextValue(context, "sched_arrive_late_time", rowData.close_time, true);
			}

		  }).catch(reason => {
			setDataContextValue(context, "appt_required", false);
			setDataContextValue(context, "contact_name", null);
			setDataContextValue(context, "phone", null);
		  });
	}
	if(event.target.value != null && event.target.value !== "")
		context.setValue(props.field, event.target.value);
	if("PU" === data.stopType && enforceShipperID === true){
		setSaveAddressVisible(false);
	}
    if((event.target.value != null && event.target.value !== "") && data.address != null && (data.location_id == null || data.location_id === ""))
        setSaveAddressVisible(true);
    else{
        setSaveAddressVisible(false);
        if(data.save_address === true){
			setDataContextValue(context, "save_address", false);
		}
	}

	if(data.stop_type === "SO"){
		const parentData = getDataFromContext(context.data.parentContext);
		let consignee = parentData["consignee"]; //is there a better way????
		if(enforceShipperID === true && data.location_id){
			if(typeof data.location_name === "object")
			    consignee["name"] = data.location_name.name;
			else
			    consignee["name"] = data.location_name
			consignee["address1"] = data.address;
			consignee["id"] = data.location_id;
		}
		else{
			consignee["name"] = event.target.value;
		}
		setDataContextValue(parentData, "consignee", consignee, true);
	}
	else if(data.stop_type === "PU"){
		const parentData = getDataFromContext(context.data.parentContext);
		let shipper = parentData["shipper"];
		if(enforceShipperID === true && data.location_id){
			if(typeof data.location_name === "object")
				shipper["name"] = data.location_name.name;
			else
				shipper["name"] = data.location_name
			shipper["address1"] = data.address;
			shipper["id"] = data.location_id;
		}
		else{
			shipper["name"] = event.target.value;
		}
		setDataContextValue(parentData, "shipper", shipper, true);
	}
}

function handleAddressBlur(context, props, event, setSaveAddressVisible, enforceShipperID){
    const data = getDataFromContext(context);
	if(data.stop_type === "SO"){
		const parentData = getDataFromContext(context.data.parentContext);
		let consignee = parentData["consignee"];
		if(enforceShipperID === true && data.location_id){
			consignee["name"] = data.location_name;
			consignee["address1"] = data.address;
			consignee["id"] = data.location_id;
		}
		else{
			consignee["address1"] = event.target.value;
		}
		setDataContextValue(parentData, "consignee", consignee, true);
	}
	else if(data.stop_type === "PU"){
		const parentData = getDataFromContext(context.data.parentContext);
		let shipper = parentData["shipper"];
		if(enforceShipperID === true && data.location_id){
			shipper["name"] = data.location_name;
			shipper["address1"] = data.address;
			shipper["id"] = data.location_id;
		}
		else{
			shipper["address1"] = event.target.value;
		}
		setDataContextValue(parentData, "shipper", shipper, true);
	}
}

function handleAddressChange(value, context, props, setSaveAddressVisible, enforceShipperID){
	const data = getDataFromContext(context);
	if("PU" === data.stopType && enforceShipperID === true){
		setSaveAddressVisible(false);
	}
    else if(data.location_name != null && (value != null && value !== "") && (data.location_id == null || data.location_id === ""))
        setSaveAddressVisible(true);
    else{
		setSaveAddressVisible(false);
		if(data.save_address === true){
			setDataContextValue(context, "save_address", false);
		}
	}
}

export function timeChangeValidation(context, items, index) {
	const earlyTime = getDataFromContext(context, "sched_arrive_early_time");
	const lateTime = getDataFromContext(context, "sched_arrive_late_time");
	let early = getDataFromContext(context, "sched_arrive_early");
	let late = getDataFromContext(context, "sched_arrive_late");
	early = parseTime(earlyTime, early);
	late = parseTime(lateTime, late);
	setDataContextValue(context,"sched_arrive_early", early.toString());
	setDataContextValue(context,"sched_arrive_late", late.toString());
	if(early && late ){
		let earlyDate, lateDate;
		if(typeof early === "object" && early.value)
			earlyDate = new Date(early.value);
		else
			earlyDate = new Date(early);
		if(typeof late === "object" && late.value)
			lateDate = new Date(late.value);
		else
			lateDate = new Date(late);
		if (earlyDate.getTime() > lateDate.getTime()){
			showWarningSnackbar("Early pickup time is later than late pickup time.", false);
			return false;
		}
	}
	return true;
}

function locationItemChanged(context, items, index) {
	const item = items[index];
	const address = item["address1"];
	const locationId = item["id"];
	const name = item["name"];
	if(address){
		context.setValue("address", address);
		setDataContextValue(context, "address", address);
	}
	if(locationId){
		setDataContextValue(context, "location_id", locationId);
	}
	if(name){
		setDataContextValue(context, "location_name", name);
	}
}

function validateTable(context, rowProps, parentType, addlTableValidator, isPickupMinutesValid, currentStep)
{
  let result = true;
  if (parentType !== "asset-ltl-quote" || currentStep === 0) {
    return result;
  }
  const stops = context.data.list;
  if (stops != null)
  {
    if(!isPickupMinutesValid){
      showPickupMinutesSnackbar(null,null,null,true);
      result = false;
    }
    result = validatePickupWindow(stops[0]) && result;
  }

  if (addlTableValidator != null)
  {
    result = result && addlTableValidator(context, rowProps);
  }
  return result;
}

function validatePickupMinutesFromContext(context, setPickupMinutesValid)
{
  if (context.dataIndex !== 0) {
    return;
  }
  let data = getDataFromContext(context);
  return validatePickupMinutes(data, setPickupMinutesValid);
}

function validatePickupMinutes(pickupStop, setPickupMinutesValid) {
  if (pickupStop == null) {
    return true;
  }
  const earlyDateTime = getDateTime(pickupStop, "sched_arrive_early");
  const stopData = pickupStop.modelData != null ? pickupStop.modelData : pickupStop;
  const cityId = stopData && stopData["city_id"];
  return showPickupMinutesSnackbar(earlyDateTime, cityId, setPickupMinutesValid);
}

function validatePickupWindowFromContext(context)
{
  if (context.dataIndex !== 0) {
    return;
  }
  let data = getDataFromContext(context);
  return validatePickupWindow(data);
}

function validatePickupWindow(pickupStop) {
  if (pickupStop == null) {
    return true;
  }
  const earlyDateTime = getDateTime(pickupStop, "sched_arrive_early");
  const lateDateTime = getDateTime(pickupStop, "sched_arrive_late");
  return showPickupWindowSnackbar(earlyDateTime, lateDateTime);
}

function getDateTime(stop, field)
{
  if (stop === null || stop === undefined) {
    return null;
  }
  const stopData = stop.modelData != null ? stop.modelData : stop;

  let dateTime = stopData[field];
  if (dateTime === null || dateTime === undefined) {
    return null;
  }
  if (dateTime instanceof Date) {
    return dateTime;
  }
  if (dateTime.value != null) {
    dateTime = dateTime.value;
  }
  return new Date(dateTime);
}

function getLookupModel(stopType, enforceShipperID){

	let modelName;
	if("PU" === stopType && enforceShipperID === true){
		modelName = "dispatch/location-zip-suggestion-combo";
	}
	// else if("SO" === stopType && enforceShipperID === true){
	// 	modelName = "dispatch/location-zip-suggestion";
	// }

	return modelName;
}

export function validateShipperLocation(stops, quoteId) {
  const enforceShipperID = getCompanySettings().enforce_shipper_id;
  if (enforceShipperID === true) {
    if (stops && stops[0].location_id) {
      return true;
    }
    const shipperZipCode = stops[0].zip_code;
    if (shipperZipCode) {
      callApi("api/portal/customer/shipper-location-validator", "POST", {zip_code: shipperZipCode}).then((isValidResponse) => {
        if (!isValidResponse.data[0].is_valid) {
          showInvalidShipperSnackbar(quoteId);
        }
      }).catch(reason => {
        showError("An error occurred while validating the selected shipper location", reason);
      });
    } else {
      showInvalidShipperSnackbar(quoteId);
    }
  }
}

export function showInvalidShipperSnackbar(quoteId) {
  const customerSettings = getMainPageContext().customer_settings;
  const companySettings = getCompanySettings();
  const assistanceContact = !companySettings.assistance_contact ? null
    : companySettings.assistance_contact === "S" ? customerSettings.salesperson : customerSettings.operations_user;

  const contactName = assistanceContact ? assistanceContact.name : (companySettings.order_contact_name || "us");
  const contactPhone = assistanceContact ? assistanceContact.phone : companySettings.order_contact_phone;
  const contactEmail = assistanceContact ? assistanceContact.email : companySettings.order_email_list;
  const subject = `${getUserSettings().user_name} with ${customerSettings.id} - ${customerSettings.name} needs help converting quote #${quoteId} to an order`
  let message = `Please contact ${contactName}`;
  if (contactPhone && contactEmail)
    message += ` at ${contactPhone} or {e}`;
  else if (contactPhone)
    message += ` at ${contactPhone}`;
  else if (contactEmail)
    message += ` at {e}`;

  message += ` to have this shipper added to your account and to get assistance with completing this order.`;

  // const invalidShipperDialog = <Dialog title={"Invalid Shipper" onClose}
  showWarningSnackbar(<EmailLabel text={message} email={contactEmail} subject={subject} linkStyle={{color:'#535d61', fontWeight:'bold'}} />, true);
}
