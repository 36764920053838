import React from "react";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import { createTheme } from "core/config/theme";
import { Router } from "core";
import { useMountEffect } from "core/hooks";
import { appendAxiosInterceptors } from "lib/util/fetch";
import { SnackbarProvider } from "notistack";
import { getReady } from "core/store/selectors/AppSelectors";
import { connect } from "react-redux";
import { startApp } from "core/store/actions/AppActions";
import { DialogPlaceholder } from "lib/components/Dialog";
import { Snackbar } from "lib/components/Snackbar";
import { Popover } from "lib/components/PopoupContainer";
import { Slideout } from "lib/components/Slideout";
import { jss } from "./config/jss";
import { useStyles } from "./Notifier.styles";
import { getBranding } from "./store/selectors/UserSelectors";
import { ThemeProvider } from "lib/util/Theme";
import { UnauthenticatedSettingsProvider } from "lib/util/MainPageContext";
import { setHistory } from "lib/util/Navigation";

function AppPresentation({ history, isReady, dispatchStartApp, branding }) {
  const classes = useStyles();
  useMountEffect(() => {
    dispatchStartApp();
    appendAxiosInterceptors();
  });
  setHistory(history);
  return (
    <>
      <CssBaseline />
      <UnauthenticatedSettingsProvider>
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={createTheme({ branding })}>
          <ThemeProvider>
            <Popover />
            <SnackbarProvider
              maxSnack={5}
              classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info
              }}
              autoHideDuration={4000}
            >
                {!isReady ? "" : <Router history={history} />}
                <DialogPlaceholder />
                <Slideout />
                <Snackbar />
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
      </UnauthenticatedSettingsProvider>
    </>
  );
}

const mapStateToProps = (state) => ({
  isReady: getReady(state),
  branding: getBranding(state)
});

const mapDispatchToProps = {
  dispatchStartApp: startApp
};

export const App = connect(mapStateToProps, mapDispatchToProps)(AppPresentation);
