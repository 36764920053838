import * as React from "react";

function LtlPalletQuote({...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={168}
      height={40}
      viewBox="0 0 168 40"
      {...props}
    >
      <text
        fontSize={0}
        style={{
          whiteSpace: "pre",
        }}
      >
        <tspan
          x={54}
          y={29}
          fontFamily="Roboto"
          fontSize={28}
          fontWeight={900}
          style={{
            whiteSpace: "pre",
            textTransform: "none",
          }}
        >
          {"LTL"}
        </tspan>
      </text>
      <text
        fontSize={0}
        style={{
          whiteSpace: "pre",
        }}
      >
        <tspan
          x={104}
          y={28}
          fontFamily="Roboto"
          fontSize={24}
          fontWeight={400}
          style={{
            whiteSpace: "pre",
            textTransform: "none",
          }}
        >
          {"Quote"}
        </tspan>
      </text>
      <g fillRule="evenodd">
        <path d="M10 2.799L25.92 0l4.362 1.197-16.106 3.356.26 7.4 1.828.876-.075-7.17 16.554-3.547L38 3.562l-.56 19.298L21.335 29l-10.402-7.552L10 2.798zm26.677 2.7L22.62 8.957l.282 17.953 13.431-5.052.343-16.36zM8.88 17L6 18.307 9 21l-.12-4z" />
        <path d="M0 20.325l.338 3.817L20.538 40l4.32-2.025v-2.412l7.809-3.542-.068 2.397 3.842-1.79.07-2.34 6.922-3.128-.128 2.262 3.387-1.555.308-3.971-6.798-3.547-.105 2.975 3.52 2.089-1.705.731-2.994-1.694-3.539 1.367 2.886 1.9-1.706.795-3.055-2.036-4.084 1.67 3.273 2.084-1.79.81-3.408-2.195-4.337 1.515 3.638 2.513-1.904.852L3.968 19 0 20.325zm1.986 3.624l-.19-2.272 5.818 4.351.133 2.368-5.76-4.447zm8.926 4.563l.151 2.503 6.898 5.295v-2.6l-7.05-5.198z" />
      </g>
    </svg>
  );
}

const SVGLtlPalletQuote = React.memo(LtlPalletQuote);
export { SVGLtlPalletQuote };
