import * as React from "react";

function XWithCircle({fill="#ff0063", ...props}) {
  return (
    <svg width="1em" height="1em" {...props}>
      <path
        d="M9.1 3.9a.459.459 0 00-.65 0L6.5 5.85 4.54 3.9a.459.459 0 10-.65.65L5.86 6.5 3.9 8.47c-.18.18-.18.47 0 .65.1.1.2.13.32.13s.23-.04.32-.13L6.5 7.15 8.46 9.1c.1.1.2.13.32.13s.23-.04.32-.13c.18-.18.18-.47 0-.65L7.14 6.5 9.1 4.53c.18-.18.18-.47 0-.65zM6.5 0a6.5 6.5 0 100 13 6.5 6.5 0 100-13zm3.95 10.45c-.5.5-1.1.92-1.78 1.2-.7.3-1.42.44-2.17.44s-1.5-.15-2.17-.44a5.6 5.6 0 01-1.78-1.2c-.5-.5-.92-1.1-1.2-1.78a5.51 5.51 0 01-.01-4.34 5.6 5.6 0 011.2-1.78c.5-.5 1.1-.92 1.78-1.2C5 1.06 5.75.9 6.5.9s1.5.15 2.17.44a5.6 5.6 0 011.78 1.2c.5.5.92 1.1 1.2 1.78.3.7.44 1.42.44 2.17s-.15 1.5-.44 2.17c-.28.67-.68 1.26-1.2 1.78z"
        fill={fill}
      />
    </svg>
  );
}

const SVGXWithCircle = React.memo(XWithCircle);
export { SVGXWithCircle };