import React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function ChatBubble(props) {
  return (
    <SVGIcon {...props}>
      <path d="M5.989 8.715a1.127 1.127 0 10-.002 2.254 1.127 1.127 0 00.002-2.254zm9.022 0a1.127 1.127 0 10-.001 2.254 1.127 1.127 0 00.001-2.254zm-4.511 0a1.127 1.127 0 10-.001 2.254 1.127 1.127 0 00.001-2.254z" />
      <path d="M20.166 5.986a9.766 9.766 0 00-2.261-3.125A10.527 10.527 0 0014.57.765 11.09 11.09 0 0010.5 0C9.09 0 7.72.258 6.43.765A10.527 10.527 0 003.095 2.86 9.806 9.806 0 00.834 5.986 9.262 9.262 0 000 9.83c0 2.398.925 4.69 2.608 6.486l-.9 6.253a.734.734 0 00.732.652.747.747 0 00.365-.096l4.415-3.95c1.054.324 2.155.488 3.277.488 1.41 0 2.78-.258 4.07-.765a10.527 10.527 0 003.335-2.096 9.806 9.806 0 002.261-3.125 9.223 9.223 0 00.833-3.844 9.232 9.232 0 00-.83-3.847zm-1.345 7.078a8.319 8.319 0 01-1.923 2.656 9.082 9.082 0 01-2.871 1.802 9.572 9.572 0 01-3.53.663 9.642 9.642 0 01-3.12-.513.738.738 0 00-.603.057l-3.48 3.234.75-5.067a.735.735 0 00-.206-.433c-1.522-1.542-2.36-3.543-2.36-5.633 0-1.121.235-2.208.7-3.233A8.319 8.319 0 014.103 3.94 9.082 9.082 0 016.973 2.14a9.596 9.596 0 013.527-.663c1.224 0 2.411.224 3.529.663a9.046 9.046 0 012.87 1.802 8.328 8.328 0 011.924 2.656 7.801 7.801 0 01-.002 6.468z" />
    </SVGIcon>
  );
}

const SVGChatBubble = React.memo(ChatBubble);
export { SVGChatBubble };
