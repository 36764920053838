import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function MapPin(props) {
  return (
    <SVGIcon viewBox="0 0 15 17" {...props}>
      <path
        d="M7.083 3.146c-.977 0-1.897.396-2.59 1.114a3.855 3.855 0 00-1.071 2.69c0 1.018.38 1.971 1.072 2.69a3.57 3.57 0 002.59 1.114c.979 0 1.896-.395 2.589-1.113a3.855 3.855 0 001.072-2.69c0-1.018-.381-1.971-1.072-2.691a3.576 3.576 0 00-2.59-1.114zm0 6.263c-1.305 0-2.366-1.102-2.366-2.459 0-1.356 1.06-2.458 2.366-2.458S9.45 5.594 9.45 6.95c0 1.357-1.061 2.46-2.367 2.46zm6.503-5.473a6.574 6.574 0 00-1.554-2.052A7.35 7.35 0 009.776.504 7.414 7.414 0 007.086 0h-.004C6.16 0 5.255.17 4.39.505a7.35 7.35 0 00-2.256 1.38A6.568 6.568 0 00.58 3.934 5.802 5.802 0 000 6.46C0 7.67.287 8.915.852 10.16c.468 1.03 1.13 2.07 1.97 3.092 1.501 1.827 3.191 3.135 3.848 3.611a.699.699 0 00.827 0c.656-.476 2.348-1.784 3.848-3.611.838-1.022 1.501-2.062 1.97-3.092.565-1.246.852-2.493.852-3.702 0-.873-.196-1.72-.58-2.523zM12.87 6.46c0 1.012-.245 2.065-.729 3.13-.415.92-1.015 1.858-1.78 2.79-1.196 1.457-2.534 2.57-3.278 3.137-.743-.569-2.082-1.68-3.277-3.137-.765-.932-1.365-1.873-1.782-2.792-.484-1.064-.73-2.118-.73-3.13 0-.663.15-1.31.445-1.923A5.265 5.265 0 012.981 2.9a6.048 6.048 0 011.86-1.138 6.196 6.196 0 012.24-.42h.004c.765 0 1.52.14 2.238.42.694.269 1.32.651 1.862 1.138a5.177 5.177 0 011.24 1.635c.296.618.445 1.264.445 1.925z"
        fillRule="evenodd"
      />
    </SVGIcon>
  );
}

const SVGMapPin = React.memo(MapPin);
export { SVGMapPin };
