import React from "react";
import { Container, Divider } from "@material-ui/core";
import { CropOriginal } from "@material-ui/icons";
import { TableGroup } from "common";
import { resolveArrayResponse, fetch } from "lib/util/fetch";
import {DIALOG_CONTEXT_IMAGES, HTTP_REQUEST_PATCH} from "core/utils/constCapsule";
import { USER_LICENSES } from "core/config/licenses";
import { connect } from "react-redux";
import { hasLicense } from "core/store/selectors/UserSelectors";
import { ImagesTable } from "features/ImagesTable";
import { PaymentDetailsTable } from "features/PaymentDetailsTable";
import { CommentsTable } from "features/CommentsTable";
import { SVGChatBubble } from "images/SVGChatBubble";
import { buildAPIUrl } from "core/utils/urls";
import { useQuery } from "react-query";
import { API_PATHS } from "core/config/urls";
import { mapComments } from "core/mappers/comments";
import { useDialogContext } from "core/hooks/useDialogContext";
import { AddImagesButton } from "features/AddImagesButton";
import { ImagesDialog } from "features/ImagesDialog";
import { useActiveRow } from "common/Table/hooks";
import { useStyles } from "./DetailsDrawer.styles";

const queryConfig = {
  retry: false,
  staleTime: 120000 // 2 Minutes
};

async function fetchPaymentData(settleHistId) {
  const response = await fetch({
    url: buildAPIUrl({
      path: API_PATHS.FACTORING_COMPANY_PAYMENT_DETAILS,
      queryParams: { id: settleHistId }
    })
  });
  return resolveArrayResponse(response);
}

async function fetchCommentsData(order_id, ordersType) {
  const response = await fetch({
    url: buildAPIUrl({
      path: API_PATHS.FACTORING_COMPANY_PAYMENTS_RECEIVED_ORDERS_COMMENTS,
      queryParams: { order_id: order_id, ordersType }
    }),
    transformResponse: mapComments,
    method: HTTP_REQUEST_PATCH
  });
  return resolveArrayResponse(response);
}

function DetailsDrawerPresentation({
  order_id,
  movementId,
  hasImagingLicense = false,
  ordersType,
  settleHistId
}) {
  const classes = useStyles();
  const [dialogContext, toggleDialog] = useDialogContext(null);
  const paymentDetailsQuery = useQuery(
    [settleHistId, "paymentDetails"],
    fetchPaymentData,
    queryConfig
  );
  const commentsQuery = useQuery([order_id, ordersType, "comments"], fetchCommentsData, queryConfig);
  const { inlineIndex, setInlineIndex } = useActiveRow(movementId);
  const handleChange = (idx) => setInlineIndex(idx);

  const appendPaymentDetails = (Table) => (
    <Container className={classes.container} maxWidth={false}>
      <Container className={classes.tableContainer} disableGutters maxWidth={false}>
        {Table}
      </Container>
      <Divider className={classes.divider} orientation="vertical" flexItem variant="fullWidth" />
      <PaymentDetailsTable {...paymentDetailsQuery} />
    </Container>
  );

  const tables = [
    {
      Table: appendPaymentDetails(<CommentsTable {...commentsQuery} />),
      label: "Comments",
      Icon: <SVGChatBubble />
    }
  ];

  if (hasImagingLicense) {
    tables.push({
      Table: appendPaymentDetails(<ImagesTable movementId={movementId} />),
      label: "Images",
      Icon: <CropOriginal />
    });
  }

  return (
    <>
      <TableGroup
        inline
        onChange={handleChange}
        value={inlineIndex}
        tables={tables}
        renderActions={() => (
          <>
            {hasImagingLicense && <AddImagesButton onClick={toggleDialog(DIALOG_CONTEXT_IMAGES)} />}
          </>
        )}
      />
      {hasImagingLicense && (
        <ImagesDialog
          orderId={order_id}
          movementId={movementId}
          open={dialogContext === DIALOG_CONTEXT_IMAGES}
          onClose={toggleDialog(DIALOG_CONTEXT_IMAGES)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  hasImagingLicense: hasLicense(state, USER_LICENSES.LOADMASTER_IMAGING_RUN_IMAGING_PROGRAM)
});

export const InlineOrderDetailsDrawer = connect(mapStateToProps)(DetailsDrawerPresentation);
