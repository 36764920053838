import { DisplayTypes } from "lib/components";
import { callApi } from "./api";
import { getLogger } from "./Logger";
import { fetchModelData } from "./ModelUtil";
import { trimQueryString } from "./UrlUtil";

const log = getLogger("lib.util.ModelDefaultProps");

/**
 * This is what holds all the metaData for all the models.
 */
const metaData = {};
/**
 * For data-bound components (those with props.field set and on a DataContainer with the modelName set),
 * this will populate props.metaData with the metadata from the model.  Further, it will populate the
 * props directly in the component as long as they haven't been specifically set.  In other words,
 * if the Model's metadata has a caption, it will populate props.caption.
 *
 * @param {*} context
 * @param {*} props
 * @param {*} allowWidthChange Some components are fixed width and don't want to allow their widths to be changed based on the
 * metadata width.
 */
export function addDefaultMetaDataProps(context, props, allowWidthChange = true) {
  addMetaData(context, props);
  props = combineMetaDataProps(props, allowWidthChange);
  addMetaDataItems(props);
  if (props.displayType === DisplayTypes.CITY && props.lookupModel == null)
    props.lookupModel = "dispatch/city-suggestion";
  if (props.displayType === DisplayTypes.LOCATION && props.lookupModel == null)
    props.lookupModel = "dispatch/location-suggestion";
  if (props.displayType === DisplayTypes.STATE && props.lookupModel == null)
    props.lookupModel = "dispatch/portal-state-list";
  addLookupSuggestions(props.lookupModel, props);
  defaultPlaceholderText(props);
  return props;
}

/**
 * For component with the given properties, add any combo items that
 * are appropriate for the component's data type.
 * @param {*} props
 */
export function addMetaDataItems(props) {
  if (props.metaData != null && props.metaData.items == null) {
    if (props.metaData.extDataType === "weight_uom")
      props.metaData.items = ["lbs", "kgs"];
    if (props.metaData.extDataType === "length_uom")
      props.metaData.items = ["ft", "in"];
  }
}

/**
 * This takes a the props.metaData (that is populated directly from the Model) and updates the Component's props
 * that haven't been specifically set.
 *
 * @param {*} props
 * @param {*} allowWidthChange
 */
function combineMetaDataProps(props, allowWidthChange) {
  if (props.metaData != null) {
    addMetaDataItems(props);
    const metaData = props.metaData;
    let result = { ...props };
    if (result.caption === undefined)
      result.caption = metaData.caption;
    if (result.displayType === undefined)
      result.displayType = getDefaultDisplayType(metaData);
    if (allowWidthChange && result.width === undefined && metaData.dataType !== "bool")
      result.width = getDefaultWidth(result.displayType, metaData);
    if (props.metaData.items != null && props.items === undefined)
      result.items = props.metaData.items;
    if (props.metaData.lookupModel != null && props.lookupModel === undefined)
      result.lookupModel = props.metaData.lookupModel;
    if (props.metaData.cacheLookup != null && props.cacheLookup === undefined)
      result.cacheLookup = props.metaData.cacheLookup;
    if (props.metaData.lookupModelDisplayField != null && props.lookupModelDisplayField === undefined)
      result.lookupModelDisplayField = props.metaData.lookupModelDisplayField;
    if (props.metaData.lookupModelResultField != null && props.lookupModelResultField === undefined)
      result.lookupModelResultField = props.metaData.lookupModelResultField;
    if (props.metaData.lookupModelSuggestions != null && props.lookupModelSuggestions === undefined)
      result.lookupModelSuggestions = props.metaData.lookupModelSuggestions;
    if (props.metaData.required != null && props.required === undefined)
      result.required = props.metaData.required;
    if (props.metaData.length != null && props.maxLength === undefined)
      result.maxLength = props.metaData.length;
    return result;
  }
  else
    return props;
}

export function addLookupSuggestions(lookupModel, targetProps) {
  if (lookupModel != null) {
    let def = autoCompleteDefs[lookupModel];
    if (def != null) {
      if (targetProps.lookupModelDisplayField === undefined && def.displayField != null)
        targetProps.lookupModelDisplayField = def.displayField;
      if (targetProps.lookupModelResultField === undefined && def.resultField != null)
        targetProps.lookupModelResultField = def.resultField;
      if (targetProps.lookupModelSuggestions === undefined && def.suggestions != null)
        targetProps.lookupModelSuggestions = def.suggestions;
      if (targetProps.cacheLookup === undefined && def.cacheLookup != null)
        targetProps.cacheLookup = def.cacheLookup;
      if (targetProps.searchParam === undefined && def.searchParam != null)
        targetProps.searchParam = def.searchParam;
    }
  }
}

function defaultPlaceholderText(props) {
  if (props.placeholder != null) {
    return;
  }
  if (props.displayType === DisplayTypes.LOCATION) {
    props.placeholder = "Type name, address, city, zip code or airport code";
  }
}

/**
 * Returns the basic data type for a given extended data type.
 * @param {*} metaData
 */
function getDefaultDisplayType(metaData) {
  if (metaData.extDataType != null) {
    if (metaData.extDataType === "weight")
      return DisplayTypes.WEIGHT;
    if (metaData.extDataType === "distance")
      return DisplayTypes.DISTANCE;
    if (metaData.extDataType === "length")
      return DisplayTypes.LENGTH;
    if (metaData.extDataType === "temperature")
      return DisplayTypes.TEMPERATURE;
    if (metaData.extDataType === "location")
      return DisplayTypes.LOCATION;
    if (metaData.extDataType === "city")
      return DisplayTypes.CITY;
    if (metaData.extDataType === "state")
      return DisplayTypes.STATE;
    if (metaData.extDataType === "email")
      return DisplayTypes.EMAIL;
    if (metaData.extDataType === "phone")
      return DisplayTypes.PHONE;
  }
  if (metaData.dataType === "date")
    return DisplayTypes.DATE;
  if (metaData.dataType === "datetime")
    return DisplayTypes.DATETIME;
  if (metaData.dataType === "time")
    return DisplayTypes.TIME;
  if (metaData.dataType === "int")
    return DisplayTypes.INTEGER;
  if (metaData.dataType === "float")
    return DisplayTypes.FLOAT;
  return undefined;
}

function getDefaultWidth(displayType, metaData) {
  if (displayType === DisplayTypes.STATE)
    return 200;
  else if (metaData.dataType === "date")
    return 180;
  else if (metaData.dataType === "datetime")
    return 260;
  else if (metaData.dataType === "time")
    return 100;
  else if (metaData.dataType === "int")
    return 100;
  else if (metaData.dataType === "decimal")
    return 100;
  else if (metaData.dataType === "float")
    return 100;
  else {
    const length = metaData.length;
    if (length < 12)
      return 100;
    else if (length < 20)
      return 200;
    else if (length < 30)
      return 240;
    else if (length < 40)
      return 340;
    else
      return 420;
  }
}

/**
 * This is really meant to come from the server and is roughly equivalent
 * to our thumbbox/thumbfield.
 */
const autoCompleteDefs = {
  "query-model-list": {
    displayField: "name",
    resultField: "name",
    columns: [
      { "caption": "Name", field: "name", width: 360 }
    ]
  },
  "dispatch/commodity": {
    displayField: "descr",
    resultField: "id",
    suggestions: {
      columns: [
        { caption: "Commodity", field: "descr", width: 220 },
        { caption: "Hazmat", field: "hazmat_number", width: 120, renderFunction: "lme.dsp.Commodity.renderHazmat" },
      ]
    }
  },
  "dispatch/commodity-product-book": {
    displayField: "descr",
    resultField: "id",
    suggestions: {
      columns: [
        { caption: "Commodity", field: "descr", width: 220 },
        { caption: "Hazmat", field: "hazmat_number", width: 120, renderFunction: "lme.dsp.Commodity.renderHazmat" },
      ]
    },
    cacheLookup: true,
    searchParam: { key: "product_book_location", value: "shipper.id" }
  },
  "dispatch/web-trailer-type": {
    displayField: "descr",
    resultField: "id",
    suggestions: {
      columns: [
        { caption: "Description", field: "descr", width: 360 }
      ]
    },
    cacheLookup: true
  },
  "dispatch/city-suggestion": {
    displayField: "name",
    resultField: "id",
    suggestions: {
      rowRenderFunction: "lme.dsp.LocationSuggestions.renderCitySuggestion",
      formatValueFunction: "lme.dsp.LocationSuggestions.formatCity",
      columns: [
        { "caption": "City", field: "name", width: 360 }
      ]
    }
  },
  "dispatch/location-suggestion": {
    displayField: "name",
    resultField: "id",
    suggestions: {
      rowRenderFunction: "lme.dsp.LocationSuggestions.renderLocationSuggestion",
      formatValueFunction: "lme.dsp.LocationSuggestions.formatLocationAutoComplete",
      columns: [
        { "caption": "Location", field: "id", width: 360 }
      ]
    }
  },
  "dispatch/location-zip-suggestion": {
    displayField: "location_name",
    resultField: "location_name",
    searchParam: "zip_code",
    suggestions: {
      rowRenderFunction: "lme.dsp.LocationSuggestions.renderLocationSuggestion",
      formatValueFunction: "lme.dsp.LocationSuggestions.formatLocationNameAutoComplete",
      columns: [
        { "caption": "Name", field: "location_name", width: 360 }
      ]
    }
  },
  "dispatch/location-zip-suggestion-combo": {
    displayField: "location_name",
    resultField: "location_name",
    searchParam: "zip_code",
    suggestions: {
      rowRenderFunction: "lme.dsp.LocationSuggestions.renderLocationSuggestion",
      formatValueFunction: "lme.dsp.LocationSuggestions.formatLocationNameAutoComplete",
      columns: [
        { "caption": "Name", field: "location_name", width: 360 }
      ]
    },
    cacheLookup: true
  },
  "dispatch/equipment-type": {
    displayField: "descr",
    resultField: "id",
    suggestions: {
      columns: [
        { caption: "Equipment", field: "descr", width: 236 }
      ]
    }
  },
  "dispatch/hazmat-code": {
    displayField: "id",
    resultField: "id",
    suggestions: {
      columns: [
        { caption: "Hazmat", field: "descr", width: 236 },
        { caption: "Hazmat", field: "id", width: 100 }
      ]
    }
  },
  "dispatch/freight-class": {
    displayField: "field_code_desc",
    resultField: "field_code",
    cacheLookup: true
  },
  "dispatch/nmfc-code": {
    displayField: "field_code_desc",
    resultField: "field_code",
    cacheLookup: true
  },
  "dispatch/packaging-type": {
    displayField: "field_code_desc",
    resultField: "field_code",
    cacheLookup: true
  },
  "dispatch/portal-trailer-types": {
    displayField: "descr",
    resultField: "id",
    cacheLookup: true
  },
  "dispatch/portal-state-list": {
    displayField: "name",
    resultField: "id",
    cacheLookup: true
  }
}

/**
 * Ensures that metadata for a given model name has been loaded into
 * memory so that it is accessible from the getMetaData() function.
 *
 * @param {*} modelName
 */
export async function ensureMetaDataLoaded(modelName) {
  modelName = trimQueryString(modelName);
  await fetchModelData("metadata?endpoint=" + modelName, null, null, (response) => {
    metaData[modelName] = response;
    log.debug("Received metadata for %o :  %o  Full %o", modelName, response, metaData);
  }, (reason) => {
    log.info("Error loading metadata for %o: %o", modelName, reason);
  });
}

function resolveModel(context) {
  const pathFromParent = [];
  while (context != null && context.data != null) {
    const result = trimQueryString(context.data.modelName);
    if (result != null)
      return { modelName: result, path: pathFromParent };
    pathFromParent.push(context.data.parentField);
    context = context.data.parentContext;
  }
  return null;
}

export function getMetaData(componentContext, field) {
  const modelInfo = resolveModel(componentContext);
  if (modelInfo == null) {
    log.info("Could not resolve model info from context %o", componentContext);
    return null;
  }
  modelInfo.path.push(field);
  let result = metaData[modelInfo.modelName];
  if (result != null && componentContext != null && componentContext.data != null && componentContext.data.mode === "search")
    result = result.input;
  else if (result != null)
    result = result.output;
  for (let i = 0; result != null && i < modelInfo.path.length; i++) {
    result = result[modelInfo.path[i]];
    if (result != null && result.fields != null && i < modelInfo.path.length - 1)
      result = result.fields;
  }
  if (result == null)
    log.debug("Field [%s] not found in metadata of %s: %o  Context:    %o", modelInfo.path.join("."), modelInfo.modelName, metaData[modelInfo.modelName], componentContext);
  else
    log.debug("Metadata for %s is %o    ModelInfo %o    Full %o", field, result, modelInfo, metaData[modelInfo.modelName]);
  return result;
}

function addMetaData(context, props) {
  if (props.field != null && props.metaData == null)
    props.metaData = getMetaData(context, props.field);
}
