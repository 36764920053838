import React, { useRef, useState } from "react";
import { makeStyles } from "lib/util";
import { getTheme } from "lib/util/Theme";

let styles;

function usePopupContainerStyles() {
  if (styles == null)
    styles = makeStyles("pop", {
      popover: {
        position:"absolute",
        left: "0px",
        top: "0px",
        height: "100%",
        width: "100%"
      },
      popup: {
        position: "absolute",
        zIndex: "1301",
        backgroundColor: getTheme().background,
        overflowY: "auto",
        overflowX: "hidden",
        border: "1px solid rgba(0,0,0,.25)",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
      }
    })
  return styles;
}

let globalSetContent;
let popupVisible = false;

export function Popover() {
  const [content, setContent] = useState();
  const classes = usePopupContainerStyles();
  globalSetContent = setContent;
  popupVisible = content != null;
  if (popupVisible)
    return <div className={classes.popover} onClick={handleClick}>
      {content}
    </div>
  else
    return null;
}

function handleClick(event) {
  closePopup();
}

export function isPopupVisible() {
  return popupVisible;
}

export function AnchoredContainer({anchorRef, ...props}) {
  const classes = usePopupContainerStyles();
  let containerRef = useRef();
  const [clientHeight, setClientHeight] = useState(0);
  let style = {};
  const rect = anchorRef.current.getBoundingClientRect();
  style.top = rect.bottom - 1;
  style.left = rect.left;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  if (clientHeight > 0 && rect.top + clientHeight > windowHeight - 32)
    style.top = rect.top - rect.height - clientHeight;
  return (
    <div className={classes.popup} style={style} ref={containerRef} {...props}>
      {props.children}
    </div>
  );
}

export function closePopup(onClose, context) {
  if (globalSetContent != null)
    globalSetContent(null);
  if (onClose) onClose(context);
  popupVisible = false;
}

export function showPopup(content) {
  if (globalSetContent != null)
    globalSetContent(content);
}
