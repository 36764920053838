import * as React from "react";

function MapPinOutlinedActive({gradientColor1="#2C5DD1", gradientColor2="#109BE8", ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 63 70" {...props}>
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="prefix__c">
          <stop stopColor={gradientColor1} offset="0%" />
          <stop stopColor={gradientColor2} offset="100%" />
        </linearGradient>
        <filter
          x="-67.1%"
          y="-56.2%"
          width="234.3%"
          height="215.3%"
          filterUnits="objectBoundingBox"
          id="prefix__a"
        >
          <feMorphology
            radius={2.5}
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.0745098039 0 0 0 0 0.48627451 0 0 0 0 0.866666667 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M33.514 14.444c0 2.855-.71 5.827-2.111 8.83-1.203 2.595-2.94 5.243-5.156 7.874-3.46 4.11-7.335 7.252-9.488 8.852-2.152-1.606-6.027-4.742-9.487-8.852-2.216-2.631-3.954-5.284-5.161-7.878C.709 20.266 0 17.294 0 14.44c0-1.872.43-3.701 1.286-5.429C2.132 7.3 3.34 5.747 4.883 4.397a17.597 17.597 0 015.387-3.211A18.352 18.352 0 0116.754 0h.01c2.216 0 4.4.399 6.48 1.186 2.01.76 3.822 1.839 5.392 3.21 1.549 1.35 2.756 2.903 3.591 4.615.856 1.744 1.287 3.567 1.287 5.433z"
          id="prefix__b"
        />
      </defs>
      <g fillRule="nonzero" fill="none">
        <g transform="translate(15 15)">
          <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
          <use fill="url(#prefix__c)" xlinkHref="#prefix__b" />
        </g>
        <path
          d="M45.581 29.917c0 2.355-.585 4.807-1.741 7.285-.992 2.14-2.426 4.324-4.254 6.495-2.854 3.39-6.051 5.983-7.827 7.303-1.776-1.325-4.973-3.912-7.827-7.303-1.828-2.17-3.262-4.36-4.258-6.5-1.157-2.478-1.742-4.93-1.742-7.285 0-1.544.356-3.052 1.062-4.478.697-1.412 1.693-2.693 2.967-3.807a14.518 14.518 0 014.444-2.649 15.14 15.14 0 015.35-.978h.008c1.828 0 3.63.329 5.345.978a14.539 14.539 0 014.449 2.65c1.278 1.113 2.274 2.394 2.963 3.806a10.09 10.09 0 011.061 4.483z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

const SVGMapPinOutlinedActive = React.memo(MapPinOutlinedActive);
export { SVGMapPinOutlinedActive };