import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function Image(props) {
  return (
    <SVGIcon viewBox="0 0 100 100" {...props}>
      <path
        d="M17.5 0h-16C.7 0 0 .7 0 1.5v13c0 .8.7 1.5 1.5 1.5h15.9c.8 0 1.5-.7 1.5-1.5v-13c0-.8-.7-1.5-1.5-1.5zm0 14.5h-16v-4.4l3.9-3.9 4.6 5.6c.3.3.7.3 1 .1l3.4-2.3 3 1.4v3.5zm0-5.4l-2.5-1c-.3-.2-.6-.2-.9 0l-3.3 2.2-4.7-5.7c-.1-.2-.3-.2-.6-.3s-.4.1-.6.2L1.5 7.9V1.5h15.9v7.6zm-4-6.1c-1.2 0-2.1.9-2.1 2.1s1 2.1 2.1 2.1 2.1-.9 2.1-2.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6z"
      />
    </SVGIcon>
  );
}

const SVGImage = React.memo(Image);
export { SVGImage };
