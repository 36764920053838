import React from "react";
import { Label, Image, Panel, useComponentContext } from "lib/components";
import { getDataFromContext } from "lib/util/ModelUtil";

export function StatusCell(props) {
    let context = useComponentContext();
    let data = getDataFromContext(context);
    let dataList = context.data.list;
      let status = data["status"];
    let image, bottomConnector;
      if (status === "D")
          image = <Image marginLeft={-1} marginTop={0} image="CheckWithCircle" height={27} width={27} fill="#137cdd" stroke="#FFF" />
      else  {
          let color;
          if (data["actual_arrival"] && !data["actual_departure"])
              color = "#137cdd";
          else
              color = "#979797";
          image = <Label caption={"" + (context.dataIndex + 1)}  height={25} width={25} marginTop={2} padding={8}
              borderRadius="50%" borderWidth={1} borderColor={color} color={color}/>
      }
      if (context.dataIndex < dataList.length - 1) {
          let color = "#137cdd";
          let nextStatus = dataList[context.dataIndex + 1].modelData["status"];
          if (nextStatus === "A")
            color = "#979797";
      let connectorHeight = 75;
      if(!!props.showContactAndScheduledInfo)
        connectorHeight = props.connectorHeight >=0 ? props.connectorHeight : 300;
      const connectorBorderWidth = props.connectorBorderWidth >= 0 ? props.connectorBorderWidth : 1;
          bottomConnector = (
        <Panel height={connectorHeight} padding={0} marginTop={-1} marginBottom={-3}>
          <Label borderWidth={connectorBorderWidth} borderColor={color} fillHeight padding={0} marginLeft={11.5} marginTop={0} marginBottom={-2} />
        </Panel>
      );
      }
  
      return (
          <Panel paddingTop={0} paddingBottom={0} marginTop={0} marginBottom={0}>
              {image}
              {!props.hideConnector && bottomConnector}
          </Panel>
      )
  }