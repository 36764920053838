import { API_PATHS } from "core/config/urls";
import { mapOrderCallinHistoryResponse } from "core/mappers/acceptedOrdersCallins";
import { mapAvailableOrders } from "core/mappers/availableOrders";
import { mapDeliveredOrders } from "core/mappers/deliveredOrders";
import { mapOrderImagesResponse } from "core/mappers/ordersImages";
import { mapOfferHistoryOrders } from "core/mappers/offerHistoryOrders";
import {
  HTTP_REQUEST_POST,
  HTTP_REQUEST_GET,
  HTTP_REQUEST_PATCH,
  ORDERS_CATEGORY_ACCEPTED,
  ORDERS_CATEGORY_AVAILABLE,
  ORDERS_CATEGORY_DELIVERED,
  ORDERS_CATEGORY_OFFER_HISTORY,
  ORDERS_CATEGORY_PAYMENTS_DUE,
  ORDERS_CATEGORY_PAYMENTS_RECEIVED,
  USER_TYPE_CARRIER,
  USER_TYPE_FACTORING_COMPANY,
  ORDERS_TYPE_UNPAID,
  ORDERS_TYPE_ACCEPTED,
  ORDERS_TYPE_PAID
} from "core/utils/constCapsule";
import { fetch, resolveArrayResponse, fetchAll } from "lib/util/fetch";
import { buildAPIUrl } from "core/utils/urls";
import { mapPaymentsDueOrders } from "core/mappers/paymentsDueOrders";
import { mapPaymentsReceivedOrders } from "core/mappers/paymentsReceivedOrders";
import { getMilliTime, parseDateTimeString } from "core/utils/dateTime";
import { getUserType } from "../selectors/UserSelectors";
import { getActiveCategory, getActiveOrdersType } from "../selectors/OrdersSelectors";
import { setUser } from "core/store/actions/UserActions";

export const SET_ORDERS = "SET_ORDERS";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";
export const SET_ORDERS_TYPE = "SET_ORDERS_TYPE";
export const SET_ORDERS_IMAGES = "SET_ORDERS_IMAGES";
export const FETCH_ORDERS_IMAGES = "FETCH_ORDERS_IMAGES";
export const SET_ORDERS_IS_FETCHING = "SET_ORDERS_IS_FETCHING";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const SET_ORDERS_CALLINS = "SET_ORDERS_CALLINS";

const userTypeUrlArg = {
  [USER_TYPE_CARRIER]: USER_TYPE_CARRIER.toLowerCase(),
  [USER_TYPE_FACTORING_COMPANY]: "factoring"
};

const urls = function getUrl(ordersCategory, ordersType) {
  switch (ordersCategory) {
    case ORDERS_CATEGORY_AVAILABLE:
      return API_PATHS.CARRIER_AVAILABLE_ORDERS;
    case ORDERS_CATEGORY_ACCEPTED:
      return API_PATHS.CARRIER_AVAILABLE_ORDERS;
    case ORDERS_CATEGORY_DELIVERED:
      if (ordersType === ORDERS_TYPE_PAID) return API_PATHS.CARRIER_PAID_ORDERS;
      return API_PATHS.CARRIER_UNPAID_ORDERS;
    case ORDERS_CATEGORY_OFFER_HISTORY:
      return API_PATHS.CARRIER_OFFER_HISTORY_ORDERS;
    case ORDERS_CATEGORY_PAYMENTS_DUE:
      return API_PATHS.FACTORING_COMPANY_PAYMENTS_DUE_ORDERS;
    case ORDERS_CATEGORY_PAYMENTS_RECEIVED:
      return API_PATHS.FACTORING_COMPANY_PAYMENTS_RECEIVED_ORDERS;
    default:
      return null;
  }
};

const methods = function getMethod(ordersCategory) {
  switch (ordersCategory) {
    case ORDERS_CATEGORY_AVAILABLE:
    case ORDERS_CATEGORY_ACCEPTED:
    case ORDERS_CATEGORY_DELIVERED:
    case ORDERS_CATEGORY_PAYMENTS_DUE:
    case ORDERS_CATEGORY_PAYMENTS_RECEIVED:
      return HTTP_REQUEST_PATCH;
    default:
      return HTTP_REQUEST_GET;
  }
};

const mappers = {
  [ORDERS_CATEGORY_AVAILABLE]: mapAvailableOrders,
  [ORDERS_CATEGORY_ACCEPTED]: mapAvailableOrders,
  [ORDERS_CATEGORY_DELIVERED]: mapDeliveredOrders,
  [ORDERS_CATEGORY_OFFER_HISTORY]: mapOfferHistoryOrders,
  [ORDERS_CATEGORY_PAYMENTS_DUE]: mapPaymentsDueOrders,
  [ORDERS_CATEGORY_PAYMENTS_RECEIVED]: mapPaymentsReceivedOrders
};

const imagesSideEffect = [
  ORDERS_CATEGORY_DELIVERED,
  ORDERS_CATEGORY_ACCEPTED,
  ORDERS_CATEGORY_PAYMENTS_DUE,
  ORDERS_CATEGORY_PAYMENTS_RECEIVED
];

const callinsSideEffect = [ORDERS_CATEGORY_ACCEPTED];

function setOrders(type, ordersCategory, ordersType, payload) {
  return { type, ordersCategory, ordersType, payload };
}

export function setOrdersCategory(category) {
  return (dispatch, getState) => {
    const userType = getUserType(getState());
    dispatch(setOrders(SET_ACTIVE_CATEGORY, null, null, { [userType]: category }));
  };
}

export function setOrdersType(ordersCategory, type) {
  return setOrders(SET_ORDERS_TYPE, ordersCategory, null, {
    ordersType: type
  });
}

export function setOrdersIsFetching(ordersCategory, ordersType, isFetching) {
  return setOrders(SET_ORDERS_IS_FETCHING, ordersCategory, ordersType, {
    isFetching
  });
}

export function setOrdersImages(ordersCategory, ordersType, payload) {
  return setOrders(SET_ORDERS_IMAGES, ordersCategory, ordersType, payload);
}

export function setOrdersCallins(ordersCategory, ordersType, payload) {
  return setOrders(SET_ORDERS_CALLINS, ordersCategory, ordersType, payload);
}

export function removeOrder(ordersCategory, ordersType, orderId) {
  return setOrders(REMOVE_ORDER, ordersCategory, ordersType, {
    orderId
  });
}

export function fetchImages(ordersCategory, ordersType, orders = []) {
  return async (dispatch, getState) => {
    const userType = getUserType(getState());
    if (!userType) return;
    const response = await fetch({
      url: buildAPIUrl({
        path: API_PATHS.IMAGES,
        pathArgs: { userType: userTypeUrlArg[userType] },
        queryParams: { movements: orders.map((order) => order.movementId).join(",") }
      }),
      transformResponse: mapOrderImagesResponse
    });
    dispatch(setOrdersImages(ordersCategory, ordersType, resolveArrayResponse(response)));
  };
}

export function fetchCallins(ordersCategory, ordersType, orders = []) {
  return async (dispatch) => {
    const { data } = await fetch({
      url: buildAPIUrl({
        path: API_PATHS.CARRIER_CALLIN_HISTORY,
        queryParams: {
          movementIds: orders.map((order) => order.movementId).join(",")
        }
      }),
      transformResponse: mapOrderCallinHistoryResponse
    });
    dispatch(setOrdersCallins(ordersCategory, ordersType, data));
  };
}

export function fetchOrders(ordersCategory, ordersType) {
  return async (dispatch) => {
    dispatch(setOrdersIsFetching(ordersCategory, ordersType, true));
    const response = await fetch({
      url: buildAPIUrl({
        path: urls(ordersCategory, ordersType),
        queryParams: { type: ordersType }
      }),
      transformResponse: mappers[ordersCategory],
      method: methods(ordersCategory)
    });
    const orders = resolveArrayResponse(response);
    dispatch(setOrders(SET_ORDERS, ordersCategory, ordersType, { orders }));
    dispatch(setOrdersIsFetching(ordersCategory, ordersType, false));

    if (imagesSideEffect.includes(ordersCategory))
      dispatch(fetchImages(ordersCategory, ordersType, orders));
    if (callinsSideEffect.includes(ordersCategory))
      dispatch(fetchCallins(ordersCategory, ordersType, orders));
    return orders;
  };
}

export function updateOrder(orderId) {
  return async (dispatch, getState) => {
    const state = getState();
    const activeCategory = getActiveCategory(state);
    const activeOrdersType = getActiveOrdersType(state);

    const response = await fetch(
      buildAPIUrl({
        path: urls[activeCategory],
        queryParams: { type: activeOrdersType, orderId }
      })
    );

    if (!resolveArrayResponse(response).length && activeCategory === ORDERS_CATEGORY_ACCEPTED) {
      dispatch(fetchOrders(ORDERS_CATEGORY_DELIVERED, ORDERS_TYPE_UNPAID));
      dispatch(removeOrder(ORDERS_CATEGORY_ACCEPTED, ORDERS_TYPE_ACCEPTED, orderId));
    }
    return response;
  };
}

export function updateOrderImages(movementId) {
  return async (dispatch, getState) => {
    const state = getState();
    const activeCategory = getActiveCategory(state);
    const activeOrdersType = getActiveOrdersType(state);
    await dispatch(fetchImages(activeCategory, activeOrdersType, [{ movementId }]));
  };
}

export function uploadImages(files = [], movementId, orderId) {
  return async (dispatch, getState) => {
    const userType = getUserType(getState());
    if (!userType) return;
    const uploaders = files.map((file) => {
      let data = new FormData();
      data.append("attachment", file);
      data.append("fileName", file.name);
      data.append("docType", file.docType || "Other");
      data.append("movementId", movementId);
      data.append("orderId", orderId);

      return {
        url: buildAPIUrl({
          path: API_PATHS.IMAGE_UPLOAD,
          pathArgs: { userType: userTypeUrlArg[userType] }
        }),
        method: HTTP_REQUEST_POST,
        data
      };
    });

    await fetchAll(uploaders);
    await dispatch(updateOrderImages(movementId));
  };
}

/**
 * Formats Callin form data and POSTs it to the API.
 *
 * @export
 * @param {object} callin Information about the Callin
 * @returns
 */
export function addCallin(callin) {
  const { cityStateZipId, dateTime, nextSchedCallin, ...rest } = callin;
  return async (dispatch, getState) => {
    await dispatch(setUser({ userType: USER_TYPE_CARRIER }));
    const state = getState();
    const activeCategory = getActiveCategory(state);
    const activeOrdersType = getActiveOrdersType(state);
    await fetch({
      url: buildAPIUrl(API_PATHS.CARRIER_CALLIN),
      method: HTTP_REQUEST_POST,
      data: {
        ...rest,
        ...cityStateZipId,
        dateTime: getMilliTime(parseDateTimeString(dateTime)),
        nextSchedCallin: getMilliTime(parseDateTimeString(nextSchedCallin))
      }
    });
    await dispatch(fetchOrders(activeCategory, activeOrdersType));
  };
}
