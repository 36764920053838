import { flatten } from "core/utils/common";

const blacklist = [
  "key",
  "settleHistId",
  "shipDate_milli",
  "shipDate_time",
  "deliveryDate_time",
  "deliveryDate_milli",
  "check_milli",
  "check_time",
  "invoice_time",
  "invoice_milli",
  "commentsCount"
];
const keyMap = {
  advancesOtherPay: "Advances Deductions",
  carrierName: "Carrier Name",
  carrierCity: "Carrier City",
  carrierState: "Carrier State",
  order_id: "Order Number",
  netPay: "Net Pay",
  totalPay: "Total Pay",
  deliveryDate_date: "Delivery Date",
  shipDate_date: "Shipping Date",
  invoice_id: "Invoice #",
  invoice_date: "Invoice Date",
  bolNum: "BOL #",
  ok2PayDate: "OK to Pay Date",
  status: "Status",
  mcNumber: "MC Number"
};

const fields = {
  fields: [
    "Order Number",
    "BOL #",
    "MC Number",
    "Carrier Name",
    "Carrier City",
    "Carrier State",
    "Invoice #",
    "Invoice Date",
    "Shipping Date",
    "Delivery Date",
    "Advances Deductions",
    "Total Pay",
    "Net Pay",
    "OK to Pay Date",
    "Status"
  ]
};

const headers = [
  "Order Number",
  "MC Number",
  "Carrier Info",
  "Carrier Invoice #",
  "Shipping Date",
  "Total Pay",
  "Net Pay",
  "Status"
];

export function useXLSFormatter(fileName, orders) {
  const _orders = orders || [];
  const rows = _orders.map((row) => flatten(row, { separator: "_" }));
  const newRows = rows.map((row) => {
    const formattedData = Object.keys(row).reduce((data, key) => {
      const formattedKey = keyMap[key];
      const formattedValue = row[key];
      if (blacklist.includes(key)) return data;
      return {
        ...data,
        [formattedKey]: formattedValue
      };
    }, {});
    return formattedData;
  });
  return [fileName, newRows, fields];
}

export function usePDFFormatter(fileName, orders) {
  const _orders = orders || [];
  const ordersValues = _orders.map((order) => {
    return [
      `${order.order_id}
      BOL #
      ${order.bolNum || "N/A"}`,
      order.mcNumber,
      `${order.carrierName}
      ${order.carrierCity}, ${order.carrierState}`,
      `${order.invoice ? order.invoice.id : "N/A"}
      Invoice Date
      ${order.invoice && order.invoice.date ? order.invoice.date : "N/A"}`,
      `${order.shipDate ? order.shipDate.date : "N/A"}
      Delivery Date
      ${order.deliveryDate ? order.deliveryDate.date : "N/A"}`,
      `${order.totalPay || "N/A"}
      Advances/Deductions
      ${order.advancesOtherPay || "N/A"}`,
      `${order.netPay || "N/A"}
      OK to Pay Date
      ${order.ok2PayDate || "N/A"}`,
      order.status
    ];
  });
  ordersValues.splice(0, 0, headers);

  return [
    fileName,
    {
      content: [
        {
          table: {
            headerRows: 1,
            body: ordersValues
          },
          layout: {
            fillColor(rowIndex) {
              return rowIndex % 2 === 0 ? "#CCCCCC" : null;
            }
          }
        }
      ],
      defaultStyle: {
        fontSize: 8,
        bold: false
      }
    }
  ];
}
