export function isEmptyObject(obj) {
  return obj == null || Object.entries(obj).length === 0;
}

export function isObject(a) {
  return a && typeof a === "object" && !Array.isArray(a);
}

export function deepCopy(inObject, excluded, returnDateToString = false) {
  let outObject, value, key

  if (typeof inObject !== "object" || inObject === null || inObject === undefined) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}

  if (!(inObject instanceof Date) || returnDateToString === false)
  {
    for (key in inObject) {
      if (excluded != null && excluded.includes(key)) {
        continue;
      }
      value = inObject[key]
      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = deepCopy(value, excluded, returnDateToString)
    }
  }
  else {
    outObject = inObject.toString();
  } 
  return outObject
}

export function appendObject(srcObject, targetObject) {
  if (srcObject == null)
    return targetObject;
  if (targetObject == null)
    targetObject = {};
  for (let key in srcObject)
    targetObject[key] = srcObject[key];
  return targetObject;
}

export function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
