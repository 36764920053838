import React, { useRef, useState } from "react";
import { useTextBoxStyles } from "./TextBoxStyles";
import { useComponentContext } from "../Context";
import { isCombo, isAutoComplete } from "./TextBoxUtil";
import { fetchModelData, getLogger } from "lib/util";
import { DropDown } from "./DropDown";
import { callFormatValueFunction, fetchCachedModelData, getDataFromContext } from "lib/util/ModelUtil";
import { setDataContextValue } from "../DataContainer";
import { DisplayTypes } from "lib/enum/DisplayTypes";
import { Keys } from "lib/util/Keys";
import { showDatePicker } from "../DatePicker";
import { closePopup } from "../PopoupContainer";
import { addParam } from "./DropDown";
import { handleChange } from "./TextBox";

let lastKeyPress;
let lastKeyString = "";
let acTimeout;

const log = getLogger("lib.components.TextBox.AutoCompleteInput");
const DEFAULT_MIN_SEARCH_LENGTH = 3;

export function AutoCompleteInput(props) {
  const context = useComponentContext();
  const classes = useTextBoxStyles();
  const [modelItems, setModelItems] = useState();
  const [loading, setLoading] = useState(false);
  const selBeforeLoad = useRef(false);
  const lastSearchUrl = useRef(null);
  let inputProps = props.inputProps;
  let className = classes.input;
  const listRef = React.useRef();
  let liRefs = [];
  const combo = isCombo(props);
  const autoComp = isAutoComplete(props);
  if (inputProps == null)
    inputProps = {};
  inputProps.autoComplete = "no"; // Suppress browser saved data auto complete
  if (combo || autoComp) {
    inputProps.onKeyDown = (event) => dropDownHandleKey(event, context, props, modelItems, listRef, liRefs, selBeforeLoad);
    if (props.field != null)
    { context.setStateAccessor(props.field, () => {return {stateValue: props.value, setStateValue: props.setValue, props, handleChange: (context, props, value, setValue) => handleContextValueUpdate(context, props, value, setValue, setModelItems)}}); }
  }
  if (combo) {
    inputProps.onKeyPress = (event) => comboKeyPress(event, context, props, modelItems, setModelItems);
    inputProps.onClick = (event) => inputClicked(event, context, props, modelItems);
    inputProps.onChange = (event) => {};
    className += " " + classes.combo;
  }
  else if (autoComp) {
    inputProps.onChange = (event) => autoCompleteChange(event, context, props, modelItems, setModelItems, setLoading, selBeforeLoad, lastSearchUrl);
    const textBoxOnFocusMethod = inputProps.onFocus;
    inputProps.onFocus = (event) => {
      log.debug("selBeforeLoad = false (onFocus: %s)", props.field)
      selBeforeLoad.current = false;
      if (textBoxOnFocusMethod != null) {
        textBoxOnFocusMethod(event);
      }
    }
  }
  else {
    if (props.displayType == DisplayTypes.DATE) {
      inputProps.onKeyDown = (event) => dateHandleKey(event, context, props);
    }
    if (props.parentChanged != null)
      inputProps.onChange = props.parentChanged;
  }
  let dropDown;
  if (props.dropDownVisible) {
    dropDown = <DropDown
      anchor={props.inputRef.current}
      modelItems={modelItems}
      setModelItems={setModelItems}
      selectItem={selectItem}
      loading={loading}
      setLoading={setLoading}
      minSearchLength= { props.minSearchLength == null ? DEFAULT_MIN_SEARCH_LENGTH : props.minSearchLength }
      {...props}
      listRef={listRef}
      refs={liRefs}
      inputRef={props.inputRef}
    />
  }
  else if(!props.dropDownVisible && modelItems)
    setModelItems(null);
  return (
    <React.Fragment>
      <input {...inputProps} className={className} ref={props.inputRef} disabled={props.disabled}/>
      {dropDown}
    </React.Fragment>
  );
}

function autoCompleteChange(event, context, props, modelItems, setModelItems, setLoading, selBeforeLoad, lastSearchUrl) {
  log.debug("selBeforeLoad = false (onChange: %s)", props.field)
  selBeforeLoad.current = false;
  const value = event.target.value;
  const empty = value == null || value.trim().length === 0;
  if (!props.dropDownVisible && !empty)
    showDropDown(props, getItems(props.items, modelItems, props.includeBlankItem, props.lookupModelDisplayField, props.lookupModelResultField, props.staticItem, props.metaData, props.required));
  else if (props.dropDownVisible && empty)
    props.setDropDownVisible(false);
  if (!empty)
    acKeyPress(event, context, props, setModelItems, setLoading, selBeforeLoad, lastSearchUrl);
  if (props.parentChanged)
    props.parentChanged(event);
}

function inputClicked(event, context, props, modelItems) {
  if (isCombo(props)) {
    if (props.dropDownVisible)
      props.setDropDownVisible(false);
    else
      showDropDown(props, getItems(props.items, modelItems, props.includeBlankItem, props.lookupModelDisplayField, props.lookupModelResultField, props.staticItem, props.metaData, props.required));
  }
}

function findItem(value, items) {
  if (items != null && items.length > 0)
    for (let i = 0; i < items.length; i++)
      if (value === items[i])
        return i;
  return -1;
}

function showDropDown(props, items) {
  props.setDropDownIndex(findItem(props.inputProps.value, items));
  props.setDropDownVisible(true);
}

function acKeyPress(event, context, props, setModelItems, setLoading, selBeforeLoad, lastSearchUrl) {
  window.clearTimeout(acTimeout);
  acTimeout = window.setTimeout(() => loadAutoCompleteOptions(context, props, setModelItems, setLoading, selBeforeLoad, lastSearchUrl), 300);
}

function loadAutoCompleteOptions(context, props, setModelItems, setLoading, selBeforeLoad, lastSearchUrl) {
  const value = props.inputRef.current.value;
  const minSearchLength = props.minSearchLength == null ? DEFAULT_MIN_SEARCH_LENGTH : props.minSearchLength;
  if (value != null && value.length >= minSearchLength) {
    const encodedValue = encodeURIComponent(value);
    let url = props.lookupModel + "?text_search=" + encodedValue;
    if(props.searchParam){
      url = props.lookupModel + "?text_search=" + encodedValue + "&"+props.searchParam+"="+getDataFromContext(context, props.searchParam);
    }
    if (props.staticParam != null) {
      url += "&"+props.staticParam;
    }
    log.debug("selBeforeLoad running new text search %s", url)
    lastSearchUrl.current = url;
    fetchModelData(url, context, setLoading, (data) => {
      if (props.onSearchComplete != null) {
        props.onSearchComplete(value, data);
      }
      if (selBeforeLoad.current === true) {
        log.debug("selBeforeLoad url=%o lastSearchUrl=%o", url, lastSearchUrl.current)
        if (url === lastSearchUrl.current) { //only select items from most recent search
          log.debug("selBeforeLoad: selecting item from data %o for field %s", data, props.field)
          selectItem(context, props, data, 0);
          props.setDropDownVisible(false);
          selBeforeLoad.current = false;
        }
      }
      else
        showAutoComplete(data, setModelItems, props)
    });
  }
}

function showAutoComplete(data, setModelItems, props) {
  if (props.inputRef.current !== document.activeElement)
    props.setDropDownVisible(false);
  else {
    props.setDropDownVisible(true);
    props.setDropDownIndex(0);
    setModelItems(data);
  }
}

//wish we didn't need this...
function cleanLiRefs(liRefs)
{
  for (let x=0 ; x<liRefs.length ;)
  {
    if (liRefs[x].current == null) {
      liRefs.splice(x, 1);
    }
    else {
      x++;
    }
  }
}

function dateHandleKey(event, context, props) {
  const key = event.keyCode;
  if (key === Keys.ARROW_DOWN)
    showDatePicker(props.contentRef, props.value, props.parentChanged, context, props);
  else if (key === Keys.ESCAPE)
    closePopup();
}

function dropDownHandleKey(event, context, props, modelItems, listRef, liRefs, selBeforeLoad) {
  cleanLiRefs(liRefs);
  const key = event.keyCode;
  const items = getItems(props.items, modelItems, props.includeBlankItem, props.lookupModelDisplayField, props.lookupModelResultField, props.staticItem, props.metaData, props.required);
  if (items == null)
    return;
  const index = Math.max(0, props.dropDownIndex);
  if (key === Keys.ARROW_DOWN && !props.dropDownVisible)
    showDropDown(props, items);
  else if (key === Keys.ARROW_DOWN && props.dropDownVisible && index < items.length - 1) {
    props.setDropDownIndex(index + 1);
    selectItem(context, props, items, index + 1);
    const li = liRefs[index + 1].current;
    const desiredBottom = li.offsetTop + li.offsetHeight;
    if (listRef.current.scrollTop + listRef.current.clientHeight < desiredBottom)
      listRef.current.scrollTop = desiredBottom - listRef.current.clientHeight;
  }
  else if (key === Keys.ARROW_UP && props.dropDownVisible && index > 0) {
    props.setDropDownIndex(index - 1);
    selectItem(context, props, items, index - 1);
    const li = liRefs[index - 1].current;
    if (listRef.current.scrollTop > li.offsetTop)
      listRef.current.scrollTop = li.offsetTop;
  }
  else if (key === Keys.ENTER && props.dropDownVisible) {
    if ((items == null || items.length === 0) && isAutoComplete(props)) {
      log.debug("selBeforeLoad = true (enter: %s)", props.field)
      selBeforeLoad.current = true;
    }
    else {
      selectItem(context, props, items, index);
      props.setDropDownVisible(false)
    }
    event.preventDefault();
  }
  else if (key ===Keys.TAB && props.dropDownVisible) {
    if ((items == null || items.length === 0) && isAutoComplete(props)) {
      log.debug("selBeforeLoad = true (tab: %s)", props.field)
      selBeforeLoad.current = true;
    }
    else {
      selectItem(context, props, items, index);
    }
  }
  else if (key === Keys.ESCAPE && props.dropDownVisible)
    props.setDropDownVisible(false);
}

function handleContextValueUpdate(context, props, value, setValue, setModelItems, updateContext = true)
{
  log.debug("handleContextValueUpdate %o    %d    %o", props, value, updateContext);

  if (props.lookupModel != null)
  {
    fetchCachedModelData(props.lookupModel, null, null, (modelItems) => {
      setModelItems(modelItems);
      selectItemFromValue(context, props, value, modelItems, updateContext);
    });
  }
  else if (props.parentChanged != null)
  { handleChange(context, props, value, setValue, true) }
}

function selectItemFromValue(context, props, value, modelItems, updateContext = true)
{
  log.debug("selectItemFromValue %o   %o    %d    %o", props, modelItems, value, updateContext);

  if (value == null)
  {
    props.setValue(value);
    context.setValidationWarning(props.field, null);
    if (props.onChange != null) {
      props.onChange(value, context, props);
    }
    if(props.onItemChanged) {
      props.onItemChanged(context, modelItems, -1);
    }
    return;
  }

  if (modelItems == null)
  { return; }

  let matchingIndex = -1;
  let testValue;
  for (let x=0 ; x<modelItems.length ; x++)
  {
    testValue = null;
    if (props.lookupModelResultField != null) {
      testValue = modelItems[x][props.lookupModelResultField]
    }
    else if (isCombo(props)) {
      testValue = modelItems[x];
    }

    if (testValue === value)
    {
      matchingIndex = x;
      break;
    }
  }
  if (matchingIndex === -1)
  { return; }

  let displayValue = undefined;
  if (props.lookupModelSuggestions != null && props.lookupModelSuggestions.formatValueFunction != null) {
    props.setValue(modelItems[matchingIndex][props.lookupModelDisplayField]);
    value = callFormatValueFunction(props.lookupModelSuggestions.formatValueFunction, modelItems[matchingIndex]);
  }
  else if (props.lookupModelDisplayField != null)
    value = modelItems[matchingIndex][props.lookupModelDisplayField]
  else if (isCombo(props))
    value = modelItems[matchingIndex];
  
  props.setValue(displayValue);
  if (updateContext === true) {
    setDataContextValue(context, props.field, modelItems[matchingIndex]);
  }
  if (props.onChange != null) {
    props.onChange(value, context, props);
  }
  if(props.onItemChanged) {
    props.onItemChanged(context, modelItems, matchingIndex);
  }
  if(modelItems[matchingIndex] != null){
    context.setValidationWarning(props.field, null);
  }
}

function selectItem(context, props, items, index) {
  log.debug("selectItem %o   %o    %d", props, items, index);
  setDataContextValue(context, props.field, items[index]);
  let value = undefined;
  if (props.lookupModelSuggestions != null && props.lookupModelSuggestions.formatValueFunction != null) {
    props.setValue(items[index][props.lookupModelDisplayField]);
    value = callFormatValueFunction(props.lookupModelSuggestions.formatValueFunction, items[index]);
  }
  else if (props.lookupModelDisplayField != null)
    value = items[index][props.lookupModelDisplayField]
  else if (isCombo(props))
    value = items[index];
  props.setValue(value);
  if (props.onChange != null) {
    props.onChange(value, context, props);
  }
  if(props.onItemChanged) {
    props.onItemChanged(context, items, index);
  }
  if(items[index] != null){
    context.setValidationWarning(props.field, null);
  }
}

function comboKeyPress(event, context, props, modelItems, setModelItems) {
  const thisKeyPress = new Date();
  if (event != null) {
    let c = String.fromCharCode(event.charCode);
    if (lastKeyPress == null || thisKeyPress - lastKeyPress < 500)
      lastKeyString += c;
    else
      lastKeyString = c;
  }
  lastKeyPress = thisKeyPress;
  if (isCombo(props) && !props.dropDownVisible && props.lookupModel != null && modelItems == null) {
    let lookupModel = props.lookupModel
    if (props.searchParam != null) {
      lookupModel = addParam(lookupModel, props.searchParam, context);
    }
    fetchCachedModelData(lookupModel, null, null, (value) => {
      setModelItems(value);
      comboKeyPress(null, context, props, value, setModelItems);
      lastKeyPress = new Date();
    });
    return;
  }

  const items = getItems(props.items, modelItems, props.includeBlankItem, props.lookupModelDisplayField, props.lookupModelResultField, props.staticItem, props.metaData, props.required);
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    let value = item;
    if (props.lookupModelSuggestions != null && props.lookupModelSuggestions.formatValueFunction != null)
      value = callFormatValueFunction(props.lookupModelSuggestions.formatValueFunction, item);
    else if (props.lookupModelDisplayField != null)
      value = item[props.lookupModelDisplayField];
    if (value.toLowerCase().startsWith(lastKeyString.toLowerCase())) {
      selectItem(context, props, items, i);
      props.setValue(value);
      props.setDropDownVisible(false)
      if (event != null)
        event.preventDefault();
      break;
    }
  }

}

export function getItems(propItems, modelItems, includeBlankItem = true, lookupModelDisplayField, lookupModelResultField, staticItem, metaData, required) {
  let result = [];
  if(required != null && required === true){
    includeBlankItem = false;
  }
  else if (metaData != null && metaData.required != null && metaData.required === true) {
      includeBlankItem = false;    
  }
  if (includeBlankItem === true) {
    const blankItem = {};
    blankItem[lookupModelDisplayField] = "--";
    blankItem[lookupModelResultField] = "";
    blankItem.key = "keyBlank";
    result.push(blankItem);
  }
  if (propItems != null){
      for (let i = 0; propItems != null && i < propItems.length; i++)
    result.push(propItems[i]);
    return result;
  }
  if (staticItem)
    result.push(staticItem);
  for (let i = 0; modelItems != null && i < modelItems.length; i++)
    result.push(modelItems[i]);
  return result;
}
