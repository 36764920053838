import React from "react";

export const HIDE_NULL = "hide";
export const BLANK_SPACE = "blankSpace";

export const Alignment = {
  TOP: "top",
  LEFT: "left",
  RIGHT: "right",
  BOTTOM: "bottom",
  CENTER: "center"
}

export function getComponentName(context, name) {
  let result = "";
  for (let i = 0; context != null && context.hierarchy != null && i < context.hierarchy.parents.length; i++)
    result += context.hierarchy.parents[i].name + ".";
  result += name;
  return result;
}

export function getChildArray(children) {
  let result = [];
  children = React.Children.toArray(children);
  if (children != null) {
    for (let i = 0; i < children.length; i++)
      if (children[i] != null)
        result.push(children[i]);
  }
  return result;
}

export function appendComponentToHierarchy(context, props) {
  let result = {};
  if (context.hierarchy == null)
    result.parents = [{ name: props.name, props: props }];
  else
    result.parents = [...context.hierarchy.parents, { name: props.name, props: props }];
  if (props.usePrintableVersion != null)
    result.usePrintableVersion = props.usePrintableVersion;
  else if (context.hierarchy != null)
    result.usePrintableVersion = context.hierarchy.usePrintableVersion;
  else
    result.usePrintableVersion = false;

  if (props.captionAlignment != null)
    result.captionAlignment = props.captionAlignment;
  else if (context.hierarchy != null)
    result.captionAlignment = context.hierarchy.captionAlignment;
  else
    result.captionAlignment = Alignment.TOP;

  return result;
}

export function evaluateProp(context, prop) {
  if (typeof prop === "function")
    return prop(context);
  else
    return prop;
}

/**
 * This function accepts an array of items or functions and will return the first item
 * that is non-null.  The more interesting part of this is that the array elements can
 * be functions so we won't waste the resources evaluating those elements unless
 * all the other previous values are null.  If an item in the array is a function,
 * we will pass the context (that was passed to this function) through to the function
 * that we call.
 *
 * @param {*} context
 * @param {*} values
 */

export function getFirstSuppliedValue(context, values) {
  for (let i = 0; i < values.length; i++) {
    if (values[i] != null) {
      let value = evaluateProp(context, values[i]);
      if (value != null)
        return value;
    }
  }
  return null;
}

export function handleNullDisplayValue(value, nullDisplayValue) {
  if (value == null && nullDisplayValue != null) {
    value = nullDisplayValue;
    if (value === "hide")
      return HIDE_NULL;
    if (value === "blankSpace") {
      return BLANK_SPACE;
    }
  }
  return value;
}

export function shouldUsePrintableVersion(context, props) {
  if (props.usePrintableVersion !== undefined)
    return props.usePrintableVersion;
  if (context.hierarchy != null && context.hierarchy.usePrintableVersion != null)
    return context.hierarchy.usePrintableVersion;
  return false;
}

export function getCaptionAlignment(context, props) {
  if (props.captionAlignment !== undefined)
    return props.captionAlignment;
  if (context.hierarchy != null && context.hierarchy.captionAlignment != null)
    return context.hierarchy.captionAlignment;
  return Alignment.TOP;
}
