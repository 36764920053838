import React from "react";
import { Table, Column, DisplayTypes } from "lib/components";

export function PanelDriversChoiceDetail(props) {
    const closeSpacing = { marginTop: -4, paddingTop: 0 };
    return (
      <Table headerVisible={false} field="driver_choice_details" backgroundColor="transparent" border="none" {...props}>
        <Column caption="Loaded Miles" field="loaded_miles" displayType={DisplayTypes.INTEGER}/>
        <Column caption="Delivery Date" field="dest_early_date" displayType={DisplayTypes.DATE} format="long"/>
        <Column caption="Additional Pickups" field="additional_pickups"/>
        <Column caption="Additional Deliveries" field="additional_deliveries"/>
        <Column caption="Preloaded" field="preloaded"/>
        <Column caption="Weight" field="{weight} {weight_uom}" replaceModelData displayType={DisplayTypes.DECIMAL}/>
        <Column caption="Consignee Ref Number" field="consignee_ref_num"/>
        <Column caption="Commodity" field="commodity"/>
        <Column caption="Comments">
          <Table headerVisible={false} field="comments" rowSpacing="0" firstRowTopPadding backgroundColor="transparent" border="none" showLinesBetweenRows={false}>
            <Column caption="Comments" field="comment"/>
          </Table>
        </Column>
      </Table>
    )
  }