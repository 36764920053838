import React from "react"
import { SVGIcon } from "lib/util/SVGIcon"

function Split(props) {
  return (
    <SVGIcon {...props}>
      <path d="M3 13h8v2H3zm0 4h8v2H3zm0-8h8v2H3zm0-4h8v2H3zm16 2v10h-4V7h4m2-2h-8v14h8V5z" />
    </SVGIcon>
  )
}

const SVGSplit = React.memo(Split);
export { SVGSplit };
