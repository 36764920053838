import React, { useCallback, useState, useEffect } from "react";
import dotProp from "dot-prop";
import { usePrevious } from "core/hooks";
import { ActiveRowContext, TableContext } from "./context";
import { getComparator, stableSort } from "./utils";

/**
 * Uses the local context to return to table config.
 *
 * @export
 * @returns
 */
export function useTableProps() {
  return React.useContext(TableContext);
}

/**
 * Uses the local context to determine if the passed in movementId is the active movementId. Also return the setter method for mutating the
 * context's internal activeRowId state.
 *
 * @export
 * @returns {Object} { isActive: boolean, inlineIndex: number, setActiveRow: fn, setInlineIndex: fn}
 */
export function useActiveRow(rowMovementId) {
  const {
    activeRowId,
    setActiveRow,
    activeRowInlineIndex,
    setActiveRowInlineIndex
  } = React.useContext(ActiveRowContext);

  const handleSetActiveRow = (curRowId) => {
    if (activeRowInlineIndex !== 0) setActiveRowInlineIndex(0);
    setActiveRow((prevRowId) => (curRowId === prevRowId ? null : curRowId));
  };

  const handleSetInlineIndex = (idx) => {
    if (idx !== activeRowInlineIndex) setActiveRowInlineIndex(idx);
    if (rowMovementId !== activeRowId) setActiveRow(rowMovementId);
  };

  return {
    isActive: activeRowId === rowMovementId,
    inlineIndex: activeRowInlineIndex,
    setActiveRow: handleSetActiveRow,
    setInlineIndex: handleSetInlineIndex
  };
}

export function useTableSort(rows, defaultSortKey = null, defaultSorOrder = "asc") {
  const [order, setOrder] = useState(defaultSorOrder);
  const [orderBy, setOrderBy] = useState(defaultSortKey);

  const handleSort = useCallback(
    function handleSort(property) {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const sortedRows = stableSort(rows, getComparator(order, orderBy));
  return [sortedRows, { order, orderBy, setOrderBy, handleSort }];
}

export function useTablePagination(rows = [], disabled) {
  const rowsPerPageOptions = [10, 25, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const totalPages = Math.max(0, Math.ceil(rows.length / rowsPerPage) - 1);
  const prevTotalPages = usePrevious(totalPages);
  const handleChangePage = useCallback((_, newPage) => setPage(newPage), []);
  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  useEffect(() => {
    if (totalPages !== prevTotalPages) setPage(0);
  }, [prevTotalPages, totalPages]);

  const paginatedRows = disabled
    ? rows
    : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return [
    paginatedRows,
    {
      count: rows.length,
      page: totalPages !== prevTotalPages ? 0 : page,
      rowsPerPage,
      rowsPerPageOptions,
      onChangePage: handleChangePage,
      onChangeRowsPerPage: handleChangeRowsPerPage
    }
  ];
}

export function useTableFilter(data = [], { identifier = "id", keys = [] }) {
  const [value, setValue] = useState("");
  const [filteredRows, setFilteredRows] = useState(data);

  useEffect(() => {
    setFilteredRows(data);
  }, [data]);

  const searchData = useCallback(
    (query) => {
      if (!query) return setFilteredRows(data);
      const needle = query.toLowerCase();
      const results = keys.reduce(
        (prevResults, key) => [
          ...prevResults,
          ...data.filter((result) => {
            const haystack = dotProp.get(result.rowData, key);
            if (!haystack) return false;
            return haystack.toLowerCase().includes(needle);
          })
        ],
        []
      );

      const uniqueResults = new Map();
      for (let index = 0; index < results.length; index++) {
        uniqueResults.set(results[index][identifier], results[index]);
      }

      return setFilteredRows([...uniqueResults.values()]);
    },
    [data, keys, identifier]
  );

  const handleChange = useCallback(
    ({ target: { value: newValue } }) => {
      setValue(newValue);
      searchData(newValue);
    },
    [searchData]
  );

  const handleReset = useCallback(
    (resetValue) => () => handleChange({ target: { value: resetValue } }),
    [handleChange]
  );

  return [
    filteredRows,
    {
      value,
      onReset: handleReset,
      onChange: handleChange
    }
  ];
}
