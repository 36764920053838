import React, { useState } from "react";
import { Button, Component } from ".";
import { getLogger } from "lib/util";
import { useComponentContext} from "./Context";
import { setDataContextValue } from "./DataContainer";
import { addDefaultMetaDataProps } from "lib/util/ModelDefaultProps";
import { useButtonGroupStyles } from "./ButtonGroupStyles";

const log = getLogger("lib.components.ButtonGroup");

export function ButtonGroup({ ...props }) {
  const context = useComponentContext();
  const [index, setIndex] = useState(0);
  const classes = useButtonGroupStyles();
  log.debug("Context %o   Props %o  Index %d", context, props, index);
  props = addDefaultMetaDataProps(context, props, false);
  let buttons = renderButtons(context, props, props.items, classes, index, setIndex);

  return (
    <Component {...props} >
      <div className={classes.buttonContainer}>
        {buttons}
      </div>
    </Component>
  );
}

function renderButtons(context, props, items, classes, index, setIndex) {
  let buttons = [];
  for (let i = 0; items != null && i < items.length; i++) {
    let buttonProps = items[i];
    if (typeof buttonProps === "string")
      buttonProps = { caption: buttonProps };
    if (index === i) {
      buttonProps.color = "primary";
      buttonProps.variant = "contained";
    }
    buttons.push(<Button key={"button-" + i} className={classes.button} padding={0} {...buttonProps} onClick={() => handleChange(context, props, i, setIndex)} />);
  }
  return buttons;
}

function handleChange(context, props, value, setIndex) {
  setIndex(value);
  setDataContextValue(context, props.field, value);
  if (props.onChange)
    props.onChange(value, context);
}
