import { Button, Paper, Typography } from "@material-ui/core";
import { AuthType } from "lib/util/AuthType";
import React, { useState } from "react";

export function TestSimpleReactMaterial() {
  const [caption, setCaption] = useState();
  return (
    <Paper>
      <Typography>
        {caption}
      </Typography>
      <Button onClick={() => {
        setCaption("Clicked!")
      }}>
        Click me!
      </Button>
    </Paper>
  )
}

export function getPageOptions() {
  return {
    title: "Simple React Test With Material UI",
    auth: AuthType.UNAUTH
  };
}
