import React from "react";
import { Panel, TextBox, Table, Column, Label, Button, ButtonActions } from "lib/components";
import { getCompanySettings, getUserSettings } from "lib/util";
import { DownloadSettlementSummaryButton } from "./DownloadSettlementSummaryButton";
import { DataModes } from "lib/util/ModelUtil";

export function ParamSettlementHistory() {
	const userType = getUserSettings().user_type;
	return (
		<Panel name="top" modelName="drs/settlement" dataMode={DataModes.SEARCH} fillHeight>
			<Panel variant="form">
				<TextBox caption={userType&&userType==="R"?"Load":"Order"} field="order_id" noRowBreak />
				<TextBox caption="Delivery start date" field="delivery_date.start" default="t-30" displayType="date" noRowBreak />
				<TextBox caption="Delivery end date" field="delivery_date.end" displayType="date" noRowBreak/>
				<TextBox caption="Check start date" field="pay_date.start" displayType="date" noRowBreak />
				<TextBox caption="Check end date" field="pay_date.end" displayType="date" noRowBreak />
				<TextBox caption="Check number" field="check_number" noRowBreak/>
				<TextBox caption="Carrier pro number" field="carrier_pro_nbr" visible={getCompanySettings().is_ltl} noRowBreak/>
				<Button action={ButtonActions.SUBMIT} marginTop={18} />
			</Panel>
			<Table emptyCaption="No matching settlements found."
				unsearchedCaption="Use the search above to find settlements."
				exportName="Settlement History" alternateRowColor fillRow fillHeight>
				<Column caption={userType&&userType==="R"?"Load":"Order"} field="order_id" minWidth={120}/>
				<Column caption="Origin / pickup date"  sortfield="ship_date" sortDataType="datetime" minWidth={200}>
					<Label field="origin_city" displayLabel="Origin city" color="primary" fontBold noRowBreak />
					<Label field="origin_state" displayLabel="Origin state" color="primary" fontBold />
					<Label field="ship_date" displayLabel="Pickup date" />
				</Column>
				<Column caption="Destination / delivery date" sortfield="delivery_date" sortDataType="datetime" minWidth={200}>
					<Label field="destination_city" displayLabel="Destination city" color="primary" fontBold noRowBreak />
					<Label field="destination_state" displayLabel="Destination state" color="primary" fontBold />
					<Label field="delivery_date" displayLabel="Delivery date" />
				</Column>
				<Column caption="Load pay" field="total_pay" displayType="currency" align="right" minWidth={100}/>
				<Column caption="Other pay" field="other_pay" displayType="currency"  align="right" minWidth={100}/>
				<Column caption="Advances / deductions" field="deductions" displayType="currency"  align="right" minWidth={100}/>
				<Column caption="Net pay" field="net_pay" displayType="currency" align="right" minWidth={100}/>
				<Column caption="Invoice number" field="invoice"  nullDisplayValue="N/A" minWidth={100}/>
				<Column caption="Check number / date" sortfield="pay_date" minWidth={100}>
					<Label field="check_number" displayLabel="Check number" color="primary" fontBold marginBottom={0} nullDisplayValue="N/A"/>
					<DownloadSettlementSummaryButton />
				</Column>
				<Column caption="Carrier pro number" field="carrier_pro_nbr" nullDisplayValue="N/A" visible={getCompanySettings().is_ltl} minWidth={120}/>
			</Table>
		</Panel>
	);
}
