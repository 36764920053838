import React from "react";
import { Panel, Button, ButtonActions } from "lib/components";
import { closeDialog } from "./Dialog";

export function SubmitPanel({submitButtonCaption="OK", fixedData, onComplete, ...props}) {
	return (
		<Panel fixedData={fixedData} {...props} >
			{props.children}
			<Panel fillRow noRowBreak />
			<Button caption={submitButtonCaption} color="primary" action={ButtonActions.SUBMIT} onComplete={onComplete} onClick={closeDialog} />
		</Panel>
	);
}
