import React from "react";
import { Panel, Label } from "lib/components";
import { setDataContextValue } from "lib/components/DataContainer"
import { QuoteStopPanel, validateShipperLocation } from "../QuoteStopPanel";
import { getCompanySettings } from "lib/util";
import { PaymentTerms } from "../PaymentTerms";

export function QuoteOverview(freightItemsTable, handlingReqsTable, currentStep) {
  let welcomeMessage = getCompanySettings().order_welcome_message;
  return (
    <Panel
      rowBreakDefault={false}
      maxWidth="lg"
      variant="form"
      caption="Quote Overview"
      submitCaption="Get Quote"
      onContinue={(context, setLoading, handleContinue) => getQuote(context, setLoading, handleContinue)}
    >
      <Label caption={welcomeMessage} look="default4" fillRow rowBreak />
      <QuoteStopPanel currentStep={currentStep} rowBreak/>
      {freightItemsTable}
      <PaymentTerms rowBreak/>
      {handlingReqsTable}
    </Panel>
  );
}

function getQuote(context, setLoading, handleContinue) {
  validateShipperLocation(context, function(result) {
    let formIsValid = context.validateForm();
    if (result && formIsValid) {
      context.data.submit(context, setLoading, (response) => handleGetQuoteResponse(context, response, handleContinue), null, (data) => adjustDataForGetQuote(data));
    }
  });
}

function adjustDataForGetQuote(data)
{
  delete data[0].stops;
  delete data[0].disclaimer;
  delete data[0].other_charges;
  delete data[0].total_charge;
  delete data[0].freight_charge;
  data[0].ignoreEnforceShipperCode = true;
  return data;
}

function handleGetQuoteResponse(context, response, handleContinue)
{
  setDataContextValue(context, "replaceModelData", response.data[0].data[0]);
  handleContinue();
}
