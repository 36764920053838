import React, { useCallback } from "react";
import { DefaultPageContainer } from "pages/containers";
import {
  ORDERS_CATEGORY_AVAILABLE,
  ORDERS_CATEGORY_ACCEPTED,
  ORDERS_CATEGORY_DELIVERED,
  ORDERS_CATEGORY_OFFER_HISTORY
} from "core/utils/constCapsule";
import { TableGroup } from "common";
import { setOrdersCategory } from "core/store/actions/OrdersActions";
import { connect } from "react-redux";
import { AvailableOrdersTable } from "features/AvailableOrdersTable";
import { AcceptedOrdersTable } from "features/AcceptedOrdersTable";
import { DeliveredOrdersTable } from "features/DeliveredOrdersTable";
import { OfferHistoryOrdersTable } from "features/OfferHistoryOrdersTable";
import { getActiveCategory } from "core/store/selectors/OrdersSelectors";

const tables = [
  { label: "Available Loads", value: ORDERS_CATEGORY_AVAILABLE, Table: <AvailableOrdersTable /> },
  { label: "Accepted Loads", value: ORDERS_CATEGORY_ACCEPTED, Table: <AcceptedOrdersTable /> },
  { label: "Delivered Loads", value: ORDERS_CATEGORY_DELIVERED, Table: <DeliveredOrdersTable /> },
  {
    label: "Offer History",
    value: ORDERS_CATEGORY_OFFER_HISTORY,
    Table: <OfferHistoryOrdersTable />
  }
];

function CarrierPortalPresentation({ pageTitle, activeCategory, dispatchSetOrdersCategory }) {
  const handleTabChange = useCallback((index) => dispatchSetOrdersCategory(tables[index].value), [
    dispatchSetOrdersCategory
  ]);

  return (
    <DefaultPageContainer pageTitle={pageTitle}>
      <TableGroup
        tables={tables}
        onChange={handleTabChange}
        defaultIndex={tables.findIndex(({ value }) => value === activeCategory)}
      />
    </DefaultPageContainer>
  );
}

const mapStateToProps = (state) => ({
  activeCategory: getActiveCategory(state)
});

const mapDispatchToProps = {
  dispatchSetOrdersCategory: setOrdersCategory
};

export const CarrierPortal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarrierPortalPresentation);
