import React from "react";
import { TableCell } from '@material-ui/core';
import { useStyles } from "./TableStyles";
import { Label, Panel, Switch } from "lib/components";

export function RowCell({ classes, rowIndex, colIndex, tableProps, colProps, cellStyles }) {
  classes = useStyles();
  let style = getCellStyleFromTableProps(tableProps);
  if (colProps.width)
    style.width = colProps.width;
  let passProps = { ...colProps };
  delete passProps.caption;
  let key = "cell-" + rowIndex + "-" + colIndex;
  let spacingProp = { };
  if (tableProps.rowSpacing != null) {
    spacingProp.paddingBottom = tableProps.rowSpacing;
    if (rowIndex > 0 || tableProps.firstRowTopPadding != null)
      spacingProp.paddingTop = tableProps.rowSpacing;
  }
  let contents;
  if (colProps.field) {
    if (colProps.checkable)
      contents = <Switch {...passProps} {...spacingProp} />
    else
      contents = <Label {...passProps} {...spacingProp} marginRight={0} fillRow />
  }
  else
    contents = colProps.children;

  let cellClassName;
  if(colProps.className)
    cellClassName = classes[colProps.className];
  else if(!tableProps.displayAsCards)
    cellClassName = classes.tableCell;
  else
    cellClassName = classes.tableCellCard;

  if (contents)
    return (
      <TableCell key={key} className={cellClassName} style={style}>
        <Panel className={classes.tableCellPanel} paddingLeft={0} paddingRight={0} {...spacingProp} >
          {contents}
        </Panel>
      </TableCell>
    );
}

function getCellStyleFromTableProps(tableProps) {
  let style = {};
  if (tableProps.rowSpacing != null) {
    style.paddingBottom = tableProps.rowSpacing + "px";
    style.paddingTop = tableProps.rowSpacing + "px";
  }
  if (tableProps.rowPadding != null) {
    style.paddingLeft = tableProps.rowPadding + "px";
    style.paddingRight = tableProps.rowPadding + "px";
  }
  if (tableProps.showLinesBetweenRows === false)
    style.borderBottom = "none";
  if (tableProps.cellVerticalAlign != null)
    style.verticalAlign = tableProps.cellVerticalAlign;
  return style;
}
