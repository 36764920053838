import React, { useState } from "react";
import * as MUI from "@material-ui/core";
import { Typography } from "common";
import { classNames } from "core/utils";
import { useStyles } from "./ButtonGroup.styles";

export function ButtonGroup({ options, onChange, label, size = "small", className}) {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const handleClick = (idx) => () => {
    setIndex(idx);
    onChange(options[idx]);
  };

  return (
    <>
      {label && <Typography className={classes.label} value={label} />}
      <MUI.ButtonGroup className={className} size={size} disableElevation>
        {options.map((option, idx) => (
          <MUI.Button
            className={classNames(classes.button, {
              [classes.activeButton]: idx === index
            })}
            variant={idx === index ? "contained" : undefined}
            color={idx === index ? "primary" : undefined}
            onClick={handleClick(idx)}
            key={option.label}
          >
            {option.label}
          </MUI.Button>
        ))}
      </MUI.ButtonGroup>
    </>
  );
}
