import React, { useState } from "react";
import { Panel, Spinner } from "lib/components";
import { JSONToJSX } from "./JSONToJSX";
import { useMountEffect, getLogger } from "lib/util";
import { buildAPIUrl } from "core/utils/urls";
import { fetch, resolveArrayResponse } from "lib/util/fetch";

const log = getLogger("lib.components.DynamicPanel");

export function DynamicPanel({ content }) {
  log.debug("Dynamic content %o", content);
  let children = JSONToJSX(content);
  return (
    <Panel fillHeight fillRow >
      {children}
    </Panel>
  );
}

export function DynamicPanelLoader({ name, ...props }) {
  let [content, setContent] = useState();
  let [loading, setLoading] = useState();
  let [error, setError] = useState();
  useMountEffect(() => loadContent(name, setLoading, setError, setContent, setContent));
  if (loading === true)
    return <Spinner />
  else if (error != null)
    return "Error loading layout.";
  else
    return <DynamicPanel content={content} />
}

async function fetchLayout(name) {
  const url = buildAPIUrl({ path: "layout/" + name });
  const response = await fetch({ url });
  return resolveArrayResponse(response);
}

function loadContent(name, setLoading, setError, setContent) {
  if (name == null)
    name = getDynamicPanelNameFromWindowLocation();
  setLoading(true);
  fetchLayout(name)
    .then(result => {
      setLoading(false);
      setContent(result.content);
    })
    .catch(reason => {
      setLoading(false);
      setError(reason);
    });
}

function getDynamicPanelNameFromWindowLocation() {
  let path = window.location.pathname;
  let paramIndex = path.indexOf("&");
  if (paramIndex >= 0)
    path = path.substring(0, paramIndex);
  if (path.endsWith("/"))
    path = path.substring(0, path.length - 1);
  if (path.startsWith("/dynamic/"))
    path = path.substring(9);
  log.debug("Path for %s is %s", window.location.pathname, path);
  return path;
}
