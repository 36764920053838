import {dateDiff, parseDateTime } from "lib/util/Date";
import { getLogger } from "lib/util";

const log = getLogger("ar.CustomerPortal.CustomerPortalMapFilter");

const pastItems = [
	{caption:"Yesterday or today", days: -2},
	{caption: "Within the last 3 days", days: -3},
	{caption: "Within the last 5 days", days: -5},
	{caption: "Within the last 7 days", days: -7},
	{caption: "Within the last 14 days", days: -14},
	{caption: "Any day"}
];
const futureItems = [
	{caption:"Today or tomorrow", days: 2},
	{caption: "Within the next 3 days", days: 3},
	{caption: "Within the next 5 days", days: 5},
	{caption: "Within the next 7 days", days: 7},
	{caption: "Within the next 14 days", days: 14},
	{caption: "Any day"}
];

const filterPropsPerTab = [
	{ // in transit
		showOrigin: {caption: "Actual pickup was", filterField:"shipper.actual_arrival", items: pastItems},
		showDest: {caption: "Scheduled delivery window is", filterField: "consignee.sched_arrive_early", items: futureItems},
	},
	{// scheduled
		showOrigin: {caption: "Scheduled pickup window is", filterField: "shipper.sched_arrive_early", items: futureItems},
		showDest: {caption: "Scheduled delivery window is", filterField: "consignee.sched_arrive_early", items: futureItems},
	},
	{ // delivered
		showOrigin: {caption: "Actual pickup was", filterField: "shipper.actual_arrival", items: pastItems.slice(0, pastItems.length - 1)},
		showDest: {caption: "Actual delivery was", filterField: "consignee.actual_arrival", items: pastItems.slice(0, pastItems.length - 1)},
	},
	{ // all
		showOrigin: {visible: false, items:[{}]},
		showDest: {visible: false, items:[{}]},
	},
	{ // advanced search
		showOrigin: {visible: false, items:[{}]},
		showDest: {visible: false, items:[{}]},
	}
];

export function filterByDays(data, tabIndex, showDest, filterItem, today) {
	const dayCount = filterItem.days;
	if (dayCount === undefined) // all orders
		return true;
	let filterField;
	if (showDest)
		filterField = filterPropsPerTab[tabIndex].showDest.filterField;
	else
		filterField = filterPropsPerTab[tabIndex].showOrigin.filterField;
	const fieldValue = data[filterField];
	const fieldDate = parseDateTime(fieldValue);
	const diff = dateDiff("days", fieldDate, today);
	log.debug("filter by %s with days %d  field value %o  Diff %d", filterField, dayCount, fieldDate, diff);
	if (dayCount < 0) // past
		return diff >= dayCount;
	else
		return diff <= dayCount;
}

export function getMapFilterProps(tabIndex, showDest) {
	log.debug("Get map filter props %o %o", tabIndex, showDest);
	if (showDest)
		return filterPropsPerTab[tabIndex].showDest;
	else
		return filterPropsPerTab[tabIndex].showOrigin;
}
