export function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

export function toProperCase(str) {
	if (str == null)
		return null;
	var result = "";
	for (var i = 0; i < str.length; i++) {
		var c = str.charAt(i);
		if ((c === "_" || c === ' ' || c === '-') && i < str.length - 1)
			result += str.charAt(i++ + 1).toUpperCase();
		else
			result += c;
	}
	return result;
}

export function isEmptyString(obj) {
	if (obj == null)
		return true;
	else if (obj.trim === undefined || obj.length === undefined)
		return false;
	else
		return obj.trim().length === 0;  
}