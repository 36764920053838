import React from "react";
import { Table, Panel, Field, Label, TextBox, DisplayTypes, Switch, useComponentContext} from "lib/components";
import { getDataListFromContext } from "lib/util/ModelUtil";
import { setDataContextValue } from "lib/components/DataContainer";
import { getCompanySettings } from "lib/util";
import { CustomerType, PanelMode, PageType } from "./ControlConstants";

//this table renders differently depending on what step of the wizard is being viewed
export function FreightItemsTable(customerType, mode, page, prevStep, props = {}) {
  let freightItemColumnTotalWarnings = {};
  freightItemColumnTotalWarnings.weight = {
    maxValue: getCompanySettings().ltl_max_weight,
    message: "Contact " + getCompanySettings().order_contact_name + " at " + getCompanySettings().order_contact_phone + " for shipments weighing more than " + getCompanySettings().ltl_max_weight + " lbs.",
    persistSnack: true
  }

  let freightItemMinRowsRequiredWarning = {
    rowsRequired: 1,
    message: "Please enter at least one commodity."
  }

  const freightItemBrokerageColumnTotalDisplaySettings = [
    {
      field: "weight",
      caption: "Weight",
      suffix: " lbs"
    },
    {
      field: "handling_units",
      caption: "Handling Units",
    }
  ];

  const freightItemAssetColumnTotalDisplaySettings = [
    {
      field: "pieces",
      caption: "Pieces"
    },
    {
      field: "weight",
      caption: "Weight",
      suffix: " lbs"
    },
    {
      field: "req_spots",
      caption: "Spots"
    },
    {
      field: "handling_units",
      caption: "Handling Units",
    }
  ];

  const uom = getCompanySettings().ltl_freight_dimension_uom;

  const editAssetOrderProps = {
    field: "freight_items",
    caption: "Freight Information (Adding Commodities)",
    filterVisible: false,
    allowExport: false,
    initialAddRow: true,
    displayAsCards: true,
    rowEditor: () => PanelFreightItemEditAssetOrder(props, uom),
    addCaption: "Add Item",
    emptyCaption: "No freight has been added to this quote.",
    unfinishedEditWarning: "Please save your commodity before continuing.",
    minRowsRequiredWarning: freightItemMinRowsRequiredWarning,
    columnTotalWarnings: freightItemColumnTotalWarnings,
    columnTotalDisplaySettings: freightItemAssetColumnTotalDisplaySettings,
    fillRow: true,
    maxHeight: 1000,
    rowBreak: true,
    debugID: 1,
    componentPropsCallback: componentProps
  };

  const editAssetOverviewProps = {
    field: "freight_items",
    caption: "Freight Information (Adding Commodities)",
    filterVisible: false,
    allowExport: false,
    initialAddRow: (prevStep == undefined)? true : false,
    displayAsCards: true,
    rowEditor: () => PanelFreightItemEditAssetOverview(props, uom),
    addCaption: "Add Item",
    emptyCaption: "No freight has been added to this quote.",
    unfinishedEditWarning: "Please save your commodity before continuing.",
    minRowsRequiredWarning: freightItemMinRowsRequiredWarning,
    columnTotalWarnings: freightItemColumnTotalWarnings,
    columnTotalDisplaySettings: freightItemAssetColumnTotalDisplaySettings,
    fillRow: true,
    maxHeight: 450,
    rowBreak: true,
    debugID: 1,
    componentPropsCallback: OverviewComponentProps
  };

  const editAssetDetailsProps = {
    field: "freight_items",
    caption: "Freight Information",
    filterVisible: false,
    allowExport: false,
    initialAddRow: false,
    rowEditor: () => PanelFreightItemEditAssetDetails(props, uom),
    addVisible: false,
    showDelete: () => {return false},
    maxHeight: 1000,
    displayAsCards: true,
    columnTotalDisplaySettings: freightItemAssetColumnTotalDisplaySettings,
    autoSave: true,
    debugID: 2,
    componentPropsCallback: componentProps
  };

  const editBrokerageOverviewProps = {
    field: "freight_items",
    caption: "Freight Information (Adding Commodities)",
    filterVisible: false,
    allowExport: false,
    initialAddRow: (prevStep == undefined)? true : false,
    displayAsCards: true,
    rowEditor: () => PanelFreightItemEditBrokerageOverview(props, uom),
    addCaption: "Add Item",
    emptyCaption: "No freight has been added to this quote.",
    unfinishedEditWarning: "Please save your commodity before continuing.",
    minRowsRequiredWarning: freightItemMinRowsRequiredWarning,
    columnTotalWarnings: freightItemColumnTotalWarnings,
    columnTotalDisplaySettings: freightItemBrokerageColumnTotalDisplaySettings,
    fillRow: true,
    maxHeight: 450,
    rowBreak: true,
    debugID: 1,
    componentPropsCallback: OverviewComponentProps
  };

  const editBrokerageDetailsProps = {
    field: "freight_items",
    caption: "Freight Information",
    filterVisible: false,
    allowExport: false,
    initialAddRow: false,
    rowEditor: () => PanelFreightItemEditBrokerageDetails(props, uom),
    addVisible: false,
    showDelete: () => {return false},
    maxHeight: 1000,
    displayAsCards: true,
    columnTotalDisplaySettings: freightItemBrokerageColumnTotalDisplaySettings,
    autoSave: true,
    debugID: 2,
    componentPropsCallback: componentProps
  };

  const displayBrokerageProps = {
    field: "freight_items",
    caption: "Freight Information",
    filterVisible: false,
    allowExport: false,
    initialAddRow: false,
    rowEditor: () => PanelFreightItemDisplay(props, uom),
    addVisible: false,
    showDelete: () => {return false},
    showTools: false,
    maxHeight: 1000,
    displayAsCards: true,
    columnTotalDisplaySettings: freightItemBrokerageColumnTotalDisplaySettings,
    autoSave: true,
    debugID: 2
  };

  const displayAssetProps = {
    field: "freight_items",
    caption: "Freight Information",
    filterVisible: false,
    allowExport: false,
    initialAddRow: false,
    rowEditor: () => PanelFreightItemDisplay(props, uom),
    addVisible: false,
    showDelete: () => {return false},
    showTools: false,
    maxHeight: 1000,
    displayAsCards: true,
    columnTotalDisplaySettings: freightItemAssetColumnTotalDisplaySettings,
    autoSave: true,
    debugID: 2
  };

  let freightItemProps = null;
  if(mode === PanelMode.EDIT) {
    if(customerType === CustomerType.BROKERAGE) {
      if(page === PageType.DETAILS)
        freightItemProps = editBrokerageDetailsProps;
      else if (page === PageType.OVERVIEW)
        freightItemProps = editBrokerageOverviewProps;
    }
    else if(customerType === CustomerType.ASSET) {
      if(page === PageType.DETAILS){
        freightItemProps = editAssetDetailsProps;
      }
      else if (page === PageType.OVERVIEW){
        freightItemProps = editAssetOverviewProps;
      }
      else if(page === PageType.ORDER)
        freightItemProps = editAssetOrderProps;
    }
  }
  else if(mode === PanelMode.DISPLAY) {
    if(customerType === CustomerType.BROKERAGE) {
      freightItemProps = displayBrokerageProps;
    }
    else if(customerType === CustomerType.ASSET) {
      freightItemProps = displayAssetProps;
    }
  }

  if(freightItemProps == null)
    freightItemProps = displayBrokerageProps;

  return <Table {...freightItemProps} />;
}

function componentProps(context, _, origComponentProps) {
  let result = {...origComponentProps};
  if(context && context.modelData) {
    const hazmatFieldsRequired = context.modelData.hazmat === "Y";
    result.hazmat_unna_nbr = {...result.hazmat_unna_nbr, ...{required: hazmatFieldsRequired}};
    result.hazmat_proper_shipname = {...result.hazmat_proper_shipname, ...{required: hazmatFieldsRequired}};
    result.hazmat_class_code = {...result.hazmat_class_code, ...{required: hazmatFieldsRequired}};
    result.hazmat_packing_group = {...result.hazmat_packing_group, ...{required: hazmatFieldsRequired}};
  }
  return result;
}

function OverviewComponentProps(context, _, origComponentProps){
  let result = {...origComponentProps};
  if(context && context.modelData) {
    if(context.modelData.hazmat === "Y") {
      result.hazmat_ref_type_code = {lookupModelResultField: "value"};
      result.poison_zone = {lookupModelResultField: "value"};
      result.hazmat_packing_group = {lookupModelResultField: "value"};
      result.hazmat_class_code = {lookupModelResultField: "value"};
    }
  }
  return result;
}

function PanelFreightItemEditAssetOrder(props, uom) {
  return (
    <Panel rowBreakDefault={false} {...props} >
        <Field field="commodity_id" lookupModel="dispatch/commodity-product-book" onItemChanged={(context, items, index) => handleCommodityChange(context, items, index)} width={236} divider />
        <Field field="pieces" required={true} onChange={(value, context) => handlePiecesChange(value, context)} divider />
        <Field field="weight" errorRolloverMessage={"Please enter in the format of ######.#."} divider />
        <Field field="nmfc_class_code" divider/>
        <Field field="req_spots" divider/>
        <Field field="handling_units" onChange={(value, context) => handleHandlingUnitsChange(value, context)} divider />
        <Field field="fgi_packaging_type_code" default="SKID" rowBreak />
        <Field field="description" divider/>
        <Field field="nmfc_code" />
        <Field field="length" caption={`Length (${uom})`} placeholder={"Per HU"} errorRolloverMessage={"Please enter in the format of ####.##."} required={false} />
        <Field field="width" caption={`Width (${uom})`} placeholder={"Per HU"} errorRolloverMessage={"Please enter in the format of ####.##."} required={false} />
        <Field field="height" caption={`Height (${uom})`} placeholder={"Per HU"} errorRolloverMessage={"Please enter in the format of ####.##."} required={false} divider/>
        <Switch field="hazmat" onChange={(value, context) => handleHazmatSwitchChange(value, context)} />
        <Panel visible={isHazmat} rowBreakDefault={false}>
          <PanelHazmat {...props} />
        </Panel>
    </Panel>
  );
}

function PanelFreightItemEditAssetOverview(props, uom) {
  return (
    <Panel rowBreakDefault={false} {...props} >
        <Field field="commodity_id" lookupModel="dispatch/commodity-product-book" onItemChanged={(context, items, index) => handleCommodityChange(context, items, index)} width={236} divider />
        <Field field="pieces" required={true} onChange={(value, context) => handlePiecesChange(value, context)} divider />
        <Field field="weight" errorRolloverMessage={"Please enter in the format of ######.#."} divider />
        <Field field="nmfc_class_code" divider/>
        <Field field="req_spots" divider/>
        <Field field="handling_units" onChange={(value, context) => handleHandlingUnitsChange(value, context)} divider />
        <Field field="fgi_packaging_type_code" default="SKID" rowBreak />
        <Field field="description" divider/>
        <Field field="nmfc_code" />
        <Field field="length" caption={`Length (${uom})`} placeholder={"Per HU"} errorRolloverMessage={"Please enter in the format of ####.##."} required={false} />
        <Field field="width" caption={`Width (${uom})`} placeholder={"Per HU"} errorRolloverMessage={"Please enter in the format of ####.##."} required={false} />
        <Field field="height" caption={`Height (${uom})`} placeholder={"Per HU"} errorRolloverMessage={"Please enter in the format of ####.##."} required={false} divider/>
        <Switch field="hazmat" rowBreak />
    </Panel>
  );
}

function PanelFreightItemEditBrokerageOverview(props, uom) {
  const packagingDefault = {field_code:"BOX"};
  const context = useComponentContext();
  return (
    <Panel rowBreakDefault={false} {...props} >
        <Field field="commodity_id" lookupModel="dispatch/commodity-product-book" onItemChanged={(context, items, index) => handleCommodityChange(context, items, index)} width={236} divider />
        <Field field="weight" errorRolloverMessage={"Please enter in the format of ######.#."} divider />
        <Field field="nmfc_class_code" divider/>
        <Field field="handling_units" onChange={(value, context) => handleHandlingUnitsChange(value, context)} divider />
        <Field field="length" caption={`Length (${uom})`} placeholder={"Per HU"} errorRolloverMessage={"Please enter in the format of ####.##."} />
        <Field field="width" caption={`Width (${uom})`} placeholder={"Per HU"} errorRolloverMessage={"Please enter in the format of ####.##."} />
        <Field field="height" caption={`Height (${uom})`} placeholder={"Per HU"} errorRolloverMessage={"Please enter in the format of ####.##."} divider/>
        <Field field="fgi_packaging_type_code" default="SKID" divider />
        <Switch field="hazmat" rowBreak />
        <Field field="pieces" onChange={(value, context) => handlePiecesChange(value, context)} divider />
        <Field field="req_spots" divider/>
        <Field field="description" divider/>
        <Field field="nmfc_code" rowBreak/>
    </Panel>
  );
}

function PanelFreightItemEditAssetDetails(props, uom) {
  return (
    <Panel rowBreakDefault={false} {...props}>
        <Field field="commodity_id" usePrintableVersion width={236} divider />
        <Field field="pieces" usePrintableVersion divider />
        <Field field="weight" usePrintableVersion/>
        <Field field="nmfc_class_code" usePrintableVersion/>
        <Field field="req_spots" usePrintableVersion divider/>
        <Field field="handling_units" usePrintableVersion/>
        <Field field="fgi_packaging_type_code" usePrintableVersion rowBreak/>
        <Field field="description" divider/>
        <Field field="nmfc_code" />
        <Field field="length" caption={`Length (${uom})`} required={false} usePrintableVersion/>
        <Field field="width" caption={`Width (${uom})`} required={false} usePrintableVersion/>
        <Field field="height" caption={`Height (${uom})`} required={false} usePrintableVersion/>
        <TextBox field="hazmat" displayType="string" usePrintableVersion rowBreak/>
        <Panel visible={isHazmat} rowBreakDefault={false}>
          <PanelHazmat {...props} />
        </Panel>
    </Panel>
  );
}

function PanelFreightItemEditBrokerageDetails(props, uom) {
  return (
    <Panel rowBreakDefault={false} {...props}>
        <Field field="commodity_id" usePrintableVersion width={236} divider />
        <Field field="weight" usePrintableVersion/>
        <Field field="nmfc_class_code" usePrintableVersion/>
        <Field field="handling_units" usePrintableVersion/>
        <Field field="length" caption={`Length (${uom})`} usePrintableVersion/>
        <Field field="width" caption={`Width (${uom})`} usePrintableVersion/>
        <Field field="height" caption={`Height (${uom})`}  usePrintableVersion/>
        <Field field="fgi_packaging_type_code" usePrintableVersion/>
        <TextBox field="hazmat" displayType="string" usePrintableVersion rowBreak/>
        <Field field="pieces" divider />
        <Field field="req_spots" divider/>
        <Field field="description" divider/>
        <Field field="nmfc_code" rowBreak/>
        <Panel visible={isHazmat} rowBreakDefault={false}>
          <PanelHazmat {...props} />
        </Panel>
    </Panel>
  );
}

function PanelFreightItemDisplay(props, uom) {
  return (
    <Panel rowBreakDefault={false} {...props}>
        <Field field="commodity_id" usePrintableVersion width={236} divider />
        <Field field="weight" usePrintableVersion/>
        <Field field="nmfc_class_code" usePrintableVersion/>
        <Field field="handling_units" usePrintableVersion/>
        <Field field="length" caption={`Length (${uom})`} usePrintableVersion/>
        <Field field="width" caption={`Width (${uom})`} usePrintableVersion/>
        <Field field="height" caption={`Height (${uom})`} usePrintableVersion/>
        <Field field="fgi_packaging_type_code" usePrintableVersion/>
        <TextBox field="hazmat" displayType="string" usePrintableVersion rowBreak/>
        <Field field="pieces" divider usePrintableVersion />
        <Field field="req_spots" divider usePrintableVersion/>
        <Field field="description" divider usePrintableVersion/>
        <Field field="nmfc_code" rowBreak usePrintableVersion/>
        <Panel visible={isHazmat} rowBreakDefault={false}>
          <PanelHazmat {...props} readOnly={true} />
        </Panel>
    </Panel>
  );
}

function handleHazmatSwitchChange(value, context)
{
  const isHazmat = (value === true)? "Y" : "N";
  setDataContextValue(context, "hazmat", isHazmat, true);
}

function handleCommodityChange(context, items, index)
{
  updateNonPerPieceFields(context);
  setDataContextValue(context, "handling_units", undefined, true);
  setDataContextValue(context, "req_spots", undefined, true);
  setDataContextValue(context, "weight", undefined, true);
  setDataContextValue(context, "pieces", 1, true);
  handlePiecesChange(1, context);
}

function updateNonPerPieceFields(context)
{
  const commodityInfo = context.data.list[context.dataIndex].modelData.commodity_id;
  if (commodityInfo === null || commodityInfo === undefined)
  { return; }

  if (commodityInfo.nmfc_class_code != null)
  { setDataContextValue(context, "nmfc_class_code", commodityInfo.nmfc_class_code, true); }
  else
  { setDataContextValue(context, "nmfc_class_code", undefined, true); }

  if (commodityInfo.packaging_type_code != null)
  { setDataContextValue(context, "fgi_packaging_type_code", commodityInfo.packaging_type_code, true); }
  else
  { setDataContextValue(context, "fgi_packaging_type_code", undefined, true); }

  if (commodityInfo.length != null)
  { setDataContextValue(context, "length", commodityInfo.length, true); }
  else
  { setDataContextValue(context, "length", undefined, true); }

  if (commodityInfo.width != null)
  { setDataContextValue(context, "width", commodityInfo.width, true); }
  else
  { setDataContextValue(context, "width", undefined, true); }

  if (commodityInfo.height != null)
  { setDataContextValue(context, "height", commodityInfo.height, true); }
  else
  { setDataContextValue(context, "height", undefined, true); }

  if (commodityInfo.is_hazmat != null)
  { setDataContextValue(context, "hazmat", commodityInfo.is_hazmat, true); }
  else
  { setDataContextValue(context, "hazmat", undefined, true); }

  if (commodityInfo.nmfc_code != null)
  { setDataContextValue(context, "nmfc_code", commodityInfo.nmfc_code, true); }
  else
  { setDataContextValue(context, "nmfc_code", undefined, true); }

  if (commodityInfo.descr != null)
  { setDataContextValue(context, "description", commodityInfo.descr, true); }
  else
  { setDataContextValue(context, "description", undefined, true); }

  if (commodityInfo.hazmat_number != null)
  { setDataContextValue(context, "hazmat_unna_nbr", commodityInfo.hazmat_number, true); }
  else
  { setDataContextValue(context, "hazmat_unna_nbr", undefined, true); }

  if (commodityInfo.hazmat_class_code != null)
  { setDataContextValue(context, "hazmat_class_code", commodityInfo.hazmat_class_code, true); }
  else
  { setDataContextValue(context, "hazmat_class_code", undefined, true); }

  if (commodityInfo.hazmat_packing_group != null)
  { setDataContextValue(context, "hazmat_packing_group", commodityInfo.hazmat_packing_group, true); }
  else
  { setDataContextValue(context, "hazmat_packing_group", undefined, true); }

  if (commodityInfo.hazmat_proper_shipname != null)
  { setDataContextValue(context, "hazmat_proper_shipname", commodityInfo.hazmat_proper_shipname, true); }
  else
  { setDataContextValue(context, "hazmat_proper_shipname", undefined, true); }

  if (commodityInfo.hazmat_erg_number != null)
  { setDataContextValue(context, "hazmat_erg_number", commodityInfo.hazmat_erg_number, true); }
  else
  { setDataContextValue(context, "hazmat_erg_number", undefined, true); }
}

function handlePiecesChange(value, context)
{
  const commodityInfo = context.data.list[context.dataIndex].modelData.commodity_id;
  if (commodityInfo === null || commodityInfo === undefined)
  { return; }

  if (commodityInfo.handling_units_is_per_piece === "Y")
  { setDataContextValue(context, "handling_units", commodityInfo.handling_units != null ? (value * commodityInfo.handling_units) : undefined, true); }
  else
  { setDataContextValue(context, "handling_units", commodityInfo.handling_units != null ? commodityInfo.handling_units : undefined, true); }

  const handlingUnits = commodityInfo.handling_units != null ? commodityInfo.handling_units : undefined;
  if (commodityInfo.spots_is_per_piece === "Y")
  { setDataContextValue(context, "req_spots", commodityInfo.req_spots != null ? (value * commodityInfo.req_spots) : handlingUnits, true); }
  else
  { setDataContextValue(context, "req_spots", commodityInfo.req_spots != null ? commodityInfo.req_spots : handlingUnits, true); }

  if (commodityInfo.weight_is_per_piece === "Y")
  { setDataContextValue(context, "weight", commodityInfo.weight != null ? (value * commodityInfo.weight) : undefined, true); }
  else
  { setDataContextValue(context, "weight", commodityInfo.weight != null ? commodityInfo.weight : undefined, true); }
}

function handleHandlingUnitsChange(value, context) {
  const handlingUnits = context.data.list[context.dataIndex].modelData.handling_units;
  if(handlingUnits != null)
    setDataContextValue(context, "req_spots", value, true);
}

function isHazmat(context) {
  return getDataListFromContext(context, "hazmat") === "Y";
}

function PanelHazmat(props){
  const hazardClassItems = [{caption: "1.1", value: "1.1"}, {caption: "1.2", value: "1.2"}, {caption: "1.3", value: "1.3"}, {caption: "1.4", value: "1.4"}, {caption: "1.5", value: "1.5"},
  {caption: "1.6", value: "1.6"}, {caption: "2.1", value: "2.1"}, {caption: "2.2", value: "2.2"}, {caption: "2.3", value: "2.3"}, {caption: "3", value: "3"}, {caption: "4.1", value: "4.1"},
  {caption: "4.2", value: "4.2"}, {caption: "4.3", value: "4.3"}, {caption: "5.1", value: "5.1"}, {caption: "5.2", value: "5.2"}, {caption: "6.1", value: "6.1"}, {caption: "6.2", value: "6.2"},
  {caption: "7", value: "7"}, {caption: "8", value: "8"}, {caption: "9", value: "9"}];
  const packagingGroupItems = [{caption: "I", value:"I"},{caption: "II", value:"II"},{caption: "III", value:"III"},{caption: "N/A", value:"N/A"}];
  const poisonZoneItems = [{caption: "A", value:"A"},{caption: "B", value:"B"},{caption: "C", value:"C"},{caption: "D", value:"D"},{caption: "Unknown", value:"Unknown"}];
  const contactType = [{caption: "Person", value:"CNID"},{caption: "Contract", value:"CNTC"}];
  if(props.readOnly === true) {
    return(
      <Panel rowBreakDefault={false} >
        <Field caption="Hazmat ID" field="hazmat_unna_nbr" lookupModelResultField="id" divider required={true} onItemChanged={(context, items, index) => handleHazmatChange(context, items, index)} usePrintableVersion />
        <TextBox caption="Hazmat Class" items={hazardClassItems} field="hazmat_class_code" required={true} divider usePrintableVersion/>
        <Field field="hazmat_subclass_code" caption="Subsidiary Class" divider usePrintableVersion/>
        <TextBox field="hazmat_packing_group" caption="Packaging Group" items={packagingGroupItems} required={true} divider usePrintableVersion/>
        <Field field="hazmat_erg_number" caption="ERG Number" rowBreak usePrintableVersion/>
        <Field field="hazmat_proper_shipname" caption="Proper Shipping Name" required={true} divider usePrintableVersion/>
        <Switch caption="Poison Inhalation" field="is_poison" divider usePrintableVersion/>
        <Field field="poison_zone" caption="Poison Zone" items={poisonZoneItems} rowBreak usePrintableVersion/>
        <Field field="hazmat_emergency_number" caption="Emergency Number" displayType={DisplayTypes.PHONE} divider usePrintableVersion/>
        <Field caption="Contact Type" field="hazmat_ref_type_code" items={contactType} lookupModelResultField = "value" divider usePrintableVersion/>
        <Field caption="Emergency Contact" field="hazmat_reference_data" width={350} usePrintableVersion/>
      </Panel>
    );
  }
  else {
    return(
      <Panel rowBreakDefault={false} >
        <Field caption="Hazmat ID" field="hazmat_unna_nbr" divider required={true} onItemChanged={(context, items, index) => handleHazmatChange(context, items, index)}/>
        <TextBox caption="Hazmat Class" items={hazardClassItems} field="hazmat_class_code" required={true} divider/>
        <Field field="hazmat_subclass_code" caption="Subsidiary Class" divider/>
        <TextBox field="hazmat_packing_group" caption="Packaging Group" items={packagingGroupItems} required={true} divider/>
        <Field field="hazmat_erg_number" caption="ERG Number" rowBreak/>
        <Field field="hazmat_proper_shipname" caption="Proper Shipping Name" required={true} divider/>
        <Switch caption="Poison Inhalation" field="is_poison" divider/>
        <Field field="poison_zone" caption="Poison Zone" items={poisonZoneItems} rowBreak/>
        <Field field="hazmat_emergency_number" caption="Emergency Number" displayType={DisplayTypes.PHONE} divider/>
        <Field caption="Contact Type" field="hazmat_ref_type_code" items={contactType} divider/>
        <Field caption="Emergency Contact" field="hazmat_reference_data" width={350}/>
      </Panel>
    );
  }
}


function PanelHazmatReadOnly(props){
  const hazardClassItems = [{caption: "blank", value:""},{caption: "1.1", value: "1.1"}, {caption: "1.2", value: "1.2"}, {caption: "1.3", value: "1.3"}, {caption: "1.4", value: "1.4"}, {caption: "1.5", value: "1.5"},
  {caption: "1.6", value: "1.6"}, {caption: "2.1", value: "2.1"}, {caption: "2.2", value: "2.2"}, {caption: "2.3", value: "2.3"}, {caption: "3", value: "3"}, {caption: "4.1", value: "4.1"},
  {caption: "4.2", value: "4.2"}, {caption: "4.3", value: "4.3"}, {caption: "5.1", value: "5.1"}, {caption: "5.2", value: "5.2"}, {caption: "6.1", value: "6.1"}, {caption: "6.2", value: "6.2"},
  {caption: "7", value: "7"}, {caption: "8", value: "8"}, {caption: "9", value: "9"}];
  const packagingGroupItems = [{caption: "blank", value:""},{caption: "I", value:"I"},{caption: "II", value:"II"},{caption: "III", value:"III"},{caption: "N/A", value:"N/A"}];
  const poisonZoneItems = [{caption: "blank", value:""},{caption: "A", value:"A"},{caption: "B", value:"B"},{caption: "C", value:"C"},{caption: "D", value:"D"},{caption: "Unknown", value:"Unknown"}];
  const contactType = [{caption: "blank", value:""},{caption: "Person", value:"CNID"},{caption: "Contract", value:"CNTC"}];
  return(
    <Panel rowBreakDefault={false} >
      <Field caption="Hazmat ID" field="hazmat_unna_nbr" divider required={true} onItemChanged={(context, items, index) => handleHazmatChange(context, items, index)} usePrintableVersion/>
      <TextBox caption="Hazmat Class" items={hazardClassItems} field="hazmat_class_code" required={true} divider usePrintableVersion/>
      <Field field="hazmat_subclass_code" caption="Subsidiary Class" divider usePrintableVersion/>
      <TextBox field="hazmat_packing_group" caption="Packaging Group" items={packagingGroupItems} required={true} divider usePrintableVersion/>
      <Field field="hazmat_erg_number" caption="ERG Number" rowBreak usePrintableVersion />
      <Field field="hazmat_proper_shipname" caption="Proper Shipping Name" required={true} divider usePrintableVersion/>
      <Switch caption="Poison Inhalation" field="is_poison" divider usePrintableVersion/>
      <Field field="poison_zone" caption="Poison Zone" items={poisonZoneItems} rowBreak usePrintableVersion/>
      <Field field="hazmat_emergency_number" caption="Emergency Number" displayType={DisplayTypes.PHONE} divider usePrintableVersion/>
      <Field caption="Contact Type" field="hazmat_ref_type_code" items={contactType} divider usePrintableVersion/>
      <Field caption="Emergency Contact" field="hazmat_reference_data" width={350} usePrintableVersion/>
    </Panel>
  );
}

function handleHazmatChange(context, items, index)
{
  const hazmatInfo = context.data.list[context.dataIndex].modelData.hazmat_unna_nbr;
  if (hazmatInfo === null || hazmatInfo === undefined)
  { return; }

  if (hazmatInfo.hazmat_class_code != null)
  { setDataContextValue(context, "hazmat_class_code", hazmatInfo.hazmat_class_code, true); }
  else
  { setDataContextValue(context, "hazmat_class_code", undefined, true); }

  if (hazmatInfo.hazmat_packing_group != null)
  { setDataContextValue(context, "hazmat_packing_group", hazmatInfo.hazmat_packing_group, true); }
  else
  { setDataContextValue(context, "hazmat_packing_group", undefined, true); }

  if (hazmatInfo.hazmat_proper_shipname != null)
  { setDataContextValue(context, "hazmat_proper_shipname", hazmatInfo.hazmat_proper_shipname, true); }
  else
  { setDataContextValue(context, "hazmat_proper_shipname", undefined, true); }

  if (hazmatInfo.hazmat_erg_number != null)
  { setDataContextValue(context, "hazmat_erg_number", hazmatInfo.hazmat_erg_number, true); }
  else
  { setDataContextValue(context, "hazmat_erg_number", undefined, true); }
}
