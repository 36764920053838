import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  noWrap: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  wordWrap: {
    whiteSpace: "normal"
  },
  fontWeightRegular: {
    fontWeight: theme.typography.fontWeightRegular
  },
  fontWeightBold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  textTransformRegular: {
    textTransform: "none"
  },
  textTransformUppercase: {
    textTransform: "uppercase"
  },
  textTransformCapitalize: {
    textTransform: "capitalize"
  },
  textTransformLowerCase: {
    textTransform: "lowercase"
  },
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(10)
  },
  fontSizeMedium: {
    fontSize: theme.typography.pxToRem(12)
  },
  fontsizeLarge: {
    fontSize: theme.typography.pxToRem(14)
  },
  fontColorLight: {
    color: theme.palette.common.smoke
  },
  fontColorMain: {
    color: theme.palette.common.smog
  },
  fontColorDark: {
    color: theme.palette.common.tar
  },
  fontColorPrimary: {
    color: theme.palette.primary.main
  },
  fontColorSecondary: {
    color: theme.palette.secondary.main
  },
  fontColorWarning: {
    color: theme.palette.warning.main
  },
  label: {
    extend: "fontSizeSmall",
    color: theme.palette.common.smoke
  }
}));
