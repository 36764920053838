import React from "react";
import { TextBox } from "lib/components";
import { getCompanySettings } from "lib/util";
import { format, addDays } from 'date-fns'
import { isHoliday } from "lib/util/Date";
import { useComponentContext } from "../../lib/components";
import { getDataContextValue, setDataContextValue } from "../../lib/components/DataContainer";

export function MaxDaysOut(props = {})
{
  const context = useComponentContext();
  if (props.field == null) {
    return null;
  }

  const dateItems = getDatesBasedOnMaxDaysOut(props); 
  let defaultDate = null;
  
  if(context){
    const shipDate = getDataContextValue(context, "ship_date");
    if(shipDate != null && !(shipDate instanceof Object)){
    const newShipDate = new Date(shipDate);
      newShipDate.setHours(0,0,0,0);
      for(var i = 0, len = dateItems.length; i < len; i++) {
        if (new Date(dateItems[i].value).getTime() === newShipDate.getTime()) {
          setDataContextValue(context, "ship_date", dateItems[i]);
          break;
        }
      }
    }
  }
  if (dateItems != null && dateItems.length > 0) {
    defaultDate = dateItems[0].value;
  }
  return <TextBox {...props} displayType="string" default={defaultDate} items={dateItems} />;
}

export function getDatesBasedOnMaxDaysOut(props = {})
{
  let includeSunday = getCompanySettings().ltl_include_sunday;
  if (props.includeSunday === true) {
    includeSunday = true;
  }

  let includeSaturday = getCompanySettings().ltl_include_saturday;
  if (props.includeSaturday === true) {
    includeSaturday = true;
  }

  let includeHolidays = getCompanySettings().ltl_include_holiday;
  if (props.includeHolidays === true) {
    includeHolidays = true;
  }

  let dateItems = [];
  let todayStr = "";
  const dateFormatValue = 'yyyy-MM-dd HH:mm';
  const dateFormatDropDown = 'MM/dd';
  const maxDaysOut = getCompanySettings().ltl_max_days_out;

  if(maxDaysOut && maxDaysOut >= 0) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    todayStr = format(today, dateFormatValue);
    if (includeDay(today, includeSunday, includeSaturday, includeHolidays)) {
      dateItems.push({ caption: "Today (" + format(today, dateFormatDropDown) + ")", value: todayStr });
    }
    for(let i = 1; i <= maxDaysOut; i++) {
      let nextDay = addDays(today, i);
      if (!includeDay(nextDay, includeSunday, includeSaturday, includeHolidays)) {
        continue;
      }
      const nextDayStr = format(nextDay, dateFormatValue);
      if(i === 1) {
        dateItems.push({ caption: "Tomorrow (" + format(nextDay, dateFormatDropDown) + ")", value: nextDayStr });
      }
      else {
        dateItems.push({ caption: format(nextDay, 'EEEE (' + dateFormatDropDown + ')'), value: nextDayStr });
      }
    }
  }
  return dateItems;
}

function includeDay(date, includeSunday, includeSaturday, includeHolidays)
{
  const dayOfWeek = date.getDay();
  if (dayOfWeek === 0 && !includeSunday) {
    return false;
  }
  else if (dayOfWeek === 6 && !includeSaturday) {
    return false;
  }
  else if (isHoliday(date) && !includeHolidays) {
    return false;
  }
  return true;
}