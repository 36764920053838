import React from "react";
import { Component } from ".";
import { getLogger } from "lib/util";
import { GoogleMap } from "./map-impl/GoogleMap";
import { ALKMap } from "./map-impl/ALKMap";
import { useComponentContext } from "./Context";
import { DataContainer, getDataContainerProps } from "./DataContainer";
import { getSVGUrl } from "./Image";
import { getDataListFromContext } from "lib/util/ModelUtil";

const log = getLogger("lib.components.Map");

export function Map({...props}) {
  if (props.modelName || props.data != null) { // but not if ancestor data is just passing through to children
    return (
      <DataContainer {...getDataContainerProps(props)} autoSearch={props.modelName != null}>
        <MapInternal {...props} />
      </DataContainer>
    );
  }
  else
    return <MapInternal {...props} />
}

function MapInternal(props) {
	const context = useComponentContext();
	const data = getDataListFromContext(context, props.field);
	log.debug("Map context %o   props %o  data %o", context, props, data);
	let pins = props.pins;
	if (pins == null && data != null)
		pins = createPins(data, props);
	let result;
	const dataList = context.data.list;
	if(props.setActualRecordCount){
		if(context.data.actualRowCount != null){
		  props.setActualRecordCount(context.data.actualRowCount);
		}
		else {
		  props.setActualRecordCount(dataList.length);
		}
	}
	if (props.mapType == null || props.mapType === "google")
		result = <GoogleMap pins={pins} crumbs={props.crumbs} onPinClick={props.onPinClick}
			name={props.name} zoom={props.zoom} />
	else if (props.mapType === "alk")
		result = <ALKMap pins={pins} zoom={props.zoom} />
	else
		result = <div>Unrecognized map type {props.mapType} </div>
	result = (
		<Component {...props} >
			{result}
		</Component>
	);
	log.debug("Map result %o", result);
	return result;
}

function createPins(data, props) {
	log.debug("create pins with data %o props %o", data, props);
	if (data == null || (props.setPinProps == null && (props.latField == null || props.longField == null)))
		return null;
  let result = [];
  if (data[0] != null && data[0].modelData != null) {
    if (props.tractorLat && props.tractorLng) {
      let tractorPin = {pinId: "pin-tractor-0"}
      tractorPin.lat = data[0].modelData[props.tractorLat]
      tractorPin.lng = data[0].modelData[props.tractorLng]
      tractorPin.clusterable = false;
	  tractorPin.image = getSVGUrl("map-pin-location");
      result.push(tractorPin);
    }
  }
	for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.modelData != null)
      item = item.modelData;
	if (dataPassesFilter(item, props.filter)) {
      let pin = { pinId: "pin-" + i, data: item };
			if (props.latField)
				pin.lat = pin.data[props.latField];
			if (props.longField)
				pin.lng = pin.data[props.longField];
			if (props.captionField) {
				if (pin.data[props.captionField])
					pin.caption = "" + pin.data[props.captionField];
			}
			if (props.imageField != null && pin.data[props.imageField] != null)
				pin.image = getSVGUrl(pin.data[props.imageField]);
			if (props.captionColorField)
				pin.captionColor = pin.data[props.captionColorField];
			if (props.tooltipField)
				pin.tooltip = pin.data[props.tooltipField];
			if (props.clusterPins === false)
				pin.clusterable = false;
			else if (props.clusterableField)
				pin.clusterable = pin.data[props.clusterableField];
			if (props.setPinProps)
				props.setPinProps(pin);
      if (pin.visible !== false && pin.lat != null && pin.lng != null && pin.lat !== "NaN" && pin.lng !== "NaN")
        result.push(pin);
	}
  };
	log.debug("Pins %o", result);
	return result;
}

function dataPassesFilter(data, filter) {
	if (filter == null)
		return true;
	else
		return filter(data);
}
