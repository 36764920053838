import React from "react";
import { Panel } from "lib/components";
import { getThemeColor } from "lib/util/Theme";

/*
  Provide settings to this panel like this:
  - borderWidth and borderColor are first-level props
  note: height can be omitted
*/

export function SegmentedPanel(props) {
  const borderWidth = props.borderWidth || 1;
  const borderColor = props.borderColor || getThemeColor("subtle", "light");
  const segmentPanels = [];
  const children = props.children;
  for (let x=0 ; x<children.length ; x++)
  {
    const child = children[x];
    if (child.props.visible !== false)
      segmentPanels.push(
        <Panel
          height = {child.props.height != null ? child.props.height : null}
          borderTopWidth={segmentPanels.length > 0 ? borderWidth : 0}
          borderBottomWidth={0}
          borderTopColor={borderColor}
          paddingLeft={4}
          paddingTop={4}
          paddingBottom={4}
          marginTop={0}
          marginBottom={0}
          marginLeft={-5}
          marginRight={-5}
          key={x}
          fillRow>
          {child}
        </Panel>
      );
  }
  return (
    <Panel {...props} borderWidth={borderWidth} borderColor={borderColor} borderRadius={4}>
      {segmentPanels}
    </Panel>
  );
}
