import { makeStyles } from "lib/util";
import { getLogger } from "./Logger";

const log = getLogger("lib.util.SVGUtil");

export const useSVGStyles = makeStyles((theme) => ({
  svgStyle: props => props
}));

export function getSVGStyle(passedProps, defaultProps) {
  let style = { ...defaultProps, ...passedProps };
  let props;
  delete style.className;
  delete style.image;
  if (style.width === undefined)
    style.width = 24;
  if (style.height === undefined)
    style.height = 24;

  if (style.fill != null && style.stroke == null)
    style.stroke = style.fill;
  if (style.fill == null)
    style.fill = "none";
  if (style.stroke == null)
    style.stroke = "black";
  if (style.transform == null && (style.direction != null || style.rotate != null)) {
    if (style.rotate != null)
      props = { transform: "rotate(" + style.rotate + ")" };
    else if (style.direction === "left")
      props = { transform: "rotate(90)" };
    else if (style.direction === "up")
      props = { transform: "rotate(180)" };
    else if (style.direction === "right")
      props = { transform: "rotate(270)" };
    delete style.direction;
  }
  const result = { style, props };
  log.debug("Passed %o   Default %o     Result %o", passedProps, defaultProps, result);
  return result;
}
