import { makeStyles } from "lib/util";

let styles;
export function useLabelStyles() {
  if (styles == null)
    styles = makeStyles("lbl", {
      labelComp: { display: "flex", alignItems: "center", "letter-spacing": "0.01071em", whiteSpace: "pre-wrap" },
      noSelect: { WebkitTouchCallout: "none", WebkitUserSelect: "none", KhtmlUserSelect: "none", MozUserSelect: "none", MsUserSelect: "none", userSelect: "none" },
      h6: { fontWeight: "500", fontSize: "20px" }
    });
  return styles;
}
