
export const ThemeDefaults = {
  fontSizes: [11.5, 12, 14, 16, 18, 20, 22, 24, 28, 30, 34, 38, 42, 46],
  defaultFontSize: 14,
  background: "#fff",
  headerBackground: "#000",
  headerColor: "#fff",
  boldWeight: 500,
  palette: {
    default: {
      base: "#000",
      light: "#222",
      dark: "#000",
      reverse: "#fff"
    },
    primary: {
      base: "#137cdd",
      light: "#40a9ff",
      dark: "#054075",
      reverse: "#fff"
    },
    secondary: {
      base: "#458719",
      light: "#458719",
      dark: "#458719",
      reverse: "#fff",
    },
    tertiary: {
      base: "#f60",
      light: "#f82",
      dark: "#d40",
      reverse: "#fff",
    },
    warning: {
      base: "#f00",
      light: "#d00",
      dark: "#f22",
      reverse: "#fff",
    },
    subtle: {
      base: "#666",
      light: "#CDCDCD",
      dark: "#444",
      reverse: "#fff"
    },
  }
}
