import { makeStyles } from "lib/util";

let styles;

export function useMainMenuStyles() {
  if (styles == null)
    styles = makeStyles("mmu", {
      root: {
        minWidth: "240px",
        flexShrink: 0
      },
      rootPaper: {
        minWidth: "240px",
      },
      rootHeader: {
        height: "60px",
        display: "flex",
        alignItems: "center",
        padding: "0px 6px",
        justifyContent: "flex-end"
      },
      underAppBar: {
        marginTop: "60px",
        paddingLeft: "16px"
      },
      menuItemContent: {
        paddingLeft: "32px"
      }
    });
  return styles;
}
