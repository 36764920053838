import { getThemeClassNames } from "lib/util/Theme";
import React, { memo } from "react";
import { Component } from ".";
import { useImageStyles } from "./ImageStyles";
import { getDataFromContext } from "lib/util";
import { useComponentContext } from "./Context";

const base = require.context("../../images", true);

export function getSVGUrl(name) {
  return base("./svg/"+ name + ".svg");
}

export function getSVG(name, props) {
  if (name == null) {
    console.log("Null image name %o", props);
    return null;
  }
  try {
    let slashIndex = name.lastIndexOf("/");
    let comp;
    if (slashIndex < 0) {
      name = "SVG" + name;
      comp = name;
    }
    else {
      comp = "SVG" + name.substring(slashIndex + 1);
      name = name.substring(0, slashIndex + 1) + comp;
    }
    const module = base("./" + name);
    if (module == null) {
      console.log("Module %s was not found.", name);
      return null;
    }
    const c = module[comp];
    if (c == null) {
      console.log("Component %s was not found in %s.", comp, name);
      return null;
    }
    const f = module[comp].type;
    if (f == null) {
      console.log("Type was not found in %s.  Maybe it wasn't used with React.memo()?");
      return null;
    }
    return f(props);
  } catch (err) {
    console.log("Error loading image %s  %o  %o", name, base, err);
    return null; // should display an error image of some sort
  }
}

function ImageInternal(props) {
  const context = useComponentContext();
  let data = props.field ? getDataFromContext(context, props.field) : undefined;
  if(data == null && props.data != null)
    data = props.data;
  let classes = useImageStyles();
  let sepProps = separateComponentProps(props);
  let imgProps = sepProps.target;

  if (typeof(imgProps.image) === "object") {
    const imgObj = imgProps.image;
    const name = imgObj.image;
    delete imgProps.image;
    delete imgObj.img;
    imgProps = {...imgProps, ...imgObj}
    imgProps.image = name;
  }
  let img
  if (imgProps.image && imgProps.image.endsWith(".png")) {
    let src = base("./png/" + imgProps.image);
    img = <img style={imgProps} alt="" src={src} />
  }
  else if (data)
  { img = <img style={props.style} alt="" src={"data:image/jpeg;base64," + data} /> }
  else {
    if (imgProps.className == null)
      imgProps.className = classes.svg;
    else
      imgProps.className += " " + classes.svg;
    if (props.look != null)
      imgProps.className += " " + getThemeClassNames()[props.look];

    img = getSVG(imgProps.image, imgProps);
  }
  if (sepProps.component.width == null) {
    sepProps.component.width = 24;
    sepProps.component.height = 24;
  }
  return (
    <Component {...sepProps.component} className={classes.image} >
      {img}
    </Component>
  )
}

// this is the start of what all components might/should use to separate the properties meant for Component versus what are targeted for the nested component
function separateComponentProps(props) {
  const move = ["marginRight", "marginLeft", "marginTop", "marginBottom", "margin",
    "paddingRight", "paddingLeft", "paddingTop", "paddingBottom", "padding",
    "width", "height", "tooltip"];
  let result = {component: {}, target: {...props}};
  for (let i = 0; i < move.length; i++)
    if (result.target[move[i]] != null) {
      result.component[move[i]] = result.target[move[i]];
      delete result.target[move[i]];
  }
  return result;
}

const Image = memo(ImageInternal);
export { Image };
