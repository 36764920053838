import React from "react";
import { showWarningSnackbar } from "../../lib/components/Snackbar";
import { getCompanySettings } from "../../lib/util";
import { getMainPageContext, getUserSettings } from "../../lib/util/MainPageContext";
import {EmailLabel} from "../../common/EmailLabel";


export function validateCustomerCredit(parentType) {
    const enforceCredit = getCompanySettings().enforce_credit;
    if(enforceCredit && (enforceCredit === "L" || enforceCredit === "S")){
      const customerSettings = getMainPageContext().customer_settings;
      const creditStatus = customerSettings && customerSettings.credit_status;
      if(creditStatus && creditStatus === "D"){
        const collectionsContact = customerSettings.collections_contact;
        const contactName = collectionsContact ? collectionsContact.name : (getCompanySettings().order_contact_name || "us");
        const contactPhone = collectionsContact ? collectionsContact.phone : getCompanySettings().order_contact_phone;
        const contactEmail = collectionsContact ? collectionsContact.email : getCompanySettings().ord_email_list;
        const subject = `${getUserSettings().user_name} with ${customerSettings.id} - ${customerSettings.name} credit denied`

        let messageBegin = "Your credit status is denied.  We can accept your quote request, but we cannot accept your order. ";
        if(parentType.indexOf("order")  !== -1){
            messageBegin = "Your credit status is denied. We're unable to accept your order. ";
        }
        let messageEnd = "";
        if(contactPhone && contactEmail){
            messageEnd = "Please call "+contactName +" at "+contactPhone+" or email {e} for more information.";
        }
        else if(contactPhone && !contactEmail){
            messageEnd ="Please call "+contactName +" at "+contactPhone+" for more information.";
        }
        else if(!contactPhone && contactEmail){
            messageEnd ="Please email {e} for more information.";
        }
        showWarningSnackbar(<EmailLabel text={`${messageBegin} ${messageEnd}`} email={contactEmail} linkStyle={{color:'#535d61', fontWeight:'bold'}} subject={subject} />, true);
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }
