import * as React from "react";

function ArrowTopRightWithCircle(props) {
  return (
    <svg width="1em" height="1em" {...props}>
      <path d="M12 0a12 12 0 100 24 12 12 0 100-24z" />
      <path
        d="M7 6.96v10.07c0 .53.43.96.96.96h10.07c.53 0 .96-.43.96-.96V12c0-.27-.22-.48-.48-.48s-.48.22-.48.48v5.04H7.96V6.96H13A.48.48 0 0013 6H7.96a.96.96 0 00-.96.96zm8.48 3.24l1.4 1.4c.15.15.4.04.4-.17V8.2a.48.48 0 00-.48-.48h-3.22c-.2 0-.32.26-.17.4l1.4 1.4-2.52 2.52c-.1.1-.14.22-.14.34s.05.25.14.34c.2.2.5.2.68 0l2.52-2.52z"
      />
    </svg>
  );
}

const SVGArrowTopRightWithCircle = React.memo(ArrowTopRightWithCircle);
export { SVGArrowTopRightWithCircle };