import * as React from "react";

function Printer(props) {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" {...props}>
      <path
        fill-rule="evenodd"
        d="M17 5h-1V0H4v5H3C1.33999634 5 0 6.33999634 0 8v6h4v4h12v-4h4V8c0-1.66000366-1.33999634-3-3-3zM6 2h8v3H6V2zm10 10v-2H4v2H2V8c0-.5499878.44999695-1 1-1h14c.55000305 0 1 .4500122 1 1v4h-2zm-1-3.5c0-.55227661.44770813-1 1-1s1 .44772339 1 1c0 .55227661-.44770813 1-1 1s-1-.44772339-1-1zM6 16h8v-4H6v4z"
      />
    </svg>
  );
}

const SVGPrinter = React.memo(Printer);
export { SVGPrinter };
