import React from "react";
import { useFormControlStyles } from "./FormControlStyles";
import { Tooltip } from "@material-ui/core";
import { useComponentContext } from ".";
import { Alignment, evaluateProp, getCaptionAlignment, shouldUsePrintableVersion } from "./ComponentUtil";
import { replaceModelData } from "lib/util/ModelUtil";

export function FormControlInternal(props) {
  let context = useComponentContext();
  let classes = useFormControlStyles();
  if (props.hideFormControl === true)
    return props.children;

  let captionClass, formControlClass;
  const captionAlignment = getCaptionAlignment(context, props); // only support top and left so far.  Add other alignments as needed
  if (captionAlignment === Alignment.LEFT) {
    formControlClass = classes.formControlRow;
    if (props.rightAlign !== true)
    { captionClass = classes.captionRow; }
    else
    {
      if(props.formReadOnly === true)
        captionClass = classes.captionRowRightReadOnly;
      else
        captionClass = classes.captionRowRight;
    }
  }
  else {
    formControlClass = classes.formControlColumn;
    captionClass = classes.caption;
  }

  let errorClass = classes.error;
  if (props.errorRight === true)
  {
    formControlClass = classes.formControlRowErrorRight;
    errorClass = classes.errorRight;
  }
  if (props.focused)
    captionClass += " " + classes.captionFocused;
  if(props.disabled)
    captionClass += " " + classes.captionDisabled;
  const caption = getCaptionLabel(context, props, captionClass, captionAlignment);

  let messageDiv;
  if (props.messageVisible !== false)
  {
    let style;
    if(props.errorMarginLeft && props.errorMarginLeft >= 0) {
      style = {marginLeft: props.errorMarginLeft + 'px'};
    }
    let messageContents;
    if (props.rolloverMessage != null) {
      messageContents = (
        <Tooltip title={props.rolloverMessage} className={classes.tooltip} enterDelay={700}>
          <div>
            {props.message}
          </div>
        </Tooltip>
      );
    }
    else {
      messageContents = props.message;
    }

    messageDiv = (
      <div className={errorClass} style={style}>
        {messageContents}
      </div>
    );
  }

  let contents;
  if (props.rightAlign !== true)
    contents = [caption, props.children];
  else {
    contents = [(
    <div style={{alignItems: "flex-end", width:props.captionWidth}}>
      {caption}
    </div>),
    props.children];
  }
  return (
    <div className={formControlClass} ref={props.componentRef}>
      {contents}
      {messageDiv}
    </div>
  );
}

export function getCaptionLabel(context, props, labelClass, captionAlignment) {
  if (props.captionVisible === false)
    return null;
  let caption = evaluateProp(context, props.caption);
  if (caption != null)
    caption = replaceModelData(caption, context);
  let labelProps;
  if ((caption == null || caption.length === 0) && context.designer != null) {
    if (props.field == null)
      caption = props.name;
    else
      caption = props.field;
    labelProps = { style: { color: "#E5E5FF" } };
  }
  if (props.required && props.indicateRequired !== false && !shouldUsePrintableVersion(context, props)) {
    if (captionAlignment === Alignment.LEFT)
      caption = "* " + caption;
    else
      caption += " *";
  }

  return (
    <label {...labelProps} key="caption" className={labelClass}>{caption}</label>
  );
}

export const FormControl = FormControlInternal;
