/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";

export function useMountEffect(fn) {
  	useEffect(fn, []);
}

export function useUnmountEffect(fn) {
  	useEffect(() => fn, []);
}

export function useForceUpdate() {
  const [state, setState] = useState(false);
  return () => setState(!state);
};
