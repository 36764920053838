import * as React from "react";

function FtlDryVanOrder({...props}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={168}
    height={39.861}
    viewBox="0 0 168 39.861"
    {...props}
    >
    <text
      fontSize={0}
      style={{
        whiteSpace: "pre",
      }}
    >
      <tspan
        x={57}
        y={27}
        fontFamily="Roboto"
        fontSize={28}
        fontWeight={900}
        style={{
          whiteSpace: "pre",
          textTransform: "none",
        }}
      >
        {"FTL"}
      </tspan>
    </text>
    <text
      fontSize={0}
      style={{
        whiteSpace: "pre",
      }}
    >
      <tspan
        x={109}
        y={26}
        fontFamily="Roboto"
        fontSize={24}
        fontWeight={400}
        style={{
          whiteSpace: "pre",
          textTransform: "none",
        }}
      >
        {"Order"}
      </tspan>
    </text>
    <g fillRule="evenodd">
      <path
        d="M45.643 30.13l2.8-1.054V2.89L31.08 0 0 5.144v18.847l4.526 1.693v3.999l-1.202.19v.316l1.344.631 2.054-.332v-.316l-1.362-.62h-.04v-1.773l3.694-.363.113-.01 6.276 2.346-.006.026a8.903 8.903 0 00-.186 1.896c.013 2.845 1.204 5.464 2.661 5.848.184.048.363.059.535.034l.13-.035 2.673-.62.006.018c.524 1.53 1.362 2.641 2.312 2.891.2.053.396.064.585.037l.388-.106 6.401-2.596.25-.136c.486-.321.895-.925 1.18-1.731l.033-.145 6.99-2.634.106-.038c.31.255.59.397.928.486.29.076.57.082.838.024l.084-.026.241-.078 2.745-1.117.058-.033c.594-.283 1.075-.58 1.217-1.556l.071-.03z"
      />
      <path
        fill="#FEFEFE"
        d="M1.128 20.746V6.325l28.29-3.944v26.8l-28.29-8.435zM38.177 3.168l.044.006.955.13V30.1l-1.007.345.008-27.276zM41.007 29.501l-.835.287V3.408l.835.113v25.98zM47.335 27.335l-5.667 1.94-.023-2.809.87-.196c.327-.079.49-.237.49-.475s-.208-.306-.625-.205l-.724.175.017-22.153 5.662.771v2.22l-.207-.008c-.417-.014-.626.147-.626.482s.164.507.492.518l.34.004v4.77l-.206.002c-.417.002-.626.196-.626.584 0 .387.164.58.492.58l.34-.01v4.72l-.206.006c-.417.012-.626.213-.626.6 0 .388.164.577.492.567l.34-.02v4.652l-.206.045c-.417.09-.626.329-.626.716 0 .388.164.547.492.476l.34-.083v2.13zM1.343 22.862v-1.07l27.969 9.121v1.997L1.342 22.862zM19.148 33.233l-.827.208c-.463.117-.738-.152-.843-.629v-.015c-.073-.502.123-.85.438-.967l1.16-.292-.01-.068c-.024-.163-.02-.167-.051-.323l-3.048-1.143a7.427 7.427 0 00-.204 1.836c.01 2.156.778 4.103 1.716 4.35.857.227 1.558-1.042 1.666-2.904l.003-.053zM24.796 35.203l-.827.208c-.463.117-.738-.152-.843-.63v-.014c-.073-.502.123-.85.438-.968l1.16-.291-.01-.069c-.02-.143-.017-.121-.04-.258l-3.2-1.116a8.955 8.955 0 00-.141 1.74c.01 2.155.856 4.108 1.794 4.356.856.226 1.558-1.043 1.666-2.905l.003-.053zM5.365 26.09l2.235.804-2.235.164v-.968zM31.116 28.473l.613-.162c.419-.11.628.086.628.588s-.164.796-.493.883l-.748.17v3.043l6.015-2.06-.04-3.047-.971.255c-.329.08-.493.002-.493-.236s.21-.409.628-.512l.848-.208.028-23.919-6.015-.82v2.961h.613c.419 0 .628.25.628.753 0 .502-.164.753-.493.753l-.748-.027v6.281l.613-.09c.442-.061.638.156.628.674-.009.502-.164.773-.493.814l-.748.067v6.162l.613-.146c.419-.1.628.102.628.604s-.164.792-.493.87l-.748.15v6.199z"
      />
    </g>
    </svg>
  );
}

const SVGFtlDryVanOrder = React.memo(FtlDryVanOrder);
export { SVGFtlDryVanOrder };
