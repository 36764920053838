import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function Chevron(props) {
  return (
    <SVGIcon viewBox="0 0 12 6" strokeWidth={1.5} style={{fill:"none"}} stroke="currentColor" {...props}  >
      <path d={"M1 0L6 5L11 0"} />
    </SVGIcon>
  );
}

const SVGChevron = React.memo(Chevron);
export { SVGChevron };
