import * as React from "react";

function RecordNavRight({fill="#FFF", ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 7 11" {...props}>
      <path
        d="M.596 10.496a.604.604 0 00.375-.131l5.794-4.661a.598.598 0 000-.931L.995.137a.597.597 0 00-.748.93l5.19 4.17L.221 9.435a.597.597 0 00.375 1.062z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}

const SVGRecordNavRight = React.memo(RecordNavRight);
export { SVGRecordNavRight };