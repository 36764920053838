import { appendAuthInterceptors } from "lib/util/fetch";
import { getAuthType } from "../selectors/UserSelectors";
import { AUTH_TYPE_AUTHENTICATED } from "../states/UserState";
import { fetchAuthedUserInfo, fetchUnauthedUserInfo } from "./UserActions";

export const APP_START = "APP_START";
export const APP_SET = "APP_SET";

export function setApp(state) {
  return {
    type: APP_SET,
    state
  };
}

export function startApp() {
  return async (dispatch, getState) => {
    const authType = getAuthType(getState());
    // Fetch general user info.
    await dispatch(fetchUnauthedUserInfo());
    // Append Auth Interceptors if necessary.
    if (authType === AUTH_TYPE_AUTHENTICATED) {
      await dispatch(fetchAuthedUserInfo());
      appendAuthInterceptors();
    }
    dispatch(setApp({ ready: true }));
  };
}
