import React from "react";
import { Tooltip, Divider } from "@material-ui/core";
import { evaluateProp, getChildArray, shouldUsePrintableVersion } from "./ComponentUtil";
import { classNames } from "lib/util";
import { useComponentContext } from "./Context";
import { getDataFromContext, postUrl, replaceModelData } from "lib/util/ModelUtil";
import { ComponentPropTypes, getBasicPropTypes } from "lib/components/PropTypes";
import { useComponentStyles } from "./ComponentStyles";
import { Spinner } from "./Spinner";
import { showServerErrorDialog, showUnknownResponseDialog } from "./Dialog";
import { navigateTo } from "lib/util/Navigation";
import { flatten } from "core/utils/common";
import { getThemeColor } from "lib/util/Theme";

export function Component({...props }) {
  let classes = useComponentStyles(props);
  let componentContext = useComponentContext();

  if (!isVisible(props, componentContext) && componentContext.designer == null)
    return null;
  let renderProps = { id: props.name, className: getClassName(classes, props, componentContext) };
  attachMouseEvents(props, componentContext, renderProps);
  renderProps.style = {
    ...props.style,
    ...getMarginStyle(props),
    ...getPaddingStyle(props),
    ...getSizeStyle(props),
    ...getAlignmentStyle(props),
    ...getBorderStyle(props),
    ...getShadowStyle(props),
    ...getColorStyle(props, componentContext),
    ...getMiscStyle(props, componentContext),
    ...getZIndexStyle(props),
  };
  let children = getChildArray(props.children);
  let progress;
  if (props.loading)
    progress = <div className={classes.progress}><Spinner size={24} /> </div>

  let divider;
  if (props.divider === true && !shouldUsePrintableVersion(componentContext, props))
    divider = <Divider className={classes.divider} orientation="vertical"></Divider>
  let result;
  if (props.tooltip != null) {
    result = (
      <div {...renderProps} >
        <Tooltip title={props.tooltip} className={classes.tooltip} enterDelay={700}><div>{children}</div></Tooltip>
      </div>
    );
  }
  else
    result = <span {...renderProps}>{children}{progress}{divider}</span>
  return result;
}

function attachMouseEvents(props, componentContext, renderProps) {
  if (props.onClick != null || props.postUrl != null || props.navigateTo != null)
    renderProps.onClick = (event) => handleClick(event, componentContext, props);
  if (props.onMouseDown != null)
    renderProps.onMouseDown = (event) => props.onMouseDown(event, componentContext, props);
  if (props.onMouseUp != null)
    renderProps.onMouseUp = (event) => props.onMouseUp(event, componentContext, props);
  if (props.onDoubleClick != null)
    renderProps.onDoubleClick = (event) => props.onDoubleClick(event, componentContext, getDataFromContext(componentContext));
  if (props.onMouseEnter != null)
    renderProps.onMouseEnter = (event) => props.onMouseEnter(event, componentContext, props);
  if (props.onMouseLeave != null)
    renderProps.onMouseLeave = (event) => props.onMouseLeave(event, componentContext, props);
  if (props.onMouseOver != null)
    renderProps.onMouseOver = (event) => props.onMouseOver(event, componentContext, props);
}

function isVisible(props, componentContext) {
  if (props.visible === false)
    return false;
  else if (typeof props.visible === "function")
    return props.visible(componentContext);
  else
    return true;
}

function getClassName(classes, props, componentContext) {
  let result = [];
  if (props.className != null)
    result.push(props.className);
  if (props.fontSize != null)
    result.push(classes["font_" + props.fontSize]);
  if (props.fontBold === true)
    result.push(classes.fontBold);
  if (props.color != null) {
    const colorProp = evaluateProp(componentContext, props.color)
    const colorClass = classes["font_" + colorProp];
    if (colorClass)
      result.push(colorClass);
  }
  if (props.fillHeight != null)
    result.push(classes.fillHeight);
  if (props.overflow != null) {
    if (props.overflow === "hidden")
      result.push(classes.overflowHide);
    else if (props.overflow === "auto")
      result.push(classes.overflowAuto);
  }
  result.push(classes.component);
  if (props.divider === true)
    result.push(classes.hasDivider);
  return classNames(result);
}

function appendObject(o, key, value) {
  if (value != null) {
    if (o == null)
      o = {};
    o[key] = value;
  }
  return o;
}

function getZIndexStyle(props) {
  if (props.zIndex != null)
    return { zIndex: props.zIndex };
}

function getMarginStyle(props) {
  let result;
  result = appendObject(result, "margin", props.margin);
  result = appendObject(result, "marginTop", props.marginTop);
  result = appendObject(result, "marginBottom", props.marginBottom);
  result = appendObject(result, "marginLeft", props.marginLeft);
  result = appendObject(result, "marginRight", props.marginRight);
  return result;
}

function getPaddingStyle(props) {
  let result;
  result = appendObject(result, "padding", props.padding);
  result = appendObject(result, "paddingTop", props.paddingTop);
  result = appendObject(result, "paddingBottom", props.paddingBottom);
  result = appendObject(result, "paddingLeft", props.paddingLeft);
  result = appendObject(result, "paddingRight", props.paddingRight);
  return result;
}

function getSizeStyle(props) {
  let style = {};
  if (props.widthFillWeight != null)
    style.flex = props.widthFillWeight;
  else if (props.fillRow)
    style.flex = 1;
  if (props.width != null) {
    if (props.widthFillWeight != null)
      style.minWidth = props.width;
    else {
      style.width = props.width;
      if (props.divider === true)
        style.width += 32;
    }
  }
  if (props.maxWidth != null) {
    let maxWidth = props.maxWidth;
    if (props.maxWidth === "lg")
      maxWidth = 1400;
    style.maxWidth = maxWidth;
  }
  if (props.height != null)
    style.height = props.height;
  if (props.minHeight)
    style.minHeight = props.minHeight;
  return style;
}

function getAlignmentStyle(props) {
  if (props.align != null) {
    if (props.align === "right")
      return { justifyContent: "flex-end" };
    else if (props.align === "center")
      return { justifyContent: "center" };
  }
}

function getBorderStyle(props) {
  let style;
  style = addBorderStyle(style, "border", props.borderWidth, props.borderColor, props.borderType);
  style = addBorderStyle(style, "borderTop", props.borderTopWidth, props.borderTopColor, props.borderTopType);
  style = addBorderStyle(style, "borderBottom", props.borderBottomWidth, props.borderBottomColor, props.borderBottomType);
  style = addBorderStyle(style, "borderLeft", props.borderLeftWidth, props.borderLeftColor, props.borderLeftType);
  style = addBorderStyle(style, "borderRight", props.borderRightWidth, props.borderRightColor, props.borderRightType);
  if (props.borderRadius != null) { // no need to support border radius for each corner until we have a use for them
    if (style === undefined) {
      style = {};
    }
    style.borderRadius = props.borderRadius;
  }
  else if (props.borderType === "card") {
    if (style === undefined) {
      style = {};
    }
    style.borderRadius = "4px 4px 4px 4px";
  }
  return style;
}

function addBorderStyle(style, property, width, color, type) {
  if (width === undefined)
  {
    if (type !== "card") {
      return style;
    }
    else {
      width = "1";
    }
  }
  if (style === undefined)
    style = {};
  if (color === undefined)
  {
    if (type !== "card") {
      color = "black";
    }
    else {
      color = "#CDCDCD";
    }
  }
  if (type === undefined || type === "card") {
    type = "solid";
  }
  color = getThemeColor(color);
  style[property] = width + "px " + type + " " + color;
  return style;
}

function getShadowStyle(props) {
  if (props.boxShadow !== true) {
    return;
  }
  let style = {};
  style.boxShadow = "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)";
  return style;
}

function getMiscStyle(props, componentContext) {
  if (props.cursor != null)
    return {cursor: evaluateProp(componentContext, props.cursor)};
  else
    return null;
}

function getColorStyle(props, componentContext) {
  let result = {};
  if (props.color != null)
    result.color = getThemeColor(evaluateProp(componentContext, props.color));
  if (props.backgroundColor != null)
    result.backgroundColor = getThemeColor(evaluateProp(componentContext, props.backgroundColor));
  return result;
}

function handleClick(event, context, props) {
  if (props.postUrl != null)
    handlePostUrl(context, props, null);
  else if (props.navigateTo != null) {
    let url = evaluateProp(context, props.navigateTo);
    url = replaceModelData(url, context);
    navigateTo(url)
  }
  if (props.onClick != null)
    props.onClick(event, context, getDataFromContext(context));
}

export function handlePostUrl(context, props, setLoading) {
  let activeData = context.getActiveData();
  let dataValues;
  if (props.postFields == null)
    dataValues = activeData;
  else {
    dataValues = {};
    let postFields = props.postFields;
    if(props.flattenData)
      activeData = flatten(activeData);
    if (!Array.isArray(postFields))
      postFields = [postFields];
    for (let i = 0; i < postFields.length; i++) {
      let postField = postFields[i];
      if (typeof postField === "string")
        postField = {source: postField, target: postField};
      dataValues[postField.target] = activeData[postField.source];
    }
  }
  postUrl(context, props.postUrl, dataValues, setLoading,
    response => handlePostResponse(context, props.onPost, response),
    error => handlePostError(context, props.onError, error));
}

function handlePostResponse(context, onPost, response) {
  if (onPost == null)
    return;
  try {
    onPost(context, response);
  } catch (err) {
    showUnknownResponseDialog(response, err);
  }
}

function handlePostError(context, onError, error) {
  if (onError === undefined) // allow setting to null specifically
    showServerErrorDialog("Error", error);
  else
    onError(context, error);
}

Component.extPropTypes = {
  ...ComponentPropTypes
};

Component.propTypes = {
  ...getBasicPropTypes(Component.extPropTypes)
};
