import React from "react";
import { CarrierPortal, FactoringCompanyPortal } from "pages";
import { getUserType } from "core/store/selectors/UserSelectors";
import { connect } from "react-redux";
import {
  USER_TYPE_LOCATION,
  USER_TYPE_CUSTOMER,
  USER_TYPE_CARRIER,
  USER_TYPE_FACTORING_COMPANY,
  USER_TYPE_DRIVER
} from "core/utils/constCapsule";
import { DriverPortal } from "portal/driver/DriverPortal";
import { CustomerPortal } from "portal/customer/CustomerPortal";
import { DefaultPageContainer } from "./containers";
import { AuthenticatedPage } from "lib/util/AuthenticatedPage";
import { AuthType } from "lib/util/AuthType";

function PortalComponent({ userType, ...props }) {
  function getPortalComponent() {
    let component = null;
    if (userType === USER_TYPE_CARRIER)
      component = <CarrierPortal pageTitle="Carrier Portal" />;
    else if (userType === USER_TYPE_CUSTOMER)
      component = wrap(CustomerPortal, "Customer Portal", AuthType.CUSTOMER);
    else if (userType === USER_TYPE_LOCATION)
    component = wrap(CustomerPortal, "Location Portal", AuthType.LOCATION);
    else if (userType === USER_TYPE_FACTORING_COMPANY)
      component = <FactoringCompanyPortal pageTitle="Factoring Company Portal" />;
    else if (userType === USER_TYPE_DRIVER)
      component = wrap(DriverPortal, "Driver Portal", AuthType.DRIVER);
    return component;
  }
  return getPortalComponent();
}

function wrap(comp, title, userType) {
  return (
    <DefaultPageContainer>
      <AuthenticatedPage pageOptions={{title, auth: userType}} >
        {React.createElement(comp, {}, null)}
      </AuthenticatedPage>
    </DefaultPageContainer>
  );
}

const mapStateToProps = (state) => ({
  userType: getUserType(state)
});

export const Portal = connect(mapStateToProps)(PortalComponent);
