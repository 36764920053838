import React, { useState } from "react";
import { Button as MUIButton } from "@material-ui/core";
import { getLogger } from "lib/util";
import { PropTypes, getBasicPropTypes, ComponentPropTypes } from "lib/components/PropTypes";
import { Image, useComponentContext, Component, ButtonActions, Spinner } from ".";
import { getComponentName, evaluateProp } from "./ComponentUtil";
import { Popup } from "./Popup";
import { useButtonStyles } from "./ButtonStyles";
import { DataModes, getModelDataDisplayValue, replaceModelData } from "lib/util/ModelUtil";
import { getThemeClassNames } from "lib/util/Theme";
import { handlePostUrl } from "./Component";
import { navigateTo } from "lib/util/Navigation";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const log = getLogger("lib.components.Button");

export function Button({
  	fontSize,
  	overrideImageClassName,
	dropDown,
	dropDownAlign,
	dropDownClassName,
	dropDownVisible = true,
	dropDownForm = false,
	dropDownOpen,
	...props
}) {
  const context = useComponentContext();
  const [dropDownAnchor, setDropDownAnchor] = useState(null);
  const [loading, setLoading] = useState();
  const classes = useButtonStyles();
  if (props.action != null && props.look == null)
    props.look = "primaryFilled"
	let loadingValue = loading;
	if (props.loading !== undefined)
		loadingValue = props.loading;
  const compProps = { ...props };
  delete compProps.onClick;

  let progress;
  if (loadingValue)
  { progress = <Spinner parentLook={props.look} size={18} className={classes.progress}/>; }
  
  let variant = props.variant == null ? "outlined" : props.variant;
	if (props.look === undefined && context.designer == null && props.caption == null)
		variant = "text";
  dropDown = evaluateProp(context, dropDown);
	const popup = getPopup(
    	context,
    	dropDown,
		dropDownAnchor,
		dropDownAlign,
		dropDownClassName,
		dropDownVisible,
		setDropDownAnchor,
		props.disableBackdropClick,
		dropDownForm,
		dropDownOpen
	);
	if (dropDownAnchor != null)
		compProps.tooltipVisible = false;
	return (
		<Component {...compProps}>
			{getButton(
				context,
				overrideImageClassName,
				props,
				classes,
				props.color,
				fontSize,
				variant,
				progress,
				loadingValue,
				setLoading,
				dropDown,
				setDropDownAnchor
			)}
			{popup}
		</Component>
	);
}

function getPopup(
	context,
	dropDown,
	dropDownAnchor,
	dropDownAlign,
	dropDownClassName,
	dropDownVisible,
	setDropDownAnchor,
	disableBackdropClick,
	dropDownForm,
	dropDownOpen
) {
	if (dropDownAnchor != null && dropDown != null && dropDownVisible === true) {
		let dropComponent = dropDown;
		if (typeof dropDown === "function")
			dropComponent = dropDown(context);
		return (
			<Popup
				dropDownOpen={dropDownOpen}
				anchorEl={dropDownAnchor}
				anchorAlign={dropDownAlign}
				className={dropDownClassName}
				onClose={() => setDropDownAnchor(null)}
				components={dropComponent}
				disableBackdropClick={disableBackdropClick}
				dropDownForm={dropDownForm}
			/>
		);
	}
	return undefined;
}

function getButton(
    context,
    overrideImageClassName,
	props,
	classes,
	color,
	fontSize,
	variant = "outlined",
	progress,
	loading,
	setLoading,
	dropDown,
	setDropDownAnchor
) {
	const buttonProps = {};
	if (props.action)
    buttonProps.type = "submit";
  let renderColor = evaluateProp(context, color);
  if (renderColor != null)
		renderColor = "inherit";
	let { buttonStyle } = props;
	if (fontSize) {
		if (buttonStyle == null)
			buttonStyle = {};
		buttonStyle.fontSize = fontSize;
  }
  if (props.tabIndex != null)
    buttonProps.tabIndex = props.tabIndex;
  let className = classes.button;
  if (props.look != null)
    className += " " + getThemeClassNames()["button" + evaluateProp(context, props.look)];
  if (props.className != null)
    className += " " + props.className;
	return (
		<MUIButton
			id={props.id}
			variant={variant}
			style={buttonStyle}
			className={className}
			disabled={props.enabled === false || loading || context.designer != null}
			color={renderColor}
      		href={evaluateProp(context, props.href)}
			{...buttonProps}
			onClick={(event) => handleClick(event, context, props, setLoading, dropDown, setDropDownAnchor)}
      onBlur={(event) => { if (props.onBlur != null) { props.onBlur(event); } }}
		>			
			{getContent(context, props, classes, overrideImageClassName, progress)}
		</MUIButton>
	);
}

function handleClick(event, context, props, setLoading, dropDown, setDropDownAnchor) {
  log.debug("Button %s clicked", getComponentName(context, props.name));
  if (dropDown != null)
	setDropDownAnchor(event.currentTarget);
  else if (props.action === ButtonActions.SUBMIT)
  {
    if (context.validateForm())
    { context.data.submit(context, setLoading, props.onComplete, props.onError); }
  }
  else if (props.postUrl != null)
    handlePostUrl(context, props, setLoading);
  else if (props.navigateTo != null) {
    let url = evaluateProp(context, props.navigateTo);
    url = replaceModelData(url, context);
    navigateTo(url)
  }
  if (props.onClick)
		props.onClick(event, context, setLoading);
	event.stopPropagation();
}

function getCaption(context, props) {
  let result = evaluateProp(context, props.caption)
  if (result == null && props.action != null) {
    if (props.action === ButtonActions.SUBMIT && context != null && context.data != null && context.data.mode === DataModes.SEARCH)
      result = "Search";
    else
      result = props.action.caption;
  }
	if ((result == null || result.length === 0) && context != null && context.designer != null)
    result = props.name;
  if (result == null && props.field != null)
    result = getModelDataDisplayValue(context, props.field, props.displayType, props.format);
	return result;
}

function getImage(image, classes, overrideImageClassName, hasCaption) {
	if (image != null) {
		let img = image;
		if (typeof img === "string")
      img = { image: img };
    if (hasCaption)
      img.marginRight = 8;
		return <Image className={overrideImageClassName != null ? overrideImageClassName : classes} {...img} />;
	}
	return null;
}

function getContent(context, props, classes, overrideImageClassName, progress) {
	const caption = getCaption(context, props);
	let result = (
		<>
			{progress}
			<span className={classes.caption}>{caption}</span>
		</>
	);
	if (props.image != null) {
		// add prop for text/image position (so image can be above/below/left/right of caption)
		result = (
			<>
				{progress}
				{getImage(evaluateProp(context, props.image), classes, overrideImageClassName, caption != null)}
				<span className={classes.caption}>{caption}</span>
			</>
		);
	}
	return result;
}

Button.extPropTypes = {
	...ComponentPropTypes,
	caption: { type: PropTypes.string }
};

Button.propTypes = {
	...getBasicPropTypes(Button.extPropTypes)
};
