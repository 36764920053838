import { daysInMonth, formatDate, getMonthOfYear, parseDate } from "lib/util/Date";
import { getThemeColor } from "lib/util/Theme";
import React, { useState } from "react";
import { Button, Label, Panel } from ".";
import { Alignment } from "./ComponentUtil";
import { setDataContextValue } from "./DataContainer";
import { AnchoredContainer, closePopup, isPopupVisible, showPopup } from "./PopoupContainer";

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const labelProps = {
  width: 36, height: 36, padding: 5, margin: 2, align: Alignment.CENTER,
  fontSize: "small", allowSelection: false, borderRadius: "50%"
};

export function toggleDatePicker(anchorRef, value, parentChanged, context, props) {
  if (isPopupVisible())
    closePopup(props.onClose, context);
  else
    showDatePicker(anchorRef, value, parentChanged, context, props);
}

export function showDatePicker(anchorRef, value, parentChanged, context, props) {
  let date;
  if (value != null && value.length > 0)
    date = parseDate(value);
  else
    date = new Date();
  showPopup(
    <AnchoredContainer anchorRef={anchorRef} onClick={event => event.stopPropagation()} >
      <DatePicker date={date} onSelect={(value) => {
        parentChanged({ target: { value: formatDate(value) } });
        if (props.field != null)
          setDataContextValue(context, props.field, value);
        closePopup(props.onClose, context);
      }} />
    </AnchoredContainer>
  );
}

export function DatePicker({ onSelect, date, ...props }) {
  if (date == null)
    date = new Date();
  const [displayDate, setDisplayDate] = useState(date);
  return <Panel padding={0} {...props} >
    <Panel verticalAlign={Alignment.CENTER} padding={8} backgroundColor="primary" fillRow >
      <Button image={{ image: "Chevron", direction: "left" }} width={28} height={28} borderWidth={0} padding={0} color="subtle.light" noRowBreak
        onClick={() => setDisplayDate(new Date(displayDate).addMonths(-1))}
      />
      <Label caption={getMonthOfYear(displayDate) + " " + displayDate.getFullYear()} color="primary.reverse" align={Alignment.CENTER} fillRow noRowBreak />
      <Button image={{ image: "Chevron", direction: "right" }} width={28} height={28} borderWidth={0} padding={0} color="subtle.light"
        onClick={() => setDisplayDate(new Date(displayDate).addMonths(1))}
      />
    </Panel>
    <Panel paddingRight={16} paddingLeft={16} paddingBottom={16} >
      {getDayHeadings()}
      {getDateLabels(date, displayDate, onSelect)}
    </Panel>
  </Panel>
}

function getDayHeadings() {
  const dayHeadings = [];
  for (let day of dayNames)
    dayHeadings.push(<Label key={day} caption={day} {...labelProps} rowBreak={day === dayNames[dayNames.length - 1]} />);
  return dayHeadings;
}

function getDateLabels(selDate, date, onSelect) {
  if (selDate == null)
    selDate = new Date();
  date = date.justDate();
  selDate = selDate.justDate();
  const thisMonth = date.getMonth();
  const lastDay = getLastDateToDisplay(date);
  let curr = getFirstDateToDisplay(date);
  const result = [];
  while (curr <= lastDay) {
    let thisDate = new Date(curr);
    let colors;
    if (thisMonth === thisDate.getMonth()) {
      if (selDate != null && thisDate.getTime() === selDate.getTime())
        colors = hoverColors("primary", "primary.reverse", "primary.light", "primary.reverse");
      else
        colors = hoverColors("", "", "subtle.light", "subtle.reverse");
    }
    else
      colors = hoverColors("", "subtle", "subtle.light", "subtle.reverse");
    result.push(<Label
      key={thisDate.toString()}
      {...labelProps}
      {...colors}
      cursor="pointer"
      onClick={(event) => onSelect(thisDate)}
      caption={thisDate.getDate().toString()}
      rowBreak={thisDate.getDay() === 6}
    />);
    curr.addDays(1);
  }
  return result;
}

function getFirstDateToDisplay(date) {
  const result = new Date(date);
  result.setDate(1);
  result.setDate((result.getDay() * -1) + 1);
  return result;
}

function getLastDateToDisplay(date) {
  const thisMonth = date.getMonth();
  const days = daysInMonth(thisMonth, date.getFullYear());
  const result = new Date(date);
  result.setDate(days);
  result.setDate(days + (6 - result.getDay()));
  return result;
}

function hoverColors(noHoverBackground, noHoverColor, hoverBackground, hoverColor) {
  return {
    backgroundColor: noHoverBackground,
    color: noHoverColor,
    onMouseEnter: (event) => {
      event.target.style.backgroundColor = getThemeColor(hoverBackground);
      event.target.style.color = getThemeColor(hoverColor);
    },
    onMouseLeave: (event) => {
      event.target.style.backgroundColor = getThemeColor(noHoverBackground);
      event.target.style.color = getThemeColor(noHoverColor);
    }
  }
}
