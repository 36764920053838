import { makeStyles } from "lib/util";
import { getThemeColor } from "lib/util/Theme";

let tabsetStyles, stepperStyles;

export function useTabsetStyles() {
  if (tabsetStyles == null)
    tabsetStyles = makeStyles("tbst", {
      header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "0px",
        paddingBottom: "1px",
      },
      grow: {
        flexGrow: "1"
      },
      tabs: {
        top: "2px",
        position: "relative",
      },
      headerOutlined: {
        borderBottom: "1px solid " + getThemeColor("subtle", "light"),
      },
      tabset: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      tab: {
        extend: "tabTransitionOut",
        minHeight: "47px",
        minWidth: "100px",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        paddingLeft: "18px",
        paddingRight: "18px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: + getThemeColor("subtle", "light"),
        borderBottomColor: "transparent",
        marginRight: "6px",
      },
      tabPanel: {
        height: "100%",
        overflow: "auto",
        paddingTop: "16px",
        margin: "0px",
        backgroundColor: "white",
        flex: "1"
      },
      activeTab: {
        extend: "tabTransitionIn",
        backgroundColor: "white",
        borderBottomColor: "transparent"
      },
      wrapper: {
        flexDirection: "row",
        "& svg": {
          marginBottom: "0px !important",
          marginRight: "6px"
        }
      },
      wizard: {
        display: "none"
      }
    });
  return tabsetStyles;
}

export function useStepperStyles() {
  if (stepperStyles == null)
    stepperStyles = makeStyles("stp", {
      root: {
        display: "flex",
        padding: "4px",
        width: "-webkit-fill-available"
      },
      buttonRow: {
        display: "flex",
        width: "100%",
        padding: "0px",
        "& > span:nth-last-of-type(-n+2)": {
          marginLeft: "auto"
        }
      },
      active: {
        '& $line': {
          borderColor: getThemeColor("primary"),
          borderTopWidth: "2px",
        }
      },
      completed: {
        '& $line': {
          borderColor: getThemeColor("primary"),
          borderTopWidth: "2px",
        }
      },
      line: {
        borderColor: + getThemeColor("subtle", "light"),
        borderTopWidth: "1px",
        borderRadius: "1px",
      },
      instructions: {
        marginTop: "6px",
        marginBottom: "6px",
      },
      label: {
        "& .MuiStepLabel-active": {
          color: getThemeColor("primary")
        },
        '& .MuiStepLabel-completed': {
          color: getThemeColor("primary")
        }
      }
    });
  return stepperStyles;
}
