import React from "react";
import { Panel, Label } from "lib/components";

export function QuoteTransitInfoPanel(props)
{
  return (
    <Panel {...props}>
      <Label caption={props.transitDaysDisplayValue} field={props.transitDaysField} look="secondaryBold2" rowBreak />
      <Label caption="Estimated Delivery" look="defaultLight0" marginTop={-5} rowBreak />
      <Label caption={props.estDeliveryDisplayValue} field={props.estDeliveryField} look="subtleBold2" marginTop={-10} />
    </Panel>
  );
}