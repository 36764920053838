import React, { useState, useEffect } from "react";
import { Panel, Label, Tabset } from "lib/components";
import { QuoteDetails } from "./QuoteDetails";
import { HandlingReqsTable } from "../HandlingReqsTable";
import { QuoteOverview } from "./QuoteOverview";
import { AuthType } from "lib/util/AuthType";
import { DataModes, getDataFromContext, submitModel } from "lib/util/ModelUtil";
import { getTheme } from "lib/util/Theme";
import { navigateTo } from "lib/util/Navigation";
import { callApi } from "lib/util/api";
import { getLogger } from "lib/util/Logger";
import { showWarningSnackbar } from "lib/components/Snackbar";
import { FreightItemsTable } from "../FreightItemsTable";
import { CustomerType, PanelMode, PageType } from "../ControlConstants";
import { validateCustomerCredit } from "../OrderValidation";
import { showOrderCreationErrorDialog } from "lib/components/Dialog";

const log = getLogger("portal.customer.asset-ltl-quote.AssetLtlQuote");
const parentType = "asset-ltl-quote";

export function AssetLtlQuote(props) {
    const startStep = props.startStep != null ? props.startStep : 0;
    const [currentStep, setCurrentStep] = useState(startStep);
    const [prevStep, setPrevStep] = useState();
    const [agree, setAgree] = useState(false);
    const initialState = props.initialData != null ? DataModes.EDIT : DataModes.ADD;
    const [mode, setMode] = useState(initialState);
    const image = { image: "LtlPalletQuote", height: 40, width: 200, marginRight: 10, fill: getTheme().palette.primary.base};
    const setStep = (step) => {
      setCurrentStep(step);
      setMode(DataModes.EDIT);
    }

    const freightItemsTableOverview = FreightItemsTable(CustomerType.ASSET, PanelMode.EDIT, PageType.OVERVIEW, prevStep);
    const freightItemsTableDetail = FreightItemsTable(CustomerType.ASSET, PanelMode.EDIT, PageType.DETAILS, prevStep);
    const step1FreightItemsTable = currentStep == 0 ? freightItemsTableOverview : null;
    const step2FreightItemsTable = currentStep == 1 ? freightItemsTableDetail : null;

    const handlingReqsTable = HandlingReqsTable(currentStep, prevStep);
    const step1HandlingReqsTable = currentStep == 0 ? handlingReqsTable : null;
    const step2HandlingReqsTable = currentStep == 1 ? handlingReqsTable : null;

    useEffect(() => {
        validateCustomerCredit(parentType);
    }, [currentStep]);

    return (
        <Panel  modelName="dispatch/asset-ltl-quote-order" dataMode={mode} loadInitialData={(context) => loadInitialData(context, props.initialData)}
            fillHeight fillRow>
            <Label image={image} look="primary6" marginBottom={10} fillRow />
            <Tabset variant="wizard" keepMounted selectedIndex={currentStep} {...props}
              setCurrentStep={setStep} setPrevStep={setPrevStep} onSubmit={(event, context, setLoading) => convertQuoteToOrder(event, context, setLoading, agree)}
              fillRow fillHeight >
                {QuoteOverview(step1FreightItemsTable, step1HandlingReqsTable, currentStep)}
                {QuoteDetails(props, setAgree, step2FreightItemsTable, step2HandlingReqsTable, currentStep)}
            </Tabset>
        </Panel>
    )
}

function convertQuoteToOrder(event, context, setLoading, agree) {
    if (!timeValidation(context) || !context.validateForm() || !validateCustomerCredit(parentType)) {
      return;
    }
    //const agreed = getDataFromContext(context, "agreed_to_terms"); //when coming from quote list, this isn't in context. Could add to model
    if (agree !== true) {
      showWarningSnackbar("Please accept the Terms and Conditions before proceeding.", true);
    }
    else {
        setLoading(true);
      const data = getDataFromContext(context);
      submitModel(context, null, (submitResponse) => {
        callApi("api/portal/customer/convert-asset-quote-to-order", "POST", {quote_id: data.id}).then((convertResponse) => {
          setLoading(false);
          log.debug("Convert response %o", convertResponse);
          navigateTo("Confirmation", null, false, {data: convertResponse.data[0].order});
        }).catch(reason => {
          setLoading(false);
          showOrderCreationErrorDialog("Error", reason);
        });
      }, (reason) => showOrderCreationErrorDialog("Error", reason), (data) => adjustDataBeforePosting(data), DataModes.EDIT);
    }
}

function adjustDataBeforePosting(data)
{
  delete data[0].disclaimer;
  delete data[0].shipper;
  delete data[0].ship_date;
  delete data[0].consignee;
  delete data[0].other_charges;
  delete data[0].total_charge;
  delete data[0].freight_charge;
  return data;
}

function timeValidation(context) {
	const stops = getDataFromContext(context, "stops");
	const early = stops[0]["sched_arrive_early"];
	const late = stops[0]["sched_arrive_late"];
	if(early && late ){
		let earlyDate, lateDate;
		if(typeof early === "object" && early.value)
			earlyDate = new Date(early.value);
		else
			earlyDate = new Date(early);
		if(typeof late === "object" && late.value)
			lateDate = new Date(late.value);
		else
			lateDate = new Date(late);
		if (earlyDate.getTime() > lateDate.getTime()){
			showWarningSnackbar("Early pickup time is later than late pickup time.", true);
			return false;
		}
	}
	return true;
}

function loadInitialData(context, initialData){
    const updatedDataList = [...context.data.list];
    if(initialData != null){
        for (let i = 0; i < initialData.length; i++)
          updatedDataList.push({modelData:initialData[i]});
        context.data.setDataList(updatedDataList);
    }
}

export function getPageOptions() {
    return {
      auth: [AuthType.CUSTOMER, AuthType.LOCATION],
      title: "Add Quote",
    };
}
