import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function CircleFilled(props) {
  return (
    <SVGIcon viewBox="0 0 20 20" {...props}>
      <circle stroke={props.fill} strokeWidth={2} fill="none" cx={10} cy={10} r={9} />
      <circle fill={props.fill} cx={10} cy={10} r={5} />
    </SVGIcon>
  );
}

const SVGCircleFilled = React.memo(CircleFilled);
export { SVGCircleFilled };
