import React, { useState, useEffect } from "react";
import { Tabset, Tab, TextBox, Panel, Button, ButtonActions, DisplayTypes, showDialog, Map, Switch } from "lib/components";
import { getCompanySettings, getLogger } from "lib/util";
import { DataModes,  withModelLoad } from "lib/util/ModelUtil";
import { Alignment } from "lib/components/ComponentUtil";
import { getTheme } from "lib/util/Theme";
import { getMainPageContext } from "lib/util/MainPageContext";
import { DriversChoicePreferences } from "./DriversChoicePreferences";
import { DriversChoiceTable } from "./DriversChoiceTable";
import { AuthType } from "lib/util/AuthType";

const log = getLogger("pages.DriverPortal");

export const DriverPortal = withModelLoad("driver/driver-choice-shipper-location", "lastStop", DriverPortalInternal);
const modelName = "driver/driver-choice";

function DriverPortalInternal({ lastStop }) {
  const [showAsMap, setShowAsMap] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [refreshModelData, setRefreshModelData] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [dataList, setDataList] = useState();
  useEffect(() => {
    if(refreshModelData)
      setRefreshModelData(false);
  });

  let city = "";
  if (lastStop.length > 0)
    city = lastStop[0];
  const days = [{ caption: "3 Days", value: 3 }, { caption: "5 Days", value: 5 }, { caption: "7 Days", value: 7 }, { caption: "10 Days", value: 10 }, { caption: "15 Days", value: 15 }];
  const allTypes = { descr: "All Types", id: 'ALL' };
  let driverSettings = getMainPageContext().driver_settings;
  log.debug("Settings %o", driverSettings);

  let listMapSwitch = (
    <Panel fillRow fillHeight>
      <Panel fillRow fillHeight noRowBreak />
      <Button style={{alignSelf:"center"}} look="default2" caption="Search Preferences" noRowBreak dropDown={DriversChoicePreferences(setDropDownOpen, handlePrefSubmitComplete, setRefreshModelData, refreshModelData)}
        dropDownForm dropDownAlign={Alignment.RIGHT} dropDownOpen={dropDownOpen} visible={()=>isSearchPreferencesVisible(tabIndex)} disableBackdropClick onClick={ () => toggleDropDownOpen(setDropDownOpen)}/>
      <Switch leftLabel="List" leftImage="List" rightLabel="Map" rightImage="MapPin" onChange={setShowAsMap} />
    </Panel>
  );
  if (driverSettings.enable_driver_choice)
    return (
      <Panel fillHeight >
        <Tabset fillRow fillHeight name="tabset" extraComponent={listMapSwitch} showAsMap={showAsMap} onChange={(value)  => handleTabChange(value, setTabIndex, setDataList)} >
          <Tab fillRow fillHeight caption="My Matching Loads" >
            <TableOrMap showAsMap={showAsMap} autoSearch manualSearch={false} fillHeight refreshModelData={refreshModelData}/>
          </Tab>
          <Tab caption="Manual Search" >
            <Panel variant="form" modelName={modelName} dataMode={DataModes.SEARCH} captionAlignment={Alignment.LEFT} rowBreakDefault={false} fillRow fillHeight >
              <TextBox field="ship_radius" default={250} width={200} />
              <TextBox field="ship_city" default={city} displayType={DisplayTypes.CITY} width={350} marginRight={50} />
              <TextBox field="days_out" items={days} default={3} width={250} includeBlankItem={false} rowBreak />
              <TextBox field="dest_radius" width={200} required={false}/>
              <TextBox field="dest_city" displayType={DisplayTypes.CITY} width={350} marginRight={50} required={false} />
              <TextBox field="trailer_type" staticItem={allTypes} includeBlankItem={false} lookupModel="dispatch/portal-trailer-types" width={250} marginRight={40} />
              <Button action={ButtonActions.SUBMIT} rowBreak onComplete={(response) => handleOnComplete(response, setDataList)}/>
              <TableOrMap showAsMap={showAsMap} autoSearch={false} manualSearch dataList={dataList} fillHeight />
            </Panel>
          </Tab>
        </Tabset>
      </Panel>
    )
  else
      return null;
}

function TableOrMap({ showAsMap, autoSearch, manualSearch, refreshModelData, dataList }) {
  if (showAsMap)
    return <Map data={dataList} name="driverMap" modelName={manualSearch ===true?null:modelName} latField="origin_lat" longField="origin_lng" tractorLat="tractor_lat" tractorLng="tractor_lng" onPinClick={(pins) => pinClick(pins, manualSearch)}  fillRow fillHeight />
  else
    return <DriversChoiceTable data={dataList} modelName={modelName} autoSearch={autoSearch} manualSearch={manualSearch} fillHeight refreshModelData={refreshModelData}/>;
}

function pinClick(pins, manualSearch) {
  log.debug("Clicked %o", pins);
  let data = [];
  pins.forEach(pin => data.push(pin.data));
  if (data.length === 0 || data[0] === undefined)
    return;
  const firstPinData = data[0];
  const dialogProps = { titleBackgroundColor: getTheme().palette.primary.base, titleColor: getTheme().palette.primary.reverse };

  let dialogMessage = "";
  if (data.length === 1)
    dialogMessage = `Detail for order ${firstPinData["order_id"]}`;
  else
    dialogMessage = `Orders in ${firstPinData["shipper_city"]}, ${firstPinData["shipper_state"]}`;

  showDialog(dialogMessage, (
    <Panel data={data}>
      <DriversChoiceTable manualSearch={manualSearch} fillHeight fillRow />
    </Panel>
  ), dialogProps);
}

function isSearchPreferencesVisible(tabIndex) {
  return getCompanySettings().show_search_preferences && tabIndex === 0;
}

function toggleDropDownOpen(setDropDownOpen){
  setDropDownOpen((prevOpen) => !prevOpen);
}

function handlePrefSubmitComplete(setDropDownOpen, setRefreshModelData, refreshModelData) {
  setDropDownOpen((prevOpen) => !prevOpen);
  if(!refreshModelData){
    setRefreshModelData(true);
  }
}

function handleTabChange(value, setTabIndex, setDataList) {
  setTabIndex(value);
  if(value === 0){
    setDataList(null);
  }
}

function handleOnComplete(response, setDataList){
  if(response && response.data){
    setDataList(response.data);
  }
}

export function getPageOptions() {
  return {title: "Driver Portal", auth: AuthType.DRIVER};
}
