import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function CheckWithCircle(props) {
  return (
    <SVGIcon viewBox="0 0 20 20" {...props}>
      <circle cx={10} cy={10} r={9.5} fill={props.fill} />
      <path transform="translate(0, 1)" stroke={props.stroke} fill={props.stroke} strokeWidth={.5} d="M6.857 13.5a.579.579 0 01-.497-.265L4.075 9.078c-.158-.252-.062-.572.213-.717a.603.603 0 01.783.196l1.91 3.06 7.038-6.463a.613.613 0 01.812-.001.495.495 0 01.001.743L7.29 12.822l-.027.024a.601.601 0 01-.406.154z" />
    </SVGIcon>
  );
}

const SVGCheckWithCircle = React.memo(CheckWithCircle);
export { SVGCheckWithCircle };
