import { getUserSettings } from "./MainPageContext"

export const AuthType = {
  CUSTOMER: "C",
  CARRIER: "R",
  LOCATION: "L",
  FACTORING_COMPANY: "5",
  DRIVER: "D",
  LME: "U",
  ANY: "A",
  UNAUTH: "X"
}
