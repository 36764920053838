import { getLogger } from "lib/util";
import { getDataFromContext, getDataListFromContext } from "lib/util/ModelUtil";
import React, { useContext } from "react";
import { getDataContextValue, setDataContextValue } from "./DataContainer";
import { validateTable } from "./Table/TableValidator";

export const HierarchyContext = React.createContext();
export const DesignerContext = React.createContext();
export const DataContext = React.createContext();
export const DataIndexContext = React.createContext();

export function useContextData() {
  const context = useComponentContext();
  return context.getActiveData();
}

export function useComponentContext() {
  const result = {
    data: useContext(DataContext),
    dataIndex: useContext(DataIndexContext),
    hierarchy: useContext(HierarchyContext),
    designer: useContext(DesignerContext),
  };

  result.getValue = (field) => getDataContextValue(result, field);
  result.setValue = (field, value) => setDataContextValue(result, field, value);
  result.getDataList = () => getDataListFromContext(result);
  result.getActiveData = () => getDataFromContext(result);
  result.registerComponent = (props) => {
    if (props.field != null) {
      if (result.data == null)
        result.data = {};
      if (result.data.componentProps == null) // seems like maybe this info belongs directly in result and not result.data?
        result.data.componentProps = {};
      result.data.componentProps[props.field] = props;
    }
  };
  result.registerComponentPropsCallback = (componentProps) => {
    result.data.componentPropsCallback = componentProps;
  }
  result.registerTableValidator = (validator) => {
    if (validator === null || validator === undefined) { return; }
    if (result.data.tableValidators == null) { result.data.tableValidators = {}; }
    result.data.tableValidators[validator.field] = validator;
  }
  result.registerDebugContextRefresher = (debugContextRefresher) => {
    if (debugContextRefresher === null || debugContextRefresher === undefined) { return; }
    result.data.debugContextRefresher = debugContextRefresher;
  }
  result.validateForm = (dataIndex = result.dataIndex) => {
    let allGood = true;
    getLog().debug("Beginning form validation");
    allGood = result.validateData(result.data, dataIndex, result.data.componentProps);
    if (result.data.childData != null) {
      for (const [key, value] of Object.entries(result.data.childData)) {
        getLog().debug("Validating child data %o, %o", key, value);
        if (value.list && value.list.length > 0) {
          let index = 0;
          for (const [childKey, childValue] of Object.entries(value.list)) {
            getLog().debug("Validating child inner data %o %o, %o", key, childKey, childValue);
            allGood = allGood && result.validateData(value, index, value.componentProps);
            index++;
          }
        }
        else
          allGood = allGood && result.validateData(value);
      }
    }
    getLog().debug("Form validation complete, data is ", allGood ? "valid" : "invalid");

    if (result.data.tableValidators != null) {
      getLog().debug("Beginning table validation %o", result.data.tableValidators)
      for (let x in result.data.tableValidators) {
        getLog().debug("Invoking table validator %o", result.data.tableValidators[x])
        allGood = validateTable(result.data.tableValidators[x]) && allGood;
      }
    }
    return allGood;
  };
  result.validateData = (data, dataIndex = result.dataIndex, componentProps) => {
    let allGood = true;
    getLog().debug("Validating data %o", data)

    let evalComponentProps = componentProps;
    if (data.componentPropsCallback)
      evalComponentProps = data.componentPropsCallback(data.list[dataIndex], dataIndex, componentProps);

    if (data != null && evalComponentProps != null) {
      for (const [key, value] of Object.entries(evalComponentProps)) {
        if (value.required) {
          let fieldValue;
          if (result.data.mode === "search")
            fieldValue = data.searchValues.modelData[key];
          else {
            fieldValue = data.list[dataIndex].modelData[key];
          }
          if (fieldValue == null) {
            const existingWarning = result.getValidationWarning(key, dataIndex);
            if (existingWarning == null) { result.setValidationWarning(key, "Required", null, dataIndex); }
            allGood = false;
          }
        }
      }
    }
    getLog().debug("Partial form validation complete, data is", allGood ? "valid" : "invalid");
    return allGood;
  }
  result.getValidationWarning = (field, dataIndex = result.dataIndex) => {
    getLog().debug("Getting validation warnings for field %o dataIndex %o", field, dataIndex)
    if (result.data == null || result.data.warnings == null)
      return null;
    const warningsForRecord = result.data.warnings[dataIndex];
    getLog().debug("Validation warnings %o", warningsForRecord)
    if (warningsForRecord == null)
      return null;
    getLog().debug("Validation warnings for field %o %o", field, warningsForRecord[field])
    return warningsForRecord[field];
  };
  result.getValidationRolloverWarning = (field, dataIndex = result.dataIndex) => {
    getLog().debug("Getting validation warnings for field %o dataIndex %o", field, dataIndex)
    if (result.data == null || result.data.warnings == null)
      return null;
    const warningsForRecord = result.data.warnings[dataIndex];
    getLog().debug("Validation warnings %o", warningsForRecord)
    if (warningsForRecord == null)
      return null;
    getLog().debug("Validation warnings for field %o %o", field, warningsForRecord[field])
    return warningsForRecord[field + "--Rollover"];
  };
  result.setValidationWarning = (field, value, rolloverMessage, dataIndex = result.dataIndex) => {
    getLog().debug("Setting validation warning Data %o  Field %o  Value %o", result.data, field, value);
    if (field == null)
      return;
    if (value == null) {
      if (result.getValidationWarning(field) != null) {
        result.data.warnings[dataIndex][field] = null;
        result.data.warnings[dataIndex][field + "--Rollover"] = null;
        result.data.setWarnings([...result.data.warnings]);
      }
      return;
    }

    let warnings = result.data.warnings;
    if (warnings == null) {
      warnings = [];
      result.data.warnings = warnings;
    }
    let warningsForRecord = warnings[dataIndex];
    if (warningsForRecord == null) {
      warningsForRecord = {};
      warnings[dataIndex] = warningsForRecord;
    }
    warningsForRecord[field] = value;
    warningsForRecord[field + "--Rollover"] = rolloverMessage;
    result.data.setWarnings([...result.data.warnings]);
  };
  result.getStateAccessor = (field, dataIndex = result.dataIndex) => {
    getLog().debug("Getting state accessor for field %o dataIndex %o", field, dataIndex)
    if (result.data == null || result.data.stateAccessors == null)
      return null;
    const stateAccessorsForRecord = result.data.stateAccessors[dataIndex];
    getLog().debug("State accessors %o", stateAccessorsForRecord)
    if (stateAccessorsForRecord == null)
      return null;
    getLog().debug("State accessor for field %o %o", field, stateAccessorsForRecord[field])
    return stateAccessorsForRecord[field];
  };
  result.setStateAccessor = (field, stateAccessor, dataIndex = result.dataIndex) => {
    getLog().debug("Setting state accessors Data %o  Field %o  State Accessor %o  Index %o", result.data, field, stateAccessor, dataIndex);
    if (field == null || stateAccessor == null)
      return;
    if (stateAccessor == null) {
      if (result.getStateAccessors(field, dataIndex) != null) {
        result.data.stateAccessors[dataIndex][field] = null;
        //result.data.setStateAccessors([...result.data.stateAccessors]);
      }
      return;
    }

    let stateAccessors = result.data.stateAccessors;
    if (stateAccessors == null) {
      stateAccessors = [];
      result.data.stateAccessors = stateAccessors;
    }
    let stateAccessorsForRecord = stateAccessors[dataIndex];
    if (stateAccessorsForRecord == null) {
      stateAccessorsForRecord = {};
      stateAccessors[dataIndex] = stateAccessorsForRecord;
    }
    stateAccessorsForRecord[field] = stateAccessor;
    //result.data.setStateAccessors([...result.data.stateAccessors]);
  };
  return result;
}

export function useDesignerContext() {
  return useContext(DesignerContext);
}

let log;
function getLog() {
  if (log == null)
    log = getLogger("lib.components.Context");
  return log;
}
