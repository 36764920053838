import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function AddWithCircle({...props}) {
  return (
    <SVGIcon {...props}>
      <path
        d="M12 0a12 12 0 100 24 12 12 0 100-24zm6 12c0 .47-.38.84-.84.84h-4.3v4.28c0 .47-.38.84-.84.84-.47 0-.84-.38-.84-.84v-4.28H6.84c-.47 0-.84-.38-.84-.84 0-.47.38-.84.84-.84h4.32v-4.3c0-.47.38-.84.84-.84.47 0 .84.38.84.84v4.3h4.3c.47 0 .84.38.84.84z"
      />
    </SVGIcon>
  );
}

const SVGAddWithCircle = React.memo(AddWithCircle);
export { SVGAddWithCircle };
