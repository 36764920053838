import { getLogger } from "./Logger";

let deniedItems = null;

export const PERMS_TYPE_VIEW = "0";
export const PERMS_TYPE_EDIT = "1";

const log = getLogger("lib.util.Permissions");

export function setDeniedItems(value) {
  log.debug("setDeniedItems %o", value);
  deniedItems = value;
}

export function isPermissionsAllowed(itemName, subName, permsType = 0) {
  log.debug("isPermissionsAllowed %o %o %o Denied: %o", itemName, subName, permsType, deniedItems);
  if (deniedItems == null)
    return false;
  let itemPerms = deniedItems[itemName];
  if (itemPerms == null)
    return true;
  let subItemPerms = itemPerms[subName];
  log.debug("Sub %o Passed %o", subItemPerms, permsType);
  return permsType !== subItemPerms;
}
