import { mapModelDataFromAPI } from "core/utils/mapper";
import { convertMilliToDateFormat } from "core/utils/formatters";

const keyMap = {
    entered_date: "enteredDate",
    entered_user_id: "enteredBy",
    comments: "comment"
};

const valueFormatters = {
    entered_date: convertMilliToDateFormat
};

export function mapComments(comments) {
    return mapModelDataFromAPI(comments.data, keyMap, valueFormatters);
}
