import { makeStyles } from "lib/util";

let styles;

export function useDialogStyles() {
  if (styles == null)
    styles = makeStyles("dlg", {
      root: { "& .MuiPaper-root": { maxWidth: "90%" } },
      content: {
        minWidth: "400px",
        minHeight: "120px",
        display: "flex",
        alignItems: "center"
      },
      header: {
        display: "flex",
        whiteSpace: "nowrap",
        alignItems: "flex-end",
        flexDirection: "row",
        paddingBottom: "10px",
        paddingLeft: "24px",
        paddingRight: "8px",
        paddingTop: "4px"
      },
      title: {
        paddingTop: "0px",
        paddingBottom: "4px",
      }
    });
  return styles;
}
