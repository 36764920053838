import React from "react";
import { Helmet } from "react-helmet";

export function SEO({ description, lang = "en", meta = [], title = null }) {
  let titleProp;
  if (title != null) {
    titleProp = {title: title + " - McLeod Software"};// does seem like the customer would want McLeod Software in their title
  }
  return (
    <Helmet
      {...titleProp}
      htmlAttributes={{
        lang
      }}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          name: "keywords",
          content: ""
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: description
        }
      ].concat(meta)}
    />
  );
}
