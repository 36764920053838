import React, { useState } from "react";
import { Panel, TextBox, Switch } from "lib/components";

export function Certifications() {
  const [ revoked, setRevoked ] = useState(false);
  return(
    <Panel caption="Certifications" rowBreakDefault={false} defaultSpacing={10}>
      <TextBox field="license_number" />
      <TextBox field="license_state" />
      <TextBox field="license_date"  />
      <TextBox field="license_exp_date" rowBreak/>
      <Switch field="license_revoked" leftLabel="No" rightLabel="Yes" value={revoked} onChange={setRevoked} rowBreak/>
      <TextBox field="license_revoked_d" width={600} multiline disabled={!revoked} />
    </Panel>
  );
}
