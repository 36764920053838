import React, { useState } from "react";
import { Label, Component } from ".";
import { getModelDataDisplayValue } from "../util/ModelUtil";
import { useComponentContext } from "./Context";
import { setDataContextValue, getDataContextValue } from "./DataContainer";
import { getSVG } from "./Image";
import { addDefaultMetaDataProps } from "lib/util/ModelDefaultProps";
import { useSwitchStyles } from "./SwitchStyles";
import { FormControl } from "./FormControl";
import { shouldUsePrintableVersion, getFirstSuppliedValue } from "./ComponentUtil";

export function Switch({ ...props }) {
  const context = useComponentContext();
  const [checked, setChecked] = useState(getModelValue(context, props.field));
  let checkValue = getFirstSuppliedValue(context, [
    props.checked,
    (context) => {
      if (props.field != null)
        return getDataContextValue(context, props.field);
      else
        return null;
    },
    checked,
    false
  ]);
  if(checkValue === "Y" || checkValue === true || (props.yesValue && checkValue === props.yesValue))
    checkValue = true;
  else
    checkValue = false;
  const classes = useSwitchStyles();
  props = addDefaultMetaDataProps(context, props);
  if (props.leftLabel == null && props.rightLabel == null && props.leftImage == null && props.rightImage == null) {
    props.leftLabel = "No";
    props.rightLabel = "Yes";
  }
  let value = props.checked;
  if (value == null)
    value = checkValue;
  const leftIcon = getImage(props.leftImage, classes);
  const rightIcon = getImage(props.rightImage, classes);
  let content;
  if (shouldUsePrintableVersion(context, props)) {
    if (value)
      content = <Label caption={props.rightLabel} padding={0} width={134} />
    else
      content = <Label caption={props.leftLabel} padding={0}  width={134} />
  } else {
    let startLabel, endLabel;
    if (props.leftLabel != null)
      startLabel = <Label caption={props.leftLabel} look={labelLook(checkValue)} paddingRight={0} />
    if (props.rightLabel != null)
      endLabel = <Label caption={props.rightLabel} look={labelLook(!checkValue)} paddingLeft={0} marginLeft={-4} />
    let icon, className = classes.thumb;
    if (checkValue) {
      icon = rightIcon;
      className += " " + classes.checked;
    }
    else
      icon = leftIcon;
    content = (
      <span className={classes.switchContainer}>
        {startLabel}
        <div
          className={classes.track}
          onClick={() => handleChange(context, props, !checked, setChecked)}
        >
          <div
            tabIndex={0}
            className={className}
            onKeyDown={(event) => {
              if (event.keyCode === 32) {
                handleChange(context, props, !checked, setChecked);
                event.preventDefault();
              }
            }}
          >
            <div className={classes.thumbInner}>
              {icon}
            </div>
          </div>
        </div>
        {endLabel}
      </span>
    );
  }
  return (
    <Component {...props}>
      <FormControl {...props} >
        <div className={classes.root}>
          {content}
        </div>
      </FormControl>
    </Component>
  );
}

function labelLook(value) {
  if (value)
    return "subtleLight1";
  else
    return "default1";
}

function getImage(image, classes) {
  if (image != null) {
    if (typeof image === "string")
      return getSVG(image, { className: classes.svg });
    else
      return getSVG(image.image, { className: classes.svg, ...image});
  }
  return null;
}

function handleChange(context, props, value, setChecked) {
  setChecked(value);
  let newValue = value;
  if(props.yesValue && props.noValue) {
    if(value=== "Y" || value === true || value === "true"){
      newValue = props.yesValue;
    }
    else {
      newValue = props.noValue;
    }
  }
  setDataContextValue(context, props.field, newValue);
  if (props.onChange)
    props.onChange(newValue, context);
}

function getModelValue(context, field) {
  if (field == null)
    return null;
  const modelValue = getModelDataDisplayValue(context, field);
  return modelValue === "Y" || modelValue === true || modelValue === "true";
}
