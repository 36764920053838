import React from "react";
import { Typography, Table, TableColumn } from "common";
import { connect } from "react-redux";
import { getActiveImagesByMovementId } from "core/store/selectors/OrdersSelectors";
import { fetchBuffer } from "lib/util/fetch";
import { API_PATHS } from "core/config/urls";
import { buildAPIUrl } from "core/utils/urls";
import { saveAsBlob } from "core/utils/common";
import { DownloadActionButton } from "features/DownloadActionButton";

const downloadFile = (imageId, fileName) => async () => {
  const response = await fetchBuffer({
    url: buildAPIUrl({ path: API_PATHS.IMAGE_DOWNLOAD, queryParams: { id: imageId } })
  });
  saveAsBlob(fileName, response.data);
};

export function ImagesTablePresentation({ images = [] }) {
  return (
    <Table
      data={images}
      emptyText="No Images Available for Display"
      disableFilter
      inline
      pagination
    >
      <TableColumn
        label="Date"
        minWidth={80}
        Component={({ postedDate: { date } = {} }) => <Typography component="div" value={date} />}
      />
      <TableColumn
        label="Name"
        minWidth={138}
        Component={({ fileName }) => <Typography component="div" value={fileName} />}
      />
      <TableColumn
        label="Image Uploaded By"
        minWidth={150}
        Component={({ postedBy }) => <Typography component="div" value={postedBy} />}
      />
      <TableColumn
        label="Description"
        minWidth={225}
        Component={({ comment }) => (
          <Typography style={{ maxWidth: 900 }} component="div" value={comment} />
        )}
      />
      <TableColumn
        label="Actions"
        Component={({ fileName, imageId }) => (
          <DownloadActionButton onClick={downloadFile(imageId, fileName)} size="small" />
        )}
      />
    </Table>
  );
}

const mapStateToProps = (state, { movementId }) => ({
  images: getActiveImagesByMovementId(state, movementId)
});

export const ImagesTable = connect(mapStateToProps)(ImagesTablePresentation);
