import * as React from "react";

function MapPinFilled({gradientColor1="#2C5DD1", gradientColor2="#109BE8", ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 29" {...props}>
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="prefix__a">
          <stop stopColor={gradientColor1} offset="0%" />
          <stop stopColor={gradientColor2} offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M24.297 10.472c0 2.07-.514 4.225-1.53 6.402-.872 1.881-2.132 3.8-3.738 5.709-2.509 2.979-5.318 5.257-6.878 6.417-1.561-1.164-4.37-3.438-6.879-6.417-1.606-1.908-2.866-3.832-3.742-5.713C.514 14.693 0 12.538 0 10.468c0-1.356.312-2.682.933-3.935.612-1.241 1.488-2.366 2.607-3.345A12.758 12.758 0 017.446.86C8.96.29 10.544 0 12.146 0h.008c1.607 0 3.19.29 4.698.86a12.777 12.777 0 013.909 2.328c1.123.979 1.998 2.104 2.604 3.345a8.867 8.867 0 01.932 3.94z"
        fill="url(#prefix__a)"
        fillRule="nonzero"
      />
    </svg>
  );
}

const SVGMapPinFilled = React.memo(MapPinFilled);
export { SVGMapPinFilled };