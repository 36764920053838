import React from "react";
import { Panel } from "lib/components";
import { StatusCell } from "portal/customer/StatusCell";

export function StopStatusPanel()
{
  return (
    <Panel width={75}>
      <StatusCell hideConnector noRowBreak />
      {/*<Label caption="STOP" />*/}
    </Panel>
  );
}