import React, { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { useMountEffect } from "core/hooks";
import { CircularProgress } from "@material-ui/core";
import { FormikDebugger } from "core";
import { getQueryParam } from "core/utils/urls";
import { FormContext, initialState } from "./context";
import { useStyles } from "./component.styles";

export function Form({ className, children, enableDebugger = false, ...restProps }) {
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const _enableDebugger = enableDebugger || getQueryParam("debugger");

  useMountEffect(() => {
    setState({
      ...state,
      setLoading: (loading) => setState((prevState) => ({ ...prevState, loading }))
    });
  });

  return (
    <Formik {...restProps}>
      <FormikForm className={className}>
        <FormContext.Provider value={state}>
          {children}
          {state.loading && (
            <div className={classes.overlay}>
              <CircularProgress className={classes.loadingSpinner} color="primary" />
            </div>
          )}
        </FormContext.Provider>
        {_enableDebugger && <FormikDebugger />}
      </FormikForm>
    </Formik>
  );
}
