import { makeStyles } from "lib/util";
import { styleDisableUserSelect } from "lib/util/StyleUtil";

let styles;

export function useStyles() {
  if (styles == null)
    styles = makeStyles("tbl", {
      tableComponent: { overflow: "hidden", display: "flex", flexDirection: "column" },
      tableContainer: {
        borderTop: "1px solid #CDCDCD",
        borderRadius: "4px",
        marginBottom: "12px",
        overflow: "auto",
        overflowX: "auto"
      },
      tableContainerNoBorder: {
        borderWidth: "0px",
        marginBottom: "12px",
        overflow: "auto",
        overflowX: "auto"
      },
      tableContainerCards: {
        borderWidth: "0px",
        overflow: "auto",
        overflowX: "hidden"
      },
      tableCards: {
        borderWidth: "0px",
        borderCollapse: "separate",
        borderSpacing: "0px 15px",
      },
      tableCell: { verticalAlign: "top", paddingTop: "4px", paddingBottom: "4px" },
      tableCellCard: {
        verticalAlign: "top",
        paddingTop: "4px",
        paddingBottom: "4px",
        borderRight: "0px solid #CDCDCD",
        borderTop: "1px solid #CDCDCD",
        borderBottom: "1px solid #CDCDCD",
        borderLeft: "1px solid #CDCDCD",
        borderRadius: "4px 0px 0px 4px"
      },
      tableCellCardFullBorder: {
        verticalAlign: "top",
        paddingTop: "4px",
        paddingBottom: "4px",
        borderRight: "1px solid #CDCDCD",
        borderTop: "1px solid #CDCDCD",
        borderBottom: "1px solid #CDCDCD",
        borderLeft: "1px solid #CDCDCD",
        borderRadius: "4px 4px 4px 4px"
      },
      tableCellCardNoBorder: {
        verticalAlign: "top",
        paddingTop: "4px",
        paddingBottom: "4px",
        borderRight: "0px",
        borderTop: "0px",
        borderBottom: "0px",
        borderLeft: "0px"
      },
      tableCellPanel: { padding: "0px" },
      cardRow: {
        marginBottom: "12px"
      },
      activeRow: { background: "rgb(249, 249, 249)" },
      activeRowCard: {
        marginBottom: "12px"
      },
      expansion: { background: "rgb(249, 249, 249)", transition: "height 4s", paddingLeft: "0px", paddingRight: "0px", paddingTop: "8px", paddingBottom: "8px" },
      expansionCard: {
        transition: "height 4s",
        borderRadius: "4px",
        marginBottom: "12px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "8px",
        paddingBottom: "8px"
      },
      expansionCell: { borderBottom: "solid 5px rgb(19, 124, 221)", padding: "0px" },
      expansionCellCard: {
        border: "1px solid #CDCDCD",
        borderRadius: "4px",
        padding: "0px"
      },
      expansionCellNoBorder: { borderBottom: "none", padding: "0px" },
      expandedRow: {
        "& .MuiTableCell-root": {
          borderBottom: "none", borderTop: "solid 1px rgb(19, 124, 221)"
        }
      },
      expandedRowNoBorder: {
        "& .MuiTableCell-root": {
          borderBottom: "none", borderTop: "none"
        }
      },
      hidden: { display: "none" },
      tableHeaderCell: {
        fontWeight: 400,
        fontSize: "0.75rem",
        ...styleDisableUserSelect,
        paddingTop: "0px",
        paddingBottom: "0px"
      },
      headerSpan: {
        paddingTop: "8px",
        paddingBottom: "8px",
        marginTop: "4px",
        marginBottom: "4px",
        marginLeft: "4px",
        cursor: "pointer",
        display: "inline-block",
        height: "100%"
      },
      noClick: {
        cursor: "unset"
      },
      sortArrow: {
        height: "18px",
        width: "18px",
        top: "16px",
        marginLeft: "4px",
        marginTop: "-2px",
        position: "absolute"
      },
      sortNumber: {
        left: "22px",
        top: "1px",
        position: "relative",
        fontSize: "11px"
      },
      defaultAlternateRow: {
        backgroundColor: "#e7f2fc"
      },
      expandColumn: {
        minWith: "48px",
        maxWidth: "128px"
      },
      expandColumnCard: {
        minWith: "48px",
        maxWidth: "128px",
        borderRight: "1px solid #CDCDCD",
        borderTop: "1px solid #CDCDCD",
        borderBottom: "1px solid #CDCDCD",
        borderLeft: "0px solid #CDCDCD",
        borderRadius: "0px 4px 4px 0px"
      }
    })
  return styles;
}
