import React from "react";
import { Table, Column, Label, Button, showDialog, DisplayTypes } from "lib/components";
import { getCompanySettings, getLogger } from "lib/util";
import { buildAPIUrl } from "core/utils/urls";
import { formatCityStateZip } from "lib/util/CityUtil";
import { PanelDriversChoiceDetail } from "./PanelDriversChoiceDetail";

const log = getLogger("portal.driver/DriversChoiceTable");

export function DriversChoiceTable(props) {
  const closeSpacing = { marginTop: -4, paddingTop: 0 };
  return (
    <Table emptyCaption="No matching loads were found." headerVisible={false} expandComponent={PanelDriversChoiceDetail} {...props}>
      <Column caption="Near Origin" field="miles_to_origin" displayType={DisplayTypes.DECIMAL}/>
      <Column caption="Match %" field="match_percent" />
      <Column caption="Estimated Load Pay" field="estimated_pay" look="primaryBold" />
      <Column caption="Prorated Revenue" field="prorated_revenue" look="primaryBold" displayType={DisplayTypes.CURRENCY} visible={isRevenueColumnVisible} />
      <Column caption="Order Number" field="order_id" />
      <Column caption="Customer" field="customer_name" />
      <Column caption="Shipper/Origin" sortfield="shipper_name" >
        <Label field="shipper_name" look="primaryBold" displayLabel="Shipper name" />
        <Label caption={shipperLocation} look="default1" displayLabel="Shipper location" style={closeSpacing} />
      </Column>
      <Column caption="Early Pickup" field="sched_arrive_early" displayType={DisplayTypes.DATETIME} format="long" />
      <Column caption="Consignee/Destination" sortfield="dest_name" >
        <Label field="dest_name" look="primaryBold" displayLabel="Consignee name" />
        <Label caption={destLocation} look="default1" displayLabel="Consignee location" style={closeSpacing} />
      </Column>
      <Column caption="Actions">
        <Button caption="Request" postUrl="api/driver/driverchoice/accept" postFields={["movement_id", "session_id"]} onPost={onAcceptPost} noRowBreak />
        <Button onClick={(event, context, setLoading) => handleDecline(event, context, setLoading)} caption="Decline" visible={!props.manualSearch}/>
      </Column>
    </Table>
  )
}

function isRevenueColumnVisible() {
  return getCompanySettings().enable_prorated_revenue;
}

function onAcceptPost(context, response) {
  log.debug("onAcceptPost %o", response);
  log.debug("onAcceptPost messages %o", response.data[0].messages);
  showDialog("Request successful", response.data[0].messages);
}

function handleDecline(event, context, setLoading) {
  const data = context.getActiveData();
  setLoading(true);
  fetch({
    url: buildAPIUrl("driver/driverchoice/decline"),
    method: "POST",
    data: { movementId: data.movement_id, sessionId: data.session_id }
  })
    .then(data => {
      let beforeDataList = context.data.list;
      beforeDataList.splice(context.dataIndex, 1);
      let stateData = [];
      for (let i = 0; i < beforeDataList.length; i++)
        stateData.push(beforeDataList[i]);
      if(data.data && data.data.declineResponse){
        stateData.push({modelData:data.data.declineResponse});
      }
      context.data.setDataList(stateData);
    })
    .catch(error => {
      //TODO need to display error in panel
      log.error("Error with decline", error);
    })
    .finally(() => setLoading(false));
}

function destLocation(context) {
  const data = context.getActiveData();
  return formatCityStateZip(data["dest_city"], data["dest_state"]);
}

function shipperLocation(context) {
  const data = context.getActiveData();
  return formatCityStateZip(data["shipper_city"], data["shipper_state"]);
}
