export function createCircularArray(size) {
	return { array: [], size: size, head: 0, tail: 0 }
}

export function addToCircularArray(buffer, value) {
	if (buffer.size > 0) {
		if (buffer.array.length < buffer.size) {
			buffer.array.push(value);
			buffer.tail++;
		}
		else {
			buffer.array[buffer.tail] = value;
			buffer.head++;
			buffer.tail++;
			if (buffer.tail > buffer.size)
				buffer.tail = 0;
			if (buffer.head > buffer.size)
				buffer.head = 0;
		}
	}
}

export function getFlattenedArray(buffer) {
	if (buffer.head <= buffer.tail)
		return buffer.array.slice(buffer.head, buffer.tail);
	else {
		let start = buffer.array.slice(buffer.head);
		let end = buffer.array.slice(0, buffer.tail);
		return start.concat(end);
	}
}