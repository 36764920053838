import React from "react";
import { setPageTitle } from "./PageTitle";

export function PageNotFound() {
  setPageTitle(null);
  return (
    <div>
      <div style={{marginBottom:24}}>
        Sorry, this page was not found.
      </div>
      <a href="/">Return home</a>
    </div>
  );
}
