import * as React from "react";

function MailOutlined(props) {
  return (
    <svg height="1em" width="1em" {...props}>
      <path
        fillOpacity={0.75}
        fillRule="evenodd"
        d="M12.214 0H1.066C.476 0 0 .477 0 1.066v9.265c0 .589.477 1.066 1.066 1.066h11.148c.589 0 1.065-.477 1.065-1.066V1.066C13.28.478 12.801 0 12.214 0zM11.07 1.066L7.102 4.383l-.023.02-.021.021a.529.529 0 01-.384.166h-.002a.53.53 0 01-.382-.161l-.021-.02-4.053-3.343h8.855zM1.066 10.33h11.148l.001-8.83-4.407 3.683c-.3.303-.7.47-1.128.472h-.007c-.426 0-.823-.164-1.125-.463L1.066 1.499v8.832z"
      />
    </svg>
  );
}

const SVGMailOutlined = React.memo(MailOutlined);
export { SVGMailOutlined };