import React from "react";
import { Panel, Label } from "lib/components";
import { formatCityStateZip } from "lib/util/CityUtil";
import { isEmptyString } from "lib/util";

export function renderLocationSuggestion(data, userText) {
  const cityStateZip = formatCityStateZip(data.city_name, data.state, data.zip_code);
  const props = { highlightText: userText, highlightReverse: true, paddingTop: 0, paddingBottom: 0}
  return (
    <Panel>
      <Label caption={data.name} color="primary" {...props} />
      <Label caption={data.address1} {...props} fontSize="small" nullDisplayValue="hide"/>
      <Label caption={data.address2} {...props} fontSize="small" nullDisplayValue="hide"/>
      <Label caption={cityStateZip} fontSize="small" {...props} />
    </Panel>
  );
}

export function formatLocationAutoComplete(data) {
  const cityStateZip = formatCityStateZip(data.city_name, data.state, data.zip_code);
  let displayString;
  if(data.source_name === "CITY")
    displayString = cityStateZip;
  else {
    if (!isEmptyString(data.name)){
      displayString = data.name + ", ";
    }
    if (!isEmptyString(data.address1)) {
      displayString = displayString + data.address1 + ", ";
    }
    if (!isEmptyString(displayString)) {
      displayString += cityStateZip;
    }
    else{
      displayString = cityStateZip;
    }
  }
  return displayString;
}

export function formatLocation(data) {
  const cityState = formatCityStateZip(data.city_name, data.state);
  return data.name + ", " + cityState;
}

export function formatLocationNameAutoComplete(data) {
  if (typeof data === "string")
    return data;
  return data.name;
}
export function renderCitySuggestion(data, userText) {
  const cityStateZip = formatCity(data);
  return (
      <Label caption={cityStateZip} highlightTex={userText} paddingTop={8} paddingBottom={8} />
  );
}

export function formatCity(data, userText) {
  if (typeof data === "string")
    return data;
  return formatCityStateZip(data.name, data.state_id, data.zip_code);
}
