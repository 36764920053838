import React from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { PATHS } from "core/config/urls";
import { AUTH_TYPE_AUTHENTICATED } from "core/store/states/UserState";
import { connect } from "react-redux";
import { getAuthType, getUserType } from "core/store/selectors/UserSelectors";
import { getAuthToken } from "lib/util/api";

function LoginRouteComponent({
  children,
  routeAuthType,
  routeUserType,
  authType,
  userType,
  ...rest
}) {
  // User's can hit the desired route as long as these criteria are met:
  // 1. The route's auth type and the user's context auth type match.
  // 2. The route doesn't have a user type specified, OR the
  //    route's user type matches the user's context user type.
  const location = useLocation();
  const history = useHistory();
  const {state } = location;
  //console.log({history});
  //console.log({sessionStorage});
  const {action} = history;
  const userSession = sessionStorage.getItem("persist:user");
  if (authType === routeAuthType && (!routeUserType || userType === routeUserType)) {
    return <Route {...rest} render={rest.render} />;
  }
  else if((action && action === "REPLACE")) {
    return <Route {...rest} render={rest.render} />;
  }

  let redirectPath = PATHS.LOGIN;
  if (authType === AUTH_TYPE_AUTHENTICATED && userType && action === "POP" && getAuthToken() != null) {
    redirectPath = PATHS.ROOT;
    if((state && state.from.pathname=== PATHS.ROOT) || !state) {
      // console.log("state.from is root");
      history.push(PATHS.ROOT);
    }
  }

  return (
    <Redirect
      to={{
        pathname: redirectPath,
        state: { from: rest.location }
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  authType: getAuthType(state),
  userType: getUserType(state)
});

export const LoginRoute = connect(mapStateToProps)(LoginRouteComponent);
