import React, { useState } from "react";
import { Panel, TextBox, Field, CheckBox, Switch, Button, ButtonActions, Row, DisplayTypes, Label } from "lib/components";
import { getMainPageContext } from "lib/util/MainPageContext";
import { DataModes } from "lib/util/ModelUtil";
import { Alignment } from "lib/components/ComponentUtil";

// These items are hard coded in RowDriverLane. Is it worth creating a lookupModel for this???
const priorityItems =[
  { caption: "Empty distance", value: "ED" },
  { caption: "Minimum revenue", value: "MR" }
];

export function DriversChoicePreferences(setDropDownOpen, handlePrefSubmitComplete, setRefreshPanel, refreshModelData) {
  const driverSettings = getMainPageContext().driver_settings;
  const [captionPriority1, setCaptionPriority1] = useState();
  const [captionPriority2, setCaptionPriority2] = useState();
  const [priorityUM1, setPriorityUM1] = useState();
  const [priorityUM2, setPriorityUM2] = useState();
  return (
    <Panel variant="form" autoSearch modelName="driver/driver-choice-search-preferences" width={900} dataMode={DataModes.EDIT} margin={16} defaultSpacing={20}>
      <Row>
        <TextBox caption="Driver" field="someUselessField2" value={driverSettings.name} width={200} usePrintableVersion />
        <TextBox caption="Tractor" field="someUselessField2" value={driverSettings.tractor_id} width={200} usePrintableVersion />
        <TextBox caption="Destination" field="someUselessField3" value={driverSettings.address} width={200} usePrintableVersion />
        <TextBox caption="PTA" field="someUselessField4" value={driverSettings.pta_date} width={250} usePrintableVersion displayType={DisplayTypes.DATETIME} format="long"/>
      </Row>
      <Row>
        <TextBox caption="Top Priority" field="choice_priority1" items={priorityItems} width={200} onItemChanged={(context,items,index)=>onItemChanged(context,items,index,setCaptionPriority1,setPriorityUM1)} width={200}/>
        <TextBox captionAlignment={Alignment.LEFT} style={{alignSelf:"flex-end"}} errorMarginLeft={80} caption={captionPriority1} field="choice_priority1_value" default="250" width={200} />
        <TextBox style={{alignSelf:"center"}} caption={priorityUM1} captionAlignment={Alignment.LEFT} field="priority1_um" usePrintableVersion width={40} nullDisplayValue=" "/>
        <TextBox caption="Commodity" field="choice_commodity" width={236} />
        <CheckBox style={{alignSelf:"center"}} caption="Exclude Commodity" field="exclude_commodity" width={200}/>
      </Row>
      <Row>
        <TextBox caption="Next Priority" field="choice_priority2" items={priorityItems} onItemChanged={(context,items,index)=>onItemChanged(context,items,index,setCaptionPriority2,setPriorityUM2)} width={200} />
        <TextBox captionAlignment={Alignment.LEFT} style={{alignSelf:"flex-end"}} caption={captionPriority2} field="choice_priority2_value" width={200} />
        <TextBox style={{alignSelf:"center"}} caption={priorityUM2} captionAlignment={Alignment.LEFT} field="priority2_um" usePrintableVersion width={40} nullDisplayValue=" "/>
        <Field caption="Equipment" field="choice_equip_type" width={236} lookupModelDisplayField="descr"/>
        <CheckBox style={{alignSelf:"center"}} caption="Exclude Equipment" field="exclude_equip_type" width={200}/>
      </Row>
      <Switch caption="Allow Trailer Drop" captionAlignment={Alignment.LEFT} field="allow_trailer_drop" rowBreak />
      <Row style={{justifyContent:"flex-end"}}>
        <Button caption="Cancel"  look="subtleLight1" onClick={() => toggleDropDownOpen(setDropDownOpen, setRefreshPanel)}/>
        <Button action={ButtonActions.SUBMIT} look="primaryFilledHoverDarker1" onComplete={() => handlePrefSubmitComplete(setDropDownOpen, setRefreshPanel, refreshModelData)} width={250} />
      </Row>
    </Panel>
  );
}

function toggleDropDownOpen(setDropDownOpen){
  setDropDownOpen((prevOpen) => !prevOpen);
}

function onItemChanged(context,items,index,setCaptionPriority,setPriorityUM) {
  switch(index) {
    case 0:
      if(items[0].key && items[0].key == "keyBlank"){
        //do nothing.
      }
      else {
        setCaptionPriority("less than");
        setPriorityUM("miles");     
      }
      break;
    case 1:
      if(items[0].key && items[0].key == "keyBlank") {
        setCaptionPriority("less than");
        setPriorityUM("miles");
      }
      else {
        setCaptionPriority("greater than");
        setPriorityUM("dollars");
      }
      break;
    case 2:
      setCaptionPriority("greater than");
      setPriorityUM("dollars");
      break;
    default:
      setCaptionPriority("less than");
      setPriorityUM("miles");
  }
}
