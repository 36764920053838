import React from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { useStyles } from "./SubmitButton.styles";

export function SubmitButton({ loading, children = [], ...restProps }) {
  const classes = useStyles();
  const { longWidthSubmitButton } = restProps;
  return (
    <Button
      disabled={loading}
      {...restProps}
      className={longWidthSubmitButton && classes.submitButtonWidth}
    >
      {children}
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </Button>
  );
}
