import React, { useState, useMemo, useCallback } from "react";
import { FormControl, FormHelperText, Button, Box, CircularProgress } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  FETCH_RESPONSE_TYPE_ARRAY_BUFFER,
  ORDERS_CATEGORY_ACCEPTED,
  ORDERS_TYPE_ACCEPTED,
  ORDERS_CATEGORY_OFFER_HISTORY,
  ORDERS_TYPE_OFFERED
} from "core/utils/constCapsule";
import { API_PATHS } from "core/config/urls";
import { buildAPIUrl } from "core/utils/urls";
import { fetch } from "lib/util/fetch";
import { Dialog, Typography } from "common";
import { USER_CONTROLS } from "core/config/controls";
import { getControlValue } from "core/store/selectors/UserSelectors";
import { connect } from "react-redux";
import { getActiveOrdersAndOrdersType } from "core/store/selectors/OrdersSelectors";
import { fetchOrders } from "core/store/actions/OrdersActions";
import { useStyles } from "./PendingOfferButton.styles";
import { PendingOfferInput } from "./PendingOfferInput";
import { PendingOfferSelect } from "./PendingOfferSelect";
import { PendingOfferDialog } from "./PendingOfferDialog";

function PendingOfferButtonPresentation({
  rate,
  offerDate,
  counterOfferEnabled,
  movementId,
  requireLegalTerm,
  requireOrderTerm,
  offerId,
  offerStatus,
  dispatchFetchOrders,
  id,
  makeOfferMessage,
  acceptOrderMessage,
  declineOfferMessage
}) {
  const classes = useStyles();
  const [accepted, setAccepted] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [counter, setCounter] = useState(false);
  const [counterSent, setCounterSent] = useState(false);
  const [targetMenuList, setTargetMenuList] = useState("none");
  const [menuOpen, setMenuOpen] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [showAcceptDialog, setShowAcceptDialog] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [orderConfPdfData, setOrderConfPdfData] = useState(null);
  const [responseDialogOpen, setIsResponseDialogOpen] = useState(false);
  const [responseText, setResponseText] = useState([]);
  const [processing, setProcessing] = useState(false);
  const menuItems = {
    none: [
      {
        name: "Decline Offer",
        value: "decline"
      }
    ],
    decline: [
      {
        name: "Not My Lane",
        value: "L"
      },
      {
        name: "No Equipment",
        value: "E"
      },
      {
        name: "Back",
        value: "back"
      }
    ]
  };
  if(!rate)
    rate = 0;
  if (counterOfferEnabled && counterOfferEnabled === "Y" && rate !== 0) {
    menuItems.none.push({ name: "Counter Offer", value: "counter" });
  } else if (counterOfferEnabled && counterOfferEnabled === "Y" && rate === 0) {
    menuItems.none.push({ name: "Make Offer", value: "counter" });
  }
  if (rate !== 0) {
    menuItems.none.splice(0, 0, {
      name: "Accept Offer",
      value: "accept"
    });
  }

  function toggleOfferResponseDialog() {
    setIsResponseDialogOpen((prevOpen) => !prevOpen);
  }

  const handleOfferApiResponseCallBack = useCallback(
    function handleOfferApiResponse(response, action) {
      let messageList = [];
      if (!response.data.success) {
        if (response.data.validation) messageList = response.data.validation.ruleList;
        else if (response.data.message) messageList.push({ message: response.data.message });
        else messageList.push({ message: "Error ".concat(action) });
        if (action === "Accepting") {
          setShowAcceptDialog(false);
          // setSelectDisabled(true);
        } else if (action === "Declining") {
          setMenuOpen(false);
          setTargetMenuList("none");
        }
        setResponseText(messageList);
        toggleOfferResponseDialog();
      } else {
        switch (action) {
          case "Accepting":
            messageList.push({ message: acceptOrderMessage });
            if (response.data.notificationEmails) {
              messageList.push({ message: "Emails have been sent to: " });
              messageList.push({ message: response.data.notificationEmails });
            }
            setShowAcceptDialog(false);
            setAccepted(true);
            setLabelName("Accepted");
            dispatchFetchOrders(ORDERS_CATEGORY_ACCEPTED, ORDERS_TYPE_ACCEPTED);
            break;
          case "Declining":
            messageList.push({ message: declineOfferMessage });
            setDeclined(true);
            setLabelName("Declined");
            dispatchFetchOrders(ORDERS_CATEGORY_OFFER_HISTORY, ORDERS_TYPE_OFFERED);
            break;
          case "Counter Offer":
            messageList.push({ message: makeOfferMessage });
            setCounterSent(true);
            setLabelName("Sent");
            dispatchFetchOrders(ORDERS_CATEGORY_OFFER_HISTORY, ORDERS_TYPE_OFFERED);
            break;
          default:
            break;
        }
        setResponseText(messageList);
        toggleOfferResponseDialog();
      }
      setProcessing(false);
    },
    [acceptOrderMessage, declineOfferMessage, dispatchFetchOrders, makeOfferMessage]
  );

  const offerDeclinedApiCallBack = useCallback(
    async function offerDeclinedApi(reason) {
      let response = "";
      setProcessing(true);
      const reqParam = { offerId, declineReason: reason };
      const config = { method: "post", data: reqParam };
      response = await fetch({
        url: buildAPIUrl(API_PATHS.DECLINE_OFFER),
        ...config
      });
      handleOfferApiResponseCallBack(response, "Declining");
    },
    [handleOfferApiResponseCallBack, offerId]
  );

  const offerCounteredApiCallBack = useCallback(
    async function offerCounteredApi(amount) {
      let response = "";
      const reqParam = { offerId, newAmount: amount, isBroker: false };
      const config = { method: "post", data: reqParam };
      response = await fetch({
        url: buildAPIUrl(API_PATHS.COUNTER_OFFER),
        ...config
      });
      handleOfferApiResponseCallBack(response, "Counter Offer");
    },
    [handleOfferApiResponseCallBack, offerId]
  );

  const offerAccepteddApiCallBack = useCallback(
    async function offerAccepteddApi() {
      let response = "";
      setProcessing(true);
      const config = {
        method: "post",
        data: { offerId }
      };
      response = await fetch({
        url: buildAPIUrl(API_PATHS.ACCEPT_OFFER),
        ...config
      });
      handleOfferApiResponseCallBack(response, "Accepting");
    },
    [handleOfferApiResponseCallBack, offerId]
  );

  async function loadLegalTermPdfData() {
    let response = "";
    response = await fetch({
      url: buildAPIUrl(API_PATHS.LEGAL_TERMS),
      responseType: FETCH_RESPONSE_TYPE_ARRAY_BUFFER
    });
    setPdfData(atob(response.data));
  }

  const loadOrderConfPdfDataCallBack = useCallback(
    async function loadOrderConfPdfData() {
      let response = "";
      response = await fetch({
        url: buildAPIUrl({
          path: API_PATHS.ORDER_TERMS_GENERATE,
          queryParams: { movementId }
        }),
        responseType: FETCH_RESPONSE_TYPE_ARRAY_BUFFER
      });
      setOrderConfPdfData(atob(response.data));
    },
    [movementId]
  );

  async function shouldShowAcceptDialog(offerId)
  {
    let response = await fetch({
      url: buildAPIUrl({
        path: API_PATHS.IS_ERATE,
        queryParams: { offerId }
      })
    });
    return response.data;
  }

  const handleChangeCallBack = useCallback(
    function handleChange(event) {
      // console.log(event.target.value);
      switch (event.target.value) {
        case "accept":
          shouldShowAcceptDialog(offerId)
            .then( (isERateConf) =>  {
              if (requireLegalTerm === "Y" && !isERateConf) loadLegalTermPdfData();
              if (requireOrderTerm === "Y" && !isERateConf) loadOrderConfPdfDataCallBack();
              if ((requireLegalTerm === "Y" || requireOrderTerm === "Y") && !isERateConf) setShowAcceptDialog(true);
              else offerAccepteddApiCallBack();
            });
          break;
        case "decline":
          setTargetMenuList("decline");
          setMenuOpen(true);
          break;
        case "back":
          setTargetMenuList("none");
          setMenuOpen(true);
          break;
        case "L":
        case "E":
          offerDeclinedApiCallBack(event.target.value);
          break;
        case "counter":
          setCounter(true);
          setMenuOpen(false);
          break;
        default:
          setTargetMenuList("none");
          setAccepted(false);
          setDeclined(false);
          setCounter(false);
          break;
      }
    },
    [
      loadOrderConfPdfDataCallBack,
      offerAccepteddApiCallBack,
      offerDeclinedApiCallBack,
      requireLegalTerm,
      requireOrderTerm
    ]
  );

  function handleDialogAction(event) {
    switch (event.currentTarget.value) {
      case "cancel":
        setTargetMenuList("none");
        setShowAcceptDialog(false);
        break;
      case "accept":
        offerAccepteddApiCallBack();
        break;
      default:
        setShowAcceptDialog(false);
        break;
    }
  }

  const handleCloseCallback = useCallback(
    function handleClose(event) {
      event.stopPropagation();
      if (targetMenuList === "decline") setMenuOpen(true);
      else setMenuOpen(false);
    },
    [targetMenuList]
  );

  function handleOpen(event) {
    event.stopPropagation();
    setMenuOpen(true);
  }

  const handleOfferButtonClickCallBack = useCallback(
    function handleOfferButtonClick(event) {
      offerCounteredApiCallBack(event.offerAmount);
    },
    [offerCounteredApiCallBack]
  );

  function onClickOutlinedInput(event) {
    event.stopPropagation();
  }

  function handleClickAway() {
    setTargetMenuList("none");
    setAccepted(false);
    setDeclined(false);
    setCounter(false);
  }
  function handleDialogClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /*
    Depending on what state the offer is in, different componenets will render
  */
  const DynamicComponent = useMemo(() => {
    if (!accepted && !declined && !counter && !counterSent) {
      return (
        <PendingOfferSelect
          menuItems={menuItems}
          rate={rate}
          handleChange={handleChangeCallBack}
          handleClose={handleCloseCallback}
          handleOpen={handleOpen}
          menuOpen={menuOpen}
          targetMenuList={targetMenuList}
          disabled={false}
        />
      );
    }
    if (!accepted && !declined && !counterSent) {
      return (
        <PendingOfferInput
          rate={rate}
          handleOfferButtonClick={handleOfferButtonClickCallBack}
          onClickOutlinedInput={onClickOutlinedInput}
          handleClickAway={handleClickAway}
        />
      );
    }

    return (
      <Button
        disabled
        variant="contained"
        color="secondary"
        open={menuOpen}
        className={classes.Button}
      >
        <CheckCircleIcon />
        <Typography
          component="span"
          fontWeight="regular"
          fontSize="medium"
          value={`Offer ${labelName}`}
        />
      </Button>
    );
  }, [
    accepted,
    classes.Button,
    counter,
    counterSent,
    declined,
    handleChangeCallBack,
    handleCloseCallback,
    handleOfferButtonClickCallBack,
    labelName,
    menuItems,
    menuOpen,
    rate,
    targetMenuList
  ]);

  return (
    <>
      <FormControl id="pendingOfferFormControl" variant="outlined" className={classes.formControl}>
        {DynamicComponent}
        {!accepted && !declined && !counterSent && !counter ? (
          <FormHelperText style={{ textAlign: "right" }}>
            {offerStatus === "O" ? "Counter offer sent" : `Offer placed ${offerDate}`}
          </FormHelperText>
        ) : null}
        <PendingOfferDialog
          showDialog={showAcceptDialog}
          handleDialogAction={handleDialogAction}
          pdfData={pdfData}
          orderConfPdfData={orderConfPdfData}
          processing={processing}
        />
        <Dialog
          maxWidth="xl"
          className={classes.responseDialog}
          open={responseDialogOpen}
          onClose={toggleOfferResponseDialog}
          onClick={handleDialogClick}
        >
          <Box>
            {responseText.map((response) => (
              <Typography
                textTransform="regular"
                key={new Date().getTime() + Math.random()}
                fontSize="large"
                component="div"
                variant="h6"
                fontWeight="bold"
                wordWrap
              >
                {response.message}
              </Typography>
            ))}
            <Box m={4} lineHeight={10}>
              <Typography value="Order Number" variant="h6" />
              <Typography value={id} fontWeight="bold" variant="h6" />
            </Box>

            <Button
              className={classes.responseDialogButton}
              variant="outlined"
              onClick={toggleOfferResponseDialog}
            >
              Close
            </Button>
          </Box>
        </Dialog>
        {processing && <CircularProgress size={20} className={classes.buttonProgress} />}
      </FormControl>
    </>
  );
}

const mapStateToProps = (state) => ({
  legalTermsName: getControlValue(state, USER_CONTROLS.LEGAL_TERMS_NAME),
  requireLegalTerm: getControlValue(state, USER_CONTROLS.REQUIRE_LEGAL_TERM),
  requireOrderTerm: getControlValue(state, USER_CONTROLS.REQUIRE_ORDER_TERM),
  makeOfferMessage: getControlValue(state, USER_CONTROLS.MAKE_OFFER),
  acceptOrderMessage: getControlValue(state, USER_CONTROLS.ACCEPT_ORDER),
  declineOfferMessage: getControlValue(state, USER_CONTROLS.DECLINE_OFFER),
  ...getActiveOrdersAndOrdersType(state)
});

const mapDispatchToProps = {
  dispatchFetchOrders: fetchOrders
};

export const PendingOfferButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingOfferButtonPresentation);
