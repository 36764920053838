import React from "react";
import {SvgIcon as MUISvgIcon} from "@material-ui/core";

export function SVGIcon(props) {
  let renderProps = {...props};
  if (renderProps.transform == null && (renderProps.direction != null || renderProps.rotate != null)) {
    if (renderProps.rotate != null)
      renderProps.transform = "rotate(" + renderProps.rotate + ")";
    else if (renderProps.direction === "left")
      renderProps.transform = "rotate(90)";
    else if (renderProps.direction === "up")
      renderProps.transform = "rotate(180)";
    else if (renderProps.direction === "right")
      renderProps.transform = "rotate(270)";
    delete renderProps.direction;
    delete renderProps.rotate;
}
if (renderProps.color != null) {
  renderProps.htmlColor = renderProps.color;
  delete renderProps.color;
}
return (
    <MUISvgIcon {...renderProps} >
      {props.children}
    </MUISvgIcon>
  );
}
