import React, { useState } from "react";
import { TextBox, Field, Panel, Table, DisplayTypes, Row } from "lib/components";
import { Alignment } from "lib/components/ComponentUtil";
import { fetchModelData } from "lib/util";
import { getDataFromContext } from "lib/util/ModelUtil";

//this table renders differently depending on what step of the wizard is being viewed
export function HandlingReqsTable(currentStep, prevStep, props={}) {
  const [handlingReqMasterData, setHandlingReqMasterData] = useState(null);
  const handlingReqsData = [];

  if (handlingReqMasterData === null) {
    fetchModelData("dispatch/handling-reqs", null, null, setHandlingReqMasterData);
  }

  if (handlingReqMasterData != null && handlingReqMasterData.length > 0)
  {
    for (let x=0 ; x<handlingReqMasterData.length ; x++)
    {
      const handlingReq = handlingReqMasterData[x];
      const handlingReqData = {...handlingReq};
      handlingReqData["row_num"] = x;
      handlingReqsData.push(handlingReqData);
    }
  }

  const allStepProps = {
    field: "handling_reqs",
    caption: "Accessorials",
    filterVisible: false,
    allowExport: false,
    addVisible: false,
    initialAddRow: false,
    border: false,
    showButtonPanel: false,
    showLinesBetweenRows: false,
    showSave: false,
    showCancel: false,
    fillRow: true,
    rowBreak: true
  }

  if (props.noMargin === true) {
    allStepProps.margin=0;
  }

  if (props.noPadding === true) {
    allStepProps.padding=0;
  }

  const step1Props = {
    data: handlingReqsData,
    rowEditor: (editorProps, row, rowIndex, context, toolsPanel) => PanelHandlingReqStep1(editorProps, row, context, currentStep, prevStep),
    addCaption: "Add Item",
    autoSave: true,
    showDelete: () => {return false},
    emptyCaption: "No accessorials are available for selection.",
    minHeight: props.minHeight != null ? props.minHeight : 325,
    componentPropsCallback: (data) => componentProps(data)
  }

  const step3Props = {
    rowEditor: PanelHandlingReqStep3,
    showEditor: false,
    showTools: false,
    emptyCaption: "No accessorials were selected.",
    minHeight: props.minHeight != null ? props.minHeight : 50
  }

  const stepSpecificProps = currentStep == 0 ? step1Props : step3Props;
  return <Table {...allStepProps} {...stepSpecificProps} />;
}

function componentProps(data) {
  let result = {};
  if(data.modelData.selected === true) {
    if(data.modelData.text_title)
      result.text_value = {required: true};
    if(data.modelData.integer_title)
      result.integer_value = {required: true};
    if(data.modelData.float_title)
      result.float_value = {required: true};
  }
  return result;
}

function PanelHandlingReqStep1(props, row, context, currentStep, prevStep) {
  const [showAddlPanel, setShowAddlPanel] = useState(false);
  const handling_reqs = getDataFromContext(context.data.parentContext, "handling_reqs");
  // when going back to step 1, need to combine what's in handlingReqsData and context.
  if(handling_reqs && (prevStep !== undefined && prevStep !== 0) && currentStep === 0){
    for (let i = 0; i < handling_reqs.length; i++) {
      if(row.modelData && row.modelData["hdr_uid"] === handling_reqs[i]["hdr_uid"] ){
        row.modelData = Object.assign(handling_reqs[i], row.modelData);
        break;
      }
    }
  }

  React.useEffect(() => {
    if(row != null && row.modelData != null && row.modelData.selected === true)
      setShowAddlPanel(true);
  }, [showAddlPanel]);

  function handleSelectionChange() {
      setShowAddlPanel((prevValue) => !prevValue);
  }

  const inputProps = {width:250};

  let textField;
  if (row.modelData.text_title != null)
  {
    textField =
      <TextBox
        field="text_value"
        caption={row.modelData.text_title}
        captionAlignment={Alignment.LEFT}
        width={700}
        padding={0}
        paddingBottom={4}
        errorRight={true}
        rightAlign={true}
        captionWidth={125}
        inputProps={inputProps}
        required={showAddlPanel}
      />;
  }

  let integerField;
  if (row.modelData.integer_title != null)
  {
    integerField =
      <TextBox
        field="integer_value"
        caption={row.modelData.integer_title}
        captionAlignment={Alignment.LEFT}
        displayType={DisplayTypes.INTEGER}
        width={700}
        padding={0}
        paddingBottom={4}
        errorRight={true}
        rightAlign={true}
        captionWidth={125}
        inputProps={inputProps}
        required={showAddlPanel}
      />;
  }

  let floatField;
  if (row.modelData.float_title != null)
  {
    floatField =
      <TextBox
        field="float_value"
        caption={row.modelData.float_title}
        captionAlignment={Alignment.LEFT}
        displayType={DisplayTypes.DECIMAL}
        width={700}
        padding={0}
        paddingBottom={4}
        errorRight={true}
        rightAlign={true}
        captionWidth={125}
        inputProps={inputProps}
        required={showAddlPanel}
      />;
  }

  let addlPanelPaddingTop = textField != null || integerField != null || floatField != null ? 12 : 0
  return (
    <Panel rowBreakDefault={true} {...props}>
      <Field field="selected" caption={row.modelData.hdr_title} onChange={handleSelectionChange} height={20} padding={0} />
      <Panel rowBreakDefault={false} marginLeft={29} visible={showAddlPanel} padding={0} paddingTop={addlPanelPaddingTop}>
        <Row padding={0}>{textField}</Row>
        <Row padding={0}>{integerField}</Row>
        <Row padding={0}>{floatField}</Row>
      </Panel>
    </Panel>
  );
}

function PanelHandlingReqStep3(props, row) {
  const [showAddlPanel, setShowAddlPanel] = useState(false);

  React.useEffect(() => {
    if(row != null && row.modelData != null && row.modelData.selected === true)
      setShowAddlPanel(true);
  }, [showAddlPanel]);

  let textField;
  if (row.modelData.text_title != null && row.modelData.text_value != null)
  {
    textField =
      <TextBox
        field="text_value"
        caption={row.modelData.text_title}
        captionAlignment={Alignment.LEFT}
        width={700}
        padding={0}
        errorRight={true}
        rightAlign={true}
        formReadOnly={true}
        usePrintableVersion
        captionWidth={125}
      />;
  }

  let integerField;
  if (row.modelData.integer_title != null && row.modelData.integer_value != null)
  {
    integerField =
      <TextBox
        field="integer_value"
        caption={row.modelData.integer_title}
        captionAlignment={Alignment.LEFT}
        displayType={DisplayTypes.INTEGER}
        width={700}
        padding={0}
        paddingBottom={4}
        errorRight={true}
        rightAlign={true}
        formReadOnly={true}
        captionWidth={125}
      />;
  }

  let floatField;
  if (row.modelData.float_title != null && row.modelData.float_value != null)
  {
    floatField =
      <TextBox
        field="float_value"
        caption={row.modelData.float_title}
        captionAlignment={Alignment.LEFT}
        displayType={DisplayTypes.DECIMAL}
        width={700}
        padding={0}
        paddingBottom={4}
        errorRight={true}
        rightAlign={true}
        formReadOnly={true}
        captionWidth={125}
      />;
  }
  return (
    <Panel rowBreakDefault={true} {...props}>
      <Field field="selected" caption={row.modelData.hdr_title} height={20} padding={0} />
      <Panel rowBreakDefault={false} marginLeft={29} visible={showAddlPanel} padding={0}>
        <Row padding={0}>{textField}</Row>
        <Row padding={0}>{integerField}</Row>
        <Row padding={0}>{floatField}</Row>
      </Panel>
    </Panel>
  );
}
