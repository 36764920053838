import * as React from "react";

function ChevronDown(props) {
  return (
    <svg
      viewBox="0 0 9 9"
      {...props}
    >
      <path d="M0 1L5 9L9 0" />
    </svg>
  );
}

const SVGChevronDown = React.memo(ChevronDown);
export { SVGChevronDown };

//<path d="M0 1.026c0 .226.075.453.225.643l7.997 9.948a1.026 1.026 0 001.598 0l7.954-9.905A1.026 1.026 0 0016.177.428L9.02 9.338 1.823.383A1.024 1.024 0 000 1.026z" />
