import React from "react";
import { Panel } from "lib/components";
import { setDataContextValue } from "lib/components/DataContainer";
import { QuoteStopPanel, validateShipperLocation } from "../QuoteStopPanel";

export function QuoteOverview(freightItemsTable, handlingReqsTable, currentStep) {
  return (
    <Panel
      rowBreakDefault={false}
      maxWidth="lg"
      variant="form"
      caption="Quote Overview"
      submitCaption="Get Quote"
      onContinue={(context, setLoading, handleContinue) => getQuote(context, setLoading, handleContinue)}
    >
      <QuoteStopPanel currentStep={currentStep} rowBreak/>
      {freightItemsTable}
      {handlingReqsTable}
    </Panel>
  );
}

function getQuote(context, setLoading, handleContinue) {
  validateShipperLocation(context, function(result) {
    let formIsValid = context.validateForm();
    if (result && formIsValid) {
      context.data.submit(context, setLoading, (response) => handleGetQuoteResponse(context, response, handleContinue), null, (data) => adjustDataForGetQuote(data));
    }
  });
}

function adjustDataForGetQuote(data)
{
  delete data[0].stops;
  delete data[0].carrier_rates;
  delete data[0].disclaimer;
  data[0].ignoreEnforceShipperCode = true;
  return data;
}

function handleGetQuoteResponse(context, response, handleContinue)
{
  setDataContextValue(context, "replaceModelData", response.data[0].data[0]);
  handleContinue();
}
