import React from "react";
import { withSnackbar } from "notistack";
import { Panel, Label, Button, Spinner } from ".";
import { getLogger } from "lib/util";

let enqueue, close;

let log;

function getLog() {
  if (log == null)
    log = getLogger("lib.components.Snackbar");
  return log;
}

function SnackbarPresentation(props) {
  enqueue = props.enqueueSnackbar;
  close = props.closeSnackbar;
  return null;
}

export function showProgressSnackbar(titleText, message, message2 = "Please feel free to keep working.", persist=true, customKey) {
  let title = (
    <Panel noRowBreak fillRow >
      <Label caption={titleText} fontSize="xlarge" color="white" marginRight={24} noRowBreak />
      <Spinner size={24} noRowBreak />
    </Panel>
  );
  let label1 = <Label caption={message} marginRight={32} fontSize="large" color="white" />
  let label2;
  if (message2)
    label2 = <Label caption={message2} marginRight={32} fontSize="large" color="white" />
  let body = (
    <Panel>
      {label1}
      {label2}
    </Panel>
  );
  getLog().info("Show progress snackbar %s %s", message, message2);
  showSnackbar(null, persist, title, body, null, customKey);
}

export function showWarningSnackbar(message, persist, customKey, preventDuplicate) {
  const backgroundColor = "#ff9800"
  const titleContent = (
      <Label
        image={{image: "Danger", fill: "white", marginRight: 8}} 
        marginLeft={16} 
        backgroundColor={backgroundColor} 
        color="white" 
        fontSize="large" 
        fontBold
        caption="Warning"
      />
    );
  const bodyContent = <Label marginLeft={48} marginTop={-8} marginRight={32} backgroundColor={backgroundColor} color="white" fontSize="large" caption={message} />    
  showSnackbar(message, persist, titleContent, bodyContent, backgroundColor, customKey, preventDuplicate);
}

export function showSnackbar(message, persist, titleContent, bodyContent, backgroundColor, customKey, preventDuplicate) {
  let props = {};
  if (persist)
    props.persist = true;
  props.key = customKey != null ? customKey : new Date().getTime()+"_"+message;
  if (preventDuplicate === true) {
    props.preventDuplicate = true;
  }
  props.disableWindowBlurListener = true;
  props.content = (key, message) => {
    return <SnackWrapper key={key} onDismiss={() => close(key)} message={message} titleContent={titleContent} bodyContent={bodyContent} backgroundColor={backgroundColor}/>
  }
  getLog().info("Show snackbar %s", message);
  return enqueue(message, props);
}

export async function closeSnackbar(key) {
  const startTime = key != null && key.substr(0, key.indexOf('_'));  
  if(startTime != null && !isNaN(startTime)){
    let date = new Date(parseInt(startTime));
    const diffTime = Math.abs(new Date() - date);
    console.log({diffTime});
    if(diffTime != null && diffTime < 1000){
      await new Promise(r => setTimeout(r, 1500));
      close(key);
    }
    else {
      close(key);
    }
  }
  else
    close(key);
}

export const Snackbar = withSnackbar(SnackbarPresentation)

class SnackWrapper extends React.Component {
  render() {
    let titleContent = this.props.titleContent;
    let bodyContent = this.props.bodyContent;
    let backgroundColor = "#313131";
    let panelContent;
    let closeButton = <Button image={{ image: "Chevron", direction: "left", stroke: "white" }} marginLeft={32} onClick={this.props.onDismiss} />;
    
    if (this.props.backgroundColor != null)
    { backgroundColor = this.props.backgroundColor; }    
    
    if (titleContent == null && bodyContent == null) {
      panelContent = (
          <Label marginLeft={16} marginTop={12} marginBottom={14} color="white"
            fontSize="large" caption={this.props.message} fillRow noRowBreak />
      );
    }
    else {
      if (titleContent == null)
        titleContent = <Panel fillRow noRowBreak />
      if (bodyContent == null)
        bodyContent = <Label marginLeft={16} marginTop={-18} marginBottom={14} color="white" fontSize="large" caption={this.props.message} />
      panelContent = (
        <Panel fillRow noRowBreak>
          {titleContent}
          {bodyContent}
        </Panel>
      );
    }
    return (
      <div>
        <Panel style={{ backgroundColor: backgroundColor, borderRadius: 4, minWidth: 280 }}>
          {panelContent}
          <Panel noRowBreak>
            {closeButton}
          </Panel>
        </Panel>
      </div>
    );
  }
}

// want this on MuiButtonBase-root
    // padding-right: 12px;
    // padding-top: 0px;
    // min-width: unset;
