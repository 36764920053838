import * as React from "react";

function MoneyBagOutline({ fill = "none", stroke = "#FFF", ...props }) {
  return (
    <svg width="1em" height="1em" {...props}>
      <g fill={fill}>
        <path
          stroke={stroke}
          d="M4.4 6.7l-1 .1L3 7c-.2 0-.4 0-.5-.2 0-.2 0-.4.3-.5L4 6l-.1-.1a3 3 0 00-1-.5c-.2 0-.2-.2-.2-.4s.2-.3.4-.2c.4 0 .7.2 1 .4l1 .7c.4.2.8.3 1.3.2.5-.2 1-.2 1.6 0l.8.1H9A17 17 0 0111.5 9c.7.9 1.2 1.9 1.4 3a4 4 0 01-.2 2.2c-.3.8-.9 1.4-1.5 1.8-.9.5-1.8.8-2.7.9-1.2.1-2.4 0-3.6-.3a5.4 5.4 0 01-3.6-3c-.4-1.1-.4-2.1 0-3.2.5-1 1.2-2 2-2.8l1.1-1z"
        />
        <path
          stroke={stroke}
          d="M4.5 3.2a3 3 0 011-2c.3-.2.6-.3.9-.1l.9.5c.6.4 1.2.6 1.8.5h.9c.6 0 1 .4.8 1-.2.9-.7 1.5-1.3 2l-.8.5h-.3l-.9-.2h-.7l-.7.2h-.5c-.1 0-.3-.1-.3-.3-.3-.5-.6-1-.7-1.6v-.5z"
        />
        <path
          fill={stroke}
          d="M7.9 15.6v-1c.9-.2 1.4-1 1.4-1.8 0-1.8-2.4-1.7-2.4-2.6 0-.4.2-.6.6-.6.4 0 .7.1 1 .4l.7-.7c-.3-.4-.8-.7-1.3-.8v-1h-1v1c-.8.2-1.3.8-1.3 1.7C5.6 12 8 12 8 13c0 .4-.2.6-.7.6-.5 0-.9-.2-1.4-.5l-.5 1c.4.3 1 .6 1.6.6v1h.9z"
        />
      </g>
    </svg>
  );
}

const SVGMoneyBagOutline = React.memo(MoneyBagOutline);
export { SVGMoneyBagOutline };