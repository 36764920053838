import { mapObject } from "core/utils/mapper";
import { setBranding } from "lib/util/Theme";

const keyMap = {
  headerBgColor: "colors.headerBackground",
  headerTextColor: "colors.headerText"
};

const valueFormatters = {};

export function mapUserBranding(branding) {
  const result = mapObject(branding, keyMap, valueFormatters);
  setBranding(result.colors);
  return result;
}
