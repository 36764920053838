import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function ReplyArrowFilled(props) {
  return (
    <SVGIcon viewBox="0 0 13 13" {...props}>
      <defs>
        <path
          d="M6.111 4.6V3L3 5.8l3.111 2.8V6.96C8.333 6.96 9.89 7.6 11 9c-.444-2-1.778-4-4.889-4.4z"
          id="prefix__a"
        />
      </defs>
      <path
        d="M6.5 0A6.5 6.5 0 000 6.5 6.5 6.5 0 006.5 13 6.5 6.5 0 0013 6.5 6.5 6.5 0 006.5 0z"
        fill={props.fill == null ? "#F60" : props.fill }
      />
      <use fill="#FFF" xlinkHref="#prefix__a" />
    </SVGIcon>
  );
}

const SVGReplyArrowFilled = React.memo(ReplyArrowFilled);
export { SVGReplyArrowFilled };
