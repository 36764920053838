import * as React from "react";

function ArrowTopRight(props) {
  return (
    <svg width="1em" height="1em" {...props}>
      <path d="M0 1.7v17.6A1.7 1.7 0 001.7 21h17.6a1.7 1.7 0 001.7-1.7v-8.8c0-.47-.38-.84-.84-.84s-.84.38-.84.84v8.8H1.7V1.7h8.8c.47 0 .84-.38.84-.84S10.97 0 10.5 0H1.7C.75 0 0 .75 0 1.7zm14.85 5.65l2.43 2.43c.26.26.72.08.72-.3V3.84c0-.47-.38-.84-.84-.84h-5.64c-.37 0-.56.45-.3.72l2.43 2.43-4.4 4.4c-.16.16-.25.38-.25.6s.08.43.25.6c.33.33.86.33 1.2 0l4.4-4.4z" />
    </svg>
  );
}

const SVGArrowTopRight = React.memo(ArrowTopRight);
export { SVGArrowTopRight };