/* eslint-disable */

import React, { useState } from "react";
import { Panel, Label, Button, Spinner } from "lib/components";
import { loadAuthenticatedContext } from "lib/util/MainPageContext";
import { getLogger, makeStyles, useMountEffect } from "lib/util";
import { navigateTo } from "lib/util/Navigation";
import { clearSessionToken } from "portal/general/login/WebLoginPresentation";
import { isAuthenticated } from "lib/util/api";
import { getThemeColor } from "lib/util/Theme";

const log = getLogger("lib.pages.UserMenu");

let styles;

function useStyles() {
  if (styles == null)
    styles = makeStyles("usm", {
      dropDown: {
        "& .MuiPaper-root": {
          backgroundColor: "#212121",
          "& .MuiButtonBase-root": {
            border: "1px solid #979797"
          }
        }
      },
      dropDownItems: {
        color: "white",
        fontSize: "14px",
        opacity: "0.75",
        fontWeight: 400,
        cursor: "pointer",
        "&:hover": {
          fontWeight: "600",
          color: "#137CDD",
          opacity: "1"
        }
      },
      label: {
        color: getThemeColor("branding.headerText") || "white"
      },
      image: {
        stroke: getThemeColor("branding.headerText") || "white",
        margin: "0px"
      }
    });
  return styles;
}


export function UserMenu() {
  const classes = useStyles();
	const [mainPageContext, setMainPageContext] = useState();
	const [loading, setLoading] = useState(isAuthenticated() ? true : false);
	useMountEffect(() => {
    if (isAuthenticated())
      loadAuthenticatedContext(setMainPageContext, setLoading);
  })

	if (loading)
		return <Spinner size={24}/>
	else if (mainPageContext == null || mainPageContext.user_settings == null)
		return <a className={classes.label} href="/login"> Sign In </a>;
	else
		return (
			<Panel>
				<Panel fillRow noRowBreak >
					<Label caption={mainPageContext.user_settings.user_name} className={classes.label} fontSize="large" />
					<Label caption={mainPageContext.user_settings.user_company_name}  className={classes.label} fontSize="small" />
				</Panel>
				<Button image={{image: "Chevron"}} overrideImageClassName={classes.image} marginTop={14} dropDown={ dropDown(classes) } dropDownClassName={classes.dropDown} dropDownAlign="right"/>
			</Panel>
		);
}

function dropDown(classes) {
	return [
		<Label caption="Sign out" className={classes.dropDownItems} width={200} onClick={() => {
      clearSessionToken();
      navigateTo("/login", null, true)
    }} />
	];
}
