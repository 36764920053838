import React from "react";
import { Label, TextBox, CheckBox, Panel } from "lib/components";
import { getCompanySettings } from "lib/util";
export function QuoteTermsConditions(props) {
  return (
    <Panel rowBreakDefault={true} {...props} marginRight={70}>
      <Label caption="Terms and Conditions" look="defaultBold4" fillRow />
	    <TextBox field="terms_and_conditions" fullText={getCompanySettings().order_terms_conditions} readMore maxLength={340} fillRow />
      <CheckBox caption="I agree to the Terms and Conditions" uncheckedLook="warningBold2" checkedLook="secondaryBold2" align="left" field="agreed_to_terms" onChange={(value, context) => handleAgreedToTerms(value, props.setAgree)} marginTop={-35} marginBottom={10} fillRow />
    </Panel>
  );
}

function handleAgreedToTerms(value, setAgree) {
  if (setAgree != null)
    setAgree(value);
}