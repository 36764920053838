import * as React from "react";

function RecordNavLeft({fill="#FFF", ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 12" {...props}>
      <path
        d="M6.904 11.246a.604.604 0 01-.375-.131L.735 6.454a.598.598 0 010-.931L6.505.887a.597.597 0 01.748.93l-5.19 4.17 5.216 4.197a.597.597 0 01-.375 1.062z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}

const SVGRecordNavLeft = React.memo(RecordNavLeft);
export { SVGRecordNavLeft };