import { makeStyles } from "lib/util";
let styles;
export function usePanelStyles() {
  if (styles == null)
    styles = makeStyles("pnl", {
      panel: { display: "flex", flexDirection: "column" },
      panelRow: { display: "flex", flexDirection: "row", margin: "0px", flexWrap: "wrap", alignItems: "flex-start" },
      rowFillHeight: { height: "100%", overflow: "hidden" },
      centerVertical: {alignItems: "center"},
      bottomVertical: { alignItems: "flex-end"},
      submitRow: {
        display: "flex",
        justifyContent: "flex-end",
        position:"absolute",
        bottom: "0px",
        width:"100%",
        height:"60px",
        paddingRight:"58px",
        marginBottom:"10px",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        borderTop: "solid 1px #CDCDCD",
        alignItems: "center"
      },
      undockSubmitRow: {
        display: "flex",
        justifyContent: "flex-end",
        width:"100%",
        height:"60px",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        alignItems: "center"
      }
    });
    return styles;
  }
