import React from "react";
import { getLogger, getCompanySettings, getUserSettings } from "lib/util";
import { isPermissionsAllowed } from "lib/util/Permissions";
import { Panel, Label } from "lib/components";
import { useHistory } from "react-router-dom";
import { AuthType } from "lib/util/AuthType";

const log = getLogger("lme.ar.CustomerPortal.OrderQuoteButtonPanel");

export function OrderQuoteButtonPanel(props) {
  const settings = getCompanySettings();
  const userType = getUserSettings().user_type;
  const history = useHistory();
	let items = [];
	let tlOrder = false, ltlOrder = false, tlQuote = false, ltlQuote = false;
	if (isPermissionsAllowed("InternetModule", "InternetModulePickupEntry")) {
		if (settings.is_asset_ltl)
			ltlOrder = true;
	}
	if (isPermissionsAllowed("InternetModule", "InternetModuleQuoteEntry")) {
		if (settings.is_asset_ltl || settings.is_ltl)
			ltlQuote = true;
	}
	if (isPermissionsAllowed("InternetModule", "InternetModuleOrderEntry")) {
		tlOrder = true;
    tlQuote = true;
	}

  const addOrder = "Add Order";
  const getQuote = "Get Quote";
  const ftlDryVanImage = "DryVanLeftFacing";
  const ltlPalletImage = "BoxPalletFacingRight";
  const ftlOrderPath = "portal/customer/ftl-order/FtlOrder";
  const ltlOrderPath = "portal/customer/ltl-order/LtlOrder";
  const assetLtlQuotePath = "portal/customer/asset-ltl-quote/AssetLtlQuote";
  const brokerageLtlQuotePath = "portal/customer/brokerage-ltl-quote/BrokerageLtlQuote";
  //const tlQuotePath = "portal/customer/TBD";

	if (tlOrder && ltlOrder) {
		items.push({ caption: addOrder, image: ftlDryVanImage,  page: ftlOrderPath });
		items.push({ caption: addOrder, image: ltlPalletImage, page: ltlOrderPath });
	}
	else if (tlOrder)
	 	items.push({ caption: addOrder, image: ftlDryVanImage, page: ftlOrderPath });
	else if (ltlOrder)
		items.push({ caption: addOrder, image: ltlPalletImage, page: ltlOrderPath });
	if (tlQuote && ltlQuote && userType !== AuthType.LOCATION) {
		//items.push({ caption: getQuote, image: ftlDryVanImage, page: tlQuotePath });
		if (settings.is_asset_ltl)
			items.push({ caption: getQuote, image: ltlPalletImage, page: assetLtlQuotePath });
		else
			items.push({ caption: getQuote, image: ltlPalletImage, page: brokerageLtlQuotePath });
	}
	// else if (tlQuote)
	// 	items.push({ caption: getQuote, image: ftlDryVanImage, page: tlQuotePath });
	else if (ltlQuote && userType !== AuthType.LOCATION){
		if (settings.is_asset_ltl)
			items.push({ caption: getQuote, image: ltlPalletImage, page: assetLtlQuotePath });
		else
			items.push({ caption: getQuote, image: ltlPalletImage, page: brokerageLtlQuotePath });
	}
	log.debug("AddOrderQuoteButtons settings %o items %o", settings, items);
	if (items.length === 0) {
		return null;
  }

  if (items.length > 1) {
    let buttonPanels = [];
    for (let i = 0; i < items.length; i++) {
      const image = { image: items[i].image, width:36, fill: "green", ...items[i].imgProps };
      buttonPanels.push(
        <Panel paddingLeft={10} paddingRight={10} borderWidth={0} borderLeftWidth={i !=0 ? 1 : 0} borderLeftColor="#CDCDCD" contentHAlign="center" /*verticalAlign="center"*/ onClick={() => history.push(items[i].page)}>
          <Label padding={0} image={image} rowBreak />
          <Label padding={0} paddingLeft={7} paddingRight={7} caption={items[i].caption} look="secondary2" />
        </Panel>
      );
    }
    
    return (
      <Panel marginRight={43} cursor="pointer" padding={0} rowBreakDefault={false} borderType="card">
        {buttonPanels}
      </Panel>
    );
  }
  else {
    const image = { image: items[0].image, width:40, fill: "green", ...items[0].imgProps };
    return (
      <Panel marginRight={43} paddingTop={2} paddingBottom={2} verticalAlign="center" cursor="pointer" borderType="card" onClick={() => history.push(items[0].page)}>
        <Label paddingTop={0} paddingBottom={0} image={image} marginLeft={20} marginRight={5} noRowBreak />
        <Label caption={items[0].caption} look="secondary5" marginRight={20} />
      </Panel>
    );
  }

}
