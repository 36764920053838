import * as React from "react";
import { getSVGStyle, useSVGStyles } from "lib/util/SVGUtil";

function Triangle(props) {
  let styleAndProps = getSVGStyle(props, {fill:"black", width:16});
  let classes = useSVGStyles(styleAndProps.style);
  return (
    <svg viewBox={"0 0 12 6"} className={classes.svgStyle} {...styleAndProps.props}>
        <path d={"M1 0L6 5L11 0z"} />
    </svg>
  );
}

const SVGTriangle = React.memo(Triangle);
export { SVGTriangle };
