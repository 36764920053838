import { Button, showProgressSnackbar } from "lib/components";
import { generateReport } from "lib/util";
import React from "react";

export function BOLButton({orderId, ...props}) {
  return (
    <Button
      caption="BOL"
      image={{ image: "Documents", height: 18 }}
      color="primary"
      onClick={() => downloadBOL(orderId)}
      tooltip="Download a bill of lading report for this order"
      {...props}
    />
  );
}

export function downloadBOL(orderId) {
  let filters = [];
  filters.push({ paramName: "orderId", paramValue: orderId });
  const customKey = new Date().getTime()+"_bol";
  showProgressSnackbar("BOL", "We are generating your BOL and it will download automatically.", null, false);
  generateReport("dispatch/bill-of-lading", `Order ${orderId} - BOL.pdf`, filters, customKey);
}
