import React from "react";
import { IndicatorActionButton } from "common";
import { SVGChatBubble } from "images/SVGChatBubble";

export function CommentsIndicatorAction({ movementId, commentsCount, index }) {
  return (
    <IndicatorActionButton
      index={index}
      movementId={movementId}
      disabled={!commentsCount}
      color="primary"
      icon={SVGChatBubble}
    />
  );
}
