import React from "react";
import { AuthPageContainer } from "pages/containers";
import { Form } from "antd";
import { SetupAccount } from "portal/general/login/SetupAccount";

export function SetupAccountPresentation(props) {
  const SetupAccountForm = Form.create({ name: "normal_setup_account" })(
    SetupAccount
  );
  return (
    <AuthPageContainer pageTitle={props.pageTitle}>
      <SetupAccountForm {...props} />
    </AuthPageContainer>
  );
}
