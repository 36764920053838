import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function PinOutlined(props) {
  return (
    <SVGIcon viewBox="0 0 15 18" {...props}>
      <path
        d="M7.498 3.216A3.853 3.853 0 004.76 4.349a3.846 3.846 0 00-1.134 2.739A3.85 3.85 0 004.76 9.825a3.846 3.846 0 002.738 1.133 3.85 3.85 0 002.738-1.133 3.846 3.846 0 001.134-2.739 3.85 3.85 0 00-1.134-2.738 3.853 3.853 0 00-2.738-1.133zm0 6.374a2.504 2.504 0 01-2.503-2.502 2.504 2.504 0 012.503-2.503A2.504 2.504 0 0110 7.088 2.504 2.504 0 017.498 9.59zm6.876-5.57a6.723 6.723 0 00-1.643-2.089A7.832 7.832 0 0010.346.527 8.11 8.11 0 007.5.013h-.004A8.11 8.11 0 004.65.527a7.832 7.832 0 00-2.385 1.404A6.716 6.716 0 00.62 4.019a5.723 5.723 0 00-.613 2.568c0 1.233.303 2.5.9 3.768.496 1.049 1.196 2.108 2.083 3.147 1.588 1.86 3.375 3.19 4.07 3.676a.758.758 0 00.874 0c.694-.485 2.483-1.816 4.07-3.676.885-1.04 1.587-2.098 2.082-3.147.598-1.267.901-2.537.901-3.768 0-.888-.207-1.75-.614-2.568zm-.757 2.569c0 1.03-.259 2.102-.77 3.185-.44.936-1.075 1.89-1.884 2.84-1.264 1.482-2.679 2.616-3.465 3.193-.786-.58-2.202-1.71-3.465-3.193-.81-.95-1.444-1.906-1.885-2.842-.512-1.083-.771-2.155-.771-3.185 0-.675.157-1.335.47-1.958A5.384 5.384 0 013.16 2.965a6.444 6.444 0 011.968-1.159 6.777 6.777 0 012.368-.427H7.5c.809 0 1.607.144 2.366.427a6.453 6.453 0 011.97 1.159 5.294 5.294 0 011.311 1.664c.313.63.47 1.287.47 1.96z"
        fillRule="nonzero"
      />
    </SVGIcon>
  );
}

const SVGPinOutlined = React.memo(PinOutlined);
export { SVGPinOutlined };
