import React, { useEffect, useState, useCallback } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { usePrevious, useDebounce } from "core/hooks";
import classNames from "core/utils/classNames";
import { useStyles } from "./styles";
import { AutoCompleteInput } from "./AutoCompleteInput";
import { AutoCompleteOption } from "./AutoCompleteOption";

export function AutoComplete({
  meta,
  label,
  placeholder,
  getOptionLabel,
  getOptionSelected,
  onFetch,
  setValue: setFormValue,
  className
}) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const debouncedValue = useDebounce(value, 300);
  const previousDebouncedValue = usePrevious(debouncedValue);
  const [isFetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = value !== "" && isFetching && options.length === 0;

  const handleInputChange = (_, inputValue, reason) => {
    if (["clear", "input"].includes(reason)) setValue(inputValue);
  };

  function handleOnChange(_, option, reason) {
    if (["clear", "select-option"].includes(reason)) setFormValue(option);
  }

  const onFetchCallback = useCallback((query = "", cb) => onFetch(query, cb), [onFetch]);

  useEffect(() => {
    if (debouncedValue === previousDebouncedValue) return;

    if (!debouncedValue) {
      setOptions([]);
      return;
    }

    setFetching(true);
    onFetchCallback(debouncedValue, (results = []) => {
      setOptions(results);
      setFetching(false);
    });
  }, [debouncedValue, onFetchCallback, previousDebouncedValue]);

  return (
    <>
      <Autocomplete
        autoComplete
        className={classes.root}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        noOptionsText="No Results"
        classes={{
          root: className,
          paper: classNames(classes.paddingReset, classes.paper),
          option: classNames(classes.optionsItem),
          loading: classNames(classes.statusItem),
          noOptions: classNames(classes.statusItem)
        }}
        renderInput={(renderParams) => (
          <AutoCompleteInput
            meta={meta}
            label={label}
            placeholder={placeholder}
            renderParams={renderParams}
            options={options}
          />
        )}
        renderOption={(option, renderProps) => (
          <AutoCompleteOption
            option={option}
            renderProps={renderProps}
            getOptionLabel={getOptionLabel}
          />
        )}
      />
    </>
  );
}
