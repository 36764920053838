import React from "react";
import { Panel, Label, TextBox, CheckBox } from "lib/components";
import { getCompanySettings } from "lib/util";

export function TermsConditions()
{
  return (
    <Panel fillRow rowBreakDefault={true}>
      <Label caption="Terms and Conditions" look="defaultBold4" fillRow />
      <TextBox field="terms_and_conditions" fullText={getCompanySettings().order_terms_conditions} readMore maxLength={375} marginBottom={0} paddingBottom={0} fillRow style={{whiteSpace:"pre-line"}}/>
      <CheckBox caption="I agree to the Terms and Conditions" uncheckedLook="warningBold2" checkedLook="secondaryBold2" align="left" field="agreed_to_terms" marginTop={0} paddingTop={0} marginBottom={10} fillRow />
    </Panel>
  );
}
