import { isEmptyString } from "lib/util/StringUtil";
import { parse, format, differenceInDays, differenceInMinutes, differenceInSeconds, differenceInMilliseconds, isValid } from "date-fns";
import { getUserSettings } from ".";
import { getCompanySettings } from "lib/util";

Date.prototype.toJSON = function () {
  if (this.hasTime === false)
    return formatDateTime(this, "yyyy-MM-dd");
  else
    return formatDateTime(this, "yyyy-MM-dd HH:mm:ss");
}

Date.prototype.toString = function () {
  return this.toJSON();
}

Object.defineProperty(Date.prototype, "justDate", {
  enumerable: false, value: function () {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate())
  }
});

Object.defineProperty(Date.prototype, "addDays", {
  enumerable: false, value: function (numDays) {
    this.setDate(this.getDate() + numDays);
    return this;
  }
});

Object.defineProperty(Date.prototype, "addMonths", {
  enumerable: false, value: function (numMonths) {
    this.setMonth(this.getMonth() + numMonths);
    return this;
  }
});

Object.defineProperty(Date.prototype, "addYears", {
  enumerable: false, value: function (numYears) {
    this.setFullYear(this.getFullYear() + numYears);
    return this;
  }
});

const dateKeyWords = ["T", "N", "ME", "WE", "QE", "YE", "M", "W", "Q", "Y"];

// can't use a Logger in this class because Logger uses Date!  Not sure if it's worth breaking the dependency.
const parseContextDate = new Date();

export function dateDiff(which, date1, date2) {
  if ("days" === which)
    return differenceInDays(date1, date2);
  else if ("minutes" === which)
    return differenceInMinutes(date1, date2);
  else if ("seconds" === which)
    return differenceInSeconds(date1, date2);
  else if ("millis" === which)
    return differenceInMilliseconds(date1, date2);
  else
    throw new Error("Unrecognized type of date diff " + which);
}

export function parseDateTime(value) {
  if (value == null)
    return null;
  const parsedDate = parse(value, "yyyy-MM-dd HH:mm:ss", parseContextDate);
  if (parsedDate.toString() === "Invalid Date" && isDateValid(value))
    return value;
  return parsedDate;
}

export function parseDate(value) {
  if (value == null)
    return null;
  return parse(value, "MM/dd/yyyy", parseContextDate);
}

export function getDateString(date) {
  if (date == null)
    return null;
  const year = date.getFullYear();
  if (isNaN(year))
    return null;
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;
  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;
  return month + "/" + day + "/" + year;
}

export function formatDate(value) {
  return formatDateTime(value, "MM/dd/yyyy");
}

export function formatTime(value) {
  return formatDateTime(value, "HH:mm");
}

export function formatDateTime(value, formatString) {
  if (value == null)
    return null;
  if (isNaN(value.getTime()))
    return null;
  if (formatString == null)
    formatString = "MM/dd/yyyy HH:mm";
  try {
    return format(value, formatString);
  } catch (reason) {
    return null;
  }
}

export function getDateFormat(which, overrideFormat) {
  if (which === "datetime") {
    if (overrideFormat === "long") return "iiii MMMM dd, yyyy hh:mma";
    else return getUserDateTimeFormat();
  } else if (which === "date") {
    if (overrideFormat === "long") return "iiii MMMM dd, yyyy";
    else return getUserDateFormat();
  } else if (which === "time") {
    if (overrideFormat === "long") return "hh:mma";
    else return getUserTimeFormat();
  } else throw new Error("Unrecognized parameter to getDateFormat " + which);
}

export function getUserDateFormat() {
  return getUserSettings().date_format;
}

export function getUserTimeFormat() {
  return getUserSettings().time_format;
}

export function getUserDateTimeFormat() {
  return getUserSettings().date_time_format;
}

export function parseDateWithKeywords(value, includeTime = true) {
  if (value == null)
    return value;
  value = value.trim().toUpperCase();
  const spacePos = value.indexOf(" ");
  let timePortion = null;
  if (spacePos > 0) {
    timePortion = parseTime(value.substring(spacePos + 1));
    value = value.substring(0, spacePos);
  }
  let result = parseKeywords(value);
  if (result == null)
    result = parseNumericDate(convertDateToNumeric(value));
  if (result == null)
    result = parseDateTime(result);
  if (result != null && value.substring(spacePos + 1) !== "N") {
    if (timePortion == null || !includeTime)
      result.setHours(0, 0, 0, 0);
    else
      result.setHours(timePortion.getHours(), timePortion.getMinutes());
  }
  return result;
}

function convertDateToNumeric(value) {
  let tokens = value.split(/[/-]/);
  for (let i = 0; i < tokens.length; i++)
    if (tokens[i].length === 1)
      tokens[i] = "0" + tokens[i]
  return tokens.join("");
}

function parseNumericDate(value) {
  if (!isNaN(new Number(value))) {
    let now = new Date();
    if (value.length === 2)
      return new Date(now.getYear() + 1900, now.getMonth(), parseInt(value));
    else if (value.length === 4)
      return new Date(now.getYear() + 1900, parseInt(value.substring(0, 2)) - 1, parseInt(value.substring(2, 4)));
    else if (value.length === 6) {
      let year = parseInt(value.substring(4, 6));
      year += getCenturyForTwoDigitYear(year);
      return new Date(year, parseInt(value.substring(0, 2)) - 1, parseInt(value.substring(2, 4)));
    }
    else if (value.length === 8)
      return new Date(parseInt(value.substring(4, 8)), parseInt(value.substring(0, 2)) - 1, parseInt(value.substring(2, 4)));
  }
  return null;
}

function getCenturyForTwoDigitYear(twoDigitYear) {
  const CENTURY_WINDOW = 25;
  let now = new Date();
  const year = now.getYear() + 1900;
  const currCent = Math.floor((now.getYear() + 1900) / 100) * 100;
  const currYear = year - currCent;
  if (currYear - CENTURY_WINDOW > twoDigitYear)
    return currCent + 100;
  else if (currYear + CENTURY_WINDOW < twoDigitYear)
    return currCent - 100;
  else
    return currCent;
}

export function parseTime(value, origDate) {
  if (value == null)
    return null;
  if (typeof value === "object" && value.caption) {
    value = value.caption;
  }
  else if (typeof value === "object") {
    value = JSON.stringify(value);;
    const spacePos = value.lastIndexOf(" ");
    if (spacePos >= 0)
      value = value.substring(spacePos + 1);
  }
  value = value.replace(":", "").toUpperCase();
  const numericPortion = parseInt(value);
  let hours, minutes;
  if (numericPortion < 25) {
    hours = numericPortion;
    minutes = 0;
  }
  else {
    minutes = numericPortion % 100;
    hours = Math.floor((numericPortion - minutes) / 100);
    if (value.indexOf("P") > 0)
      hours += 12;
  }
  let result = new Date();
  if (origDate) {
    result = new Date(origDate);
  }
  result.setHours(hours, minutes, 0, 0);
  return result;
}

function parseKeywords(value) {
  for (let i = 0; i < dateKeyWords.length; i++) {
    if (value.indexOf(dateKeyWords[i]) === 0) {
      const result = new Date();
      let aftKeyword = value.substring(dateKeyWords[i].length, value.length).trim();
      let valueToAdd = 0;
      if (!isEmptyString(aftKeyword))
        valueToAdd = parseInt(aftKeyword);
      if (i === 2) { // ME
        result.setMonth(result.getMonth() + valueToAdd);
        result.setDate(daysInMonth(result.getMonth(), result.getYear()));
      }
      else if (i === 3) // WE
        result.setDate(result.getDate() + (6 - result.getDay() + (7 * valueToAdd)));
      else if (i === 4) { //QE
        let quarterEndMonth = Math.floor(result.getMonth() / 3) * 3 + 2;
        quarterEndMonth += valueToAdd * 3;
        result.setMonth(quarterEndMonth);
        result.setDate(daysInMonth(quarterEndMonth, result.getFullYear()));
      } else if (i === 5) { // YE
        result.setMonth(11);
        result.setDate(31);
        result.setFullYear(result.getFullYear() + valueToAdd);
      } else if (i === 6) {// M
        result.setMonth(result.getMonth() + valueToAdd)
        result.setDate(1);
      }
      else if (i === 7) // W
        result.setDate(result.getDate() - result.getDay() + (7 * valueToAdd));
      else if (i === 8) { // Q
        let quarterStartMonth = Math.floor(result.getMonth() / 3) * 3;
        quarterStartMonth += valueToAdd * 3;
        result.setMonth(quarterStartMonth);
        result.setDate(1);
      } else if (i === 9) { // Y
        result.setDate(1);
        result.setMonth(0);
        result.setFullYear(result.getFullYear() + valueToAdd);
      }
      else if (valueToAdd !== 0)
        result.setDate(result.getDate() + valueToAdd);
      return result;
    }
  }
  return null;
}

export function daysInMonth(month, year) {
  return new Date(year, month + 1, 0).getDate();
}

export function isDateValid(date) {
  return isValid(date);
}

export function getDayOfWeek(date) {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return days[date.getDay()];
}

export function getMonthOfYear(date) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return months[date.getMonth()];
}

export function isHoliday(dateTime) {
  const holidays = getCompanySettings().upcoming_holidays;
  if (holidays == null) {
    return false;
  }
  for (let x = 0; x < holidays.length; x++) {
    const holiday = parseDateTime(holidays[x].date);
    if (holiday.getFullYear() === dateTime.getFullYear() &&
      holiday.getMonth() === dateTime.getMonth() &&
      holiday.getDate() === dateTime.getDate()) {
      return true;
    }
  }
  return false;
}
