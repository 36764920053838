import React from "react";
import { DialogActions, Button } from "@material-ui/core";
import { useFormikContext } from "formik";
import { SubmitButton } from "common";

export function FormActions({
  onClose,
  submitLabel = "Submit",
  closeLabel = "Cancel",
  longWidthSubmitButton = false
}) {
  const props = useFormikContext();
  const { isSubmitting } = props;
  return (
    <DialogActions>
      <Button variant="outlined" onClick={onClose}>
        {closeLabel}
      </Button>
      <SubmitButton
        loading={isSubmitting}
        variant="contained"
        size="medium"
        color="primary"
        type="submit"
        longWidthSubmitButton={longWidthSubmitButton}
      >
        {submitLabel}
      </SubmitButton>
    </DialogActions>
  );
}
