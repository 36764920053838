import React from "react"
import { SVGIcon } from "lib/util/SVGIcon";

function Combo(props) {
  return (
    <SVGIcon {...props} >
      <path d="M12 4c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0-2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 13l-4-4h8z" />
    </SVGIcon>
  )
}

const SVGCombo = React.memo(Combo);
export { SVGCombo };
