import React from "react";
import { Panel, useComponentContext } from "lib/components";
import { OrderConfirmationHeader } from "portal/customer/OrderConfirmationHeader";
import { AdditionalDetails } from "portal/customer/AdditionalDetails";
import { QuoteSummaryPanel } from "portal/customer/QuoteSummaryPanel";
import { FreightItemsTable } from "../FreightItemsTable";
import { HandlingReqsTable } from "../HandlingReqsTable";
import { AuthType } from "lib/util/AuthType";
import { getLogger } from "lib/util";
import { PaymentTerms } from "portal/customer/PaymentTerms";
import { CustomerType, PanelMode } from "../ControlConstants";
import { StopTable } from "portal/customer/StopTable";

const log = getLogger("portal.customer.asset-ltl-quote.Confirmation");

export function Confirmation(props) {
  log.info("Confirmation %o  %o", props, useComponentContext());
  const parentType = "asset-ltl-quote";
  const stopTableProps = {
    parentType: parentType,
    tableCaption: "Pickup and Delivery"
  }
  return (
    <Panel data={props.data} fillRow modelName="dispatch/ltl-order">
      <OrderConfirmationHeader />
      <Panel fillRow>
        <QuoteSummaryPanel data={props.data} type="Order" idField="order_id" stopsVisible={false} carrierVisible={false} disclaimerVisible={false} ratingDetailsVisible={false} transitInfoPanelProps={{marginTop: 25, padding: 0}} buttonLook="primary1" noRowBreak />
        <Panel fillRow>
          <StopTable {...stopTableProps} fillRow />
          <AdditionalDetails parentType={parentType} usePrintableVersion />
          {FreightItemsTable(CustomerType.ASSET, PanelMode.DISPLAY)}
          <PaymentTerms usePrintableVersion />
          {HandlingReqsTable(3)}
        </Panel>
      </Panel>
    </Panel>

    )
}

export function getPageOptions() {
  return {
    auth: AuthType.CUSTOMER,
    title: "Order Confirmation"
  };
}
