import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function Download(props) {
  return (
    <SVGIcon {...props}>
      <path d="M12 6.507H9V0H7v6.507H4l4 4 4-4zm-12 6v2h16v-2H0z" />
    </SVGIcon>
  );
}

const SVGDownload = React.memo(Download);
export { SVGDownload };
