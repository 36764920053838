export const DisplayTypes = {
  STRING: "string",
  INTEGER: "int",
  DECIMAL: "decimal",
  FLOAT: "float",
  DATE: "date",
  DATETIME: "datetime",
  TIME: "time",
  CURRENCY: "currency",
  WEIGHT: "weight",
  DISTANCE: "distance",
  LENGTH: "length",
  TEMPERATURE: "temperature",
  LOCATION: "location",
  EMAIL: "email",
  PHONE: "phone",
  CITY: "city",
  STATE: "state"
}

export function isDisplayTypeNumeric(type) {
  return (type === DisplayTypes.INTEGER
    || type === DisplayTypes.DECIMAL
    || type === DisplayTypes.FLOAT
    || type === DisplayTypes.CURRENCY
    || type === DisplayTypes.WEIGHT
    || type === DisplayTypes.DISTANCE
    || type === DisplayTypes.LENGTH
    || type === DisplayTypes.TEMPERATURE);
}
