import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 61,
    height: 48
  },
  switchBase: {
    padding: "12px 9px",
    "&$checked": {
      color: theme.palette.common.white,
      transform: "translateX(18px)",
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: theme.shadows[4]
  },
  track: {
    borderRadius: 26 / 2
  },
  // Styling above references this class. Do Not Remove.
  checked: {}
}));
