import { Table, TableColumn, TableDrawer, TableDrawerColumn } from "common";
import {
  ORDERS_TYPE_PENDING,
  ORDERS_CATEGORY_AVAILABLE,
  ORDERS_TYPE_ALL
} from "core/utils/constCapsule";
import React, { useEffect } from "react";
import { useMountEffect, usePrevious } from "core/hooks";
import {
  OrderNumberTableCell,
  ExchangeeDetailsTableCell,
  CommodityDetailsTableCell,
  AdditionalDetailsTableCell,
  ContactDetailsTableCell,
  CommentsDetailsTableCell
} from "common/TableCells";
import { fetchOrders } from "core/store/actions/OrdersActions";
import { getActiveOrdersAndTypeByCategory } from "core/store/selectors/OrdersSelectors";
import { connect } from "react-redux";
import { carrierFilterKeys } from "core/config/filterKeys";
import { IndicatorActions } from "common/TableCells/IndicatorActions";
import { EmailIndicatorAction } from "features/IndicatorActions";
import { PendingOfferAction } from "features/IndicatorActions/PendingOfferAction";
import { doesControlNotMatch } from "core/store/selectors/UserSelectors";
import { USER_CONTROLS, CONTROL_CHARS } from "core/config/controls";
import { AdditionalStopsDetailsTableCell } from "./AdditionalStopDetailsTableCell";
import { OrdersTypeSwitch } from "./OrdersTypeSwitch";

function AvailableOrdersTablePresentation({
  isFetching,
  orders,
  ordersType = ORDERS_TYPE_PENDING,
  dispatchFetchOrders,
  isAvailableOrdersEnabled = false
}) {
  const previousOrdersType = usePrevious(ordersType);

  useMountEffect(() => {
    dispatchFetchOrders(
      ORDERS_CATEGORY_AVAILABLE,
      isAvailableOrdersEnabled && ordersType === ORDERS_TYPE_ALL
        ? ORDERS_TYPE_ALL
        : ORDERS_TYPE_PENDING
    );
  }, [isAvailableOrdersEnabled]);

  useEffect(() => {
    if (!previousOrdersType || previousOrdersType === ordersType) return;
    dispatchFetchOrders(ORDERS_CATEGORY_AVAILABLE, ordersType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersType]);

  return (
    <Table
      loading={!orders && isFetching}
      key={ordersType}
      data={orders}
      defaultSortKey="pickup.milli"
      stickyHeader
      emptyText={
        ordersType === ORDERS_TYPE_PENDING
          ? "No Pending Loads Available for Display"
          : "No Loads Available for Display"
      }
      renderToolbarRight={() => <OrdersTypeSwitch />}
      filterKeys={carrierFilterKeys}
    >
      <TableColumn
        label="Load Number"
        sortKey="id"
        minWidth={70}
        Component={<OrderNumberTableCell />}
      />
      <TableColumn
        label="Pickup"
        sortKey="pickup.milli"
        minWidth={140}
        Component={<ExchangeeDetailsTableCell dataKey="pickup" label="Pickup" />}
      />
      <TableColumn
        label="Delivery"
        sortKey="delivery.milli"
        minWidth={140}
        Component={<ExchangeeDetailsTableCell dataKey="delivery" label="Delivery" />}
      />
      <TableColumn
        label="Commodity"
        sortKey="commodity.name"
        minWidth={300}
        Component={<CommodityDetailsTableCell label="Equipment" />}
      />
      <TableColumn
        label="Additional Details"
        minWidth={300}
        Component={<AdditionalDetailsTableCell ordersType={ordersType} />}
      />
      <TableColumn
        label="Actions"
        minWidth={80}
        Component={
          <IndicatorActions>
            <PendingOfferAction ordersType={ordersType} />
            <EmailIndicatorAction ordersCategory={ORDERS_CATEGORY_AVAILABLE} />
          </IndicatorActions>
        }
      />
      <TableDrawer>
        <TableDrawerColumn />
        <TableDrawerColumn colSpan={2} Component={<ContactDetailsTableCell />} />
        <TableDrawerColumn colSpan={1} Component={<CommentsDetailsTableCell />} />
        <TableDrawerColumn Component={<AdditionalStopsDetailsTableCell />} />
        <TableDrawerColumn colSpan={2} />
      </TableDrawer>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  isAvailableOrdersEnabled: doesControlNotMatch(
    state,
    USER_CONTROLS.LOAD_BOARD_ACTIVE,
    CONTROL_CHARS.D
  ),
  ...getActiveOrdersAndTypeByCategory(state, ORDERS_CATEGORY_AVAILABLE)
});

const mapDispatchToProps = {
  dispatchFetchOrders: fetchOrders
};

export const AvailableOrdersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableOrdersTablePresentation);
