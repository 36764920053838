import React from "react";
import { Panel, Label, Table, Column, DisplayTypes } from "lib/components";
import { getDataFromContext } from "lib/util/ModelUtil";

export function PanelLTLDetails(props) {
	return (
		<Panel modelName="dispatch/ltl-order-details?search={orders.id}" autoSearch fillRow>
			<Label caption="Freight Items" fontBold fontSize="large" />
			<Label caption="Pieces" width={80} align="right" fontBold noRowBreak />
			<Label caption="Weight" width={140} align="right" fontBold noRowBreak />
			<Label caption="Spots" width={140} align="right" fontBold />
			<Label field="pieces" displayType={DisplayTypes.INTEGER} width={80} align="right" fontBold noRowBreak />
			<Label field="{weight} {weight_uom}" width={140} align="right" displayType={DisplayTypes.DECIMAL} replaceModelData fontBold noRowBreak />
			<Label field="spots" width={140} align="right" fontBold fontSize="large" />
			<Table field="freight_items" headerVisible={false} emptyCaption="No freight items available at this time" fillRow >
				<Column caption="#" field="fgi_sequence_nbr" />
				<Column caption="Pieces" align="right" field="pieces" displayType={DisplayTypes.INTEGER} />
				<Column caption="Packaging" field="fgi_packaging_type_code" />
				<Column caption="Description" field="description" />
				<Column caption="Weight" field="{weight} {weight_uom_type_code}" displayType={DisplayTypes.DECIMAL} replaceModelData align="right" sortField="weight" />
				<Column caption="Spots" align="right" field="req_spots" />
				<Column caption="Class" field="nmfc_class_code" />
				<Column caption="SKU" field="product_sku" />
				<Column caption="HU" field="handling_units" />
				<Column caption="L" field="length" />
				<Column caption="W" field="width" />
				<Column caption="H" field="height" />
				<Column caption="Cube" field="cubic_units" align="right"/>
				<Column caption="Density" field="density" align="right"/>
			</Table>
			<Panel visible={ratingsVisible} fillRow>
				<Label caption="Ratings" fontBold fontSize="large" />
				<Label caption="Total charges" noRowBreak />
				<Label field="total_charge" displayType="currency" nullDisplayValue="N/A"/>
				<Table field="charges" headerVisible={false} emptyCaption="This order has no rating detail" fillRow >
					<Column caption="Charge type" field="description" />
					<Column caption="Amount" field="fgi_revenue_amount" align="right" displayType="currency"/>
				</Table>
			</Panel>
		</Panel>
	);
}

function ratingsVisible(componentContext) {
	let data = getDataFromContext(componentContext);
	return (data && data["charges"] != null);
}
