import React from "react";
import { Typography } from "common";
import { formatUSDCurrency } from "core/utils/formatters";

export function BalanceDetailsTableCell({ balance = {} }) {
  return (
    <Typography
      component="div"
      fontSize="large"
      fontWeight="bold"
      color="primary"
      value={balance ? formatUSDCurrency(balance.amount, { prefix: true }) : "N/A"}
    />
  );
}
