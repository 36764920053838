import { getLogger } from "lib/util/Logger";
import { parseDateTime } from "lib/util/Date";

const log = getLogger("lib.components.Table.TableSort");

export function sortData(data, sortedColumns) {
  if (sortedColumns == null || sortedColumns.length === 0 || data === null || data === undefined) {
    return data;
  }
  log.debug("Sort %o %o", data, sortedColumns);
  let sorted = [...data];
  sorted.sort((item1, item2) => sortCompare(item1, item2, sortedColumns));
  return sorted;
}

function sortCompare(item1, item2, sortedColumns) {
  for (let i = 0; i < sortedColumns.length; i++) {
    let col = sortedColumns[i];
    let value1 = item1[col.field] != null ? item1[col.field] : item1.modelData[col.field];
    let value2 = item2[col.field] != null ? item2[col.field] : item2.modelData[col.field];
    let compValue = compareValues(value1, value2, col.dataType);
    if (compValue !== 0) {
      if (col.ascending === false)
        compValue *= -1;
      return compValue;
    }
  }
  return 0;
}

function compareValues(value1, value2, dataType) {
  if (value1 == null && value2 == null)
    return 0;
  else if (value1 == null)
    return -1;
  else if (value2 == null)
    return 1;
  if (typeof value1 === "string") {
    if (dataType === "datetime") {
      value1 = parseDateTime(value1);
      value2 = parseDateTime(value2);
    }
    else
      return value1.localeCompare(value2);
  }
  if (value1 < value2)
    return -1;
  else if (value1 > value2)
    return 1;
  else
    return 0;
}
