import { makeStyles } from "lib/util";

let styles;

export function useCheckBoxStyles() {
  if (styles == null)
    styles = makeStyles("cbx", {
      formControlLabel: {
        marginLeft: "unset",
        marginRight: "unset",
        fontSize: "14px",
        "&.Mui-disabled": {
          color: "#666666"
        }
      },
      checkComponent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      checkComponentLeft: {
        display: "flex",
        justifyContent: "start",
        alignItems: "start"
      },
      root: {
        padding: "6px",
        marginLeft: "3px",
        marginRight: "3px"
      },
    });
  return styles;
}

