import React from "react";
import { Panel, Label, Button, DisplayTypes } from "lib/components";
import { TableFilterBox } from "./TableFilterBox";
import { downloadText, downloadBinary, downloadBlob } from "lib/util/DownloadUtil";
import { exportToCSV, exportToXLS, exportToPDF } from "./ExportTable";
import { showSnackbar } from "../Snackbar";
import { numberWithCommas } from "../../../lib/util/JSUtil";
import { useComponentContext } from "../Context";
import { TextBox } from "../TextBox/TextBox";
import { getCompanySettings } from "lib/util";

export function TableHeader(props) {
  const context = useComponentContext();
	if (props.headerVisible === false)
		return null;
	let countCaption;
	if (props.rows) {
		if (props.rows.length === 0)
			countCaption = "No results";
		else if (props.rows.length === 1)
			countCaption = "Displaying 1 result";
		else
			countCaption = "Displaying " + props.rows.length + " results";
	}
	let extraComponent;
	if (props.headerComponent)
    extraComponent = props.headerComponent;
  let filter;
  if (props.filterVisible !== false)
    filter = (
      <Panel verticalAlign="center" fillRow noRowBreak>
        <TableFilterBox width={200} onChange={props.setFilter} placeHolder={props.filterPlaceholder} fillRow noRowBreak />
        <Label caption={countCaption} marginLeft={12} look="subtleLight3" visible={props.loading !== true} noRowBreak />
      </Panel>
    );
  const dropDown = getDropDown(props, context);
  let ellipseButton;
  if (dropDown.length > 0)
      ellipseButton = <Button image="Ellipsis" enabled={props.rows.length > 0} dropDown={dropDown} dropDownAlign="right" height={32} margin={0} />
  let caption;
  if (props.caption != null)
    caption = <Label caption={props.caption} look="defaultBold4" noRowBreak />
  let addButton;
  if (props.rowEditor != null && props.addVisible !== false) {
    let addEnabled = true;
    let tooltip;
    if (props.allowMultipleAdds !== true)
    {
      for (let i = 0; addEnabled && i < props.rowProps.length; i++)
        if (props.rowProps[i].editing) {
          addEnabled = false;
          if (props.rowProps[i].adding)
            tooltip = "You can't add a record right now because you're already adding a record.";
          else
            tooltip = "You can't add a record right now because you're already editing a record.";
        }
    }
    addButton = <Button
      image={{image:"AddWithCircle", fill:"#137CDD"}}
      caption={props.addCaption == null ? "Add" : props.addCaption}
      tooltip={tooltip}
      color="primary"
      variant="text"
      enabled={addEnabled}
      onClick={props.addRow}
      noRowBreak
    />
  }
  let pagination;
  if (props.paginate === true && props.rows.length > props.rowsPerPage && getCompanySettings().paginate_rows > 0) {
    const pageItems = getPageItems(props.rows, props.rowsPerPage);
    pagination = (
      <Panel verticalAlign="center" noRowBreak>
        <TextBox items={["5", "10", "25", "50", "100"]}
          caption="Results per page" captionAlignment="left" width={176}
          value={props.rowsPerPage} onChange={props.handleChangeRowsPerPage}
          paddingRight={12} messageVisible={false} usePrintableVersion={false} noRowBreak />
        <TextBox items={pageItems}
          caption="Show page" captionAlignment="left" width={128}
          value={pageItems[props.page]} onChange={props.handleChangePage}
          usePrintableVersion={false} messageVisible={false} noRowBreak />
        <Label caption={"of " + pageItems.length} style={{fontSize: 11.5}} paddingTop={7} color="#666666" marginRight={8} noRowBreak />
        <Button image={{image:"Chevron", direction: "left", height: 14, width: 14 }} enabled={props.page > 0} tooltip="Previous page" onClick={() => props.setPage(props.page - 1) } noRowBreak />
        <Button image={{image:"Chevron", direction: "right", height: 14, width: 14 }} enabled={props.page < pageItems.length - 1} tooltip="Next page" onClick={() => props.setPage(props.page + 1)} />
      </Panel>
    );
  }
	return (
		<Panel verticalAlign="center">
      {caption}
      {filter}
			{TotalsPanel(props)}
			{extraComponent}
      {pagination}
      {addButton}
			{ellipseButton}
		</Panel>
	)
}

function getPageItems(rows, rowsPerPage) {
  const pageCount = (rows.length / rowsPerPage);
  let result = [];
  for (let i = 0; i < pageCount; i++)
    result.push((i + 1).toString());
  return result;
}

function TotalsPanel(props) {
  const labels = [];
  if (props.columnTotalDisplaySettings != null)
  {
    for (let x in props.columnTotalDisplaySettings) {
      const settingsForColumn = props.columnTotalDisplaySettings[x];
      const caption = settingsForColumn.caption;
      let columnTotal = props.columnTotals[settingsForColumn.field];
      if (isNaN(columnTotal))
      { columnTotal = 0; }
      const value = numberWithCommas(columnTotal);
      const suffix = settingsForColumn.suffix;
      if (caption != null)
      { labels.push(<Label key={"prefix" + x} caption={caption} look="default3" paddingLeft={0} paddingRight={0} marginLeft={40} marginRight={7}/>); }
      labels.push(<Label key={"value" + x} caption={value} displayType={DisplayTypes.DECIMAL} look="primary3" paddingLeft={0} paddingRight={0}/>);
      if (suffix != null)
      { labels.push(<Label key={"suffix" + x} caption={suffix} look="default3" paddingLeft={0} paddingRight={0}/>); }
    }
  }
  if (labels.length > 0)
  { labels.unshift(<Label key="total" caption="Totals" look="defaultBold3" marginLeft={50} />)}
  return (
    <Panel rowBreakDefault={false} fillRow noRowBreak >
      {labels}
    </Panel>
  );
}

function getDropDown(props, context) {
	let result = [];
	if (props.allowExport !== false) {// also check permissions
		result.push(<Label caption="Export to XLS" onClick={() => downloadXLS(props, context)} />);
		result.push(<Label caption="Export to CSV" onClick={() => downloadCSV(props, context)} />);
		result.push(<Label caption="Export to PDF" onClick={() => downloadPDF(props, context)} />);
	}
	return result;
}

function getFileName(fileName, extension) {
	if (fileName == null)
		fileName = "Export";
	if (!fileName.toLowerCase().endsWith("." + extension))
		fileName += "." + extension;
	return fileName;
}

function downloadCSV(props, context) {
	exportToCSV(props, context).then(csv => {
		downloadText(csv, getFileName(props.exportName, "csv"));
		showSnackbar("Listing has been exported to CSV.  Check your download area.");
	});
}

function downloadXLS(props, context) {
	const xls = exportToXLS(props, context);
	downloadBinary(xls, getFileName(props.exportName, "xlsx"));
	showSnackbar("Listing has been exported to XLS.  Check your download area.");
}

function downloadPDF(props, context) {
	exportToPDF(props, context).then(pdf => {
		pdf.getBlob(blob => {
			downloadBlob(blob, getFileName(props.exportName, "pdf"));
			showSnackbar("Listing has been exported to PDF.  Check your download area.");
		});
	});
}
