import { makeStyles } from "lib/util";
let styles;

export function useComponentStyles() {
  if (styles == null)
    styles = makeStyles("comp", {
      component: { padding: "4px" },
      hasDivider: { display: "flex" },
      overflowAuto: { overflow: "auto" },
      overflowHide: { overflow: "hidden" },
      fontBold: { fontWeight: "500" },
      font_primary: { color: "rgb(19, 124, 221)" },
      font_disabled: { color: "rgb(102, 102, 102)" },
      font_xsmall: { fontSize: "10px" },
      font_small: { fontSize: "12px" },
      font_medium: { fontSize: "14px" },
      font_large: { fontSize: "16px" },
      font_xlarge: { fontSize: "20px" },
      fillHeight: { height: "100%", overflow: "hidden" },
      progress: {
        position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px",
        display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(0, 0, 0, 0.2)", zIndex: 100
      },
      divider: {
        height: "50px",
        margin: "14px",
        marginRight: "12px",
        width: "1px",
        backgroundColor: "#CDCDCD"
      },
      tooltip: {
        display: "flex",
        alignItems: "middle"
      }
    });
  return styles;
}
