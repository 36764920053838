import React from "react";
import { Panel } from ".";
import { Alignment } from "./ComponentUtil";
import { usePanelStyles } from "./PanelStyles";
import { useComponentContext } from "./Context";

export function Row({alignItems, verticalAlign, ...props}) {
  let classes = usePanelStyles();
  let context = useComponentContext();
  if (!isVisible(props, context))
  { return null; }

  let className = classes.panelRow;
  if (verticalAlign === "center")
    className += " " + classes.centerVertical;
  else if (verticalAlign === "bottom")
    className += " " + classes.bottomVertical;

  let alignComp;
  if (alignItems === Alignment.RIGHT)
    alignComp = <Panel fillRow />
  return (
    <div className={className} {...props} >
      {alignComp}
      {props.children}
    </div>
  );
}

function isVisible(props, context) {
  if (props.visible === false) {
    return false;
  }
  else if (typeof props.visible === "function") {
    return props.visible(context);
  }
  return true;
}
