import React from 'react';
import {Label} from "../lib/components";

export function EmailLabel(props) {
  const {text, caption, email, subject, body, look, linkStyle, style} = props;
  if (!text) return null;

  let result = [];
  if (!email) {
    result.push(text);
  } else {
    let link = `mailto:${email}`;
    if (subject || body) {
      link += '?';
      if (subject) link += `subject=${subject.replace(/\s/g, '%20')}`;
      if (subject && body) link += '&';
      if (body) link += `subject=${body.replace(/\s/g, '%20')}`;
    }

    const txtArray = text.split('{e}');
    for (let i = 0; i < txtArray.length; ++i) {
      result.push(txtArray[i])
      if (i < txtArray.length-1)
        result.push(<Label caption={caption?caption:email} linkURL={link} style={{...linkStyle, display:"inline"}} />);
    }
  }
  return <Label caption={result} style={{...style, display:"inline"}} look={look} />;
}
