import { getCompanySettings } from "lib/util";
import { showWarningSnackbar } from "lib/components/Snackbar";
import { callApi } from "lib/util/api";
import { showError } from "lib/components/Error";

export function showPickupMinutesSnackbar(dateTime, cityId, setPickupMinutesValid, showSnackBar) {
  const prepMinutes = getCompanySettings().order_prep_time_minutes;
  if (showSnackBar === true) {
    showWarningSnackbar("We need at least " + prepMinutes + " minutes lead time for a pickup. Please adjust your pickup time.", false);
    return false;
  }
  if (dateTime == null || (typeof dateTime === "object" && isNaN(dateTime.getTime()))) {
    return false;
  }
  const earliestPossiblePickup = new Date();
  earliestPossiblePickup.setMinutes(earliestPossiblePickup.getMinutes() + prepMinutes);

  if (cityId != null) {
    callApi("api/portal/customer/early-pickup-validator", "POST", { early_time: dateTime, city_id: cityId }).then((isValidResponse) => {
      const isValid = isValidResponse.data[0].is_valid;
      if (isValid === false) {
        let message = "We need at least " + prepMinutes + " minutes lead time for a pickup. Please adjust your pickup time.";
        if (prepMinutes === 0)
          message = "Shipper's early pickup time must be later than shipper's present time. Please adjust your pickup time."
        showWarningSnackbar(message, false);
        setPickupMinutesValid(false);
        return false;
      }
      else {
        setPickupMinutesValid(true);
        return true;
      }
    }).catch(reason => {
      showError("An error occurred while validating the earliest possible pickup", reason);
    });
  }
  else {
    return true;
  }
}

export function showPickupWindowSnackbar(earlyDateTime, lateDateTime) {
  if (earlyDateTime == null || lateDateTime == null) {
    return true;
  }

  const minWindowMinutes = getCompanySettings().order_pickup_window_minutes;
  lateDateTime.setMinutes(lateDateTime.getMinutes() - minWindowMinutes);

  if (lateDateTime < earlyDateTime) {
    showWarningSnackbar("We need at least a " + minWindowMinutes + " minute pickup window.  Please adjust your Early Pickup and/or Late Pickup to allow for at least a " + minWindowMinutes + " minute window.", false);
    return false;
  }
  return true;
}
