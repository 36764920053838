import React, { useState } from "react";
import { Panel, TextBox, Label, Spinner, useComponentContext } from "lib/components";
import { PanelCustomerOrderDetail } from "./PanelCustomerOrderDetail";
import { getDataFromContext } from "lib/util/ModelUtil";

export function PanelCustomerOrderFullDetail({data, unauthenticated=false, searchQueryString="", ...props}) {
	let modelName;
	if (unauthenticated)
		modelName =`ar/customer-orders-unauthenticated${searchQueryString}`;
	let [loading, setLoading] = useState(false);
	return (
		<Panel modelName={modelName} data={data} setLoading={setLoading} autoSearch={unauthenticated} {...props} >
			<Detail unauthenticated={unauthenticated} loading={loading}/>
		</Panel>
	);
}

function Detail({unauthenticated, loading}) {
  let context = useComponentContext();
	if (!context || context.data == null || context.data.list == null || context.data.list.length !== 1) {
		if (loading)
			return <Spinner />
		return (
			<Panel >
				<Label caption="No orders found." fontBold fontSize="xlarge"/>
			</Panel>
		);
	}
	let data = getDataFromContext(context);
	if (data != null && data.error) {
		return (
			<Panel >
				<Label caption={data.error} fontBold fontSize="xlarge"/>
			</Panel>
		);
	}
	let detailfield;
	if (unauthenticated)
		detailfield = "detail";
	return (
		<Panel fillRow>
			<Panel usePrintableVersion fillRow >
				<Label caption="Order" fontBold fontSize="xlarge" visible={unauthenticated} noRowBreak/>
				<Label field="orders.id" fontBold fontSize="xlarge" visible={unauthenticated} />
				<TextBox caption="BOL number" field="orders.blnum" fillRow noRowBreak nullDisplayValue="N/A"/>
				<TextBox caption="Status" field="status_descr" fillRow noRowBreak />
				<TextBox caption="Ship date" field="shipper.sched_arrive_early" displayType="datetime" format="long" fillRow noRowBreak />
				<TextBox caption="Shipper city" field="shipper.city_name" fillRow noRowBreak />
				<TextBox caption="Shipper state" field="shipper.state" fillRow noRowBreak />
				<TextBox caption="Consignee city" field="consignee.city_name" fillRow noRowBreak />
				<TextBox caption="Consignee state" field="consignee.state" fillRow noRowBreak/>
				<TextBox caption="Consignee ref number" field="orders.consignee_refno" nullDisplayValue="N/A" />
			</Panel>
			<PanelCustomerOrderDetail field={detailfield} unauthenticated={unauthenticated} fillRow />
		</Panel>
	);
}
