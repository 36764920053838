import * as React from "react";

function MapPinWhiteDotSmall({fill, ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 41" {...props}>
      <path
        d="M12.826 40.719l-.019.141.018-.137v-.004zM12.5 8a5 5 0 100 10 5 5 0 000-10zm.29 33l-.001-.277-.009-.068c-.614-4.286-3.136-9.775-7.566-16.468C4.177 22.61 2.879 20.92 1.85 19.05A12.446 12.446 0 010 12.5C0 5.596 5.596 0 12.5 0c5.944 0 10.919 4.148 12.187 9.708.386 1.577.428 2.886.428 3.395 0 4.15-2.24 7.39-4.191 10.3l-.399.597-.26.393c-4.346 6.601-6.823 12.023-7.43 16.266l-.01.064.001.277-.019-.135-.018.135zm.017-.135v-.005l-.001.003.001.002z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

const SVGMapPinWhiteDotSmall = React.memo(MapPinWhiteDotSmall);
export { SVGMapPinWhiteDotSmall };