import React, { useState, useEffect } from "react";
import * as MUI from "@material-ui/core";
import { useField } from "formik";

export function Select({
  name,
  options = [],
  children,
  variant = "outlined",
  placeholder,
  label,
  classes = {},
  ...restProps
}) {
  const [field] = useField(name);
  // const _options = placeholder ? [{ label: placeholder, value: placeholder }, ...options] : options;
  const defaultValue = options.length > 0 ? options[0].value : "";
  const [val, setVal] = useState(defaultValue);

  useEffect(() => {
    if (field.value != null) {
      options.findIndex(option => {
        if (option.value == field.value)
          setVal(option.value);
      });
    }
    else
      setVal("");
  }, [field.value])

  return (
    <MUI.FormControl className={classes.formControl}>
      {label && <MUI.InputLabel shrink={false}>{label}</MUI.InputLabel>}
      <MUI.Select size="small" variant={variant} value={val} {...restProps}>
        {options.map(({ value, label }) => {
          return (<MUI.MenuItem key={value} value={value} disabled={placeholder && value === placeholder}>
            {label}
          </MUI.MenuItem>)}
          )}
        {children}
      </MUI.Select>
    </MUI.FormControl>
  );
}
