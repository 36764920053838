import React from "react";
import { showProgressSnackbar, Button, useComponentContext } from "lib/components";
import { isPermissionsAllowed, generateReport, getDataFromContext, getLogger } from "lib/util";
import { getDateString } from "../../lib/util/Date";

const log = getLogger("portal.carrier.DownloadSettlementSummaryButton");

export function DownloadSettlementSummaryButton({checkNumber, checkDate, caption}) {
	let convertedDate;
  	const context = useComponentContext();
	const data = getDataFromContext(context);
	if (!isPermissionsAllowed("InternetModule", "InternetModuleCarrierSettlementHistory"))
		return null;
	if (data != null && checkNumber == null)
		checkNumber = data["check_number"];
	if (data != null && checkDate == null){
		checkDate = data["pay_date"];
	}
  if(checkDate != null){
    const tempDate = new Date(checkDate.replace(/-/g, '\/'));
    convertedDate = getDateString(tempDate);
  }
	if (caption == null)
		caption = checkDate;
	function downloadSummary() {
		let filters = [{ paramName: "check_number", paramValue:checkNumber }, { paramName: "check_date", paramValue:convertedDate }];
    log.info("DownloadSettlementSummaryButton checkDate %o, filter %o", checkDate, filters);
		const customKey = new Date().getTime()+"_settlementsummary";
		showProgressSnackbar("Settlement Summary","We are generating your settlement summary and it will download automatically.", undefined, undefined, customKey);
		generateReport("drs/settlement-summary", `Settlement Summary ${convertedDate}.pdf`, filters, customKey);
	}
	if (checkDate == null)
		return null;
	else
		return <Button caption={caption} marginLeft={-8} marginTop={0} image="DocumentSingle" color="primary"
			variant="text" fontSize="small" onClick={downloadSummary} />
}
