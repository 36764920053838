// Content Type Consts
export const CONTENT_TYPE_SETTLEMENT_SUMMARY = "SETTLMENT_SUMMARY";
// Axios Consts
export const FETCH_RESPONSE_TYPE_ARRAY_BUFFER = "arraybuffer";
export const FETCH_RESPONSE_TYPE_DOCUMENT = "document";
export const FETCH_RESPONSE_TYPE_JSON = "json";
export const FETCH_RESPONSE_TYPE_TEXT = "text";
export const FETCH_RESPONSE_TYPE_STREAM = "stream";
export const FETCH_RESPONSE_TYPE_BLOB = "blob";
// HTTP Consts
export const HTTP_REQUEST_GET = "get";
export const HTTP_REQUEST_PATCH = "patch";
export const HTTP_REQUEST_POST = "post";
export const HTTP_RESPONSE_STATUS_BAD_REQUEST = 400;
export const HTTP_RESPONSE_STATUS_FORBIDDEN = 403;
export const HTTP_RESPONSE_STATUS_NO_CONTENT = 204;
export const HTTP_RESPONSE_STATUS_OK = 200;
export const HTTP_RESPONSE_HEADER_KEY_CONTENT_TYPE = "content-type";
export const HTTP_RESPONSE_CONTENT_TYPE_STREAM = "application/octet-stream";
export const HTTP_RESPONSE_CONTENT_TYPE_JSON = "application/json";
export const HTTP_RESPONSE_CONTENT_TYPE_BLOB = "application/pdf";
export const HTTP_RESPONSE_DATA_TYPE_JSON = "JSON";
export const HTTP_RESPONSE_DATA_TYPE_ARRAY = "ARRAY";
export const HTTP_RESPONSE_DATA_TYPE_BLOB = "blob";
// Auth Consts
export const REFRESH_AUTH_TOKEN_HEADER = "refresh-token";
// Session Storage Keys
export const SESSION_STORAGE_LOGIN_TOKEN = "login-token";
export const SESSION_STORAGE_USER_TYPE = "user-type";
export const SESSION_STORAGE_TEMP_TOKEN = "temp-token";
export const SESSION_STORAGE_USER_NAME = "user-name";
export const SESSION_STORAGE_USER_DISPLAY_NAME = "user-display-name";
export const SESSION_STORAGE_USER_COMPANY = "user-company";
// User types
export const USER_TYPE_ALL = "ALL";
export const USER_TYPE_CUSTOMER = "CUSTOMER";
export const USER_TYPE_CARRIER = "CARRIER";
export const USER_TYPE_LOCATION = "LOCATION";
export const USER_TYPE_FACTORING_COMPANY = "FACTORING_COMPANY";
export const USER_TYPE_DRIVER = "DRIVER";
// Media Type Consts see more: "https://www.iana.org/assignments/media-types/media-types.xhtml#application"
export const MEDIA_TYPE_TEXT = "text/plain;charset=utf-8";
export const MEDIA_TYPE_OCTET_STREAM = "application/octet-stream;base64";
export const MEDIA_TYPE_PDF = "application/pdf;base64";
export const MEDIA_TYPE_CSV = "text/csv;charset=utf-8";
// Orders Consts
export const ORDERS_CATEGORY_AVAILABLE = "available";
export const ORDERS_CATEGORY_ACCEPTED = "accepted";
export const ORDERS_CATEGORY_DELIVERED = "delivered";
export const ORDERS_CATEGORY_OFFER_HISTORY = "offerHistory";
export const ORDERS_CATEGORY_PAYMENTS_DUE = "paymentsDue";
export const ORDERS_CATEGORY_PAYMENTS_RECEIVED = "paymentsReceived";
export const ORDERS_TYPE_PAID = "paid";
export const ORDERS_TYPE_UNPAID = "unpaid";
export const ORDERS_TYPE_PENDING = "pending";
export const ORDERS_TYPE_ACCEPTED = "accepted";
export const ORDERS_TYPE_ALL = "all";
export const ORDERS_TYPE_OFFERED = "offered";
export const ORDERS_TYPE_PAYMENTS_DUE = "paymentsDue";
export const ORDERS_TYPE_PAYMENTS_RECEIVED = "paymentsReceived";
// Offer Actions
export const OFFER_ACCEPT = "accept";
export const OFFER_COUNTER = "counter";
export const OFFER_BROKER_COUNTER = "brokerCounter";
export const OFFER_DECLINE = "decline";
// Dialog Contexts
export const DIALOG_CONTEXT_IMAGES = "IMAGES_DIALOG";
export const DIALOG_CONTEXT_CALLINS = "CALLINS_DIALOG";
export const DIALOG_CONTEXT_DISPATCH = "DISPATCH_DIALOG";
export const DIALOG_CONTEXT_TERMS_CONDITIONS = "TERMS_CONDITIONS_DIALOG";
