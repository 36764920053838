import * as React from "react";

function MapPinWhiteDotLarge({fill="#137CDD", circleFill="#FFF", ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 41" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.79 41l-.001-.277-.009-.068c-.614-4.286-3.136-9.775-7.566-16.468-1.052-1.6-2.358-3.287-3.378-5.163A12.44 12.44 0 010 12.5C0 5.596 5.596 0 12.5 0c5.944 0 10.919 4.148 12.187 9.708.386 1.577.428 2.886.428 3.395 0 4.15-2.24 7.39-4.191 10.3l-.399.597-.26.393c-4.346 6.601-6.823 12.023-7.43 16.266l-.01.064.001-.004V41l-.019-.14-.018.14z"
          fill={fill}
        />
        <circle fill={circleFill} cx={12.5} cy={12.5} r={9.5} />
      </g>
    </svg>
  );
}

const SVGMapPinWhiteDotLarge = React.memo(MapPinWhiteDotLarge);
export { SVGMapPinWhiteDotLarge };