let baseUrl;

function calculateBaseUrl() {
  if (baseUrl == null) {
    let result = window.location.origin;
    const localHostPos = result.indexOf("localhost");
    if (localHostPos >= 0) {
      const slashPos = result.lastIndexOf("/", localHostPos);
      result = result.substring(slashPos + 1, localHostPos + 9)
      result = "http://" + result + ":8080/";
    }
    else
      result += "/api-main-portal/";
    baseUrl = result;
  }
  return baseUrl;
}

export const BASE_API_URL = calculateBaseUrl();

export const PATHS = {
  CALLIN: "/callin",
  CARRIER_PORTAL: "/carrier-portal",
  ERROR: "/error",
  LOGIN: "/login",
  OFFER: "/offer",
  RESET_PASSWORD: "/reset",
  ROOT: "/",
  SETUP_ACCOUNT: "/setup"
};

export const API_PATHS = {
  ACCEPT_OFFER: "carrier/offer/accept",
  BRANDING_LOGO: "branding/logo",
  BRANDING: "branding/all",
  CARRIER_AVAILABLE_ORDERS: "api/br/carrier_available_orders",
  CARRIER_CALLIN: "carrier/callin",
  CARRIER_CALLIN_HISTORY: "carrier/callin/history",
  CARRIER_CALLIN_NEXT_SCHEDULED_DATE: "carrier/callin/nextSched",
  CARRIER_DELIVERED_ORDERS: "api/br/carrier_delivered_orders",
  CARRIER_PAID_ORDERS: "api/br/carrier_paid_orders",
  CARRIER_UNPAID_ORDERS: "api/br/carrier_unpaid_orders",
  CARRIER_DISPATCH: "carrier/dispatch",
  CARRIER_DISPATCH_INFO: "carrier/dispatch/formdata",
  COMMODITY_FREIGHT_CLASS: "commodity/freightclass",
  COMMODITY_PACKAGING_TYPE: "commodity/packagingtype",
  COMMODITY_PRODUCT_BOOK: "dispatch/commodity_product_book",
  COMMODITY_LIST: "commodity/list",
  IMAGE_UPLOAD: "image/upload/:userType",
  IMAGES: "image/:userType/all",
  CARRIER_IMAGES: "image/carrier/all",
  CARRIER_OFFER_HISTORY_ORDERS: "carrier/offer/history",
  IS_ERATE: "carrier/offer/isErateConfirmation",
  COMPANY_META_DATA: "company/metadata",
  COUNTER_OFFER: "carrier/offer/counter",
  DECLINE_OFFER: "carrier/offer/decline",
  FACTORING_COMPANY_PAYMENT_DETAILS: "factoring/payment/details",
  FACTORING_COMPANY_PAYMENTS_DUE_ORDERS: "api/factoring/payments_due",
  FACTORING_COMPANY_PAYMENTS_DUE_ORDERS_COMMENTS:
    "api/factoring/payments_due_comments",
  FACTORING_COMPANY_PAYMENTS_RECEIVED_ORDERS:
    "api/factoring/payments_received",
  FACTORING_COMPANY_PAYMENTS_RECEIVED_ORDERS_COMMENTS:
    "api/factoring/payments_received_comments",
  HANDLING_REQUIREMENTS: "dispatch/handling-requirement",
  IMAGE_DOWNLOAD: "image/download",
  LEGAL_TERMS: "carrier/offer/legalterms",
  ORDER_TERMS_GENERATE: "carrier/offer/order/confirmation/generate",
  ORDER_TERMS_RETRIEVE: "carrier/offer/order/confirmation",
  QUERY_LOCATION: "city/list",
  SETTLEMENT_HISTORY: "getSettlementHistory",
  SETTLEMENT_SUMMARY: "generateSettlementSummary",
  USER_ADMIN_EMAIL: "users/admin/email",
  USER_CONTROLS: "users/controls",
  USER_LICENSES: "users/licenses",
  USER_PERMISSIONS: "permissions/portal/all",
};

// These paths to root are temporary until we figure out stuff in WebLoginPresentation
export const DEFAULT_USER_TYPE_PATH = {
  CUSTOMER: PATHS.ROOT,
  CARRIER: PATHS.ROOT,
  LOCATION: PATHS.ROOT,
  FACTORING_COMPANY: PATHS.ROOT,
  DRIVER: PATHS.ROOT,
};
