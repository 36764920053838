import React from "react";
import { Panel, useComponentContext } from "lib/components";
import { AuthType } from "lib/util/AuthType";
import { getLogger } from "lib/util";
import { OrderConfirmationHeader } from "portal/customer/OrderConfirmationHeader";
import { QuoteSummaryPanel } from "portal/customer/QuoteSummaryPanel";
import { PanelMap } from "portal/customer/PanelMap";
import { StopTable } from "portal/customer/StopTable";
import { AdditionalDetails } from "portal/customer/AdditionalDetails";

const log = getLogger("portal.customer.brokerage-ltl-quote.Confirmation");

export function Confirmation(props) {
  log.debug("Confirmation %o  %o", props, useComponentContext());
  const parentType = "ftl-order";
  return (
    <Panel data={props.data} fillRow modelName={"dispatch/ftl-order"}>
      <OrderConfirmationHeader />
      <Panel fillRow>
        <QuoteSummaryPanel data={props.data} type="Order" idField="id" hideBOL={true} stopsVisible={false} totalVisible={false} carrierVisible={false} freightSummaryVisible={false} otherChargesVisible={false} ratingDetailsVisible={false} disclaimerVisible={false} buttonLook="primary1" noRowBreak />
        <Panel fillRow>
          <PanelMap minHeight={300} fillRow noDataContainer />
          <StopTable editMode={false} fillRow />
          <AdditionalDetails parentType={parentType} usePrintableVersion />
        </Panel>
      </Panel>
    </Panel>
    )
}

export function getPageOptions() {
  return {
    auth: [AuthType.CUSTOMER, AuthType.LOCATION],
    title: "Order Confirmation"
  };
}
