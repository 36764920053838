import React, { useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Divider,
  CircularProgress
} from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
import { Typography, PDFFileDownloader } from "common";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloseIcon from "@material-ui/icons/Close";
import { MEDIA_TYPE_OCTET_STREAM } from "core/utils/constCapsule";
import { connect } from "react-redux";
import { useStyles } from "./PendingOfferDialog.styles";
import { getCompanySettings } from "../../lib/util";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PendingOfferDialogPresentation({
  showDialog,
  handleDialogAction,
  legalTermsName,
  pdfData,
  requireLegalTerm,
  requireOrderTerm,
  orderConfPdfData,
  processing
}) {
  const classes = useStyles();
  const LegalTermDoc = useMemo(
    () => (
      <Document
        file={{
          data: pdfData
        }}
      >
        <Page pageNumber={1} />
      </Document>
    ),
    [pdfData]
  );

  const OrderConfDoc = useMemo(
    () => (
      <Document
        file={{
          data: orderConfPdfData
        }}
      >
        <Page pageNumber={1} />
      </Document>
    ),
    [orderConfPdfData]
  );

  function onOpenClose(event) {
    event.stopPropagation();
    handleDialogAction(event);
  }
  function handleDialogClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }
  return (
    <Dialog
      open={showDialog}
      aria-labelledby="offer-dialog-title"
      fullWidth
      maxWidth="md"
      disableBackdropClick
      onClick={handleDialogClick}
      classes={{
        paperScrollPaper: classes.paper
      }}
    >
      <DialogTitle
        disableTypography
        classes={{
          root: classes.title
        }}
        id="offer-dialog-title"
      >
        Offer Details
        <IconButton classes={{ root: classes.iconButton }} onClick={onOpenClose} value="cancel">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.header}>
        Order Confirmation &amp; Terms
        {requireLegalTerm === "Y" && pdfData ? (
          <PDFFileDownloader
            fileName={legalTermsName}
            fileContent={btoa(pdfData)}
            mediaType={MEDIA_TYPE_OCTET_STREAM}
          >
            {({ loading }) => (
              <Button
                style={{ cursor: loading ? "wait !important" : "pointer" }}
                startIcon={<CloudDownloadIcon />}
                id="settlement-summary-link"
                size="small"
                color="primary"
              >
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize="medium"
                  value="Download Terms"
                />
              </Button>
            )}
          </PDFFileDownloader>
        ) : null}
        {requireOrderTerm === "Y" && orderConfPdfData ? (
          <PDFFileDownloader
            fileName="OrderConfirmation.pdf"
            fileContent={btoa(orderConfPdfData)}
            mediaType={MEDIA_TYPE_OCTET_STREAM}
          >
            {({ loading }) => (
              <Button
                style={{ cursor: loading ? "wait !important" : "pointer" }}
                startIcon={<CloudDownloadIcon />}
                id="settlement-summary-link"
                size="small"
                color="primary"
              >
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize="medium"
                  value="Download Rate Confirmation"
                />
              </Button>
            )}
          </PDFFileDownloader>
        ) : null}
      </div>
      <DialogContent>
        {requireLegalTerm === "Y" && pdfData ? LegalTermDoc : null}
        {requireOrderTerm === "Y" && orderConfPdfData ? OrderConfDoc : null}
      </DialogContent>
      <Divider />
      <DialogActions className={classes.footer}>
        <Typography variant="subtitle2" align="left" style={{ overflow: "visible" }}>
          By accepting this offer, you agree to the terms and conditions above.
        </Typography>
        <Typography variant="button" style={{ overflow: "visible" }}>
          <Button
            classes={{
              outlined: classes.cancelButton
            }}
            onClick={onOpenClose}
            variant="outlined"
            value="cancel"
            size="large"
          >
            Cancel
          </Button>
          <Button
            classes={{
              outlined: classes.acceptButton
            }}
            onClick={onOpenClose}
            variant="outlined"
            value="accept"
            size="large"
          >
            Accept
          </Button>
        </Typography>
      </DialogActions>
      {processing && <CircularProgress size={30} className={classes.buttonProgress} />}
    </Dialog>
  );
}

const mapStateToProps = (state, props) => {
  const { legalTermsName = null, orderTerms = false, legalTerms = false } = props || {};
  return {
    legalTermsName: legalTermsName || getCompanySettings().legal_terms_name,
    requireLegalTerm: getCompanySettings().require_legal_term,
    requireOrderTerm: getCompanySettings().require_order_term
  };
};

export const PendingOfferDialog = connect(mapStateToProps)(PendingOfferDialogPresentation);
