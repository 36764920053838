import React, { useState } from "react";
import { Select, MenuItem } from '@material-ui/core';
import { Component, useComponentContext } from ".";
import { getDataContextValue, setDataContextValue } from "./DataContainer";
import { PropTypes, getBasicPropTypes, ComponentPropTypes } from "./PropTypes";
import { addDefaultMetaDataProps } from "lib/util/ModelDefaultProps";
import { useComboBoxStyles } from "./ComboBoxStyles";
import { getFirstSuppliedValue, evaluateProp } from "./ComponentUtil";
import { FormControl } from "./FormControl";

function ComboBoxInternal({ captionVisible, selectProps, variant = "standard", ...props }) {
  const context = useComponentContext();
  const [selectedIndex, setSelectedIndex] = useState();
  let [focused, setFocused] = useState();
  const classes = useComboBoxStyles();
  props = addDefaultMetaDataProps(context, props);
  let value = getFirstSuppliedValue(context, [
    props.selectedIndex,
    (context) => getDataContextValue(context, props.field),
    selectedIndex,
    -1,
  ]);
  let items = buildMUIItems(evaluateProp(context, props.items));
  if (selectProps == null)
    selectProps = {};
  if (value === undefined)
    selectProps.value = '';
  else
    selectProps.value = value;
  let selectVariant = "outlined";
  if (variant === "undecorated")
    selectVariant = "standard"
  const focusProps = {
    onFocus: (event) => { handleFocus(event, props.onFocus, setFocused) },
    onBlur: (event) => { handleBlur(event, props.onFocus, setFocused) },
  };
  return (
    <Component {...props} >
      <FormControl {...props} focused={focused}>
        <Select	{...selectProps}
          className={classes.select}
          variant={selectVariant}
          disabled={context.designer != null}
          {...focusProps}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          onChange={(event) => handleChange(context, props, event.target.value, setSelectedIndex)}
          label={props.caption}
        >
          {items}
        </Select>
      </FormControl>
    </Component>
  );
}

function buildMUIItems(items) {
  let result = [];
  for (let i = 0; items != null && i < items.length; i++) {
    let caption = items[i];
    if (caption.caption)
      caption = caption.caption;
    // can also handle image names and complex components here
    result.push(<MenuItem key={"item" + i} value={i}>{caption}</MenuItem>); // items should be able to be an array of objects that contains the "value" here
  }
  return result;
}

function handleFocus(event, onFocus, setFocused) {
  setFocused(true);
  if (onFocus)
    onFocus(event);
}

function handleBlur(event, onBlur, setFocused) {
  //  if (validate(value, setValidationWarning)) {
  if (onBlur)
    onBlur(event);
  //}
  setFocused(false);
}

function handleChange(context, props, value, setSelectedIndex) {
  setSelectedIndex(value);
  setDataContextValue(context, props, value);
  if (props.onChange)
    props.onChange(value, context);
}

export const ComboBox = ComboBoxInternal;

ComboBox.propTypes = {
  ...getBasicPropTypes(ComboBox.extPropTypes)
}

ComboBox.extPropTypes = {
  ...ComponentPropTypes,
  caption: { type: PropTypes.string },
  captionVisible: { type: PropTypes.bool, defaultValue: true },
  default: { type: PropTypes.string },
  field: { type: PropTypes.string },
  fontBold: { type: PropTypes.bool },
  items: { type: PropTypes.string },
  selectedIndex: { type: PropTypes.number },
  variant: { type: PropTypes.oneOf(["standard", "undecorated"]) },
}
