import * as React from "react";

function Dot({fill="#137CDD", ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 3 3" {...props}>
      <circle cx={1.5} cy={1.5} r={1.5} fillRule="nonzero" />
    </svg>
  );
}

const SVGDot = React.memo(Dot);
export { SVGDot };