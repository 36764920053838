import React from "react";
import { Row, Label, Panel, TextBox } from "lib/components";
import { QuoteStopDetailTable } from "../QuoteStopDetailTable";
import { QuoteSummaryPanel } from "../QuoteSummaryPanel";
import { QuoteTermsConditions } from "../QuoteTermsConditions";

export function QuoteDetails(props, setAgree, freightItemsTable, handlingReqsTable, currentStep) {
  const tableProps = {...props};
  delete tableProps.data;
  const parentType = "ltl-quote";
  return(
      <Panel caption="Quote Details" submitCaption="Book order" maxWidth="lg">
        <QuoteSummaryPanel type="Quote" idField="id" hideBOL={true} hideShareOrder={true} stopsVisible={false} otherChargesVisible={false} disclaimerVisible={false} transitInfoPanelProps={{marginTop: 25, padding: 0}} noRowBreak />
        <Panel fillRow>
          <QuoteTermsConditions {...props} setAgree={setAgree} />
          <Label caption="Pickup and Delivery" look="defaultBold4" fillRow />
          <QuoteStopDetailTable field="stops" parentType={parentType} border={false} fillRow currentStep={currentStep} {...tableProps}  />
          <PanelAdditionalDetails {...props} />
          {freightItemsTable}
          <PanelHandlingReqs {...tableProps} table={handlingReqsTable}/>
        </Panel>
      </Panel>
  )
}

export function PanelAdditionalDetails(props)
{
  return (
    <Panel rowBreakDefault={false} {...props}>
      <Label caption="Additional Details" look="defaultBold4" fillRow rowBreak />
      <Row>
        <TextBox caption="BOL Number" field="blnum" />
        <TextBox caption="Consignee Reference Number" field="consignee_refno" />
        <TextBox caption="Shipper PO Number" field="shipper_po" required={false} />
        <TextBox caption="Internal Note" field="planning_comment" />
      </Row>
      <Row>
        <TextBox caption="BOL Note" multiline rows={4} field="bol_note" />
      </Row>
    </Panel>
  );
}

export function PanelHandlingReqs(props) {
  return (
    <Panel rowBreakDefault={false} {...props} fillRow fillHeight>
      {props.table}
    </Panel>
  );
}
