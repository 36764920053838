import React from "react";
import { Panel } from "./Panel";
import { getBasicPropTypes } from "./PropTypes";

export function Tab({ ...props }) {
  return (
    <Panel fillRow fillHeight {...props} >
      {props.children}
    </Panel>
  )
}

Tab.extPropTypes = {
  ...Panel.extPropTypes
}

Tab.propTypes = {
  ...getBasicPropTypes(Tab.extPropTypes)
}
