import React from "react";
import { Table, Column, showProgressSnackbar } from "lib/components";
import { downloadURL } from "lib/util/DownloadUtil";

export function TableImagingList(props) {
	return (
		<Table
			name="images"
			field="images"
			emptyCaption="No images available at this time"
			alternateRowColor
			showLinesBetweenRows={false}
			headerVisible={false}
			border="none"
			fillRow
			{...props}
		>
			<Column
				caption="Description"
				field="descr"
				variant="link"
				onClick={downloadImage}
				minWidth={200}
			/>
			<Column
				caption="Scan date"
				field="scan_date"
				minWidth={140}
			/>
			<Column
				caption="Image count"
				field="count"
				minWidth={80}
				align="right"
			/>
		</Table>
	);
}

function downloadImage(event, componentContext, data) {
	showProgressSnackbar("Image Download", "We are generating this image and it will download automatically.");
	const fetchOptions = {  method: "GET" };
	downloadURL(`image/id/${data["image_id"]}`, `Order ${data["order_id"]} - ${data["descr"]}.tif`,
		"Image downloaded.", "Error downloading image", fetchOptions);
}
