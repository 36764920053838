import React from "react"
import { SVGIcon } from "lib/util/SVGIcon"

function Pointer(props) {
  return (
    <SVGIcon
      viewBox="0 0 360.39 360.389"
      {...props}
    >
      <path d="M301.123 173.306L118.995 3.265A12.091 12.091 0 00106.89.626a12.096 12.096 0 00-8.086 9.389L55.602 255.404a12.102 12.102 0 005.464 12.341c4.152 2.625 9.49 2.465 13.491-.378l59.968-42.699 47.219 127.448c2.127 6.336 8.984 9.765 15.332 7.648l62.087-20.771c6.342-2.116 9.771-8.984 7.648-15.327l-47.228-127.43 73.596-1.975a12.127 12.127 0 0011.006-7.802 12.112 12.112 0 00-3.062-13.153zm-98.532-.851c-3.842.109-7.4 2.004-9.599 5.163a12.092 12.092 0 00-1.561 10.781l48.557 131.467-39.111 13.075-48.56-131.455a12.099 12.099 0 00-7.743-7.679 12.082 12.082 0 00-10.772 1.649l-49.216 35.027 34.206-194.281 144.19 134.636-60.391 1.617z" />
    </SVGIcon>
  )
}

const SVGPointer = React.memo(Pointer);
export { SVGPointer };

/* <svg {...props}>
  <path d="M4 0l16 12.279-6.951 1.17 4.325 8.817L13.778 24l-4.35-8.879L4 19.823z" />
    </svg> */

