export const CustomerType = {
  ASSET: "asset",
  BROKERAGE: "brokerage"
}

export const PanelMode = {
  EDIT: "edit",
  DISPLAY: "display"
}

export const PageType = {
  OVERVIEW: "overview",
  DETAILS: "details",
  ORDER: "order"
}
