import React from "react";
import { Panel, Label, TextBox } from "lib/components";

export function PaymentTerms(props = {}) {
  const usePrintableVersion = props.usePrintableVersion === true;
  const collectionMethods = [{caption: "Prepaid", value:"P"}, {caption: "Collect", value:"C"}, {caption: "Third Party", value:"T"}];
  let caption = "Payment Terms";
  if (!usePrintableVersion) {
    caption += "*";
  }
  return (
    <Panel fillRow rowBreakDefault={false} caption="Payment Terms">
      <Label caption={caption} look="defaultBold4" fillRow rowBreak />
      <TextBox caption="" indicateRequired={false} placeholder={"Select Payment"} items={collectionMethods} field="collection_method" width={150} usePrintableVersion={usePrintableVersion} />     
    </Panel>
  );
}
