import { makeStyles } from "@material-ui/core/styles";
import loginBackground from "images/png/login-background.jpg";

export const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "black",
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: "cover"
  },
  content: {
    height: "100%",
    display: "flex"
  }
}));
