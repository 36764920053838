import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Component, useComponentContext } from ".";
import { evaluateProp, getFirstSuppliedValue, shouldUsePrintableVersion } from "./ComponentUtil";
import { getDataContextValue, setDataContextValue } from "./DataContainer";
import { PropTypes, ComponentPropTypes, getBasicPropTypes } from "./PropTypes";
import { addDefaultMetaDataProps } from "lib/util/ModelDefaultProps";
import { useCheckBoxStyles } from "./CheckBoxStyles";
import { getThemeClassNames } from "lib/util/Theme";

export function CheckBox({...props}) {
  let context = useComponentContext();
  let classes = useCheckBoxStyles();
  let [checked, setChecked] = useState(props.default);
  let [labelClass, setLabelClass] = useState(classes.formControlLabel);
  props = addDefaultMetaDataProps(context, props);
  let checkValue = getFirstSuppliedValue(context, [
    props.checked,
    (context) => getDataContextValue(context, props.field),
    checked,
    false
  ]);
  if(checkValue === "Y" || checkValue === true)
    checkValue = true;
  else
    checkValue = false;
  let printableVersion = shouldUsePrintableVersion(context, props);
  let checkbox = (
    <Checkbox checked={checkValue} disabled={printableVersion || context.designer != null} className={classes.root} color="primary"
      onChange={(event) => { handleChange(context, props, event.target.checked, setChecked, setLabelClass) }} />
  );
  let caption = evaluateProp(context, props.caption);
  if ((caption == null || caption.length === 0) && context.designer != null) {
    if (props.field == null)
      caption = props.name;
    else
      caption = props.field;
  }

  const newLabelClass = getUpdatedLabelClass(props, checkValue);
  if (newLabelClass != null)
    labelClass = newLabelClass;

  let checkClass = classes.checkComponent;
  if (props.align === "left")
    checkClass = classes.checkComponentLeft;

    return (
    <Component {...props} className={checkClass}>
      <FormControlLabel classes={{ label: labelClass }} control={checkbox} label={caption} />
    </Component>
  );
}

function getUpdatedLabelClass(props, checked, )
{
  if (checked)
  {
    if (props.checkedLook != null)
      return getThemeClassNames()[props.checkedLook];
    else if (props.look != null)
      return getThemeClassNames()[props.look];
  }
  else
  {
    if (props.uncheckedLook != null)
      return getThemeClassNames()[props.uncheckedLook];
    else if (props.look != null)
      return getThemeClassNames()[props.look];
  }
  return null;
}

function handleChange(context, props, value, setChecked, setLabelClass) {
  setChecked(value);
  setDataContextValue(context, props.field, value);

  const newLabelClass = getUpdatedLabelClass(props, value);
  if (newLabelClass != null)
    setLabelClass(newLabelClass);

  if (props.onChange != null)
    props.onChange(value, context);
}

CheckBox.extPropTypes = {
  ...ComponentPropTypes,
  caption: { type: PropTypes.string },
  checked: { type: PropTypes.bool },
  default: { type: PropTypes.bool },
  field: { type: PropTypes.string },
  modelName: { type: PropTypes.string },
}

CheckBox.propTypes = {
  ...getBasicPropTypes(CheckBox.extPropTypes)
}
