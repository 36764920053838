import React from "react";
import { Form, Input, Select } from "antd";
import { getCompanySettings, getLogger } from "lib/util";

const InputGroup = Input.Group;
const { Option } = Select;
const { Search } = Input;

let log = getLogger("lib.pages.login.ComboSearch");

export class ComboSearch extends React.Component {

  constructor(props, state) {
    super(props, state);
    this.state = { ...props };
  }

  handleSearch = value => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        log.debug("Search value %s", value);
        this.setState({ searchValue: value });
        let path = `/portal/customer/Tracking?search_value=${value}&search_type=${this.state.selectedValue}`;
        this.props.history.push(path);
        return;
      }
    });
  };

  handleChange = value => {
    log.debug("Combo value %s", value);
    this.setState({ selectedValue: value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    };
    let comboValue = "";
    if (this.state.selectedValue) comboValue = this.state.selectedValue;

    return (
      <div style={{ width: this.props.width }}>
        <InputGroup compact>
          <Form layout="inline" {...formItemLayout}>
            <Form.Item style={{ marginRight: "0px" }}>
              {getFieldDecorator("searchType", {
                rules: [{ required: true, message: "Search Type Required" }],
                initialValue: comboValue
              })(
                <Select
                  style={{
                    width: "150px",
                    display: "block",
                    paddingBottom: "2px"
                  }}
                  onChange={this.handleChange}
                >
                  <Option value="">Select Type</Option>
                  <Option value="BOLNum">BOL #</Option>
                  <Option value="ConsigneeRefNum">Consignee Ref. #</Option>
                  <Option value="OrderNum">Order #</Option>
                  {getCompanySettings().is_asset_ltl === true && <Option value="ProNum">Pro #</Option>}
                  {getCompanySettings().is_brokerage_ltl === true && (
                    <Option value="CarrierProNum">Carrier Pro #</Option>
                  )}
                  {getCompanySettings().is_brokerage_ltl === true && (
                    <Option value="ShipperPONum">Shipper PO #</Option>
                  )}
                </Select>
              )}
            </Form.Item>
            <Form.Item style={{ marginRight: "0px" }}>
              {getFieldDecorator("trackingNum", {
                rules: [{ required: true, message: "Tracking Number Required" }]
              })(
                <Search
                  className="combo-search-input"
                  placeholder="Enter Tracking Number"
                  onSearch={value => this.handleSearch(value)}
                />
              )}
            </Form.Item>
          </Form>
        </InputGroup>
      </div>
    );
  }
}

ComboSearch = Form.create({ name: "combo_search" })(ComboSearch);
