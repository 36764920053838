import React, { useState, useEffect, useRef } from "react";
import { getLogger, getDataFromContext, fetchModelData } from "lib/util";
import { getFirstSuppliedValue } from "./ComponentUtil";
import { useComponentContext, DataContext, DataIndexContext } from "./Context";
import { getAutoContextDebugger } from "./ContextDebugger";
import { createEmptyModelRow, DataModes, searchModel, submitModel } from "lib/util/ModelUtil";
import { ensureMetaDataLoaded } from "lib/util/ModelDefaultProps";
import { Spinner } from ".";
import { sortData } from "lib/util/sort";

let log;

function getLog() {
  if (log == null)
    log = getLogger("lib.components.DataContainer");
  return log;
}

export function DataContainer(props) {
  let context = useComponentContext();
  getLog().debug("DataContainer context %o  props %o", context, props);
  let [stateData, setStateData] = useState();
  let [searchValues, setSearchValues] = useState();
  let [loading, setLoading] = useState(false);
  let [metaDataLoaded, setMetaDataLoaded] = useState(false);
  let [warnings, setWarnings] = useState();
  let [stateAccessors, setStateAccessors] = useState();
  let [actualRowCount, setActualRowCount] = useState();
  const firstUpdate = useRef(true);
  //const dataIndex = useState(props.dataIndex || 0);
  const data = getFirstSuppliedValue(null, [
    () => wrapPropsData(props.data, props.sortFields),
    stateData,
  ]);
  useEffect(() => {
    async function loadIt() {
      await ensureMetaDataLoaded(props.modelName);
      setMetaDataLoaded(true);
      if (props.autoSearch)
        fetchModelData(props.modelName, context, setLoading,
          (modelData, fullResponse) => {
            wrapData(modelData, setStateData, props.sortFields);
            if (fullResponse.actual_row_count != null)
              setActualRowCount(fullResponse.actual_row_count);
          });
      else if (props.dataMode === DataModes.ADD)
        setStateData([createEmptyModelRow()]);
      else if (props.dataMode === DataModes.SEARCH)
        setSearchValues(createEmptyModelRow());
    }

    getLog().debug("Mount props %o   Data %o", props, data);
    if (props.modelName != null &&firstUpdate.current && props.refreshModelData === false)  {
      loadIt();
      firstUpdate.current = false;
    }
    if (props.modelName != null && (props.refreshModelData === undefined || props.refreshModelData === true )){
      loadIt();
      if(firstUpdate.current)
        firstUpdate.current = false;
    }
  }, [props.refreshModelData, props.modelName]);
  if (props.modelName != null && !metaDataLoaded)
    return <Spinner />;
  let dataIndex = props.dataIndex;
  if (dataIndex == null)
    dataIndex = 0;
  let contextValue = {
    modelName: props.modelName,
    list: data,
    actualRowCount: actualRowCount,
    warnings,
    stateAccessors,
    loading,
    fixedData: props.fixedData,
    mode: props.dataMode,
    parentContext: context,
    parentField: props.field,
    searchValues,
    setSearchValues,
    setDataList: setStateData,
    setWarnings,
    setStateAccessors,
    submit: (componentContext, setLoading, onComplete, onError, modifyDataMethod) => {
      if (props.dataMode === DataModes.SEARCH) {
        searchModel(componentContext, setLoading, onComplete, onError, modifyDataMethod);
      }
      else if (props.dataMode === DataModes.EDIT) {
        submitModel(componentContext, setLoading, onComplete, onError, modifyDataMethod, DataModes.EDIT);
      }
      else {
        submitModel(componentContext, setLoading, onComplete, onError, modifyDataMethod);
      }
  }};
  if (props.field != null) {
    if (context.data.childData == null)
      context.data.childData = {};
    context.data.childData[props.field] = contextValue;
    if (context.data.debugContextRefresher != null)
    { context.data.debugContextRefresher(); }
    getLog().debug("Set childData for field %s to %o.  Full context %o", props.field, contextValue, context);
  }
  return (
    <DataContext.Provider value={contextValue} >
      <DataIndexContext.Provider value={dataIndex} >
        <DataContainerInternal {...props}>
          {props.children}
        </DataContainerInternal>
      </DataIndexContext.Provider>
    </DataContext.Provider>
  )
}

function wrapPropsData(data, sortFields) {
  if (data == null)
    return null;
  else if (Array.isArray(data)) {
    let result = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].modelData == null)
        result.push({modelData:data[i]});
      else
        result.push(data[i]);
    }
    result = sortData(result, sortFields);
    return result;
  }
  else if (data.modelData == null)
    return {modelData: data};
  else
    return data;
}

function wrapData(modelData, setStateData, sortFields) {
  if (modelData == null)
    setStateData(null);
  else {
    let stateData = [];
    for (let i = 0; i < modelData.length; i++)
      stateData.push({modelData: modelData[i]});
    stateData = sortData(stateData, sortFields);
    setStateData(stateData);
  }
}

export function DataContainerInternal(props) {
  const context = useComponentContext();
  getLog().debug("DC Context %o   Props  %o   GDFC %o", context, props.data, getDataFromContext(context, props.field));
  if (context.data.list == null) {
    getLog().debug("DC Context clearing data list for field %o", props.field)
    context.data.list = [];
    context.dataIndex = 0;
  }

  const debugSection = getAutoContextDebugger(context, props, props.debugContext);
  getLog().debug("DC Context %o  Props %o ", context, props);
  return (
    <React.Fragment >
      {debugSection}
      {props.children}
    </React.Fragment>
  );
}

/**
 * This function defines which props a DataContainer cares about.
 *
 * @param param0
 */
export function getDataContainerProps({ modelName, data, field, fixedData, autoSearch, dataMode, modelIndex = 0, refreshModelData }) {
  if (data != null && field != null) {
    data = data[field];
    //field = null;
  }
  return { modelName, data, field, fixedData, autoSearch, modelIndex, dataMode, refreshModelData };
}

/**
 * This function is redundant.  Remove it when there's a convenient time to do so.
 *
 * @param {*} context
 * @param {*} field
 */
export function getDataContextValue(context, field) {
  return getDataFromContext(context, field);
}


/**
 * This should probably be moved to ModelUtil where the getter() lives
 * @param {*} context
 * @param {*} field
 * @param {*} value
 */
export function setDataContextValue(context, field, value, invokeStateAccessor = false) {
  if (context != null && field != null && context.data != null) {
    let target;
    let targetParent;
    if (context.data.mode === DataModes.SEARCH)
    {
      target = context.data.searchValues.modelData;
      targetParent = context.data.searchValues;
    }
    else if (context.data.list != null && context.dataIndex != null) {
      const currRecord = context.data.list[context.dataIndex];
      if (currRecord != null)
      {
        target = currRecord.modelData;
        targetParent = currRecord;
      }
    }
    let stateAccessor = null;
    if (target != null) {
      if (field != 'replaceModelData')
      {
        target[field] = value;
        if (context.data.stateAccessors != null && context.data.stateAccessors[context.dataIndex] != null)
        { stateAccessor = context.data.stateAccessors[context.dataIndex][field]; }
      }
      else
        targetParent.modelData = value;

      getLog().debug("setDataContextValue Target[field] %o,   Context %o,   Field %o,   Value %o,   Target %o", target[field], context, field, value, target);
      if (context.data.mode === DataModes.SEARCH)
        context.data.setSearchValues({...context.data.searchValues});
      else
      {
        if (stateAccessor != null && invokeStateAccessor === true)
        {
          stateAccessor = stateAccessor();
          getLog().debug("setDataContextValue State Accessor %o", stateAccessor);
          if (stateAccessor.handleChange != null && stateAccessor.setStateValue != null && stateAccessor.props != null)
          { stateAccessor.handleChange(context, stateAccessor.props, value, stateAccessor.setStateValue); }
        }
        triggerRender(context);
      }
    }
  }
}

function triggerRender(context, modelData) {
  context.data.setDataList([...context.data.list]);
  // should be able to come up with a way to trigger a render without rebuilding the whole array.
  // useForceUpdate() will likely work
  // worse, rendering the whole context is gonna be bad when we have a Table will a couple hundred rows and want to change one of the values
  // in it.  Does redux solve this by letting us render just the changed row?
  // or could we have a context for each row in the list?
  // I wish Ryan was here.
}
