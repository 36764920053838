import React, { useEffect } from "react";
import { TableCell, TableRow as MUITableRow } from '@material-ui/core';
import { RowCell } from "./RowCell";
import { useStyles } from "./TableStyles";
import { RowToolsCell } from "./RowToolsCell";
import { getChildArray } from "../ComponentUtil";
import { DataIndexContext } from "../Context";
import { Panel, Button, useComponentContext } from "..";
import { useState } from "react";
import { useUnmountEffect } from "lib/util/Hooks";

export function TableRow({ virtualized, virtualizedHeight, observer, heightCallback, rowIndex, expandedRowIndex, rowIndexAfterFilter, setExpandedRowIndex, tableProps, ...props }) {
  let classes = useStyles();
  let rowRef = React.useRef();
  const context = useComponentContext();
  const [observed, setObserved] = useState(false);
  useEffect(() => {
    if (virtualized && rowRef.current != null && rowRef.current.setObserved == null && observer != null) {
      rowRef.current.setObserved = setObserved;
      rowRef.current.observer = observer;
      observer.observe(rowRef.current);
    }
  });
  useUnmountEffect(() => {
    if (rowRef.current != null && rowRef.current.observer != null) {
      rowRef.current.observer.unobserve(rowRef.current);
      rowRef.current.setObserved = null;
      rowRef.current.observed = false;
    }
  });
  if (observed && heightCallback != null && rowRef.current != null)
      heightCallback(rowRef.current.offsetHeight);
  // need to ensure that props.rowProps array matches data array (in length) instead of doing these null checks
  if (props.rowProps != null && props.rowProps[rowIndex] != null && hasRowEditor(props) && props.rowProps[rowIndex].editing === true) {
    const adding = props.rowProps[rowIndex].adding;
    const editing = props.rowProps[rowIndex].editing;
    let showCancelButton = true;
    if(typeof props.showCancel === 'boolean' && props.showCancel === false && (adding || editing)) {
      showCancelButton = false;
    }
    let showButtonPanel = true;
    let expansionCellClass = classes.expansionCell;
    if (tableProps.displayAsCards === true) {
      expansionCellClass = classes.expansionCellCard;
    }
    else if (tableProps.rowBorder === false) {
      expansionCellClass = classes.expansionCellNoBorder;
    }
    let expansionClass = classes.expansion;
    if (tableProps.displayAsCards === true) {
      expansionClass = classes.expansionCard;
    }
    else if (tableProps.rowBorder === false) {
      expansionClass = classes.expansionCellNoBorder;
    }
    if(typeof props.showButtonPanel === 'boolean' && props.showButtonPanel === false && (adding || editing)){
      showButtonPanel = false;
      expansionCellClass = classes.expansionCellNoBorder;
      expansionClass = null;
    }
    let showSaveButton = true;
    if(typeof props.showSave === 'boolean' && props.showSave === false && (adding || editing)) {
      showSaveButton = false;
    }
    let rowStyle;
    if (props.backgroundcolor)
      rowStyle = { backgroundColor: props.backgroundcolor };

    let editModeToolsEditorPanel;
    let editModeToolsCellPanel;
    if (props.autoSave !== true)
    {
      let saveButton;
      if (props.saveCaption && !showCancelButton)
      { saveButton = <Button visible={showSaveButton} caption={props.saveCaption || "Save"} image={{image:"AddWithCircle", fill:"#137CDD"}} variant="text" color="primary" onClick={(event, context) => saveWithValidation(context, rowIndex, props.saveRow)} /> }
      else
      { saveButton = <Button visible={showSaveButton} caption="Save" color="primary" onClick={(event, context) => saveWithValidation(context, rowIndex, props.saveRow)} /> }

      const saveCancelPanel = (
        <Panel fillRow visible={showButtonPanel}>
          <Panel visible={showCancelButton} fillRow noRowBreak />
          <Button visible={showCancelButton} caption="Cancel" noRowBreak onClick={props.cancelEdit} />
          {saveButton}
        </Panel>
      );

      if (tableProps.toolsInPanel === true)
      { editModeToolsEditorPanel = saveCancelPanel; }
      else
      { editModeToolsCellPanel = saveCancelPanel; }
    }
    else
    {
      let showDelete = true;
      if (tableProps.showDelete != null)
      { showDelete = tableProps.showDelete(context, props.rowProps, rowIndex, context.data.list[rowIndex]); }
      if (showDelete !== false)
      {
        const deletePanel = (
          <Panel fillRow>
            <Panel fillRow noRowBreak />
            <Button border="none" image={{image: "X", width: 18, height: 18}} color="#979797" onClick={(event, context) => props.deleteRow()} tooltip="Delete this item from the list"/>
          </Panel>
        );

        if (tableProps.toolsInPanel === true)
        { editModeToolsEditorPanel = deletePanel; }
        else
        { editModeToolsCellPanel = deletePanel; }
      }
    }
    const editor = createRowEditor(props, tableProps, context, rowIndex, () => save(props.saveRow), () => saveWithValidation(context, rowIndex, props.saveRow), editModeToolsEditorPanel);
    return (
      <DataIndexContext.Provider key={"context-" + rowIndex} value={rowIndex} >
        <MUITableRow key={"addrow-" + rowIndex} className={expansionClass} style={rowStyle}>
          <TableCell colSpan={2} className={expansionCellClass}>
            <Panel fillRow usePrintableVersion={false} noRowBreak >
              {editor}
            </Panel>
            {editModeToolsCellPanel}
          </TableCell>
        </MUITableRow>
      </DataIndexContext.Provider>
    );
  }
  let cells = [];
  let rowClassName = "", expansionRow;
  let extraProps;
  if (!virtualized || observed) {
    let toolsCell;
    let toolsPanel;
    if ((props.expandComponent != null || (hasRowEditor(props) || props.rowTools != null)) && props.showTools !== false) {
      const tools = (
        <RowToolsCell
          context={context}
          key="expand"
          showExpand={props.expandComponent != null}
          showEdit={hasRowEditor(props)}
          rowIndex={rowIndex}
          tools={props.rowTools}
          editRow={props.editRow}
          deleteRow={props.deleteRow}
          expandedRowIndex={expandedRowIndex}
          setExpandedRowIndex={setExpandedRowIndex}
          showLinesBetweenRows={tableProps.showLinesBetweenRows}
          displayAsCards={tableProps.displayAsCards}
          returnPanel={tableProps.toolsInPanel}
        />);

      if (tableProps.toolsInPanel === true) {
        toolsPanel = tools;
      }
      else {
        toolsCell = tools;
      }
    }
    const columns = getChildArray(props.children);
    if (columns.length === 0 && hasRowDisplayPanel(props)) {
      cells.push(<RowCell key={"cell-" + rowIndex + "-default"} classes={classes} rowIndex={rowIndex} colIndex={0} colProps={{ children: [createRowDisplayPanel(props, tableProps, context, rowIndex, () => save(props.saveRow), () => saveWithValidation(context, rowIndex, props.saveRow), toolsPanel)] }} tableProps={tableProps} />);
    } else {
      for (let i = 0; columns != null && i < columns.length; i++)
        if (columns[i] != null && isVisible(context, columns[i].props.visible) !== false)
          cells.push(<RowCell key={"cell-" + rowIndex + "-" + i} classes={classes} rowIndex={rowIndex} colIndex={i} tableProps={tableProps} colProps={columns[i].props} cellStyles={columns[i].props.cellStyles} />);
    }
    if (toolsCell != null) {
      cells.push(toolsCell);
    }
    if (rowIndex === expandedRowIndex && props.expandComponent) {
      rowClassName = classes.expandedRow + " " + !tableProps.displayAsCards ? classes.activeRow : classes.activeRowCard;

      let expandComponent = React.createElement(props.expandComponent, props.expandProps, null);
      let rowStyle;
      if (expandComponent.props.backgroundColor)
        rowStyle = { backgroundColor: expandComponent.props.backgroundColor };
      expansionRow = (
        <DataIndexContext.Provider key={"context-" + rowIndex} value={rowIndex} >
          <MUITableRow key={"expansion-" + rowIndex} className={!tableProps.displayAsCards ? classes.expansion : classes.expansionCard} style={rowStyle}>
            <TableCell colSpan={cells.length} className={classes.expansionCell}>
              {expandComponent}
            </TableCell>
          </MUITableRow>
        </DataIndexContext.Provider>
      );
    }

    if (tableProps.displayAsCards)
    { rowClassName = rowClassName + " " + classes.cardRow; }
    let rowColorProps = getRowColorProps(classes, props, rowIndexAfterFilter);
    if (rowColorProps && rowColorProps.className)
      rowClassName += " " + rowColorProps.className;
    else
      extraProps = rowColorProps;
  }
  let heightStyle;
  if (virtualized === true)
    heightStyle = {style:{height: virtualizedHeight || 32}};
  return (
    <DataIndexContext.Provider key={"context-" + rowIndex} value={rowIndex} >
      <MUITableRow key={"row-" + rowIndex} ref={rowRef} className={rowClassName} {...heightStyle} {...extraProps}>
        {cells}
      </MUITableRow>
      {expansionRow}
    </DataIndexContext.Provider>
  );
}

function isVisible(context, visProp) {
  if (visProp === false) {
    return false;
  }
  else if (typeof visProp === "function") {
    return visProp(context);
  }
  return true;
}

function saveWithValidation(context, rowIndex, saveMethod) {
  if (context.validateForm(rowIndex)){
    saveMethod();
  }
}

function save(saveMethod)
{ saveMethod(); }

function getRowColorProps(classes, props, rowIndex) {
  let result = null;
  if (props.alternateRowColor && rowIndex % 2 === 0) {
    if (typeof props.alternateRowColor === "boolean")
      result = { className: classes.defaultAlternateRow };
    else
      result = { style: { backgroundColor: props.alternateRowColor } };
  }
  return result;
}

function hasRowDisplayPanel(props)
{ return props.rowDisplayPanel != null || props.rowEditor != null; }

function createRowDisplayPanel(props, tableProps, context, rowIndex, saveMethod, saveWithValidationMethod, toolsPanel)
{
  if (props.rowDisplayPanel != null)
  {
    const passThroughTableProps = getPassThroughTableProps(tableProps);
    const editorProps = {...props, ...passThroughTableProps};
    const row = context.data.list[rowIndex];
    return props.rowDisplayPanel(editorProps, row, rowIndex, context, toolsPanel);
  }
  return createRowEditor(props, tableProps, context, rowIndex, saveMethod, saveWithValidationMethod, toolsPanel);
}

function hasRowEditor(props)
{ return props.rowEditor != null; }

function createRowEditor(props, tableProps, context, rowIndex, saveMethod, saveWithValidationMethod, toolsPanel)
{
  if (props.rowEditor != null)
  {
    const passThroughTableProps = getPassThroughTableProps(tableProps);
    const editorProps = {...props, ...passThroughTableProps, saveMethod, saveWithValidationMethod};
    const row = context.data.list[rowIndex];
    return props.rowEditor(editorProps, row, rowIndex, context, toolsPanel);
  }
  return null;
}

function getPassThroughTableProps(tableProps)
{
  const result = {};
  if (tableProps.displayEditorPanelProps != null) {
    for (let x in tableProps.displayEditorPanelProps) {
      result[x] = tableProps.displayEditorPanelProps[x];
    }
  }
  result.showDelete = tableProps.showDelete;
  return result;
}
