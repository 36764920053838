import React from "react";
import { PropTypes, getBasicPropTypes, ComponentPropTypes, displayTypeProp } from "lib/components/PropTypes";
import { isEmptyString, getLogger } from "lib/util";
import { getModelDataDisplayValue, replaceModelData } from "lib/util/ModelUtil";
import { useComponentContext } from "./Context";
import { Component, Image } from ".";
import { useLabelStyles } from "./LabelStyles";
import { evaluateProp } from "./ComponentUtil";
import { getThemeClassNames } from "lib/util/Theme";

const log = getLogger("lib.components.Label");

function Label(props) {
  let classes = useLabelStyles();
  const context = useComponentContext();
  let caption = getCaption(props, context);
  if (props.highlightText != null)
    caption = highlightText(caption, props.highlightText, props.highlightReverse);
  let image;
  if (props.image != null) {
    let img;
    if (typeof (props.image) === "string")
      img = { image: props.image };
    else
      img = props.image;
    image = <Image {...img} />
  }
  let style = { ...props.style };
  if (props.vAlign) {
    if (props.vAlign === "top")
      style.alignItems = "flex-start";
    else if (props.vAlign === "bottom")
      style.alignItems = "flex-end";
  }
  let className = classes.labelComp;
  if (props.className != null)
    className += " " + props.className;
  if (props.look != null)
    className += " " + getThemeClassNames()[props.look];
  if (props.allowSelection === false || props.onDoubleClick != null)
    className += " " + classes.noSelect;
  if (caption == null && props.nullDisplayValue === "hide")
    return null;
  return (
    <Component {...props} className={className} style={style} >
      {image}
      {caption}
    </Component>
  );
}

function getCaption(props, context) {
  log.debug("Rendering label props %o context %o", props, context);
  let result = evaluateProp(context, props.caption);
  if (result == null || result === undefined) {
    if (props.field != null) {
      if (props.replaceModelData)
        result = replaceModelData(props.field, context, props.displayType, props.format);
      else
        result = getModelDataDisplayValue(context, props.field, props.displayType, props.format);
    }
    else if (context.designer == null)
      result = replaceModelData(result, context, props.displayType, props.format);

    if (isEmptyString(result)) {
      if (props.nullDisplayValue) {
        if ("hide" !== props.nullDisplayValue)
        {
          if ("blankSpace" !== props.nullDisplayValue) {
            result = props.nullDisplayValue;
          }
          else {
            result = (<pre style={{margin: 0}}> </pre>);
          }
        }
      }
      else if (isEmptyString(result))
        result = null;
    }
  }

  if ((props.linkURL != null && context.designer == null) || props.variant === "link" || props.navigateTo != null) {
    const link = replaceModelData(props.linkURL, context, props.displayType, props.format);
    let linkProps;
    if (props.openLinkInNewTab === true)
      linkProps = {target:"_blank"};
    result = (<a href={link} {...linkProps} style={props.style}>{result}</a>);
  }
  if ((result == null || result.length === 0) && context.designer != null) {
    if (props.field == null)
      result = props.name;
    else
      result = props.field;
  }
  if (typeof result === "function")
    result = result(context);
  return result;
}

export function highlightText(value, searchText, reverse=false) {
  if (value == null || searchText == null || searchText.length === 0)
    return value;
  const index = value.toLowerCase().indexOf(searchText.toLowerCase());
  if (index < 0)
    return <span style={{fontWeight: 100}}>{value}</span>
  let pre = value.substring(0, index);
  let high = value.substring(index, index + searchText.length);
  let post = value.substring(index + searchText.length);
  if(reverse === true)
    return <React.Fragment><span style={{fontWeight: 1000, whiteSpace:"pre"}}>{pre}</span><span style={{fontWeight:100, whiteSpace:"pre"}}>{high}</span><span  style={{fontWeight: 1000, whiteSpace:"pre"}}>{post}</span></React.Fragment>
  else
    return <React.Fragment><span style={{fontWeight: 100, whiteSpace:"pre"}}>{pre}</span><span style={{fontWeight:1000, whiteSpace:"pre"}}>{high}</span><span  style={{fontWeight: 100, whiteSpace:"pre"}}>{post}</span></React.Fragment>
}

export { Label };

Label.extPropTypes = {
  ...ComponentPropTypes,
  allowSelection: { type: PropTypes.bool, defaultValue: true },
  caption: { type: PropTypes.string },
  displayType: displayTypeProp,
  field: { type: PropTypes.string },
  format: { type: PropTypes.string },
  image: { type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]) },
  nullDisplayValue: { type: PropTypes.string },
  variant: { type: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(["link"])]) },
  vAlign: { type: PropTypes.oneOf(["top", "bottom", "center"]) },
}

Label.propTypes = {
  ...getBasicPropTypes(Label.extPropTypes)
}
