import React from "react";
import { Panel, Label } from "lib/components";
import { StopTable } from "../StopTable";
import { AdditionalDetails } from "../AdditionalDetails";
import { TermsConditions } from "../TermsConditions";
import { AuthType } from "lib/util/AuthType";
import { DataModes, submitModel } from "lib/util/ModelUtil";
import { getTheme } from "lib/util/Theme";
import { getLogger } from "lib/util/Logger";
import { getCompanySettings } from "lib/util";
import { getDataFromContext } from "lib/util/ModelUtil";
import { showWarningSnackbar } from "lib/components/Snackbar";
import { navigateTo, navigateToRoot } from "lib/util/Navigation";
import { validateCustomerCredit } from "../OrderValidation";
import { useMountEffect } from "../../../lib/util";
import { showOrderCreationErrorDialog } from "lib/components/Dialog";

const log = getLogger("portal.customer.ftl-order.FtlOrder");
const parentType = "ftl-order";
export function FtlOrder(props) {
  const image = { image: "FtlDryVanOrder", height: 40, width: 200, marginRight: 10, fill: getTheme().palette.primary.base};
  let welcomeMessage = getCompanySettings().order_welcome_message;
  useMountEffect(() => { validateCustomerCredit(parentType) });
  return (
    <Panel
      modelName="dispatch/ftl-order"
      maxWidth="lg" variant="form"
      dataMode={DataModes.ADD}
      submitCaption="SUBMIT ORDER"
      onSubmit={(event, context, setLoading) => submitOrder(event, context, setLoading)}
      onCancel={navigateToRoot}
      undockSubmitRow={true}>
        <Label image={image} look="primary6" marginBottom={10} noRowBreak/>
        <Label caption={welcomeMessage} fillRow rowBreak />
        <StopTable parentType={parentType} editMode={true} fillRow />
        {AdditionalDetails({parentType: parentType})}
        {TermsConditions()}
    </Panel>
  )
}

function submitOrder(event, context, setLoading)
{
  if (!context.validateForm() || !validateCustomerCredit(parentType))
  { return; }

  const agreed = getDataFromContext(context, "agreed_to_terms");
  if (agreed !== true)
  { showWarningSnackbar("Please accept the Terms and Conditions before proceeding.", true); }
  else
  {
    submitModel(context, setLoading, (submitResponse) => {
      navigateTo("Confirmation", null, false, {data: submitResponse.data[0].data});
    }, (reason) => showOrderCreationErrorDialog("Error", reason), null, DataModes.ADD);
  }
}

export function getPageOptions() {
    return {
      auth: [AuthType.CUSTOMER, AuthType.LOCATION],
      title: "Add Order",
    };
}
