import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function PieChart(props) {
  return (
    <SVGIcon viewBox="0 0 18 18" {...props} >
      <svg >
        <path
          d="M8.2 18a8.19 8.19 0 005.806-2.401L8.2 9.82V1.645c-4.528 0-8.2 3.66-8.2 8.176S3.672 18 8.2 18zm1.13-9.357H18C18 3.87 14.117 0 9.33 0v8.643zm.46 1.148l5.83 5.753a8.132 8.132 0 002.376-5.753H9.791z"
        />
      </svg>
    </SVGIcon>
  );
}

const SVGPieChart = React.memo(PieChart);
export { SVGPieChart };
