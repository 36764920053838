import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function DollarSignInEllipse(props) {
  return (
    <SVGIcon viewBox="0 0 25 25" {...props} >
      <path
        d="M10.4 24c6.2 0 12.3-5.1 13.4-11.5S20.9 1 14.6 1 2.4 6.1 1.2 12.5 4.1 24 10.4 24z"
        strokeWidth={2}
        fill="none"
        stroke={props.fill}
      />
      <path
        d="M16.7 8.7c-.8-.7-1.7-.9-2.4-.9-1.4 0-2.3.5-2.6 1.4-.2.7.2 1 .9 1.4l1.8.9c1.4.7 2.1 1.6 1.7 3.3-.5 1.9-1.8 3.3-3.8 3.8l-.5 2.1H9.6l.5-1.9c-1-.1-2.1-.4-2.6-1l.6-2.5c.7.7 2 1.2 3.1 1.2 1.3 0 2.2-.5 2.4-1.5.2-.7-.2-1-1-1.4l-1.9-.9c-1.1-.5-1.9-1.4-1.5-3.3.4-1.8 1.9-3.2 4-3.7l.5-1.9H16l-.4 1.8c.9.1 1.8.4 2.3.8l-1.1 2.3z"
        fill={props.fill}
      />
    </SVGIcon>
  );
}

const SVGDollarSignInEllipse = React.memo(DollarSignInEllipse);
export { SVGDollarSignInEllipse };
