import React from "react";
import { Typography } from "common";
import classNames from "core/utils/classNames";
import { SVGCheckWithCircle } from "images/SVGCheckWithCircle";
import { SVGDollarSignInCircle } from "images/SVGDollarSignInCircle";
import { useStyles } from "./PaymentsDueStatusTableCell.styles";

export function PaymentsDueStatusTableCell({ status }) {
  const classes = useStyles();
  const StatusDisplay = function getStatusDisplay() {
    switch (status) {
      case "Pending":
        return <SVGCheckWithCircle className={classes.icon} fill="#FAAD14" stroke="#FFF" />;
      case "Unpaid":
        return <SVGDollarSignInCircle className={classes.icon} />;
      default:
        return null;
    }
  };

  return (
    <Typography className={classNames(classes.root)} component="div" fontSize="large" color="dark">
      {StatusDisplay()}
      {status}
    </Typography>
  );
}
