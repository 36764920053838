import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function X(props) {
  return (
    <SVGIcon viewBox="0 0 357 357" {...props}>
      <path d="M357 35.7L321.3 0 178.5 142.8 35.7 0 0 35.7l142.8 142.8L0 321.3 35.7 357l142.8-142.8L321.3 357l35.7-35.7-142.8-142.8z" />
    </SVGIcon>
  );
}

const SVGX = React.memo(X);
export { SVGX };
