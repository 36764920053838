
import { fetchBuffer } from "lib/util/fetch";
import { buildAPIUrl } from "core/utils/urls";
import { showSnackbar } from "lib/components";
import { getLogger } from "./Logger";
import { closeSnackbar } from "lib/components/Snackbar";

const log = getLogger("lib.util.ReportUtil");

export function downloadText(text, downloadName) {
  downloadAnchor('data:text/plain;charset=utf-8,' + encodeURIComponent(text), downloadName);
}

export function downloadBlob(blob, downloadName) {
  downloadAnchor(window.URL.createObjectURL(blob), downloadName);
}

export function downloadBinary(binary, downloadName) {
  const len = binary.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++)
    bytes[i] = binary.charCodeAt(i);
  const blob = new Blob([bytes.buffer], { type: 'application/octet-stream' });
  return downloadBlob(blob, downloadName);
}

export async function downloadURL(url, downloadName, onCompleteMessageOrFunction, onErrorMessageOrFunction, fetchOptions, snackbarKey) {
  let body;
  if (fetchOptions != null && fetchOptions.body != null)
    body = fetchOptions.body;
  else
    body = [];
  let parsedJson = [];
  if (body.length)
    parsedJson = JSON.parse(body);
  // use fetch buffer instead of fetch when retrieving reports.
  // converts binary data into array buffer.
  let method;
  if (fetchOptions != null && fetchOptions.method != null)
    method = fetchOptions.method;
  else
    method = "post";
  await fetchBuffer({
    url: buildAPIUrl(url),
    method: method,
    data: parsedJson
  }).then(function (response) {
    downloadResponse(downloadName, onCompleteMessageOrFunction, snackbarKey, response.data);
    console.log(response);
  }).catch(reason => {
    if (snackbarKey)
      closeSnackbar(snackbarKey)
    if (onErrorMessageOrFunction)
      callMessageOrFunction(onErrorMessageOrFunction);
    log.debug(reason);
  });
}
function downloadResponse(downloadName, onCompleteMessageOrFunction, snackbarKey, response) {
  if (downloadName == null)
    downloadName = "document.pdf";
  //downloadAnchor(`data:application/octet-stream;base64,${Buffer.from(response).toString("base64")}`, downloadName);
  downloadAnchor(`data:application/octet-stream;base64,${response}`, downloadName);
  if (snackbarKey)
    closeSnackbar(snackbarKey)
  if (onCompleteMessageOrFunction)
    callMessageOrFunction(onCompleteMessageOrFunction);
}

function downloadAnchor(href, downloadName) {
  if (downloadName == null)
    throw new Error("Download name must be specified.");
  const download = document.createElement("a");
  download.href = href;
  download.download = downloadName;
  download.click();
  download.remove();
}

function callMessageOrFunction(messageOrFunction) {
  if (typeof messageOrFunction === "string")
    showSnackbar(messageOrFunction);
  else
    messageOrFunction();
}
