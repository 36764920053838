import React from "react";
import { Table, Label, CheckBox, Panel, Column, TextBox } from "lib/components";
import { getDataFromContext } from "lib/util/ModelUtil";
import { formatCityStateZip } from "lib/util/CityUtil";
import { StatusCell } from "./StatusCell";

export function TableStopDetail(props) {
  let apptInfo = null;
  let actualInfo = null;
  let contactAndScheduledInfo = null;
  if (!props.hideApptInfo)
  {
    apptInfo =
      <Column>
				<TextBox caption={schedEarlyCaption} field="sched_arrive_early" variant="label" fontBold displayType="datetime" format="long" nullDisplayValue="hide" />
				<TextBox caption={schedLateCaption} field="sched_arrive_late" variant="label" fontBold displayType="datetime" format="long" nullDisplayValue="hide" paddingTop={0} marginTop={-4} />
      </Column>
  }
  if (!props.hideActualInfo)
  {
    actualInfo =
			<Column>
				<TextBox caption="Actual arrival" field="actual_arrival" variant="label" fontBold  displayType="datetime" format="long" nullDisplayValue="N/A"/>
				<TextBox caption="Actual departure" field="actual_departure" variant="label" fontBold  displayType="datetime" format="long" nullDisplayValue="N/A" paddingTop={0} marginTop={-4} />
			</Column>
  }
  if(!!props.showContactAndScheduledInfo)
  {
    contactAndScheduledInfo =
      <Panel fillHeight fillRow rowBreakDefault={false}>
          <Panel>
            <Label caption="Contact Information" look="defaultBold4" />
            <TextBox caption="Contact Name" field="contact_name" usePrintableVersion={false} />
            <TextBox caption="Contact Phone" field="phone" usePrintableVersion={false} />
          </Panel>
          <Panel marginLeft={35} width={450}>
            <Label caption="Scheduled Pickup" look="defaultBold4" />
            <TextBox caption={schedEarlyCaption} field="sched_arrive_early" variant="label" fontBold displayType="datetime" format="long" nullDisplayValue="hide" />
            <CheckBox name="checkAppointmentRequired" caption="Appointment Required" field="appt_required" usePrintableVersion={false}/>
            <TextBox caption="Comments" field="comments" usePrintableVersion={false} fillRow />
          </Panel>
      </Panel>
  }
	return (
		<Table {...props} columnHeadingsVisible={false} headerVisible={false} showLinesBetweenRows={false} rowSpacing={0} cellVerticalAlign="top">
			<Column width={32}>
				<StatusCell {...props} />
			</Column>
			<Column>
				<Label field="stop_type_descr" color="primary" />
				<Label field="location_name" nullDisplayValue="hide" paddingTop={0} marginTop={-4} fontBold />
				<Label field="address" nullDisplayValue="hide" paddingTop={0} marginTop={-4} />
				<Label caption={formatCity} paddingTop={0} />
        {contactAndScheduledInfo}
			</Column>
			{apptInfo}
      {actualInfo}
		</Table>
	);
}

function formatCity(context) {
	let data = getDataFromContext(context);
	return formatCityStateZip(data["city_name"], data["state"], data["zip_code"]);
}

function schedEarlyCaption(context) {
	let data = getDataFromContext(context);
	if (!data || data["sched_arrive_late"])
		return "Scheduled to arrive between";
	else
		return "Scheduled to arrive";
}

function schedLateCaption(context) {
  let data = getDataFromContext(context);
	if (!data || data["sched_arrive_early"])
		return "and";
	else
		return "Scheduled to arrive";
}
