import React from "react";
import { Panel, Label, Button, Image } from "lib/components";
import { getCompanySettings } from "lib/util";

export function Congrats() {
  return (
    <Panel contentHAlign="center" >
        <Image image="CheckWithCircle" stroke="green" fill="white" width={36} height={36} />
        <Label caption="Congratulations! Your driver application is complete." look="default6" />
        <Label caption={getCompanySettings().dr_response} margin={12} look="default3"/>
        <Label caption={getCompanySettings().dr_contact_name} margin={12} look="default3"/>
        <Label caption={getCompanySettings().dr_contact_phone} look="default3"/>
        <Label caption={getCompanySettings().dr_contact_email} look="default3"/>
        <Button navigateTo="/" margin={12} width={250} caption="Back to Sign In" look="defaultSubtle2" />
    </Panel>
  )
}
