import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function Reticle(props) {
  return (
    <SVGIcon viewBox="0 0 19 19" {...props}>
      <path d="M9.5 6.045A3.454 3.454 0 006.045 9.5 3.454 3.454 0 009.5 12.955 3.454 3.454 0 0012.955 9.5 3.454 3.454 0 009.5 6.045zm7.72 2.591a7.768 7.768 0 00-6.856-6.857V0H8.636v1.78A7.768 7.768 0 001.78 8.635H0v1.728h1.78a7.768 7.768 0 006.856 6.857V19h1.728v-1.78a7.768 7.768 0 006.857-6.856H19V8.636h-1.78zm-7.72 6.91A6.041 6.041 0 013.455 9.5 6.041 6.041 0 019.5 3.455 6.041 6.041 0 0115.545 9.5 6.041 6.041 0 019.5 15.545z" />
    </SVGIcon>
  );
}

const SVGReticle = React.memo(Reticle);
export { SVGReticle };
