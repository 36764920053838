import * as React from "react";

function MapPinCluster({fill="#137CDD", stroke="#137CDD", ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 51 51" {...props}>
      <g transform="translate(1 1)" fill={fill} fillRule="evenodd">
        <circle
          stroke={stroke}
          fillOpacity={0.5}
          cx={24.5}
          cy={24.5}
          r={24.5}
        />
        <circle cx={24.5} cy={24.5} r={14.5} />
      </g>
    </svg>
  );
}

const SVGMapPinCluster = React.memo(MapPinCluster);
export { SVGMapPinCluster };