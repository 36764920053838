import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function PictureOutlined(props) {
  return (
    <SVGIcon viewBox="0 0 21 19" {...props}>
      <path
        d="M18.796.981H2.1C1.219.981.5 1.701.5 2.58v13.888c0 .88.72 1.599 1.599 1.599h16.697c.88 0 1.599-.72 1.599-1.599V2.58c0-.88-.72-1.599-1.599-1.599zm0 15.483l-.002.002H2.104l-.003-.002v-4.725l4.121-4.214 4.825 5.992a.798.798 0 001.048.134l3.564-2.41 3.137 1.477v3.746zm0-5.758l-2.622-1.081a.797.797 0 00-.933-.027l-3.46 2.334-4.93-6.11a.805.805 0 00-1.173-.032l-3.58 3.662v-6.87l.003-.002h16.69l.003.002.002 8.124zm-4.219-6.488a2.245 2.245 0 00-2.242 2.243 2.245 2.245 0 002.242 2.243A2.245 2.245 0 0016.82 6.46a2.245 2.245 0 00-2.243-2.243zm0 2.887a.644.644 0 11.001-1.289.644.644 0 010 1.289z"
        fillRule="nonzero"
      />
    </SVGIcon>
  );
}

const SVGPictureOutlined = React.memo(PictureOutlined);
export { SVGPictureOutlined };
