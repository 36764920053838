import React from "react";
import { Panel, TextBox, Label, Table } from "lib/components";

export function PastJobs() {
  return(
      <Panel caption="Job Experience" defaultSpacing={10}>
          <Label caption="Please enter your employment history starting with the most recent." look="default3" marginBottom={10} />
          <Table field="emp_history" initialAddRow={true} rowEditor={PanelJobExperience} addCaption="Add Item" filterVisible={false}
              emptyCaption="No jobs have been added." fillRow height="100%" backgroundcolor="transparent" border="none" allowExport={false}
          />
      </Panel>
  )
}

function PanelJobExperience() {
  return (
    <Panel rowBreakDefault={false} defaultSpacing={10}>
        <TextBox field="employer_name" width={400} rowBreak/>

        <TextBox field="address1" width={500} />
        <TextBox field="city" width={250} />
        <TextBox field="state" />
        <TextBox field="zip_code" width={150} rowBreak/>

        <TextBox field="phone" width={250}/>
        <TextBox field="from_date" width={250}/>
        <TextBox field="to_date" width={250} rowBreak/>

        <TextBox field="leave_reason" width={400} />
        <TextBox field="position_held" width={250}/>
        <TextBox field="number_states" />
    </Panel>
  );
}
