import React, {useState} from "react";
import { getLogger, useMountEffect } from "lib/util";
import { getUserSettings, loadAuthenticatedContext } from "lib/util/MainPageContext";
import { PageNotFound } from "lib/util/PageNotFound";
import { evaluateProp } from "lib/components/ComponentUtil";
import { setPageTitle } from "./PageTitle";
import { useHistory } from "./Navigation";
import { AuthType } from "./AuthType";

const log = getLogger("lib.util.AuthenticatedPage");

export function AuthenticatedPage({children, childFunc, pageOptions, childProps}) {
	const [loaded, setLoaded] = useState(false);
  useMountEffect(() => loadSettings(setLoaded));
  useHistory();
  if (!loaded)
    return null;
  if (!isValidUserType(pageOptions)) // don't display something telling them they aren't a valid user type
    return <PageNotFound />
  setPageTitle(pageOptions.title);
  if (children == null && childFunc != null)
    children = React.createElement(childFunc, childProps, null);
	return (
		<React.Fragment>
			{children}
		</React.Fragment>
	);
}

function loadSettings(setLoaded) {
	loadAuthenticatedContext(() => setLoaded(true));
}

export function isValidUserType(pageOptions) {
  let compareTypes = [AuthType.LME];
  if (pageOptions != null && pageOptions.auth != null) {
    let pageType = evaluateProp(null, pageOptions.auth);
    if (pageType === AuthType.ANY)
      return true;
    if (typeof pageType === "string")
      compareTypes = [pageType];
    else
      compareTypes = pageType;
  }
  const userType = getUserSettings().user_type;
  const result = compareTypes.includes(userType);
  log.info("Valid user type: %o   User type: %o   Page types: %o", result, userType, compareTypes);
  return result;
}
