import React, {useState } from "react";
import { InputAdornment, IconButton, Input} from '@material-ui/core';
import {Search, Close} from '@material-ui/icons';
import { getLogger, makeStyles } from "lib/util";

const log = getLogger("lib.components.Table.TableFilterBox");
let styles;

function useStyles() {
  if (styles == null)
    styles = makeStyles("tfb", {
      input: { marginBottom: "6px" }
    });
  return styles;
}

export function TableFilterBox({onChange, placeHolder="Filter"}) {
	const [value, setValue] = useState("");
	let classes = useStyles();
    function changeValue(newValue) {
        log.debug("Filter changed %o", newValue);
        setValue(newValue);
        if (onChange != null)
            onChange(newValue);
    }

    function handleChange(event) {
        changeValue(event.target.value);
    }

    return (
			<Input onChange={handleChange}
				className = {classes.input}
                placeholder={placeHolder}
                value={value}
                startAdornment = { <InputAdornment position="start"> <Search/> </InputAdornment> }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton size="small" disabled={!value} onClick={() => changeValue("")}>
                            <Close />
                        </IconButton>
                    </InputAdornment>
                }
            />
    );
}
