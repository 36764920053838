import React, { useState, useEffect } from "react";
import { Table as MUITable, TableBody, TableContainer } from '@material-ui/core';
import { TableColumnHeadings } from "./TableColumnHeadings";
import { TableHeader } from "./TableHeader";
import { useStyles } from "./TableStyles";
import { TableRow } from "./TableRow";
import { Label, Component, useComponentContext, Spinner } from "lib/components";
import { DataContainer, getDataContainerProps, setDataContextValue } from "lib/components/DataContainer";
import { isEmptyString, getLogger, getDataFromContext, useMountEffect, getCompanySettings } from "lib/util";
import { appendComponentToHierarchy } from "lib/components/ComponentUtil";
import { sortTable } from "./TableSort";
import { getDataListFromContext } from "lib/util/ModelUtil";
import { HierarchyContext } from "../Context";
import { showDialog } from "../Dialog";
import { createTableValidator, setValidatorColumnTotals, warnOnColumnTotals } from "./TableValidator";
import { useUnmountEffect } from "lib/util/Hooks";
import { useRef } from "react";

const log = getLogger("lib.components.Table");
const DEFAULT_VIRTUALIZATION_THRESHOLD = 30;
let userDefaultRowsPerPage;

export function Table({ ...props }) {
  let context = useComponentContext();
  const hierarchy = appendComponentToHierarchy(context, { ...props, usePrintableVersion: true });
  let classes = useStyles();
  let [modelWithFilter, setModelWithFilter] = useState(props.modelName);
  let [filter, setFilter] = useState("");
  let [expandedRowIndex, setExpandedRowIndex] = useState();
  let [sortedColumns, setSortedColumns] = useState([]);
  let [rowProps, setRowProps] = useState([]);
  let [columnTotals, setColumnTotals] = useState(null);

  if (props.onSearchComplete != null) {
    props.onSearchComplete.current = () => { setExpandedRowIndex(null) };
    log.debug("onSearchComplete set to: %o", props.onSearchComplete)
  }
  
  const needToCalcTotals = useRef(false);
  if (columnTotals === null || columnTotals === undefined) {
    needToCalcTotals.current = true;
    columnTotals = {};
    if (props.columnTotalWarnings != null) {
      let x;
      for (x in props.columnTotalWarnings) { columnTotals[x] = 0; }
    }
    if (props.columnTotalDisplaySettings != null) {
      let x;
      for (x in props.columnTotalDisplaySettings) {
        columnTotals[props.columnTotalDisplaySettings[x].field] = 0;
      }
    }
  }
  let tableValidator;
  if (props.unfinishedEditWarning != null || props.minRowsRequiredWarning != null || props.columnTotalWarnings != null || props.autoSave || props.customValidator != null) {
    tableValidator = createTableValidator(props.field, rowProps, props.unfinishedEditWarning, props.minRowsRequiredWarning, columnTotals, props.columnTotalWarnings, props.autoSave);
    context.registerTableValidator(tableValidator);
  }

  let maxRows;
  if (props.maxRows != null) { maxRows = props.maxRows; }
  else { maxRows = -1; }
  let sortToUse = sortedColumns;
  if (props.defaultSort && sortToUse.length === 0)
    sortToUse = props.defaultSort;
  let data;
  let usingSuppliedData = false;
  if (props.data != null) {
    data = props.data;
    usingSuppliedData = true;
  }
  else if (props.field == null) {
    data = getDataListFromContext(context);
  }
  else {
    data = getDataFromContext(context, props.field);
  }
  log.debug("Context %o   Props %o  Data %o", context, props, data);

  if (data && needToCalcTotals.current === true) {
    const updatedColumnTotals = { ...columnTotals };
    for (let x in data) {
      const rowData = data[x];
      updateColumnTotalsForRow("add", rowData, null, updatedColumnTotals, tableValidator);
    }
    needToCalcTotals.current = false;
    setColumnTotals(updatedColumnTotals);
  }
  else if(!data && needToCalcTotals.current == true ) {
    needToCalcTotals.current = false;
  }

  return (
    <Component fillRow fillHeight {...props} className={classes.tableComponent}>
      <DataContainer {...getDataContainerProps(props)} modelName={modelWithFilter} data={data} sortFields={sortToUse}>
        <HierarchyContext.Provider value={hierarchy} >
          <TableGuts {...props}
            sortedColumns={sortToUse} setSortedColumns={setSortedColumns}
            filter={filter} setFilter={setFilter}
            setModelWithFilter={setModelWithFilter}
            rowProps={rowProps} setRowProps={setRowProps}
            columnTotals={columnTotals} setColumnTotals={setColumnTotals}
            tableValidator={tableValidator}
            expandedRowIndex={expandedRowIndex} setExpandedRowIndex={setExpandedRowIndex}
            maxRows={maxRows}
            usingSuppliedData={usingSuppliedData}
          />
        </HierarchyContext.Provider>
      </DataContainer>
    </Component>
  );
}

function getDefaultRowsPerPage() {
  if (userDefaultRowsPerPage == null)
    userDefaultRowsPerPage = getCompanySettings().paginate_rows;
  return userDefaultRowsPerPage;
}

function rowObserved(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting && entry.target.setObserved != null && !entry.target.observed) {
      entry.target.observed = true;
      entry.target.setObserved(true);
      observer.unobserve(entry.target);
    }
  });
}

function TableGuts(props) {
  let context = useComponentContext();
  let classes = useStyles();
  let tBodyRef = React.createRef();
  let [rowObserver, setRowObserver] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(getDefaultRowsPerPage());
  let [virtualizedRowHeight, setVirtualizedRowHeight] = useState();
  let filterDebounce = useRef();

  const handleChangePage = (value, context, props) => {
    setPage(props.items.indexOf(value));
  };

  const handleChangeRowsPerPage = (value) => {
    userDefaultRowsPerPage = value;
    setRowsPerPage(parseInt(value));
    setPage(0);
  };

  useMountEffect(() => {
    setRowObserver(new IntersectionObserver(rowObserved, { root: tBodyRef.current }));
    if (props.initialAddRow)
      addRow(context, props);
    if (props.loadInitialRows != null) {
      props.loadInitialRows(context, props, loadRows);
    }
  });
  useUnmountEffect(() => {
    if (rowObserver != null)
      rowObserver.disconnect();
  });
  useEffect(() => {
    //Don't like doing this.
    if (props.autoAddRow && props.rowProps[0] && !props.rowProps[0].adding) {
      addRow(context, props);
    }
  });
  context.registerComponentPropsCallback(props.componentPropsCallback);
  let containerClass, tableClass;
  if (props.border !== false)
    containerClass = classes.tableContainer;
  else
    containerClass = classes.tableContainerNoBorder;
  if (props.displayAsCards === true) {
    containerClass = classes.tableContainerCards;
    tableClass = classes.tableCards;
  }

  if (props.tableValidator != null && props.customValidator != null) {
    //have to register this with the validator here so we can provide it the table context and not the parent context
    props.tableValidator.customValidator = () => props.customValidator(context, props.rowProps);
  }

  const dataList = context.data.list;
  const rows = buildRowsFromDataList(context, props, dataList, props.filter, props.expandedRowIndex, props.setExpandedRowIndex, props.rowProps, rowObserver, virtualizedRowHeight, setVirtualizedRowHeight);
  log.debug("Guts rows %o     from   %o   %d", rows, context.data, dataList.length);
  const loading = context.data.loading;
  const emptyLabel = getEmptyLabel(props, rows, dataList, loading);
  let containerStyle = { maxHeight: props.maxHeight, minWidth: props.minWidth, minHeight: props.minHeight, marginBottom: props.marginBottom };
  if (props.scrollY === true)
    containerStyle.overflowY = "scroll";

  const setSortedColumns = (newSort) => sortTable(dataList, context.data.setDataList, newSort, props.setSortedColumns, props.setExpandedRowIndex);

  let pagedRows = rows;
  if (props.paginate === true && rowsPerPage > 0 && rows.length > rowsPerPage)
    pagedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  if(props.setActualRecordCount){
    if(context.data.actualRowCount != null){
      props.setActualRecordCount(context.data.actualRowCount);
    }
    else {
      props.setActualRecordCount(dataList.length);
    }
  }

  return (
    <React.Fragment>
      <TableHeader {...props}
        loading={loading}
        rows={rows}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        addRow={() => addRow(context, props)}
        setFilter={(value) => {
          props.setExpandedRowIndex(null);
          handleFilter(context, value, props.setFilter, props.modelName, props.setModelWithFilter, filterDebounce);
          props.setFilter(value);
        }}
        addVisible={props.addVisible}
        columnTotalSettings={props.columnTotalSettings}
        columnTotals={props.columnTotals}
      />
      <TableContainer ref={tBodyRef} className={containerClass} style={containerStyle}>
        <MUITable stickyHeader className={tableClass} {...props.tableProps} >
          <TableColumnHeadings {...props}
            context={context}
            setSortedColumns={setSortedColumns} />
          <TableBody>
            {pagedRows}
          </TableBody>
        </MUITable>
      </TableContainer>
      {emptyLabel}
    </React.Fragment>
  );
}

function handleFilter(context, filter, setFilter, modelName, setModelWithFilter, debounceRef) {
  if (modelName != null && context.data.actualRowCount != null) {
    if (debounceRef.current != null)
      clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      if (filter == null || filter.trim().length === 0)
        setModelWithFilter(modelName);
      else
        setModelWithFilter(modelName + "&adhoc_filter=" + encodeURIComponent(filter));
      debounceRef.current = null;
    }, 500);
  }
  else
    setFilter(filter);
}

function deleteRow(context, props, index) {
  updateColumnTotals("delete", context, props, index);
  context.data.list.splice(index, 1);
  if (context.data.warnings != null) { context.data.warnings.splice(index, 1); }
  if (context.data.stateAccessors != null) { context.data.stateAccessors.splice(index, 1); }
  props.rowProps.splice(index, 1);
  let parentContext = getDataFromContext(context.data.parentContext, props.field);
  if (parentContext) {
    parentContext.splice(index, 1);
    setDataContextValue(context.data.parentContext, props.field, parentContext);
  }
  context.data.setDataList([...context.data.list]);
  if (context.data.warnings != null) { context.data.setWarnings([...context.data.warnings]); }
  if (context.data.stateAccessors != null) { context.data.setStateAccessors([...context.data.stateAccessors]); }
  props.setRowProps([...props.rowProps]);
}

function editRow(context, props, index) {
  props.rowProps[index].editing = true;
  props.rowProps[index].original = { ...context.data.list[index] };
  props.rowProps[index].original.modelData = { ...context.data.list[index].modelData };
  props.setRowProps([...props.rowProps]);
}

function saveRow(context, props, index) {
  log.debug("Saving %o    %o", context, props);
  const updateType = props.rowProps[index].adding === false ? "update" : "add";
  let oldRowData = null;
  if (props.rowProps[index].original != null) { oldRowData = props.rowProps[index].original.modelData; }

  //if saving a new row for the first time, add it at the bottom of the list
  //otherwise, save it whereever it already is in the list
  if (updateType === "add") {
    if (!props.autoSave) { props.rowProps[index].editing = false; }
    props.rowProps[index].adding = false;

    const saveIndex = props.rowProps[index].saveIndex;

    const updatedDataList = [...context.data.list];
    const addedRowData = updatedDataList[index];
    updatedDataList.splice(index, 1);
    updatedDataList.splice(saveIndex, 0, addedRowData);
    context.data.setDataList(updatedDataList);

    if (context.data.warnings != null && context.data.warnings.length > 0) {
      const updatedWarnings = [...context.data.warnings];
      const addedRowWarnings = updatedWarnings[index];
      updatedWarnings.splice(index, 1);
      updatedWarnings.splice(saveIndex, 0, addedRowWarnings);
      context.data.setWarnings(updatedWarnings);
    }

    if (context.data.stateAccessors != null && context.data.stateAccessors.length > 0) {
      const updatedStateAccessors = [...context.data.stateAccessors];
      const addedRowStateAccessors = updatedStateAccessors[index];
      updatedStateAccessors.splice(index, 1);
      updatedStateAccessors.splice(saveIndex, 0, addedRowStateAccessors);
      context.data.setStateAccessors(updatedStateAccessors);
    }

    const updatedRowProps = [...props.rowProps];
    const addedRowProps = updatedRowProps[index];
    updatedRowProps.splice(index, 1);
    updatedRowProps.splice(saveIndex, 0, addedRowProps);
    props.setRowProps(updatedRowProps)
  }
  else {
    context.data.setDataList([...context.data.list]);
    if (!props.autoSave) { props.rowProps[index].editing = false; }
    props.rowProps[index].adding = false;
    props.setRowProps([...props.rowProps])
  }
  updateColumnTotals(updateType, context, props, index, oldRowData);
}

function addRow(context, props, addIndex = 0, saveIndex = props.rowProps.length) {
  if (props.maxRows >= 0 && props.rowProps.length >= props.maxRows) {
    showDialog("Maximum Reached", "Only " + props.maxRows + " " + (props.maxRows === 1 ? "entry is" : "entries are") + " allowed.");
    return;
  }

  if (props.getAddSaveIndexes != null) {
    const indexes = props.getAddSaveIndexes(props.rowProps);
    addIndex = indexes.addIndex;
    saveIndex = indexes.saveIndex;
  }

  const updatedDataList = [...context.data.list];
  const newRowData = (props.getNewRowData != null) ? props.getNewRowData() : { modelData: {} };
  updatedDataList.splice(addIndex, 0, newRowData);

  let parentContext = getDataFromContext(context.data.parentContext, props.field);
  if (parentContext) {
    parentContext.splice(addIndex, 0, newRowData);
    setDataContextValue(context.data.parentContext, props.field, parentContext);
  }
  context.data.setDataList([...updatedDataList]);

  if (context.data.warnings != null) {
    const updatedDataWarnings = [...context.data.warnings];
    updatedDataWarnings.splice(addIndex, 0, {});
    context.data.setWarnings(updatedDataWarnings);
  }

  if (context.data.stateAccessors != null) {
    const updatedStateAccessors = [...context.data.stateAccessors];
    updatedStateAccessors.splice(addIndex, 0, {});
    context.data.setStateAccessors(updatedStateAccessors);
  }

  props.rowProps.splice(addIndex, 0, { editing: true, adding: true, saveIndex: saveIndex });
  props.setRowProps([...props.rowProps]);
}

function loadRows(context, props, rows, adding, editing, addStartIndex = 0, saveStartIndex = props.rowProps.length) {
  if (rows === null || rows === undefined || rows.length === 0) {
    return;
  }
  if (props.maxRows >= 0 && (props.rowProps.length + rows.length) >= props.maxRows) {
    showDialog("Maximum Reached", "Only " + props.maxRows + " " + (props.maxRows === 1 ? "entry is" : "entries are") + " allowed.");
    return;
  }

  if (props.getAddSaveIndexes != null) {
    const indexes = props.getAddSaveIndexes(props.rowProps);
    addStartIndex = indexes.addIndex;
    addStartIndex = indexes.saveIndex;
  }

  const updatedDataList = [...context.data.list]
  let addIndex = addStartIndex - 1;
  for (let x = 0; x < rows.length; x++) {
    updatedDataList.splice(++addIndex, 0, { ...rows[x] });
  }
  context.data.setDataList([...updatedDataList]);

  if (context.data.warnings != null) {
    const updatedDataWarnings = [...context.data.warnings];
    for (let x = 0; x < rows.length; x++) {
      updatedDataWarnings.splice(addStartIndex, 0, {});
    }
    context.data.setWarnings(updatedDataWarnings);
  }

  if (context.data.stateAccessors != null) {
    const updatedStateAccessors = [...context.data.stateAccessors];
    for (let x = 0; x < rows.length; x++) {
      updatedStateAccessors.splice(addStartIndex, 0, {});
    }
    context.data.setStateAccessors(updatedStateAccessors);
  }

  let saveIndex = saveStartIndex - 1;
  /*
  for (let i = 0; i < props.rowProps.length; i++) {
    props.rowProps[i].editing = false;
    props.rowProps[i].adding = false;
    props.rowProps[i].saveIndex = ++saveIndex;
  }
   */
  for (let x = 0; x < rows.length; x++) {
    saveIndex++;
    props.rowProps.push({ editing: editing, adding: adding, saveIndex: saveIndex });
  }
  props.setRowProps([...props.rowProps]);
}

function addToRowProps(props, editing, adding) { props.rowProps.push({ editing: editing, adding: adding }); }

function cancelEdit(context, props, index) {
  let data = [...context.data.list];
  let parentContext = getDataFromContext(context.data.parentContext, props.field);
  if (props.rowProps[index].adding) {
    data.shift();
    props.rowProps.shift();
    if (parentContext) {
      parentContext.splice(index, 1);
      setDataContextValue(context.data.parentContext, props.field, parentContext);
    }
  }
  else {
    props.rowProps[index].editing = false;
    data[index] = props.rowProps[index].original;
    if (parentContext) {
      parentContext.splice(index, 1, props.rowProps[index].original);
      setDataContextValue(context.data.parentContext, props.field, parentContext);
    }
  }
  context.data.setDataList(data);
  context.data.setWarnings(null);
  props.setRowProps([...props.rowProps]);
}

function updateColumnTotals(type, context, props, index, oldRowData) {
  const rowData = context.data.list[index].modelData;
  const updatedColumnTotals = updateColumnTotalsForRow(type, rowData, oldRowData, { ...props.columnTotals }, props.tableValidator);
  props.setColumnTotals(updatedColumnTotals);
}

function updateColumnTotalsForRow(type, rowData, oldRowData, updatedColumnTotals, tableValidator) {
  for (let x in updatedColumnTotals) {
    let floatValue = parseFloat(rowData[x]);
    if (isNaN(floatValue)) { floatValue = 0; }
    if (type === "add") { updatedColumnTotals[x] = updatedColumnTotals[x] + floatValue; }
    else if (type === "update") { updatedColumnTotals[x] = updatedColumnTotals[x] - oldRowData[x] + floatValue; }
    else if (type === "delete") { updatedColumnTotals[x] = updatedColumnTotals[x] - floatValue; }
  }

  if (tableValidator != null) {
    setValidatorColumnTotals(tableValidator, updatedColumnTotals);
    warnOnColumnTotals(tableValidator);
  }

  return updatedColumnTotals;
}

function buildRowsFromDataList(context, props, dataList, stateFilter, expandedRowIndex, setExpandedRowIndex, rowProps, rowObserver, virtualizedRowHeight, setVirtualizedRowHeight) {
  let filterValue = stateFilter;
  if (props.filter != null)
    filterValue = props.filter;
  let bypassFilter = isEmptyString(filterValue);
  let passFunction = dataPassesFilter;
  if (props.dataPassesFilter)
    passFunction = props.dataPassesFilter;
  let result = [];
  const needToAddRowProps = (props.usingSuppliedData === true || props.autoSave === true) && props.rowProps.length < dataList.length;
  const virtualizationThreshold = props.virtualizationThreshold || DEFAULT_VIRTUALIZATION_THRESHOLD;
  const virtualized = virtualizationThreshold > 0 && dataList.length > virtualizationThreshold;
  for (let i = 0; dataList != null && i < dataList.length; i++) {
      if (bypassFilter || passFunction(filterValue, dataList[i].modelData)) {

        if (needToAddRowProps) { addToRowProps(props, props.autoSave, false); }

        if (props.rowEditor && rowProps[i] == null) { addToRowProps(props, false, false); }

        if (props.rowProps[i] != null) {
          if (props.rowProps[i].original === null || props.rowProps[i].original === undefined) {
            props.rowProps[i].original = { ...dataList[i] };
            props.rowProps[i].original.modelData = { ...dataList[i].modelData };
          }
          props.rowProps[i].saveRowWithValidation = () => saveWithValidation(context, i, () => saveRow(context, props, i));
          if (props.customRowValidator != null) { props.rowProps[i].customRowValidator = () => props.customRowValidator(context, props, i); }
        }

        let rowKey;
        if (props.rowsUseTimestampId === true)
        {
          let rowData = context.data.list[i];
          if (rowData.modelData != null)
          { rowData = rowData.modelData; }
          if (rowData.tableRowKey == null)
          {
            rowKey = 'row-' + new Date().getTime() + Math.random();
            rowData.tableRowKey = rowKey;
          }
          else
          { rowKey = rowData.tableRowKey; }
        }
        else
        { rowKey = `row-${i}`; }

        result.push(<TableRow key={rowKey}
          virtualized={virtualized}
          observer={rowObserver}
          virtualizedHeight={virtualizedRowHeight}
          heightCallback={setVirtualizedRowHeight}
          rowIndex={i}
          showLinesBetweenRows={props.showLinesBetweenRows}
          expandComponent={props.expandComponent}
          expandProps={props.expandProps}
          rowProps={rowProps}
          tableProps={props}
          rowEditor={props.rowEditor}
          rowDisplayPanel={props.rowDisplayPanel}
          children={props.children}
          autoSave={props.autoSave}
          deleteRow={() => deleteRow(context, props, i)}
          editRow={() => editRow(context, props, i)}
          saveRow={() => saveRow(context, props, i)}
          cancelEdit={() => cancelEdit(context, props, i)}
          expandedRowIndex={expandedRowIndex} setExpandedRowIndex={setExpandedRowIndex}
          rowIndexAfterFilter={result.length}
          showCancel={props.showCancel}
          saveCaption={props.saveCaption}
          showSave={props.showSave}
          showTools={props.showTools}
          backgroundcolor={props.backgroundcolor}
          showButtonPanel={props.showButtonPanel}
          customValidationMethod={props.customValidationMethod}
        />);
      }
  }
  return result;
}

function saveWithValidation(context, rowIndex, saveMethod) {
  if (context.validateForm(rowIndex)) {
    saveMethod();
    return true;
  }
  else { return false; }
}

function dataPassesFilter(filter, data) {
  if (isEmptyString(filter))
    return true;
  let filterLower = filter.toLowerCase();
  for (let name in data) {
    if (typeof (data[name]) === "string") { // really should handle other data types
      let compValue = data[name].toLowerCase();
      if (compValue.indexOf(filterLower) >= 0)
        return true;
    }
  }
  return false;
}

function getEmptyLabel(props, rows, dataList, loading) {
  log.debug("getEmptyLabel %o %o", dataList, rows);
  if (loading)
    return (<Spinner size={32} style={{ marginLeft: "50%", padding: -16, marginTop: 24 }} />)
  else if (rows.length === 0) {
    if (props.unsearchedCaption != null && dataList === undefined)
      return <Label marginTop={28} caption={props.unsearchedCaption} align="center" vAlign="top" fillRow />
    else if (props.emptyCaption != null)
      return <Label marginTop={28} caption={props.emptyCaption} align="center" vAlign="top" fillRow />
  }
  return null;
}
