import React from "react";
import { Panel, TextBox, Switch } from "lib/components";

export function PersonalInfo(props) {
  return(
      <Panel caption="Personal Information" variant="form" rowBreakDefault={false} defaultSpacing={10} {...props}>
          <TextBox field="driver_fname" width={300} />
          <TextBox field="driver_lname" width={300} />
          <TextBox field="driver_initial" width={75} rowBreak/>

          <TextBox field="driver_address" width={400} />
          <TextBox field="driver_city" width={200} />
          <TextBox field="driver_state" width={200} />
          <TextBox field="driver_zip" width={100} rowBreak/>

          <TextBox field="driver_email" width={400}/>
          <TextBox field="driver_phone" width={200}/>
          <TextBox field="driver_cell_phone" width={175} />
          <TextBox field="fax" width={200} rowBreak/>

          <TextBox field="birth_date" width={150}/>
          <TextBox field="social_security_no" width={300} rowBreak/>

          <Switch field="citizen" leftLabel="No" rightLabel="Yes" rowBreak/>
          <Switch field="right_to_work" leftLabel="No" rightLabel="Yes" rowBreak/>
      </Panel>
  )
}
