import { PATHS } from "core/config/urls";
import { buildUrl, parseUrl } from "./urls";
import {
  SESSION_STORAGE_LOGIN_TOKEN,
  SESSION_STORAGE_TEMP_TOKEN,
  REFRESH_AUTH_TOKEN_HEADER,
  HTTP_RESPONSE_STATUS_FORBIDDEN,
  HTTP_RESPONSE_STATUS_NO_CONTENT,
  SESSION_STORAGE_USER_TYPE
} from "./constCapsule";

/**
 * Gets the incoming token from the header and rehydrate the sessions token property.
 *
 * @param {object} response
 * @returns
 */
function refreshAuthToken(response) {
  const token = response.headers[REFRESH_AUTH_TOKEN_HEADER];
  if (token != null)
    sessionStorage.setItem(SESSION_STORAGE_LOGIN_TOKEN, token);
}

/**
 * Removes the different types of auth tokens from session storage and redirects
 * the user to login page.
 *
 * @param {object} [{ redirect = true }={}] Options to alter the default behavior
 * @returns {undefined}
 */
function revokeAuthToken({ redirect = true } = {}) {
  sessionStorage.clear();
  if (!redirect) return;
  const { pathname = null } = parseUrl();
  if (pathname !== PATHS.LOGIN) {
    window.location.href = buildUrl({
      path: PATHS.LOGIN,
      queryParams: { invalidToken: true }
    });
  }
}

/**
 * Examines the response status and handles the different methods
 * using auth handler methods related to auth token.
 *
 * @export
 * @param {object} response The HTTP response object
 */
export function handleAuthToken(response) {
  switch (response.status) {
    case HTTP_RESPONSE_STATUS_FORBIDDEN:
      revokeAuthToken();
      break;
    case HTTP_RESPONSE_STATUS_NO_CONTENT:
      return;
    default:
      refreshAuthToken(response);
      break;
  }
}

/**
 * Retrieves the a users auth token from session storage if possible. Otherwise
 * returns null.
 *
 * @export
 * @returns {string} Auth token
 */
export function getAuthToken() {
  return (
    sessionStorage.getItem(SESSION_STORAGE_LOGIN_TOKEN) ||
    sessionStorage.getItem(SESSION_STORAGE_TEMP_TOKEN) ||
    null
  );
}
