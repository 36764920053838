import React from "react"
import { SVGIcon } from "lib/util/SVGIcon"

function Button(props) {
  return (
    <SVGIcon {...props} >
      <path
        d="M9.17 15.5h5.64l1.14 3h2.09l-5.11-13h-1.86l-5.11 13h2.09l1.12-3zM12 7.98l2.07 5.52H9.93L12 7.98zM20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 18H4V4h16v16z"
      />
    </SVGIcon>
  )
}

const SVGButton = React.memo(Button);
export { SVGButton };
