import React, { useState } from "react";
import { Drawer, Divider, IconButton } from "@material-ui/core";
import { List } from "@material-ui/core";
import { Image } from ".";
import { MainMenuItem } from "./MainMenuItem";
import { getLogger, useMountEffect, fetchModelData, getCompanySettings } from "lib/util";
import { classNames } from "lib/util/classNames";
import { useMainMenuStyles } from "./MainMenuStyles";

const log = getLogger("lib.components.MainMenu");

export function MainMenu({ open, onClose  }) {
	useMountEffect(() => loadMenu(setItems));
	let [items, setItems] = useState();
	const classes = useMainMenuStyles();
	log.debug("Main menu item %o", items);
	const menuItems = [];
	for (let i = 0; items != null && i < items.length; i++)
		menuItems.push(<MainMenuItem key={"menu-" + i} {...getItemProps(items[i])} onClose={onClose} />);
	return (
		<Drawer className={classNames(classes.root)} variant="persistent" onClose={onClose} open={open}
			classes={{ paper: classes.rootPaper }}>
			<div className={classes.rootHeader}>
				<IconButton onClick={onClose}>
					<Image image="Chevron" direction="left" stroke="#777"/>
				</IconButton>
			</div>
			<Divider />
			<List>
				{menuItems}
			</List>
		</Drawer>
	);
}

function getItemProps(data) {
	return { image: data["image_name"], caption: data["caption"], id: data["page_identifier"], type: data["page_type"], url: data["url"] };
}

function loadMenu(setItems) {
	fetchModelData("lib/menu", null, null, setItems);
	getCompanySettings();
}
