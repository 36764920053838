import React from "react";
import { Tab, Panel, Label, Tabset, Map, useComponentContext } from "lib/components";
import { TableStopDetail } from "./TableStopDetail";
import { PanelAdditionalOrderDetails } from "./PanelAdditionalOrderDetails";
import { getSVGUrl } from "lib/components/Image";
import { getLogger } from "lib/util";
import { TableImagingList } from "lme/imaging/TableImagingList";
import { PanelLTLDetails } from "./PanelLTLDetails";
import { getDataFromContext } from "lib/util/ModelUtil";
import { getMainPageContext } from "../../lib/util/MainPageContext";
import { getDisplayEta } from "./CustomerPortal";

const log = getLogger("lme.ar.CustomerPortal.PanelCustomerOrderDetail");

export function PanelCustomerOrderDetail({unauthenticated = false, setLoading, ...props}) {
  const context = useComponentContext();
  let data = getDataFromContext(context);
	let isLTL = false;
	isLTL = data.is_ltl === true && !unauthenticated;
  let modelName = "ar/customer-order-detail?search={orders.id}";
  let dataProp;
	if (props.field != null) {
    modelName = undefined;
    dataProp = {data};
  }
  const displayEta = getDisplayEta();

  log.debug("Incoming data %o   Model name  %s   Field: %s", data, modelName, props.field);
  return (
		<Panel modelName={modelName} {...dataProp} field={props.field}
			setLoading={setLoading} marginTop={24}
			marginRight={0} marginLeft={-4} autoSearch fillRow
		>
			<Tabset variant="outlined" widthFillWeight={3} noRowBreak marginRight={0} fillHeight minHeight={520}>
				<Tab caption="Stop details" activeImage={{image:"CheckWithCircle", fill:"#FFF", stroke: "#137cdd"}} inactiveImage={{image:"CheckWithCircle", fill:"#FFF", stroke: "#999"}} >
					<Label visible={displayEta} field="eta_label" noRowBreak fontBold fontSize="large" />
					<Label visible={displayEta} field="eta_date_time" displayType="datetime" format="long" fontBold fontSize="large" />
					<TableStopDetail height={300} field="stops" border="none" fillRow />
				</Tab>
				<Tab caption="Map view" image="PinOutlined">
					<Label field="eta_label" noRowBreak fontBold fontSize="large" />
					<Label field="eta_date_time" displayType="datetime" format="long" fontBold fontSize="large" />
					<Map name="map-order-detail" field="positions"
						captionField="sequence" imageField="image" captionColorField="caption_color" tooltipField="descr"
						latField="latitude" longField="longitude"
						setPinProps={setPinProps} clusterPins={false}
						height={400} fillRow fillHeight />
				</Tab>
				<Tab caption="Images" image="PictureOutlined" visible={shouldShowImagesTab}>
					<TableImagingList />
				</Tab>
				<Tab caption="LTL details" image="LtlPackage" visible={isLTL}>
					<PanelLTLDetails />
				</Tab>
			</Tabset>
			<PanelAdditionalOrderDetails isLTL={isLTL} data={data} visible={!unauthenticated}
				marginTop={53} marginLeft={-4} paddingTop={4} minHeight={520 - 53}
				borderTopWidth={1} borderTopColor="#CDCDCD" fillHeight
			/>
		</Panel>
  )
}

function setPinProps(pin) {
	const status = pin.data["status"];
	if (status === "D") {
		pin.image = getSVGUrl("map-pin-filled");
		pin.fontColor = '#FFFFFF';
	}
	else if (status === "A") {
		pin.image = getSVGUrl("map-pin-outlined");
		pin.fontColor = '#000000';
	}
}

function shouldShowImagesTab(context) {
	let data = getDataFromContext(context);
	return data && data["images"] != null;
}
