import React, { useState } from "react";
import { Tabs, Tab, Container, Step, StepLabel, Stepper as MuiStepper, StepConnector, StepButton  } from "@material-ui/core";
import { useTabsetStyles, useStepperStyles } from "./Tabset.styles";
import { Component, Image, Panel, Label, Button, Row } from ".";
import { classNames } from "lib/util/classNames";
import { getChildArray, appendComponentToHierarchy } from "./ComponentUtil";
import { useComponentContext, HierarchyContext } from "./Context";
import { ComponentPropTypes } from "./PropTypes";
import { getLogger } from "lib/util";
import { PATHS } from "core/config/urls";
import { ButtonActions } from "lib/enum/ButtonActions";
import { navigateTo } from "lib/util/Navigation";

const log = getLogger("lib.components.Tabset");

export function Tabset({ selectedIndex, onTabClose, ...props }) {
  const componentContext = useComponentContext();
  const [value, setValue] = useState(0);
  const classes = useTabsetStyles();
  appendComponentToHierarchy(componentContext, props);
  const handleChange = (event, newValue) => {
    event.stopPropagation();
    setValue(newValue);
    if (props.onChange)
      props.onChange(newValue);
  };
  let index = value;
  if (selectedIndex !== undefined)
    index = selectedIndex;
  if (index == null)
    index = 0;
  let tabs = [], tabPanels = [], tabCaptions = [];
  let children = getChildArray(props.children);
  for (let i = 0; i < children.length; i++) {
    let childProps = { ...children[i].props };
    if (!isTabVisible(childProps, componentContext))
      continue;
    let tabIndex = tabPanels.length;
    let tabClasses;
    if (props.variant === 'outlined')
      tabClasses = { root: classNames(classes.tab, { [classes.activeTab]: tabIndex === index }), wrapper: classes.wrapper };
    if (props.variant === 'wizard')
      tabClasses = {root: classNames(classes.wizard)}
    const image = getTabImage(childProps, tabIndex === index);
    let label = childProps.caption;
    tabCaptions.push(label);
    if (childProps.labelComponent != null)
      label = childProps.labelComponent;
    else if (childProps.allowClose === true)
      label = getCloseComponent(childProps, i, onTabClose);
    const tab = <Tab key={"tab-" + tabIndex} label={label} classes={tabClasses} icon={image} />
    tabs.push(tab);
    let name = childProps.name;
    if (name == null)
      name = "tab-panel-" + tabIndex;
    const tabPanel = (
      <TabPanel name={name} className={classes.tabPanel} key={"tab-panel" + tabIndex} value={index} index={tabPanels.length} keepMounted={props.keepMounted} showAsMap={props.showAsMap}
              defaultSpacing={childProps.defaultSpacing} rowBreakDefault={childProps.rowBreakDefault} variant={childProps.variant} modelName={childProps.modelName} dataMode={childProps.dataMode}
              autoSearch={childProps.autoSearch} refreshModelData={childProps.refreshModelData} setRefreshModelData={childProps.setRefreshModelData} fillRow noDataContainer={childProps.noDataContainer}
              setActualRecordCount={childProps.setActualRecordCount}>
        {childProps.children}
      </TabPanel>
    );
    tabPanels.push(tabPanel);
  }

  let tabsetProps, headerClassName = classes.header;
  if (props.variant === 'outlined') {
    tabsetProps = { className: classes.tabs, TabIndicatorProps: { style: { display: "none" } } };
    headerClassName += " " + classes.headerOutlined;
  }
  else if (props.variant === 'wizard') {
    tabsetProps = {className:classes.wizard };
    headerClassName = classes.wizard;
  }

  let content, style;
  if (tabPanels.length === 0 && componentContext.designer != null) {
    if (props.border == null)
      style = { border: "1px solid #E5E5FF" };
    if (props.children == null || props.children.length === 0) {
      content = <span style={{ color: "#E5E5FF", align: "center", paddingLeft: 8, paddingRight: 8, paddingTop: 6 }}>No tabs on {props.name}<br></br>Place Panels here</span>
      style = { ...style, minHeight: 64 };
    }
  } else {
    content = (
      <React.Fragment>
        <Container className={headerClassName} maxWidth={false}>
          <Tabs value={index} variant="scrollable" onChange={handleChange} indicatorColor="primary" textColor="primary" {...tabsetProps} >
            {tabs}
          </Tabs>
          <div className={classes.grow} />
          {props.extraComponent}
        </Container>
        {tabPanels}
      </React.Fragment>
    );
  }
  if(props.variant === "wizard" )
    content = (<WizardStepper steps={tabCaptions} setCurrentStep={props.setCurrentStep} agreed={props.agreed} successPage={props.successPage} onSubmit={props.onSubmit} tabPanelChildren={children} {...props}> {content}  </WizardStepper> );

  return (
    <Component fillRow fillHeight {...props} style={style} className={classes.tabset}>
      <HierarchyContext.Provider value={componentContext.hierarchy}>
        {content}
      </HierarchyContext.Provider>
    </Component>
  );
}

function getCloseComponent(childProps, index, onTabClose) {
  let closeImage = "x";
  if (childProps.closeImage != null)
    closeImage = childProps.closeImage;
  return (
    <Panel>
      <Label caption={childProps.caption} fillRow noRowBreak />
      <Button image={closeImage} imageWidth={10} width={40} paddingLeft={8} onClick={() => {
        if (onTabClose != null)
          onTabClose(index);
      }} />
    </Panel>
  );
}

function isTabVisible(props, componentContext) {
  if (props.visible === false)
    return false;
  else if (typeof props.visible === "function")
    return props.visible(componentContext);
  else
    return true;
}

function makeImageProps(image) {
  if (typeof (image) === "string")
    return { image, width: 24, height: 24, marginRight:6 };
  else
    return { image: image.image, width: 24, height: 24, marginRight: 6, ...image };
}

function getTabImage(colProps, isActive) {
  if (isActive && colProps.activeImage != null)
    return <Image {...makeImageProps(colProps.activeImage)} />
  else if (!isActive && colProps.inactiveImage != null)
    return <Image {...makeImageProps(colProps.inactiveImage)} />
  else if (colProps.image != null) {
    let props = { width: 24, height: 24, ...makeImageProps(colProps.image) }
    if (isActive)
      props.fill = "#137cdd";
    else
      props.fill = "#999";

    return <Image {...props} />
  }
}

function TabPanel(props) {
  log.debug("Tab panel %o", props);
  const children = props.defaultSpacing ? React.Children.map(props.children, (child) =>
        React.cloneElement(child, { ...child.props, style:{marginRight:props.defaultSpacing}  }) ) : props.children
  if(props.keepMounted && !props.showAsMap) {
    return (
      <div className={props.className} style={{display: props.value === props.index ?'flex' : 'none'}}>
        <Panel {...props} >
          {children}
        </Panel>
      </div>
    );
  }
  else {
    if (props.value === props.index)
      return (
          <Panel {...props} >
            {props.children}
          </Panel>
      );
    else
      return null;
  }

}

function WizardStepper(props) {
  let context = useComponentContext();
  const startStep = props.startStep != null ? props.startStep : 0;
  const [currentStep, handleStep, handleContinue, isLastStep, completed, handleComplete, totalSteps, handleBack] = useWizardSteps(startStep, props.steps, props.setCurrentStep, props.setPrevStep, {...props});
  const classes = useStepperStyles();
  const [finishedSuccessfully, setFinishedSuccessfully] = useState(false);
  const SuccessPage = props.successPage || null;
  const handleSubmitComplete = (response) => {
    if(response && response.data && response.data.length > 0 && response.data[0].success === true)
      setFinishedSuccessfully(true);
  }
  let isSubmitEnabled = props.agreed !== false;
  let isContinueEnabled = !isLastStep() && props.continueDisabled !== false;
  let children = getChildArray(props.tabPanelChildren);
  let submitButton;
  let caption = children[currentStep].props.submitCaption || "Continue";
  if(isLastStep()) {
    caption = children[currentStep].props.submitCaption || "Submit";
    if (props.onSubmit != null)
      submitButton = <Button onClick={(event, context, setLoading) => props.onSubmit(event, context, setLoading)} caption={caption} enabled={isSubmitEnabled} width={250} marginLeft={8} look="primaryFilled"/>
    else
      submitButton = <Button action={ButtonActions.SUBMIT} caption={caption} onComplete={handleSubmitComplete} enabled={isSubmitEnabled} width={250} marginLeft={8} look="primaryFilled"/>
  }
  else if(!isLastStep()) {
      const onContinueMethod = children[currentStep].props.onContinue;
      let onClick;
      if (onContinueMethod != null) {
        onClick = (event, context, setLoading) => { onContinueMethod(context, setLoading, () => handleContinue(currentStep)) };
      }
      else {
        onClick = (event, context, setLoading) => { handleContinue(currentStep) };
      }
      const contVisible = props.continueVisible;
      let buttonStyle= contVisible === false?{display:"none"} :{width:250, marginLeft:8};
      submitButton = <Button id="continueButton" onClick={onClick} enabled={isContinueEnabled} caption={caption} style={buttonStyle} look="primaryFilled"/>;  

  }
  let previousButton;
  if (currentStep !== 0) {
   previousButton = <Button onClick={() => { handleBack(); props.setCurrentStep(currentStep-1)}} width={125} enabled={currentStep > 0} caption="Previous" variant="outlined" color="primary" noRowBreak/>
  }
  return (
    finishedSuccessfully === false ?
      <>
        <MuiStepper activeStep={currentStep} className={classes.root} connector={<StepConnector
            classes={{line:classes.line, active:classes.active, completed:classes.completed}} />}>
          {props.steps.map((label, index) => {
            return (
                <Step key={label+index} >
                    <StepButton id="stepButton" onClick={handleStep(index)} completed={completed[index]}>
                        <StepLabel className={classes.label}>{label}</StepLabel>
                    </StepButton>
                </Step>
            );
          })}
        </MuiStepper>
        {props.children}
        <Row className={classes.buttonRow}>
          {previousButton}
          <Button onClick={() => navigateTo(props.cancelPath || PATHS.ROOT )} width={125} caption="Cancel" variant="outlined" color="primary" noRowBreak/>
          {submitButton}
        </Row>
      </>
      :
      <>
      {props.successPage}
      </>
  );
}

function useWizardSteps(initialStep = 0, steps, setParentStep, setPrevStep, props) {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const initialCompleted = {};
  for (let x=0 ; x<initialStep ; x++) {
    initialCompleted[x] = true;  
  }
  const [completed, setCompleted] = useState(initialCompleted);
  const context = useComponentContext();
  const handleStep = (step) => () => {
    if(setPrevStep)
      setPrevStep(currentStep);
    setCurrentStep(step);
    setParentStep(step);
  };
  const children = getChildArray(props.tabPanelChildren);
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[currentStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  const handleContinue = (activeStep) => {
    const validate = context.validateForm();
    if(!validate){
      return;
    }
    handleStep(activeStep); handleComplete(); setParentStep(activeStep+1);
  }
  const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
  };
  const completedSteps = () => {
      return Object.keys(completed).length;
  };
  const handleNext = () => {
      const newActiveStep =
          isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
          : currentStep + 1;
          setCurrentStep(newActiveStep);
          if(setPrevStep)
            setPrevStep(currentStep);
  };
  const handleBack = () => {
    if(setPrevStep)
      setPrevStep(currentStep);
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  };
  const isLastStep = () => {
      return currentStep === totalSteps() - 1;
  };
  const totalSteps = () => {
      return steps.length;
  };

  return [currentStep, handleStep, handleContinue, isLastStep, completed, handleComplete, totalSteps.length, handleBack];
}

Tabset.extPropTypes = {
  ...ComponentPropTypes,
}
