import React from "react";
import { Typography, TableCellList } from "common";
import { format } from "date-fns";
import { formatUSDCurrency } from "core/utils/formatters";
import classNames from "core/utils/classNames";
import { useStyles } from "./OfferHistoryStatusTableCell.styles";
import { SVGCheckWithCircle } from "images/SVGCheckWithCircle";
import { SVGClock } from "images/SVGClock";
import { SVGReplyArrowFilled } from "images/SVGReplyArrowFilled";
import { SVGCancel } from "images/SVGCancel";

export function OfferHistoryStatusTableCell({
  status,
  offerAmount,
  offerDate
}) {
  const classes = useStyles();
  const StatusDisplay = function getStatusDisplay() {
    switch (status) {
      case "Awarded":
        return <SVGCheckWithCircle stroke="white" className={classNames(classes.success)} />;
      case "Declined":
      case "Declined/Lane":
      case "Declined/Equipment":
        return <SVGCancel className={classNames(classes.error)} />;
      case "Expired":
        return <SVGClock className={classNames(classes.error)} />;
      case "Countered":
        return <SVGReplyArrowFilled className={classNames(classes.reply)} />;
      default:
        return null;
    }
  };

  return (
    <TableCellList
      alignContent="between"
      className={classNames(classes.row)}
      row
    >
      <li>
        <Typography
          className={classNames(classes.root)}
          component="div"
          fontSize="large"
          color="dark"
        >
          {StatusDisplay()}
          {status}
        </Typography>
      </li>
      <li style={{ paddingLeft: "0" }}>
        <TableCellList>
          <Typography label component="div" value="Offer" />
          <Typography
            fontWeight="bold"
            fontSize="medium"
            component="div"
            value={formatUSDCurrency(offerAmount)}
          />
          <Typography label component="div" value="Date" />
          <Typography
            fontWeight="bold"
            fontSize="medium"
            component="div"
            value={offerDate}
          />
        </TableCellList>
      </li>
    </TableCellList>
  );
}
