import React, { useState, useEffect, useCallback } from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { DateTimePicker, TextField } from "common";
import { buildAPIUrl } from "core/utils/urls";
import { API_PATHS } from "core/config/urls";
import { fetch, resolveJSONResponse } from "lib/util/fetch";
import { HTTP_REQUEST_POST } from "core/utils/constCapsule";
import classNames from "core/utils/classNames";
import { formatDate, parseDateTimeString } from "core/utils/dateTime";
import { isValid } from "date-fns";
import { Alert } from "@material-ui/lab";
import { useStyles } from "./DispatchConfirmationForm.styles";
import { FormActions } from "./FormActions";
import { SVGCircleCheck } from "images/SVGCircleCheck";

const validationSchemaRequired = Yup.object().shape({
  earliestArrivalDate: Yup.date().nullable(),
  earliestArrivalTimeZone: Yup.string().nullable(),
  arrivalDate: Yup.string(),
  movementId: Yup.string().required(),
  cityStateZip: Yup.string(),
  scheduledDateTime: Yup.date()
    .nullable()
    .typeError("Invalid Date Format")
    .required("SCHEDULED_Date & Time is Required"),
  arrivalDateTime: Yup.date()
    .nullable()
    .typeError("Invalid Date Format")
    .required("Arrival Date & Time Required")
    .when(
      ["earliestArrivalDate", "earliestArrivalTimeZone"],
      (earliestArrivalDate, earliestArrivalTimeZone, schema) =>
        earliestArrivalDate
          ? schema.min(
              earliestArrivalDate,
              `Arrival Date & Time must be on or after ${formatDate(
                earliestArrivalDate,
                "MM/dd/yyyy hh:mm a"
              )} ${earliestArrivalTimeZone}`
            )
          : schema
    ),
  comments: Yup.string()
});
const validationSchemaDeptartureDateTimeRequired = Yup.object({
  departureDateTime: Yup.date()
    .nullable()
    .typeError("Invalid Date Format")
    .min(Yup.ref("arrivalDateTime"), "Departure Date & Time must be later than Arrival Date & Time")
    .required("Departure Date & Time Required")
});
const validationSchemaDeptartureDateTimeNotRequired = Yup.object({
  departureDateTime: Yup.date()
    .nullable()
    .typeError("Invalid Date Format")
    .min(Yup.ref("arrivalDateTime"), "Departure Date & Time must be later than Arrival Date & Time")
});
const validationSchema = (arrivalDateTime) => {
  return arrivalDateTime
    ? validationSchemaDeptartureDateTimeRequired.concat(validationSchemaRequired)
    : validationSchemaDeptartureDateTimeNotRequired.concat(validationSchemaRequired);
};
function Form({ children, className, onChange }) {
  const formik = useFormikContext();
  const onChangeCallback = useCallback(onChange, [onChange]);
  useEffect(() => {
    onChangeCallback(formik);
  }, [formik, onChangeCallback]);
  return (
    <form className={className} onSubmit={formik.handleSubmit}>
      {formik.status ? <Alert severity="error">{formik.status.msg}</Alert> : null}
      {children}
    </form>
  );
}

export function DispatchConfirmationForm({
  movementId,
  onClose,
  scheduledDateTime,
  arrivalDateTime,
  arrivalDateTimeFlag,
  setArrivalDateTime,
  earliestArrivalDate,
  earliestArrivalTimeZone,
  stopId
}) {
  const classes = useStyles();
  const [submitButtonValue, setSubmitButtonValue] = useState("Arrival");
  useEffect(() => {
    if (arrivalDateTime) setSubmitButtonValue("Dispatch");
  }, [arrivalDateTime]);
  async function handleSubmit(values, helpers) {
    try {
      const response = await fetch({
        url: buildAPIUrl(API_PATHS.CARRIER_DISPATCH),
        method: HTTP_REQUEST_POST,
        data: values
      });
      const { validation } = resolveJSONResponse(response);
      const validationRules =
        response.data.validation && validation.ruleList && validation.ruleList.length
          ? validation.ruleList
          : [];
      if (validationRules.length > 0) {
        helpers.setStatus({ msg: validationRules.map((rule) => rule.message) });
        return;
      }
      setArrivalDateTime(null);
      helpers.resetForm({});
      onClose();
    } catch (error) {
      helpers.setSubmitting(false);
    }
  }
  function handleOnChange({
    values: { arrivalDateTime: formArrivalDateTime = "", departureDateTime } = {}
  } = {}) {
    if (!arrivalDateTime && isValid(parseDateTimeString(formArrivalDateTime))) {
      if (isValid(parseDateTimeString(departureDateTime))) {
        setSubmitButtonValue("Dispatch");
      } else {
        setSubmitButtonValue("Arrival Only");
      }
    }
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        movementId,
        scheduledDateTime,
        earliestArrivalDate,
        earliestArrivalTimeZone,
        arrivalDateTime,
        departureDateTime: null,
        comments: "",
        stopId,
        arrivalDateTimeFlag
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(arrivalDateTime)}
    >
      {({ status }) => {
        return (
          <Form className={classes.form} onChange={handleOnChange}>
            <DateTimePicker
              name="scheduledDateTime"
              label="Scheduled Arrival Time"
              fullWidth
              disabled
            />
            <DateTimePicker
              name="arrivalDateTime"
              label="Arrival Date & Time"
              fullWidth
              disabled={!!arrivalDateTime}
              className={classNames(arrivalDateTime && classes.success)}
              keyboardIcon={arrivalDateTime && <SVGCircleCheck fill="green"/>}
            />
            <DateTimePicker name="departureDateTime" label="Departure Date & Time" fullWidth />
            <TextField name="comments" label="Comments" fullWidth multiline rows={3} />
            <FormActions
              onCancel={onClose}
              submitButtonValue={submitButtonValue}
              cancelId="dispatchConfCancel"
              submitId="dispatchConfSubmit"
              submitButtonVisible={!(status && status.msg)}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
