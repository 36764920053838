import React from "react";
import { Map, useComponentContext } from "lib/components";
import { getDataFromContext } from "lib/util";
import { getSVGUrl } from "lib/components/Image";

export function PanelMap(props)
{
  const context = useComponentContext();
  let data = getDataFromContext(context);
  const mapData = [];
  if (data != null && data.stops != null)
  {
    for (let x=0 ; x<data.stops.length ; x++)
    {
      const stop = data.stops[x];
      mapData.push({sequence: x+1, description: stop.location_name, latitude: stop.latitude, longitude: stop.longitude})
    }
  }
  return(
      <Map {...props}
        name="routeMap"
        data={mapData}
        captionField="sequence"
        tooltipField="description"
        latField="latitude"
        longField="longitude"
        setPinProps={setPinProps}
        clusterPins={false}
      />
  );
}

function setPinProps(pin)
{
  pin.image = getSVGUrl("map-pin-outlined");
  pin.fontColor = '#000000';
}
