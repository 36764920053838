import React from "react";
import { TableCellList, Typography } from "common";

export function AdditionalStopsDetailsTableCell({
  additionalPickups,
  additionalDropoffs
}) {
  return (
    <TableCellList row>
      <li>
        <Typography label component="div" value="Additional Pickups" />
        <Typography
          fontWeight="bold"
          fontSize="medium"
          component="div"
          value={additionalPickups}
        />
      </li>
      <li>
        <Typography label component="div" value="Additional Dropoffs" />
        <Typography
          fontWeight="bold"
          fontSize="medium"
          component="div"
          value={additionalDropoffs}
        />
      </li>
    </TableCellList>
  );
}
