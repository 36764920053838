import React from "react";
import { Panel, Label, TextBox, Table, Column, Button, DisplayTypes, useComponentContext } from "lib/components";
import { getLogger, getDataFromContext } from "lib/util";
import { ShareOrderButton } from "./ShareOrder";
import { BOLButton } from "./BOL";

const log = getLogger("lme.ar.CustomerPanel.PanelAdditionalOrderDetails");

export function PanelAdditionalOrderDetails({ isLTL, data, ...props }) {
  let bolButton;
  if (isLTL)
    bolButton = <BOLButton orderId={data["orders.id"]} noRowBreak />
  const context = useComponentContext();
  const detailData = getDataFromContext(context);
  return (
    <Panel fillRow {...props} backgroundColor="white">
      <Label caption="Additional details" fontSize="large" fontBold fillRow noRowBreak />
      {bolButton}
      <Button image={{ image: "Mail" }} href={getMailTo} color="primary" tooltip="Send an email to ask a question about this order" visible={mailVisible} noRowBreak />
      <ShareOrderButton orderIdField="orders.id" />
      {getAdditionalDetailsHeader(isLTL, data, detailData)}
      <Panel fillRow>
        <Label caption="Reference numbers" />
        <Table field="reference_numbers" emptyCaption="No reference numbers available at this time"
          showLinesBetweenRows={false} headerVisible={false} alternateRowColor border="none" fillRow >
          <Column field="order_sequence" caption="Stop" align="right" />
          <Column field="description" caption="Description" />
          <Column field="reference_number" caption="Numbers" align="right" />
          <Column field="weight" caption="Weight" displayType={DisplayTypes.INTEGER} align="right" />
          <Column field="pieces" caption="Pieces" displayType={DisplayTypes.INTEGER} align="right" />
        </Table>
      </Panel>
    </Panel>
  );
}

function getMailTo(context) {
  const data = getDataFromContext(context);
  if (data != null) {
    let address = data["email_address"];
    if (address != null) {
      let subject = "Question about order #" + data["orders.id"];
      if (address != null)
        return "mailto:" + encodeURIComponent(address) + "?subject=" + encodeURIComponent(subject);
    }
  }
  return null;
}

function mailVisible(context) {
  return getMailTo(context) != null;
}

function getAdditionalDetailsHeader(isLTL, data, detailData) {
  // ideally we would have access to the "parent" datacontext provider here without having to do this.
  // Not sure how I envision the code looking to access the parent.
  // As mentioned in other comments, I don't like that data is passed in here in the first place.
  if (isLTL) {
    let billTo;
    log.debug("LTL %o", data);
    let brLtl = data.is_br_ltl === true;
    if (data.is_asset_ltl === true) {
      billTo = (
        <Panel marginLeft={0} fillRow>
          <TextBox field="customer.name" caption="Bill to" variant="label" nullDisplayValue="hide" fillRow noRowBreak />
          <TextBox field="billing_method" caption="Billing method" variant="label" nullDisplayValue="hide" fillRow />
        </Panel>
      );
    }
    return (
      <Panel fillRow>
        <Panel fillRow>
          <Label caption="Shipper contact" color="primary" fillRow noRowBreak />
          <Label caption="Consignee contact" color="primary" fillRow />
          <Label caption={data["shipper.contact_name"]} nullDisplayValue="N/A" fontBold fillRow noRowBreak />
          <Label caption={data["consignee.contact_name"]} nullDisplayValue="N/A" fontBold fillRow />
          <Label caption={data["shipper.phone"]} fontSize="small" fillRow noRowBreak />
          <Label caption={data["consignee.phone"]} fontSize="small" fillRow />
        </Panel>
        {billTo}
        <TextBox caption="Last known location" field="last_known_location" variant="label" nullDisplayValue="N/A" usePrintableVersion fillRow noRowBreak />
        <TextBox caption="Carrier" field="payee.name" variant="label" visible={brLtl} nullDisplayValue="N/A" usePrintableVersion fillRow />
      </Panel>
    );
  } else {
    let returnTemp = null;
    if(detailData != null && detailData["orders.return_temp"] != null) {
      returnTemp = (
        <Panel fillRow >
        <Label caption="Reported Temp" color="primary" fillRow />
        <Label field="orders.return_temp" displayType={DisplayTypes.DECIMAL} marginTop={-4} />
      </Panel>    
      );
    }
    return (
      <Panel fillRow>
        <Panel noRowBreak fillRow>
          <Label caption="Tractor" field="tractor" color="primary"   />
          <Label field="tractor" marginTop={-4} />
        </Panel>
        <Panel noRowBreak={returnTemp != null} fillRow>
          <Label caption="Trailer" field="trailer" color="primary" />
          <Label field="trailer"marginTop={-4} />
        </Panel>
        {returnTemp}
        <Label caption="Commodity" fontSize="small" />
        <Label field="commodity" nullDisplayValue="N/A" marginTop={-4} />
      </Panel>
    );
  }
}
