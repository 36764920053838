import React from "react";
import { Label, TextBox, SubmitPanel, Button, showSnackbar, showDialog, DisplayTypes } from "lib/components";
import { copyTextToClipboard } from "lib/util/Clipboard";
import { getDataFromContext } from "lib/util";
import { DataModes } from "lib/util/ModelUtil";

export function ShareOrder({orderId, ...props}) {
	return (
		<SubmitPanel
      modelName="portal/customer/email-order"
      dataMode={DataModes.ADD}
      fixedData={{"order_id": orderId}}
      onComplete={(response) => showSnackbar(response[0].response)}
      submitButtonCaption="Send"
      width={500}
      {...props}
    >
			<TextBox caption="To" field="recipients" displayType={DisplayTypes.EMAIL} allowMultipleValues required tooltip="For multiple emails, separate with a comma" fillRow />
			<TextBox caption="Comments (optional)" field="comment" multiline height={120} fillRow />
		</SubmitPanel>
  );
}

export function ShareOrderButton({orderIdField, ...props}) {
  return <Button color="primary" image={{ image: "Link", rotate: 135 }} dropDown={(context) => shareDropDown(context, orderIdField)} dropDownAlign="right" {...props} />
}

function copyLink(context, response) {
  copyTextToClipboard(response.data[0].link)
  showSnackbar("A link to this order been copied to your clipboard.");
}

function shareDropDown(context, orderIdField="order_id") {
  const data = getDataFromContext(context);
  if (data) {
    const order = data[orderIdField];
    return [
      <Label caption="Copy link to clipboard" image={{ image: "Clipboard", fill: "#999", marginRight: 16 }}
        postUrl="api/portal/customer/get-order-link" postFields={{source: orderIdField, target:"order_id"}} onPost={copyLink} />,
      <Label caption="Share link via email" image={{ image: "Mail", fill: "#999", marginRight: 16 }}
        onClick={() => showDialog(`Share order ${order} via email`, <ShareOrder orderId={order} />)} />,
    ];
  };
}
