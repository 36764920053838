import React from "react";
import { Label, Panel } from "lib/components";
import { QuoteStopDetailTable } from "../QuoteStopDetailTable";
import { QuoteSummaryPanel } from "../QuoteSummaryPanel";
import { PaymentTerms } from "../PaymentTerms";
import { QuoteTermsConditions } from "../QuoteTermsConditions";
import { AdditionalDetails } from "../AdditionalDetails";


export function QuoteDetails(props, setAgree, freightItemsTable, handlingReqsTable, currentStep) {
  const tableProps = {...props};
  delete tableProps.data;
  const parentType = "asset-ltl-quote";
  return(
      <Panel caption="Quote Details" submitCaption="SUBMIT ORDER" maxWidth="lg">
        <QuoteSummaryPanel type="Quote" idField="id" hideBOL={true} hideShareOrder={true} carrierVisible={false} stopsVisible={false} ratingDetailsVisible={false} disclaimerVisible={false} transitInfoPanelProps={{marginTop: 25, padding: 0}} noRowBreak />
        <Panel fillRow>
          <QuoteTermsConditions setAgree={setAgree} />
          <Label caption="Pickup and Delivery" look="defaultBold4" fillRow />
          <QuoteStopDetailTable field="stops" parentType={parentType} border={false} fillRow currentStep={currentStep} {...tableProps}  />
          <AdditionalDetails parentType={parentType} usePrintableVersion={false}/>
          {freightItemsTable}
          <PaymentTerms usePrintableVersion />
          <PanelHandlingReqs {...tableProps} table={handlingReqsTable}/>
        </Panel>
      </Panel>
  )
}

export function PanelHandlingReqs(props) {
  return (
    <Panel rowBreakDefault={false} {...props} fillRow fillHeight>
      {props.table}
    </Panel>
  );
}
