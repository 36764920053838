import React from "react";
import { getChildArray } from "../ComponentUtil";
import { TableRow, TableHead } from "@material-ui/core";
import { Column } from "./Column";
import { RowToolsCell } from "./RowToolsCell";

export function TableColumnHeadings({context, children, sortedColumns, setSortedColumns, columnHeadingsVisible, expandComponent}) {
	if (columnHeadingsVisible !== false && children != null && children.length > 0) {
    children = getChildArray(children);
    if (children.length === 0)
      children = [
        <Column />
      ];
		let visible = [];
		for (let i = 0; i < children.length; i++)
			if (children[i].props != null && isVisible(context, children[i].props.visible))
				visible.push(<Column key={`col-${i}`} columnIndex={i}
					sortedColumns={sortedColumns} setSortedColumns={setSortedColumns}
					{...children[i].props} />);
        if (expandComponent != null)
            visible.push(<RowToolsCell key="expand" noImage/>);
        return (
            <TableHead>
                <TableRow>
                    {visible}
                </TableRow>
            </TableHead>
        );
	}
	else
		return null;
}

function isVisible(context, visProp) {
  if (visProp === false) {
    return false;
  }
  else if (typeof visProp === "function") {
    return visProp(context);
  }
  return true;
}
