import React from "react";
import { AuthPageContainer } from "pages/containers";
import { WebLogin } from "portal/general/login/WebLogin";
import { Form } from "antd";

export function Login(props) {
  const { pageTitle } = props;
  const LoginForm = Form.create({ name: "normal_login" })(WebLogin);
  return (
    <AuthPageContainer pageTitle={pageTitle}>
      <LoginForm {...props} />
    </AuthPageContainer>
  );
}
