import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.branding.colors.headerText || theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
}));
