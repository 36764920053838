import React, { useState } from "react";
import { Panel, TextBox, Switch, Label, Divider, DisplayTypes } from "lib/components";

export function Experience () {
  const items=[{ caption: "Any", value: "Any" }, { caption: "Cross Country", value: "Cross Country" }, { caption: "Regional", value: "Regional" }, { caption: "Short Haul", value: "Short Haul" }, { caption: "City Delivery", value: "City Delivery" }];
  const [showOwnerFields, setShowOwnerFields] = useState(false);
  function handleOwnerChange() {
      setShowOwnerFields((prevOwner) => !prevOwner);
  }
  return(
    <Panel caption="Driver Experience" defaultSpacing={10}>
      <Label caption="Endorsements" look="default3" marginBottom={10} />
      <Switch field="hazmat" noRowBreak />
      <Switch field="doubles" noRowBreak />
      <Switch field="tanks_endorsement" />

      <TextBox field="experience" />
      <TextBox field="licenses" />

      <TextBox field="haultype" items={items} default="Any" width={250} />
      <Divider variant="horizontal" />

      <Switch field="driver_type" onChange={handleOwnerChange} yesValue="O" noValue="C"/>
      <TextBox field="tractor_manufact" width={250} visible={showOwnerFields} noRowBreak />
      <TextBox field="tr_type" width={250} visible={showOwnerFields} noRowBreak />
      <TextBox field="yearmodel" visible={showOwnerFields} displayType={DisplayTypes.INTEGER} />

      <TextBox field="wheelbase" visible={showOwnerFields} noRowBreak />
      <TextBox field="fifth_hgt" visible={showOwnerFields} noRowBreak />
      <TextBox field="rearend" visible={showOwnerFields} noRowBreak />
      <TextBox field="maxrpm" visible={showOwnerFields} displayType={DisplayTypes.INTEGER} noRowBreak />
      <TextBox field="tr_weight" visible={showOwnerFields}  displayType={DisplayTypes.INTEGER} />
    </Panel>
  )
}
