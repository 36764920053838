import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function Ellipsis(props) {
  return (
    <SVGIcon viewBox="0 0 20 20" {...props} >
      <svg>
        <circle cx={3} cy={10} r={1.5} />
        <circle cx={10} cy={10} r={1.5} />
        <circle cx={17} cy={10} r={1.5} />
      </svg>
    </SVGIcon>
  );
}

const SVGEllipsis = React.memo(Ellipsis);
export { SVGEllipsis };
