import React, { useState } from "react";
import { Panel, Button, showProgressSnackbar } from ".";
import { closeDialog } from "./Dialog";
import { getChildArray } from "./ComponentUtil";
import { getLogger } from "lib/util";
import { generateReport } from "lib/util/ReportUtil";

const log = getLogger("lib.components.ReportPanel");

export function ReportPanel(props) {
	let [childValues ] = useState({});

	return (
    <Panel {...props} name="panelReportParam">
      {getChildArray(props.children)}
      <Button name="buttonGenerate" caption="Generate report" color="primary" hAlign="right" marginTop={20}
        onClick={() => runReport(props.title, props.reportName, props.downloadName, childValues)}>
      </Button>
    </Panel>
	);

	function runReport(title, reportName, downloadName, childValues) {
		let reportFilters = createFiltersFromChildren(childValues);
		if (title == null)
			title = "Report";
		if (downloadName == null)
			downloadName = title + ".pdf";
		const customKey = new Date().getTime()+"_"+reportName;
		closeDialog();
		showProgressSnackbar(title, "We are generating your report and it will download automatically.", undefined, undefined, customKey);
		generateReport(reportName, downloadName, reportFilters, customKey);
	}

	function createFiltersFromChildren(childValues) {
		let reportFilters = [];
		Object.entries(childValues).forEach(([childName, child]) => {
			if (child.value != null)
				reportFilters.push(createFilterFromChild(childName, child));
		});
		log.debug("Report filters %o", reportFilters);
		return reportFilters;
	}

	function createFilterFromChild(childName, child) {
		log.debug("Create filter from %o : %o", childName, child);
		let result = { paramName: childName, paramValue: child.value, description: child.props.caption };
		if (result.description != null) {
			if (result.paramValue === true) {
				result.description += ": Yes";
				result.paramValue = "Y";
			}
			else if (result.paramValue === false) {
				result.description += ": No";
				result.paramValue = "N";
			}
			else if (Number.isInteger(result.paramValue) && child.props.items != null) { // combo box
				let selItem = child.props.items[result.paramValue];
				if (selItem.caption) {
					if (selItem.value)
						result.paramValue = selItem.value;
					selItem = selItem.caption;
				}
				result.description += ": " + selItem;
			}
			else
				result.description += ": " + result.paramValue;
		}
		return result;
	}
}
