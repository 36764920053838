import { getFunctionForIdentifier } from "lib/util/DynamicLoader";

/**
 * This function is just a wrapper around Dialog.showServerErrorDialog that uses the dynamically loaded
 * Dialog module.  This prevents a chicken/egg scenario when we encounter errors loading the default
 * page container (which has the Dialog declaration).
 * @param {*} title
 * @param {*} error
 */
export function showError(title, error) {
  console.log("Show error dialog: %o", error);
  const dlg = getFunctionForIdentifier("lib.components.Dialog.showServerErrorDialog");
  if (dlg == null)
    console.log("Dialog has not been initialized yet to display this error [%o]", error);
  else
  {
    let panelTitle = "Error";
    if (title != null) {
      panelTitle = title;
    }
    dlg(panelTitle, error);
  }
}
