import React, { useState, useCallback, useMemo, useContext, createContext, useEffect } from "react";
import { DefaultPageContainer } from "pages/containers";
import { useLocation } from "react-router";
import { Typography } from "common";
import { parseQueryParams } from "core/utils/urls";
import { Container, Button, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { PATHS } from "core/config/urls";
import { useGetOfferQuery } from "./utils";
import { Loading } from "./Loading";
import { OfferDetails } from "./OfferDetails";
import { useStyles } from "./Offer.styles";
import { OfferActions } from "./OfferActions";
import { OfferAlerts } from "./OfferAlerts";
import { OFFER_BROKER_COUNTER } from "core/utils/constCapsule";

export const AlertsContext = createContext({
  setAlerts: () => undefined,
  alerts: []
});

export const CompleteContext = createContext({
  setComplete: () => undefined,
  complete: true
});

export const useAlerts = () => useContext(AlertsContext);
export const useComplete = () => useContext(CompleteContext);

export function Offer({ pageTitle }) {
  const classes = useStyles();
  const location = useLocation();
  const [alerts, setAlerts] = useState([]);
  const [complete, setComplete] = useState(false);
  const { offerId, action } = parseQueryParams(location.search);
  const { data, isFetching } = useGetOfferQuery(offerId, action);
  const memoedAlerts = useMemo(() => alerts, [alerts]);
  const memoedComplete = useMemo(() => complete, [complete]);
  const setAlertsCallback = useCallback(setAlerts, [setAlerts]);
  const setCompleteCallback = useCallback(setComplete, [setComplete]);

  useEffect(() => {
    if (!data && !isFetching)
      setAlerts([{ valid: false, message: `The load is no longer available.` }]);
  }, [data, isFetching, offerId]);

  return (
    <DefaultPageContainer pageTitle={pageTitle}>
      <OfferAlerts alerts={alerts} />
      <Container className={classes.root} maxWidth={false}>
        {isFetching && <Loading />}
        {data && !isFetching && (
          <>
            {action !== OFFER_BROKER_COUNTER && <Typography variant="h6" value={pageTitle} />}
            <OfferDetails offer={data} />
            <CompleteContext.Provider
              value={{ complete: memoedComplete, setComplete: setCompleteCallback }}
            >
              <AlertsContext.Provider
                value={{ alerts: memoedAlerts, setAlerts: setAlertsCallback }}
              >
                <OfferActions offer={data} />
              </AlertsContext.Provider>
            </CompleteContext.Provider>
          </>
        )}
      </Container>
      <Container className={classes.root} maxWidth={false}>
        <Divider className={classes.divider} />
        <Button component={Link} to={PATHS.LOGIN} variant="outlined">
          Sign In
        </Button>
      </Container>
    </DefaultPageContainer>
  );
}
