import React from "react";
import {Panel, Label, useComponentContext} from "lib/components";
import { getCompanySettings } from "lib/util";
import { ReturnHomeButton } from "portal/customer/ReturnHomeButton";
import {getModelDataDisplayValue} from "../../lib/util/ModelUtil";
import {EmailLabel} from "../../common/EmailLabel";

export function OrderConfirmationHeader(props) {
  const WarningLabel = () => {
    const context = useComponentContext();
    const warn = getModelDataDisplayValue(context, "hold_warning");

    if(!warn) return null;
    return <EmailLabel text={warn.text} email={warn.email} subject={warn.subject} linkStyle={{color:'#b13c3c', fontWeight:'bold'}} look="warningBold6" />
  }

  return (
    <Panel fillRow>
      <Panel fillRow noRowBreak>
        <Label caption="Order Confirmation" look="primary12" />
        <WarningLabel />
        <Label caption={getCompanySettings().order_submit_ok} look="subtle2" />
      </Panel>
      <ReturnHomeButton />
    </Panel>
  );
}
