import { AppBar, Toolbar } from "@material-ui/core";
import classNames from "core/utils/classNames";
import React from "react";
import { connect } from "react-redux";
import { getAuthType } from "core/store/selectors/UserSelectors";
import { getDrawerOpen } from "core/store/selectors/UISelectors";
import { openNavDrawer, closeNavDrawer } from "core/store/actions/UIActions";
import { MainMenu } from "lib/components/MainMenu";
import { UserMenu } from "lib/pages/UserMenu";
import { Logo } from "common/Logo";
import { DrawerButton } from "./DrawerButton";
import { useStyles } from "./Nav.styles";
import { isAuthenticated } from "lib/util/api";

function NavPresentation({
  navDrawerOpen,
  dispatchCloseNavDrawer,
  dispatchOpenNavDrawer
}) {
  const classes = useStyles();

  function toggleDrawer() {
    return navDrawerOpen ? dispatchCloseNavDrawer() : dispatchOpenNavDrawer();
  }
  let isAuthed = isAuthenticated();
  return (
    <>
      <AppBar
        color="inherit"
        position="relative"
        elevation={0}
        className={classNames(classes.root, {
          [classes.rootShift]: navDrawerOpen
        })}
      >
        <Toolbar className={classes.toolBar}>
          {isAuthed && (<DrawerButton open={navDrawerOpen} onClick={toggleDrawer} /> )}
          <Logo />
          <div className={classes.grow} />
          <UserMenu/>
        </Toolbar>
      </AppBar>
      {isAuthed && (<MainMenu open={navDrawerOpen} onClose={toggleDrawer} />)}
    </>
  );
}

const mapStateToProps = (state) => ({
  navDrawerOpen: getDrawerOpen(state)
});

const mapDispatchToProps = {
  dispatchOpenNavDrawer: openNavDrawer,
  dispatchCloseNavDrawer: closeNavDrawer
};

export const Nav = connect(mapStateToProps, mapDispatchToProps)(NavPresentation);
