import { makeStyles } from "lib/util";

let styles;

export function useComboBoxStyles() {
  if (styles == null)
    styles = makeStyles("cmb", {
      select: {
        marginTop: "2px",
        height: "38px",
        marginBottom: "2px",
      }
    });
  return styles;
}

