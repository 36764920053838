import React from "react";
import { Panel, Label, Row, Button } from "lib/components";

export function Confirmation(agree, setAgree) {
  const message = "I certify that I personally completed this application for the purpose of employment and that all the information here is true and correct."
  + " I understand that the information in this form will be used and that prior employers will be contacted for purposes of investigation as required by 391.23 "
  + " of the Motor Carriers Safety Regulations, and that a complete background investigation will be done in accordance with federal and state laws."
  const buttonColor = agree ? "green" : "primary";
  const image = agree ? {image:'CheckWithCircle', stroke:'green', fill:"white", width:18, height:18} : null;
  return(
      <Panel caption="Confirmation" >
          <Label caption="Please read the following and agree to the terms." look="default2"/>
          <Row style={{margin:"12px"}}/>
          <Label caption={message} look="default3" style={{whiteSpace:"normal"}}/>
          <Row style={{margin:"12px"}}/>
          <Button caption="I Agree" onClick={()=> setAgree(true)} color={buttonColor} width={120} image={image}/>
      </Panel>
  )
}
