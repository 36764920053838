import React, { useEffect, useRef, useState } from "react";
import { Component, DisplayTypes, useComponentContext } from "lib/components";
import { getLogger } from "lib/util";
import { getDisplayValue, getModelDataDisplayValue, getDataFromContext } from "lib/util/ModelUtil";
import { parseDateWithKeywords, parseTime, formatDateTime, formatDate, formatTime} from "lib/util/Date";
import { PropTypes, getBasicPropTypes, ComponentPropTypes, displayTypeProp } from "lib/components/PropTypes";
import { addDefaultMetaDataProps } from "lib/util/ModelDefaultProps";
import { getFirstSuppliedValue, handleNullDisplayValue, HIDE_NULL, shouldUsePrintableVersion } from "lib/components/ComponentUtil";
import { setDataContextValue } from "lib/components/DataContainer";
import { FormControl } from "lib/components/FormControl";
import { useTextBoxStyles } from "./TextBoxStyles";
import { AutoCompleteInput } from "./AutoCompleteInput";
import { showSnackbar } from "../Snackbar";
import { adornInput } from "./AdornInput";
import { isCombo } from "./TextBoxUtil";
import { validateTextBox } from "./TextBoxValidation";
import { TextBoxAsLabel } from "./TextBoxAsLabel";
import { showDialog } from "../Dialog";
import { closePopup } from "../PopoupContainer";

const log = getLogger("lib.components.TextBox");

export function TextBox({ ...props }) {
  let context = useComponentContext();
  let [stateValue, setStateValue] = useState();
  let [focused, setFocused] = useState();
  let [dropDownVisible, setDropDownVisible] = useState(false);
  let [dropDownIndex, setDropDownIndex] = useState(0);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current)
    {
      isFirstRun.current = false;
      return;
    }

    if(dropDownVisible === false && props.onDropDownInvisible != null)
    {
      props.onDropDownInvisible(context);
    }
    else if(dropDownVisible === true && props.onDropDownVisible != null)
    {
      props.onDropDownVisible(context);
    }
  }, [dropDownVisible]);

  const classes = useTextBoxStyles();
  const componentRef = React.useRef();
  const inputRef = React.useRef();
  const contentRef = React.useRef();
  props = addDefaultMetaDataProps(context, props);
  setComboModelFields(props);
  context.registerComponent(props);
  if (props.field != null)
  { context.setStateAccessor(props.field, () => { return {stateValue, setStateValue, props, handleChange: (context, props, value, setValue) => handleChange(context, props, value, setValue, false)}}); }
  let formatValueFunction;
  if (props.lookupModelSuggestions != null)
    formatValueFunction = props.lookupModelSuggestions.formatValueFunction;
  let value = getFirstSuppliedValue(context, [
    () => getDisplayValue(props.value, props.displayType, props.format, props.lookupModelDisplayField, formatValueFunction),
    stateValue,
    (context) => getModelDataDisplayValue(context, props.field, props.displayType, props.format, props.lookupModelDisplayField, formatValueFunction, props.items),
    (context) => parseDefault(context, props, props.default, props.displayType, formatValueFunction),
    null
  ]);
  const setValue = (value) => {
    setStateValue(value);
  };
  /*If field values need to be saved to hidden field values. ie. DriverRecruiment contact fields*/
  if(props.saveHiddenFieldValue){
    let record = context.getActiveData();
    if (record != null && props.field && value !== null){
      record[props.field] = value;
    }
  }
  const nullValue = props.nullDisplayValue == null ? "--" : props.nullDisplayValue;  //TextBoxAsLabel will display -- properly, but not when rendered as an input.  Maybe should correct.
  if (handleNullDisplayValue(value, nullValue) === HIDE_NULL)
    return null;
  let formControlMessage = getFirstSuppliedValue(context, [
    props.errorMessage,
    (context) => context.getValidationWarning(props.field)
  ]);
  let formControlRolloverMessage = getFirstSuppliedValue(context, [
    props.errorRolloverMessage,
    (context) => context.getValidationRolloverWarning(props.field)
  ]);
  const usePrintable = shouldUsePrintableVersion(context, props);
  log.debug("Props %o  Context %o  Value %o", props, context, value);
  let input;
  if (usePrintable)
    input = <TextBoxAsLabel {...props} value={value} />
  else {
    props = { ...props, dropDownVisible, setDropDownVisible, dropDownIndex, setDropDownIndex };
    input = renderAsInput(context, classes, props, value, setValue, focused, setFocused, componentRef, inputRef, contentRef);
  }

  return (
    <Component {...props}>
      <FormControl  focused={focused} messageVisible={!usePrintable} {...props} componentRef={componentRef} message={formControlMessage} rolloverMessage={formControlRolloverMessage}>
        {input}
      </FormControl>
    </Component>
  );
}

/**
 * Check to see if this is a combo box and if its items are caption/value objects.
 * If so, set its lookupModelDisplayField and lookupModelResultField appropriately.
 * @param {*} props
 */
function setComboModelFields(props) {
  if (isCombo(props)
    && props.items != null
    && props.items.length > 0
    && props.lookupModelDisplayField == null
    && props.lookupModelResultField == null
    && props.items[0].caption != null) {
      props.lookupModelDisplayField = "caption";
      props.lookupModelResultField = "value";
    }
}

function renderAsInput(context, classes, props, value, setValue, focused, setFocused, componentRef, inputRef, contentRef) {
  if (value == null)
    value = "";
  let inputProps = {
    placeholder: props.placeholder,
    name: props.name, // common names, like "email" will tie to the user's auto-complete
    onFocus: (event) => handleFocus(context, props, event, setFocused),
    onBlur: (event) => handleBlur(context, props, event, value, setValue, setFocused),
    value,
    ...props.inputProps
  };
  props.inputRef = inputRef;
  if (context.designer != null)
    inputProps.disabled = true;
  props.focused = focused;
  let input;
  if (props.multiline){
    input = (
      <textarea
        {...props}
        className={classes.textarea}
        {...inputProps}
        disabled={props.disabled}
        onChange={(event) => handleChange(context, props, event.target.value, setValue)}
        />
    );
  }
  else if (props.readMore) {
    input = truncateText(value, props.fullText, props.maxLength);
  }
  else {
    const parentChanged = (event) => handleChange(context, props, event.target.value, setValue);
    input = adornInput(
      <AutoCompleteInput
        {...props}
        parentChanged={parentChanged}
        handleBlurOnDropDownClick={handleBlurOnDropDownClick}
        componentRef={componentRef}
        contentRef={contentRef}
        setValue={setValue}
        inputProps={{ ...inputProps }}
        value={value}
      />, context, props, contentRef, classes, value, parentChanged)
    }
  let error = false;
  //check context.data.warning. map through and check field, if there change class.
  if(context.data && context.data.warnings){
    let warningFields = context.data.warnings.flatMap(x =>
      {
        if (x !== undefined && x !== null)
          return Object.keys(x);
      });
    if(warningFields.includes(props.field)){
      let thisValue = context.data.warnings[context.dataIndex];
      if (thisValue !== null && thisValue !== undefined)
        thisValue = thisValue[props.field];
      if(thisValue !== null && thisValue !== undefined)
        error = true;
    }
  }
  let className = !error ? classes.content: classes.error;
  if (focused && isCombo(props))
    className += " " + classes.comboContainer;
  if (props.multiline)
      className += " " + classes.contentFullHeight
  if (props.readMore)
    className = classes.contentReadMore
  var result = (
    <div ref={contentRef} className={className}>
      {input}
    </div>
  );
  return result;
}

function handleFocus(context, props, event, setFocused) {
  if (props.onFocus == null || props.onFocus(context, props, event) !== false)
    setFocused(true);
  else
    event.preventDefault();
}

function handleBlurOnDropDownClick(context, props, event)
{
  if (props.onBlur == null || props.onBlur(context, props, event) !== false) {
    //setFocused(false);
  }
  if (props.onBlurComplete != null) {
    props.onBlurComplete(context, props, event);
  }
}

function handleBlur(context, props, event, value, setValue, setFocused) {
  if (event.relatedTarget != null)
    closePopup();
  if (value != null && value.trim)
    value = value.trim();
  if (props.dropDownVisible &&
    (event.relatedTarget == null || event.relatedTarget.id == null || (event.relatedTarget.id.indexOf("dropDownRow") < 0
    && event.relatedTarget.id.indexOf("dropDownButton") < 0 && event.relatedTarget.id.indexOf ("addAddress") < 0))) {
      props.setDropDownVisible(false);
      if(props.displayType && props.displayType === "time") //bandaid for time issue
        return;
  }
  //skip validating the entered value if the value was selected from a drop down row
  //this also helps with a timing scenario that occurs when the value is selected from a drop down row via the mouse
  //in that case, data is not saved yet when we reach onBlur(), so validating the input would fail anyway
  //(note that this is not true when the value is selected via the keyboard; in that case we don't leave the field on selection,
  //so onBlur isn't fired until later, when the value has already been saved to the context)
  else if (isCombo(props) || (event.relatedTarget != null && event.relatedTarget.id != null && (event.relatedTarget.id.indexOf("dropDownRow") >= 0 || event.relatedTarget.id.indexOf("dropDownButton") >= 0))) {
    const validation = validateTextBox(context, {...props, validateEmptyOnly: true}, value, event);
    log.debug("Validation on blur for %o is %o.  Props %o", value, validation, props);
    if (validation.error != null)
      context.setValidationWarning(props.field, validation.error, validation.rolloverMessage);
    setFocused(false);
    return;
  }

  const validation = validateTextBox(context, props, value, event);
  log.debug("Validation on blur for %o is %o.  Props %o", value, validation, props);
  if (validation.error == null)
    context.setValidationWarning(props.field, null);
  else
    context.setValidationWarning(props.field, validation.error, validation.rolloverMessage);

  if (validation.value != null)
    setValue(validation.value);
  if (props.onBlur == null || props.onBlur(context, props, event) !== false)
      setFocused(false);
  else
    context.setValidationWarning(props.field, validation.error, validation.rolloverMessage);

  setDataContextValue(context, props.field, validation.contextValue);

  if (props.onBlurComplete != null)
  { props.onBlurComplete(context, props, event); }
}

export function handleChange(context, props, value, setValue, updateContext = true) {
  log.debug("TextBox change %o  %o", context, value);
  setValue(value);
  if (updateContext) {
    setDataContextValue(context, props.field, value);
  }
  if (context.getValidationWarning(props.field) != null) {
    if (value != null) {
      const validation = validateTextBox(context, props, value);
      context.setValidationWarning(props.field, validation.error, validation.rolloverMessage);
    }
    else
    { context.setValidationWarning(props.field, null); }
  }
  if (props.onChange)
    props.onChange(value, context, props);
}

function parseDefault(context, props, value, displayType, formatValueFunction) {
  if (displayType === DisplayTypes.DATE && value) {
    const parsed = parseDateWithKeywords(value);
    if (parsed != null)
      parsed.hasTime = false;
    value = formatDate(parsed);
    updateContextValueFromDefault(context, props.field, parsed);
  }
  else  if (displayType === DisplayTypes.DATETIME && value) {
    const parsed = parseDateWithKeywords(value);
    value = formatDateTime(parsed, true);
    updateContextValueFromDefault(context, props.field, parsed);
  }
  else  if (displayType === DisplayTypes.TIME && value) {
    const parsed = parseTime(value);
    value = formatTime(parsed);
    updateContextValueFromDefault(context, props.field, parsed);
  }
  else if (props.items != null && props.items.length > 0) {
    if (props.items[0].value !== undefined) {
      for (let i = 0; i < props.items.length; i++)
        if (value === props.items[i].value) { // should use lookupModelDisplayField instead
          value = props.items[i].caption;
          updateContextValueFromDefault(context, props.field, props.items[i]);
          return value;
        }
    }
  }
  else if (formatValueFunction != null) {
    updateContextValueFromDefault(context, props.field, value);
    return getDisplayValue(value, displayType, props.displayFormat, props.lookupModelDisplayField, formatValueFunction);
  }
  if(displayType !== DisplayTypes.DATETIME && displayType !== DisplayTypes.DATE && displayType !== DisplayTypes.TIME)
    updateContextValueFromDefault(context, props.field, value);
  return value;
}

function updateContextValueFromDefault(context, field, value) {
  if (field != null) {
    let record = context.getActiveData();
    if (record != null)
      record[field] = value;
  }
}

function truncateText(value, fullText, maxLength=75) {
  if ((value == null || value === "") && fullText.length > maxLength)
    return (<p>{fullText.substring(0, maxLength) + "..."}<a onClick={() => showDialog(null, fullText)}>Read More</a></p>)
  else if ((value == null || value === "") && fullText.length <= maxLength)
    return (<p>{fullText}</p>);
}

TextBox.propTypes = {
  ...getBasicPropTypes(TextBox.extPropTypes)
}

TextBox.extPropTypes = {
  ...ComponentPropTypes,
  caption: { type: PropTypes.string },
  captionVisible: { type: PropTypes.bool, defaultValue: true },
  displayType: displayTypeProp,
  default: { type: PropTypes.string },
  field: { type: PropTypes.string },
  format: { type: PropTypes.string },
  fontBold: { type: PropTypes.bool },
  multiline: { type: PropTypes.bool },
  nullDisplayValue: { type: PropTypes.string },
  placeholder: { type: PropTypes.string },
  value: { type: PropTypes.string },
  variant: { type: PropTypes.oneOf(["label"]) },
}
