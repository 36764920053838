import React from "react";
import { Panel, Label, TextBox, Button, ComboBox, CheckBox, Table, Image, Map, Tabset, Splitter } from ".";
import { getLogger } from "lib/util/Logger";

const log = getLogger("lib.components.JSONToJSX");

export function JSONToJSX(jsonDef) {
  log.debug("JSON: %o", jsonDef);
  if (jsonDef == null)
    return null;
  if (Array.isArray(jsonDef)) {
    let result = [];
    for (let i = 0; i < jsonDef.length; i++)
      result.push(JSONToJSX(jsonDef[i]));
    return result;
  }
  if (jsonDef.key == null)
    jsonDef.key = jsonDef.name;
  if (isContainer(jsonDef)) {
    let def = { ...jsonDef };
    let children = JSONToJSX(def.children);
    delete def.children;
    if (jsonDef.type === "panel")
      return <Panel {...def} >{children}</Panel>
    else if (jsonDef.type === "tabset")
      return <Tabset {...def} >{children}</Tabset>
  }
  else if (jsonDef.type === "label")
    return <Label {...jsonDef} />
  else if (jsonDef.type === "text")
    return <TextBox {...jsonDef} />
  else if (jsonDef.type === "button")
    return <Button {...jsonDef} />
  else if (jsonDef.type === "combo")
    return <ComboBox {...jsonDef} />
  else if (jsonDef.type === "check")
    return <CheckBox {...jsonDef} />
  else if (jsonDef.type === "table")
    return <Table {...jsonDef} />
  else if (jsonDef.type === "image")
    return <Image {...jsonDef} />
  else if (jsonDef.type === "map")
    return <Map {...jsonDef} />
  else if (jsonDef.type === "tabset")
    return <Tabset {...jsonDef} />
  else if (jsonDef.type === "split")
    return <Splitter {...jsonDef} />
}

export function isContainer(comp) {
  return comp.type === "panel" || comp.type === "tabset";
}
