import * as React from "react";

function MapPinOutlined({gradientColor1="#2C5DD1", gradientColor2="#109BE8", ...props}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 29" {...props}>
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="prefix__a">
          <stop stopColor={gradientColor1} offset="0%" />
          <stop stopColor={gradientColor2} offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M24.297 10.472c0 2.07-.514 4.225-1.53 6.402-.872 1.881-2.132 3.8-3.738 5.709-2.509 2.979-5.318 5.257-6.878 6.417-1.561-1.164-4.37-3.438-6.879-6.417-1.606-1.908-2.866-3.832-3.742-5.713C.514 14.693 0 12.538 0 10.468c0-1.356.312-2.682.933-3.935.612-1.241 1.488-2.366 2.607-3.345A12.758 12.758 0 017.446.86C8.96.29 10.544 0 12.146 0h.008c1.607 0 3.19.29 4.698.86a12.777 12.777 0 013.909 2.328c1.123.979 1.998 2.104 2.604 3.345a8.867 8.867 0 01.932 3.94z"
          fill="url(#prefix__a)"
        />
        <path
          d="M22 10.667c0 1.713-.423 3.496-1.26 5.298-.717 1.557-1.754 3.145-3.077 4.724-2.064 2.466-4.377 4.35-5.661 5.311-1.285-.963-3.597-2.845-5.662-5.311-1.323-1.579-2.36-3.17-3.08-4.727C2.423 14.159 2 12.376 2 10.663c0-1.122.257-2.22.768-3.256a8.934 8.934 0 012.146-2.769 10.49 10.49 0 013.215-1.927A10.9 10.9 0 0111.999 2h.006c1.322 0 2.625.24 3.866.711 1.2.456 2.281 1.104 3.218 1.927.924.81 1.645 1.742 2.143 2.769a7.37 7.37 0 01.768 3.26z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

const SVGMapPinOutlined = React.memo(MapPinOutlined);
export { SVGMapPinOutlined };