import { makeStyles } from "lib/util";

let styles;

export function useSlideoutStyles() {
  if (styles == null)
    styles = makeStyles("sld", {
      shiftDown: {
        marginTop: "60px"
      },
      wrapper: {
        padding: "24px",
        paddingLeft: "40px",
        height: "100%",
        marginBottom: "32px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }
    });
  return styles;
}
