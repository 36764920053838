import React, { useState } from "react";
import { fetchModelData } from "./ModelUtil";
import { getLogger, setLogConfig } from "./Logger";
import { setDeniedItems } from "./Permissions";
import { useMountEffect } from ".";

const log = getLogger("lib.util.MainPageContext");

const mainPageContext = {
  uiContext: {},
  user_settings: {}
};

export function getUIContext() {
  return mainPageContext.uiContext;
}

export function getCompanySettings() {
  if (mainPageContext.company_settings == null)
    return {};
  else
    return mainPageContext.company_settings;
}

export function getUserSettings() {
  return mainPageContext.user_settings;
}

export function getMainPageContext() {
  return mainPageContext;
}


function loadUnauthenticatedContext(onComplete, setLoading, onError) {
  fetchModelData("lme/unauth-settings", null, setLoading, data => {
    if (data == null)
      return;
    if (data.company_settings != null)
      mainPageContext.company_settings = { ...mainPageContext.company_settings, ...data.company_settings };
    if (data.log_config != null)
      setLogConfig(data.log_config);
    if (onComplete)
      onComplete(mainPageContext);
    log.debug("Main context %o", data);
  }, onError);
}

export function loadAuthenticatedContext(onComplete, setLoading) {
  fetchModelData("lme/auth-settings", null, setLoading, (data) => {
    mainPageContext.company_settings = { ...mainPageContext.company_settings, ...data };
    delete mainPageContext.company_settings.license;
    delete mainPageContext.company_settings.user_settings;
    delete mainPageContext.company_settings.permissions;
    if (data.permissions != null)
      setDeniedItems(data.permissions.denied);
    if (data.user_settings != null)
      mainPageContext.user_settings = data.user_settings;
    if (data.app_settings != null)
      mainPageContext.app_settings = { ...mainPageContext.app_settings, ...data.app_settings };
    if (data.driver_settings != null) // need to handle this generically instead of having to specify each element that the model might pass
      mainPageContext.driver_settings = { ...mainPageContext.driver_settings, ...data.driver_settings };
    if (data.customer_settings != null) // need to handle this generically instead of having to specify each element that the model might pass
      mainPageContext.customer_settings = { ...mainPageContext.customer_settings, ...data.customer_settings };
    log.debug("Main context %o", data);
    if (onComplete)
      onComplete(mainPageContext);
  });
}

export function UnauthenticatedSettingsProvider({ children }) {
  let [loaded, setLoaded] = useState(false);
  let [error, setError] = useState(null);
  useMountEffect(() => {
    loadUnauthenticatedContext(() => setLoaded(true), null, (reason) => setError(reason));
  });
  if (error != null) {
    if (error != null && error.errorTag != null)
      error = "There was a problem connecting to the server.\nThe error tag associated with this is " + error.errorTag + ".";
    if (Array.isArray(error) && error.length > 0 && typeof error[0] === "string")
      error = error.join("\n");
    return <h3 style={{ margin: 16 }}><pre>{error}</pre></h3>
  }
  if (loaded)
    return <>{children}</>
  else
    return null;
}
