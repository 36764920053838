import { getCompanySettings } from "./MainPageContext";

export function setPageTitle(value) {
  let name = getCompanySettings().name;
  if (name == null)
    name = "McLeod Software";
  if (value == null)
    value = name;
  else
    value += " - " + name;
  document.title = value;
}
