import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function CircleCheck({stroke="#137CDD", fill="#FFF", ...props}) {
  return (
    <SVGIcon viewBox="0 0 20 20" {...props} >
      <circle cx={9} cy={9} r={9} fill={fill}/>
      <path
        d="M6.857 13a.579.579 0 01-.497-.265L4.075 9.078c-.158-.252-.062-.572.213-.717a.603.603 0 01.783.196l1.91 3.06 7.038-6.463a.613.613 0 01.812-.001.495.495 0 01.001.743L7.29 12.822l-.027.024a.601.601 0 01-.406.154z"
        stroke={stroke}
        fill={stroke}
        strokeWidth={0.5}
      />
    </SVGIcon>
  );
}

const SVGCircleCheck = React.memo(CircleCheck);
export { SVGCircleCheck };
