import { splitLocation, convertMilliToDateTimeObject } from "core/utils/formatters";
import { mapModelDataFromAPI } from "core/utils/mapper";

function convertMilli(milli) {
  return convertMilliToDateTimeObject(milli, "MM/dd/yyyy");
}

const keyMap = {
  net_pay: "balance",
  trailer: "trailer.id",
  driver: "driver.id",
  tractor: "tractor.id",
  order_id: "id",
  pickup_loc: "pickup",
  delivery_loc: "delivery",
  invoice_number: "invoice.id",
  invoice_date: "invoice",
  shipping_date: "shipping",
  check_number: "check.id",
  check_date: "check",
  move_id: "movementId",
  ok2pay_date: "ok2PayDate"
};

const valueFormatters = {
  pickup_loc: splitLocation,
  delivery_loc: splitLocation,
  invoice_date: convertMilli,
  shipping_date: convertMilli,
  check_date: convertMilli,
  ok2pay_date: convertMilli
};

export function mapDeliveredOrders(orders) {
  return mapModelDataFromAPI(orders.data, keyMap, valueFormatters);
}
