export const carrierFilterKeys = [
  "id",
  "pickup.city",
  "pickup.state",
  "pickup.date",
  "delivery.city",
  "delivery.state",
  "delivery.date",
  "commodity.name",
  "commodity.description"
];

export const factoringFilterKeys = [
  "order_id",
  "mcNumber",
  "bolNum",
  "carrierName",
  "invoice.id",
  "deliveryDate.date",
  "shipDate.date",
  "check.date",
  "check.id"
];
