import React from "react";
import { Popover, MenuItem } from "@material-ui/core";

export function Popup({onClose, anchorEl, anchorAlign="left", className, components, disableBackdropClick=false, dropDownForm=false, dropDownOpen=true}) {
  //console.log("Popup %o", anchorEl);
  let content;
  if (Array.isArray(components)) {
    content = [];
    for (let i = 0; components != null && i < components.length; i++)
      content.push(
        <MenuItem key={"popup-item-" + i}
          onClick={(event) => itemClicked(event, components[i].props.onClick, onClose)}>
          {components[i]}
        </MenuItem>);
  }
  else
    content = components;
  const paperProps = dropDownForm ? {style: { width: "100%" }} : {};
	return (
		<Popover
			open={dropDownOpen}
			className={className}
			onClose={onClose}
			anchorEl={anchorEl}
			anchorOrigin={{vertical: 'bottom', horizontal: anchorAlign }}
      transformOrigin={{vertical: 'top', horizontal: anchorAlign }}
      disableBackdropClick={disableBackdropClick}
      PaperProps={paperProps}
    >
			{content}
		</Popover>
	);
}

function itemClicked(event, childClick, onClose) {
	if (childClick && event.target === event.currentTarget) // make sure we don't fire onclick twice
    childClick(event);
  if (onClose != null)
    onClose();
}
