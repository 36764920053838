import React from "react";
import { DisplayTypes, Button } from "lib/components";
import { isCombo, isAutoComplete } from "./TextBoxUtil";
import { Image } from "../Image";
import { toggleDatePicker } from "../DatePicker";

export function adornInput(input, context, props, contentRef, classes, value, parentChanged) {
  const leadingAdornment = getLeadingAdornment(props, classes);
  const trailingAdornment = getTrailingAdornment(context, props, contentRef, classes, value, parentChanged);
  return (
    <React.Fragment>
      {leadingAdornment}
      {input}
      {trailingAdornment}
    </React.Fragment>
  )
}

function getLeadingAdornment(props, classes) {
  if (props.displayType === DisplayTypes.CURRENCY) {
    return <div className={classes.leadingAdornment}>$</div>
  }
  return null;
}

function getTrailingAdornment(context, props, contentRef, classes, value, parentChanged) {
  let lookPrefix = "subtle";
  if (props.focused)
    lookPrefix = "primary";
  if (props.trailingAdornment != null)
    return props.trailingAdornment;
  else if (props.displayType === DisplayTypes.DATE || props.displayType === DisplayTypes.DATETIME)
    return (
      <Button
        image={{ image: "Calendar", width: 16, height: 16 }}
        look={lookPrefix + "NoBorder1"}
        className={classes.trailingAdornment}
        tabIndex={-1}
        onClick={() => toggleDatePicker(contentRef, value, parentChanged, context, props) }
      />
    );
  else if (props.displayType === DisplayTypes.WEIGHT)
    return (
      <Button
        caption="lbs"
        height={34}
        field={props.field + "_uom"}
        look={lookPrefix + "NoBorder1"}
        className={classes.trailingAdornment + " " + classes.uom}
        tabIndex={-1}
        dropDown={["lbs", "kgs"]}
      />
    );
  else if (props.displayType === DisplayTypes.LENGTH)
    return (
      <Button
        caption="ft"
        height={34}
        field={props.field + "_uom"}
        look={lookPrefix + "NoBorder1"}
        className={classes.trailingAdornment + " " + classes.uom}
        tabIndex={-1}
        dropDown={["ft", "in"]}
      />
    );
  else if (props.displayType === DisplayTypes.LOCATION || props.displayType === DisplayTypes.CITY) {
    let tooltip;
    if (props.displayType === DisplayTypes.LOCATION)
      tooltip = "This is a location field.  Start typing a location name, city, state, or zip code to see suggested values.";
    else
      tooltip = "This is a city/state field.  Start typing a city, state, or zip code to see suggested values.";
    return (
      <Image image="MapPin" look={lookPrefix + "1"} width={16} height="100%" marginRight={8} tooltip={tooltip}
        onClick={() => props.inputRef.current.focus()}
      />
    );
  }
  else if (isCombo(props))
    return (
      <Button
        id="dropDownButton"
        image = {props.dropDownVisible ? { image: "ArrowUp", width: 12, height: 12 } : { image: "Arrow", width: 12, height: 12 } }
        look="subtleNoBorder1"
        className={classes.trailingAdornment + " " + classes.chevron}
        tabIndex={-1}
        onClick={() => { props.inputRef.current.focus(); props.inputRef.current.click();}}
        onKeyDown={(event) => props.buttonKeyDown(event)}
      />
    );
  else if (isAutoComplete(props)) {
    return (
      <Image image="MagnifyingGlassWithLetter" look={lookPrefix + "1"} width={16} height="100%" marginRight={8}
        tooltip="This field will help you decide what to enter.  Just start typing to see suggested values."
        onClick={() => props.inputRef.current.focus()}
      />
    );
  }
}
