import React, {useState} from "react";
import { Image, Label, Panel } from ".";
import { getLogger } from "lib/util";
import { getChildArray } from "./ComponentUtil";
import { useWindowStyles } from "./WindowStyles";

const log = getLogger("lib.components.Window");
let mouseDownLocation, globalSetLocation;

export function Window(props) {
  const [location, setLocation] = useState(getDefaultLocation(props));
  const classes = useWindowStyles();
  let header = props.header;
  if (header === undefined) // let the caller pass in null to hide the header
    header = (
      <Panel backgroundColor="blue" fillRow >
        <Label caption={props.caption} allowSelection={false} color="white" fillRow noRowBreak onMouseDown={(event) => mouseDown(event, setLocation)} />
        <Image image="X" color="white" width={14} height={14} marginTop={6} marginRight={4} onClick={() => close(props.onClose)}/>
      </Panel>
    );
  let children = getChildArray(props.children);
  return (
    <div className={classes.window} style={{left:location.x, top:location.y}}>
      <Panel width={props.width} height={props.height} padding={0} >
        {header}
        <Panel fillRow fillHeight >
          {children}
        </Panel>
      </Panel>
    </div>
  )
}

function mouseDown(event, setLocation) {
  mouseDownLocation = {x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY};
  globalSetLocation = setLocation;
  document.addEventListener("mousemove", mouseMove);
  document.addEventListener("mouseup", mouseUp);
}

function mouseMove(event) {
  const newLocation = {x: event.clientX - mouseDownLocation.x, y:event.clientY - mouseDownLocation.y};
  globalSetLocation(newLocation);
}

function mouseUp(event) {
  document.removeEventListener("mousemove", mouseMove);
  document.removeEventListener("mouseUp", mouseUp);
}

function close(onClose) {
  if (onClose == null)
    log.info("No onClose property was passed to this window.");
  else
    onClose();
}

function getDefaultLocation(props) {
  let result = {x: 100, y: 100};
  if (props.left != null) {
    result.x = props.left;
    if (props.width != null)
      result.x -= props.width;
  }
  if (props.top != null)
    result.y = props.top;
  return result;
}
