import queryString from "query-string";
import { isObject } from "./JSUtil";

/**
 * Parse a query string into an object. Leading ? or # are ignored, so you can pass location.search or location.hash directly.
 * The returned object is created with Object.create(null) and thus does not have a prototype.
 *
 * @export
 * @param {string} [optionalUrl=window.location.href] Query String Parameter to parse.
 * @returns {object}  {foo: ['1', '2', '3']}
 */
export function parseQueryParams(optionalUrl = window.location.href) {
  optionalUrl = queryString.extract(optionalUrl);
  return queryString.parse(optionalUrl);
}

/**
 * This function accepts either a query string or an array of strings or objects and returns a query string that can be passed on a URL.
 * Example usages with the return value:
 *
 * buildQueryString("someParameter=value")  -> "?someParameter=value"
 * buildQueryString("?someParameter=value") -> "?someParameter=value"
 * buildQueryString("someParameter=value&other=value2") -> "?someParameter=value&other=value2"
 * buildQueryString(["someParameter=value", "other=value2"]) -> "?someParameter=value&other=value2"
 * buildQueryString({someParameter:"value", other:"value2"}) -> "?someParameter=value&other=value2"
 *
 * @param {*} queryStringOrArray
 */
export function buildQueryString(queryStringOrArrayOrObject, needsUrlEncoding = true) {
  if (queryStringOrArrayOrObject == null)
    return null;
  let result;
  if (typeof queryStringOrArrayOrObject === "string") {
    result = queryStringOrArrayOrObject;
    if (result.length > 0 && !result.startsWith("?"))
      result = "?" + result;
  }
  else if (Array.isArray(queryStringOrArrayOrObject)) {
    if (queryStringOrArrayOrObject.length === 0)
      return null;
    result = "?" + queryStringOrArrayOrObject.join("&");
  } else if (isObject(queryStringOrArrayOrObject)) {
    let first = true;
    for (let key in queryStringOrArrayOrObject) {
      if (first)
        result = "?";
      else
        result += "&";
      first = false;
      result += key;
      let value = queryStringOrArrayOrObject[key];
      if (value != null)
        result += "=" + value;
    }
  }
  if (needsUrlEncoding)
    result = encodeURI(result);
  return result;
}

export function trimQueryString(url) {
  if (url != null) {
    const index = url.indexOf("?");
    if (index >= 0)
      return url.substring(0, index);
  }
  return url;
}
