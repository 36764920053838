import { makeStyles } from "lib/util";
import { getTheme } from "lib/util/Theme";

let styles;

export function useSwitchStyles() {
  if (styles == null)
    styles = makeStyles("swch", {
      root: {
        display: "inline-flex",
        tabindex: 0,
      },
      track: {
        width: "44px",
        height: "20px",
        backgroundColor: getTheme().palette.primary.dark,
        borderRadius: "16px",
        marginLeft: "8px",
        marginTop: "2px",
        marginRight: "8px"
      },
      thumb: {
        width: "36px",
        height: "36px",
        marginTop: "-8px",
        marginLeft: "-6px",
        borderRadius: "50%",
        transition: "transform .2s",
        backgroundColor: "transparent",
        outline: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          backgroundColor: getTheme().palette.primary.base + "18"
        },
        "&:focus": {
          backgroundColor: getTheme().palette.primary.base + "18"
        }
      },
      switchContainer: {
        display: "flex",
        marginTop: "10px",
      },
      thumbInner: {
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        backgroundColor: getTheme().palette.primary.base,
      },
      checked: {
        transform: "translateX(22px)"
      },
      svg: {
        color: getTheme().palette.primary.reverse,
        width: "24px",
        height: "24px",
        padding: "3px"
      }
    })
  return styles;
}

