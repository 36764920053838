import { makeStyles } from "lib/util";

let styles;

export function useFormControlStyles() {
  if (styles == null)
    styles = makeStyles("fctl", {
      formControlColumn: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
      },
      formControlRow: {
        display: "inline-block",
        flexDirection: "column",
        width: "100%"
      },
      formControlRowErrorRight: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
      },
      message: {},
      error: {
        marginTop: "1px",
        minHeight: "16px",
        fontSize: "11px",
        color: "red"
      },
      errorRight: {
        display: "inline-block",
        marginLeft: "7px",
        minHeight: "16px",
        fontSize: "11px",
        color: "red",
        verticalAlign: "middle",
        height: "100%",
        whiteSpace: "nowrap",
        float: "left",
        marginTop: "14.5px"
      },
      caption: {
        fontSize: "11.5px",
        fontFamily: "Roboto, Helevtica, Arial, sans-serif",
        fontWeight: 400,
        color: "#666666",
        whiteSpace: "nowrap",
        marginBottom: "1px",
        userSelect: "none"
      },
      captionRow: {
        fontSize: "11.5px",
        fontFamily: "Roboto, Helevtica, Arial, sans-serif",
        fontWeight: 400,
        color: "#666666",
        whiteSpace: "nowrap",
        marginRight: "7px",
        display: "inline-block",
        float: "left",
        marginTop: "14px",
        userSelect: "none"
      },
      captionRowRight: {
        fontSize: "11.5px",
        fontFamily: "Roboto, Helevtica, Arial, sans-serif",
        fontWeight: 400,
        color: "#666666",
        whiteSpace: "nowrap",
        marginRight: "7px",
        display: "flex",
        float: "right",
        marginTop: "14px",
        maxWidth: "16ch",
        alignItems: "flex-end"
      },
      captionRowRightReadOnly: {
        fontSize: "11.5px",
        fontFamily: "Roboto, Helevtica, Arial, sans-serif",
        fontWeight: 400,
        color: "#666666",
        whiteSpace: "nowrap",
        marginRight: "7px",
        display: "flex",
        float: "right",
        marginTop: "3px",
        maxWidth: "16ch",
        alignItems: "flex-end"
      },
      captionFocused: {
        color: "#137CDD"
      },
      captionError: {
        color: "red"
      },
      captionDisabled: {
        color: "#EBEBE4"
      },
      tooltip: {
        display: "flex",
        alignItems: "middle"
      }
    });
  return styles;
}
