import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function Documents(props) {
  return (
    <SVGIcon viewBox="0 0 18 18" {...props} >
      <svg>
        <path
          d="M13.124 4.18L9.366.374a1.35 1.35 0 00-.334-.242c-.016-.009-.034-.016-.05-.023A1.258 1.258 0 008.472 0H3.534c-.7 0-1.283.566-1.283 1.266v.984h-.976C.574 2.25 0 2.823 0 3.523V16.74C0 17.441.575 18 1.274 18h8.702c.7 0 1.256-.559 1.256-1.259v-.991h1.002c.7 0 1.248-.566 1.248-1.266V5.071c0-.334-.123-.654-.358-.89zM9.123 1.94l2.405 2.42H9.123V1.94zm.844 14.794H1.266V3.516h.984v10.968c0 .7.584 1.266 1.283 1.266h6.434v.984zm2.25-2.25H3.516V1.266h4.341v3.093c0 .7.584 1.266 1.284 1.266h3.076v8.86zM9 7.383a.632.632 0 00-.633-.633H5.133a.632.632 0 100 1.266h3.234c.35 0 .633-.283.633-.633zm2.25 2.25A.632.632 0 0010.617 9H5.133a.632.632 0 100 1.266h5.484c.35 0 .633-.283.633-.633zm-1.125 2.25a.632.632 0 00-.633-.633h-4.36a.632.632 0 100 1.266h4.36c.35 0 .633-.283.633-.633z"
        />
      </svg>
    </SVGIcon>
  );
}

const SVGDocuments = React.memo(Documents);
export { SVGDocuments };
