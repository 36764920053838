import React from "react";
import { useField } from "formik";
import { DateTimePicker, AutoComplete } from "common";
import { getMilliTime, parseDateTimeString } from "core/utils/dateTime";
import { buildAPIUrl } from "core/utils/urls";
import { API_PATHS } from "core/config/urls";
import { resolveArrayResponse, fetch } from "lib/util/fetch";
import { Grid } from "@material-ui/core";

export function LocationDateTimeField({ locationName, dateTimeName, loading, ...restProps }) {
  const [locationField, locationMeta, locationHelpers] = useField(locationName);
  const [dateTimeField] = useField(dateTimeName);

  const handleLocationFetch = async (query, dateTime = null) => {
    const response = await fetch(
      buildAPIUrl({
        path: API_PATHS.QUERY_LOCATION,
        queryParams: {
          location: query,
          dateTime: dateTime
            ? getMilliTime(dateTime)
            : getMilliTime(parseDateTimeString(dateTimeField.value))
        }
      })
    );
    return resolveArrayResponse(response);
  };

  const handleAutoCompleteFetch = async (query = "", setOptions) => {
    if (query.trim().length < 2) return;
    const options = await handleLocationFetch(query);
    // Filter out invalid options.
    const filteredOptions = options.filter(
      (option) => option.cityName && option.state && option.zip
    );
    setOptions(filteredOptions.slice(0, 25));
  };

  const handleUpdateDateTime = async (dateTime) => {
    if (!locationField.value) return;
    const options = await handleLocationFetch(
      `${locationField.value.cityName}, ${locationField.value.state} ${locationField.value.zip}`,
      dateTime
    );
    const optionMatch = options.find((option) => option.cityId === locationField.value.cityId);
    if (optionMatch) locationHelpers.setValue(optionMatch);
  };

  const handleGetOptionLabel = ({ cityName, state, zip } = {}) =>
    cityName && state && zip ? `${cityName}, ${state} ${zip}` : "";
  const handleGetOptionSelected = (option, value) => option.cityId === value.cityId;
  const handleBlur = ({ target: { value } }) => handleUpdateDateTime(parseDateTimeString(value));
  const handleAccept = (dateTime) => handleUpdateDateTime(dateTime);

  return (
    <>
      <Grid item xs={6}>
        <AutoComplete
          {...restProps}
          {...locationHelpers}
          meta={locationMeta}
          label="City, State, Zipcode"
          onFetch={handleAutoCompleteFetch}
          getOptionSelected={handleGetOptionSelected}
          getOptionLabel={handleGetOptionLabel}
        />
      </Grid>
      <Grid item xs={5}>
        <DateTimePicker
          onAccept={handleAccept}
          onBlur={handleBlur}
          name="dateTime"
          label="Date & Time"
          fullWidth
          disabled={loading}
        />
      </Grid>
    </>
  );
}
