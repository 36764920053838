import { getLogger } from "lib/util";
import { getThemeClassNames } from "lib/util/Theme";
import React from "react";
import { DisplayTypes } from "..";
import { handleNullDisplayValue, HIDE_NULL, BLANK_SPACE } from "../ComponentUtil";
import { useTextBoxStyles } from "./TextBoxStyles";

const log = getLogger("lib.components.TextBoxAsLabel");

export function TextBoxAsLabel(props) {
  let classes = useTextBoxStyles();
  let display = props.value;
  if (display != null && display.length > 0) {
    if (props.displayType === DisplayTypes.WEIGHT){
      display = display.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      display += " lbs";
    }
    else if (props.displayType === DisplayTypes.LENGTH){
      display = display.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      display += " ft";
    }
    else if (props.displayType === DisplayTypes.DISTANCE){
      display = display.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      display += " mi";
    }
  }
  else if(display != null && (props.displayType === DisplayTypes.INTEGER || props.displayType === DisplayTypes.FLOAT || props.displayType === DisplayTypes.DECIMAL)){
    display = display.toLocaleString('en-US');
  }
  const nullValue = props.nullDisplayValue == null ? "--" : props.nullDisplayValue;
  display = handleNullDisplayValue(display, nullValue);
  if (display === HIDE_NULL)
    return null;
  let className = classes.fullHeight;
  if (props.look != null)
    className += " " + getThemeClassNames()[props.look];
  if (display !== BLANK_SPACE)
  {
    if (typeof display !== "string" && typeof display === "object") {
      log.info("Tried to display object %o in textbox with props %o", display, props);
      display = "Object"; // need to make this handle displaying objects like a normal text box can
    }
  }
  else {
    display = (<pre style={{margin: 0}}> </pre>);
  }
  const input = (
    <div className={className}>
      <div>{display}</div>
    </div>
  );
  return input;
}
