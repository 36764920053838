import React from "react";
import { Panel, Table, Column, TextBox, Label, Button } from "lib/components";
import { navigateTo } from "lib/util/Navigation";
import { fetchModelData, getLogger, getCompanySettings } from "lib/util";
import { getDataContextValue } from "lib/components/DataContainer";

const log = getLogger("ar.CustomerPortal.QuoteList");

export function QuoteList(props) {
  return (
    <Table
      {...props}
      paginate
      autoSearch
      modelName={"dispatch/ltl-quote-list"}
      name="quoteTable"
      emptyCaption={"No quotes available for display"}
      fillRow
      fillHeight
      defaultSort={[{ field: "ship_date", dataType: "datetime", ascending: false }]}
      exportName="Quote Listing"
      cellAlign="center"
      cellVerticalAlign="middle">
      <Column caption="Quote Number" field="id" sortfield="quote_id_numeric" minWidth={120} />
      <Column caption="Quote Request Date" field="ordered_date_display_value" sortfield="quote_id_numeric" minWidth={120} />
      <Column caption="Shipper" sortfield="shipper_city_name" minWidth={250}>
        <Label field="shipper_name" look="defaultBold" displayLabel="Shipper" />
        <Label field="shipper_address" nullDisplayValue="blankSpace" look="defaultLight1" displayLabel="Shipper address" style={{marginTop: -4, paddingTop: 0}} />
        <Label field="shipper_city_state_zip" nullDisplayValue="blankSpace" look="defaultLight1" displayLabel="Shipper city/state/zip" style={{marginTop: -8, paddingTop: 0}} />
      </Column>
      <Column caption="Consignee" sortfield="consignee_city_name" minWidth={250}>
        <Label field="consignee_name" look="defaultBold" displayLabel="Consignee" />
        <Label field="consignee_address" nullDisplayValue="blankSpace" look="defaultLight1" displayLabel="Consignee address" style={{marginTop: -4, paddingTop: 0}} />
        <Label field="consignee_city_state_zip" nullDisplayValue="blankSpace" look="defaultLight1" displayLabel="Consignee city/state/zip" style={{marginTop: -8, paddingTop: 0}} />
      </Column>
      <Column caption="Pickup Request Date" sortfield="ship_date" sortDataType="datetime" minWidth={120}>
        <Label field="ship_date_display_value" displayLabel="Ship date" />
      </Column>
      <Column caption="Freight Summary" minWidth={230}>
        <Panel noRowBreak>
          <TextBox caption={"Weight"} field="total_weight_display_value" displayLabel="Weight" width={110} noRowBreak/>
          <TextBox caption={"Freight Class"} field="freight_class_descr" displayLabel="Freight class" width={110} noRowBreak/>
        </Panel>
      </Column>
      <Column minWidth={80}>
        <Button caption="Open" onClick={(event, context) => openQuote(context)} />
      </Column>
    </Table>
  );
}

function openQuote(context) {
  const quoteId = getDataContextValue(context, "id", true);
  const isAssetLtl = getCompanySettings().is_asset_ltl;
  const isBrokerageLtl = getCompanySettings().is_brokerage_ltl;
  let modelName;
  let targetPath;
  if (isBrokerageLtl) {
    modelName = "dispatch/ltl-quote-order";
    targetPath = "portal/customer/brokerage-ltl-quote/BrokerageLtlQuote";
  }
  else if (isAssetLtl) {
    modelName = "dispatch/asset-ltl-quote-order";
    targetPath = "portal/customer/asset-ltl-quote/AssetLtlQuote";
  }
  if (modelName != null && targetPath != null) {
    fetchModelData((modelName + "?search="+quoteId), null, null, (response) => navigateToQuoteCarrierSelection(targetPath, response));
  }
}

function navigateToQuoteCarrierSelection(targetPath, data) {
  navigateTo(targetPath, null, false, {startStep: 1, initialData: data})
}
