import React from "react";
import { Typography, TableCellList } from "common";

export function CarrierInformationTableCell({ carrierName, carrierCity, carrierState }) {
  return (
    <TableCellList>
      <Typography component="li" fontSize="large" value={carrierName || "N/A"} />
      <Typography component="li" fontSize="large" value={carrierCity + ", " + carrierState || "N/A"} />
    </TableCellList>
  );
}
