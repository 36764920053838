import React, { useState } from "react";
import { getLogger } from "lib/util";
import { useComponentContext } from "./Context";
import { parseQueryParams } from "lib/util/UrlUtil";
import { deepCopy } from "../../lib/util/JSUtil"
import { Image, Row, Window } from ".";
import { getFunctionForIdentifier } from "lib/util/DynamicLoader";
//import { JsonView } from "./JsonView";

let log;

function getLog() {
  if (log == null)
    log = getLogger("lib.components.ContextDebugger");
  return log;
}

export function getAutoContextDebugger(context, props, debugContextOverride) {
  const params = parseQueryParams();
  getLog().debug("Debug params %o %o", context, props);
  if (debugContextOverride === true || (params.debugContext !== undefined && params.debugContext !== "false"))
    return <ContextDebugger />
  else
    return null;
}

function getRecursiveData(data) {
  let result;
  if (data != null) {
    result = {...data}; // don't modify the original data
    delete result.componentProps;
    delete result.parentContext;
    delete result.stateAccessors;
    delete result.childData;
    if (data.childData != null) {
      result.childData = {};
      for (const field in data.childData)
        result.childData[field] = getRecursiveData(data.childData[field]);
    }
  }
  return result;
}

export function ContextDebugger(props) {
  let [visible, setVisible] = useState(false)
  let [location, setLocation] = useState({x: 0, y: 0})
  let [refreshCount, setRefreshCount] = useState(0);
  let context = useComponentContext();
  context.registerDebugContextRefresher(refreshContextDebugger);
  function refreshContextDebugger()
  {
    //this only exists so that the context debugger can be re-rendered after child data is assigned to the context
    setRefreshCount((prevValue) => {
      let newValue = prevValue + 1;
      if (newValue > 100)
        newValue = 0;
      return newValue;
    });
  }

  let contentDiv;
  if (visible) {
    const contextCopy = deepCopy(context.data, ["parentContext", "componentProps", "stateAccessors", "tableValidators", "oaDate", "getJulian"], true);  // don't modify the original context
    delete contextCopy.hierarchy;
    let content = JSON.stringify(contextCopy, 0, 2);
    let comp = getFunctionForIdentifier("lib.components.JsonView.JsonView"); // when we import this directly, get the dreaded "Object(...) is not a function" error
    // would like to expand content.data.list.[last element of array]
    comp = React.createElement(comp, {json: content}, []);
    let windowCaption = "Context Debugger: " + (context.data.parentField != null ? context.data.parentField : "Overall");
    contentDiv = (
      <Window refreshCount={refreshCount} caption={windowCaption} width={400} height={400} left={location.x} top={location.y} onClose={() => setVisible(false)}>
          <Row style={{overflow: "auto"}}>
            {comp}
          </Row>
      </Window>
    );
  }

  return (
    <div style={{position:"relative"}} >
      <div style={{
          position:"absolute",
          zIndex: 100,
          left: "calc(100% - 12px)",
        }}
      >
        <Image image="MagnifyingGlass" width={12} height={12} onClick={(event) => {
            setLocation({x: event.clientX, y: event.clientY});
            setVisible(true);
          }}
        />
      </div>
      {contentDiv}
    </div>
  );
}
