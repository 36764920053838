import React from "react";
import { TableCell } from '@material-ui/core';
import { Button, Panel, Label } from "lib/components";
import { useStyles } from "./TableStyles";

export function RowToolsCell({ context, rowIndex, noImage, expandedRowIndex, setExpandedRowIndex, showExpand, showEdit, tools, ...props}) {
  let classes = useStyles();
  let comps = [];
	if (!noImage) {
    const buttonProps = {variant: "text", buttonStyle:{borderRadius:"50%"},noRowBreak: true, look: "subtle2"}
    if (showExpand) {
      let image = {image: "Chevron"};
      if (rowIndex !== expandedRowIndex)
        image.direction ="left";
      comps.push(
        <Button {...buttonProps}
          key="expand"
          image={image}
          onClick={() => toggleRowExpansion(rowIndex, expandedRowIndex, setExpandedRowIndex)}
        />
      );
    }
    if (showEdit) {
      const dropDown = getEllipseDropDown(context, rowIndex, props);
      comps.push(
        <Button key="edit/delete" image="Ellipsis" dropDown={dropDown} dropDownAlign="right" height={32} margin={0} />
      );
    }
    if (tools != null) {
      for (let i = 0; i < tools.length; i++)
        comps.push(tools[i]);
    }
  }

  let panel = ( 
    <Panel>
      {comps}
    </Panel>);
  if (props.returnPanel !== true) {
    const className = !props.displayAsCards ? classes.expandColumn : classes.expandColumnCard;
    return (
      <TableCell className={className}>
        {panel}
      </TableCell>
    );
  }
  else {
    return panel;
  }
}

function toggleRowExpansion(rowIndexToToggle, expandedRowIndex, setExpandedRowIndex) {
    let index = rowIndexToToggle;
    if (rowIndexToToggle === expandedRowIndex)
        index = -1;
    setExpandedRowIndex(index);
}

function getEllipseDropDown(context, rowIndex, props) {
	const result = [<Label caption="Edit" onClick={() => props.editRow()} look="default" />, 
    <Label caption="Delete" onClick={() => props.deleteRow()} look="default"/>];
	//result.push(<Label caption="Resequence" onClick={() => props.resequenceRow()} />);
	return result;
}
