import { Button, Label, Panel } from "lib/components";
import { AuthType } from "lib/util/AuthType";
import React, { useState } from "react";

export function TestSimpleReactMcLeod() {
  const [caption, setCaption] = useState();
  return (
    <Panel>
      <Label caption={caption} />
      <Button caption="Click me!" onClick={() => {
        setCaption("Clicked!")
      }}/>
    </Panel>
  )
}

export function getPageOptions() {
  return {
    title: "Simple React Test With McLeod Library",
    auth: AuthType.UNAUTH
  };
}
