import React from "react";
import { Panel, Label, Field } from "lib/components";
import { getCompanySettings } from "lib/util";
import { showWarningSnackbar } from "lib/components/Snackbar";
import { MaxDaysOut } from "./MaxDaysOut";

export function QuoteStopPanel(props) {
    //Setting to null so data from detail page is picked up.
    if(props.currentStep && props.currentStep !== 0){
        return null;
    }
    return(
        <Panel fillRow rowBreakDefault={false}>
	        <Label caption="Pickup From" look="defaultBold4" widthFillWeight={2} fillRow />
          <Label caption="Deliver To" look="defaultBold4" widthFillWeight={2} fillRow />
          <Label caption="Pickup Date" look="defaultBold4" widthFillWeight={1} fillRow rowBreak/>
          <Field caption="Shipper" field="shipper" lookupModelResultField={null} onDropDownInvisible={validateShipperLocation} widthFillWeight={2} fillRow />
          <Field caption="Consignee" field="consignee" lookupModelResultField={null} widthFillWeight={2} fillRow />
          <MaxDaysOut field="ship_date" fillRow widthFillWeight={1} rowBreak currentStep={props.currentStep}/>
        </Panel>
    );
}


export function validateShipperLocation(context, callback) {
  callback && callback(true);
}

export function showInvalidShipperSnackbar() {
    showWarningSnackbar("Your account does not have any shipping locations that match this zip code so we will not be able to provide a quote.\nContact us at " + getCompanySettings().order_contact_phone + " if you need a quote picking up at this zip code.", true);
}
