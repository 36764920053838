import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function PhoneRight(props) {
  return (
    <SVGIcon {...props} >
      <svg viewBox="0 0 18 18" {...props}>
        <path
          d="M.966 1.939C1.551 1.036 2.358 0 3.416 0c.31 0 .774.094 1.224.545.422.42 1.02 1.068 1.475 1.77.57.878.752 1.603.558 2.214-.125.401-.51.806-1.183 1.485l-.008.007c-.284.288-.606.614-.729.787-.115.163.03.604.376 1.152.453.717 1.208 1.546 1.887 2.26.58.551 1.258 1.176 1.895 1.639.797.577 1.204.663 1.377.663.086 0 .124-.025.143-.038.17-.121.48-.426.794-.737.702-.69 1.086-1.058 1.485-1.18.157-.049.32-.074.489-.074.548 0 1.173.248 1.911.758.626.431 1.205.97 1.579 1.346.514.514.575 1.041.538 1.394-.103.986-1.085 1.737-1.927 2.284-.987.64-2.123.964-3.373.964-2.592 0-5.51-1.432-8.004-3.927a16.08 16.08 0 01-2.226-2.763C1.083 9.575.629 8.585.345 7.61c-.618-2.13-.403-4.09.621-5.672zm.687 5.29c.247.852.65 1.724 1.196 2.591a14.57 14.57 0 002.037 2.528c.565.565 1.164 1.08 1.775 1.528.599.437 1.212.811 1.822 1.11a9.36 9.36 0 001.784.67 6.684 6.684 0 001.662.222c.984 0 1.87-.251 2.633-.746.313-.203.708-.474.988-.765.264-.273.318-.443.326-.518.003-.038.013-.13-.146-.288a10.294 10.294 0 00-1.392-1.189c-.65-.448-.992-.515-1.138-.515a.324.324 0 00-.072.007 1.66 1.66 0 00-.23.175c-.183.157-.401.37-.714.677-.443.439-.727.71-.957.876l-.027.02a1.602 1.602 0 01-.914.275c-.311 0-.637-.074-.997-.228a6.261 6.261 0 01-1.178-.694c-.698-.506-1.42-1.172-2.034-1.755l-.02-.017-.026-.029c-.76-.8-1.535-1.653-2.054-2.471a4.873 4.873 0 01-.468-.915 2.434 2.434 0 01-.155-.698c-.031-.495.138-.848.285-1.059.169-.237.441-.52.838-.92l.042-.044c.305-.307.526-.533.68-.712.114-.134.16-.205.176-.234.038-.151-.05-.504-.407-1.054-.383-.593-.915-1.168-1.29-1.546l-.002-.002c-.098-.098-.19-.147-.265-.147-.12 0-.303.103-.499.285-.304.282-.594.698-.81 1.03a4.636 4.636 0 00-.716 2.044c-.079.78.01 1.624.267 2.508z"
        />
      </svg>
    </SVGIcon>
  );
}

const SVGPhoneRight = React.memo(PhoneRight);
export { SVGPhoneRight };
