import * as React from "react";

function ChevronLeft(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 18"
      {...props}
    >
      <path d="M10.974 0c-.226 0-.453.075-.643.225L.383 8.222a1.026 1.026 0 000 1.598l9.905 7.954a1.026 1.026 0 001.284-1.597L2.662 9.02l8.955-7.197A1.024 1.024 0 0010.974 0z" />
    </svg>
  );
}

const SVGChevronLeft = React.memo(ChevronLeft);
export { SVGChevronLeft };