import * as React from "react";

function BoxPalletFacingRight(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 47 40" {...props}>
      <path d="M26.375 0l4.385 1.192-16.192 3.344.263 7.37 1.836.874-.075-7.142 16.642-3.534 5.285 1.444-.563 19.224-16.192 6.117-10.457-7.523-.937-18.578L26.375 0zm10.813 5.478l-14.13 3.445.283 17.884 13.503-5.032.344-16.297zM8.77 17.037l-2.844 1.21 2.963 2.494z" />
      <path d="M8.77 17.037l-2.844 1.21 2.963 2.494z" />
      <path d="M0 20.568l.336 3.77L20.392 40l4.29-2v-2.382l7.753-3.5-.067 2.369 3.814-1.769.07-2.31 6.873-3.09-.127 2.234 3.363-1.535.306-3.922-6.75-3.504-.104 2.939 3.495 2.063-1.693.722-2.973-1.673-3.514 1.35 2.865 1.878-1.693.784-3.034-2.01-4.055 1.649 3.25 2.058-1.777.799-3.384-2.167-4.307 1.497 3.613 2.48-1.891.843L3.94 19.259 0 20.568zm1.973 3.58l-.189-2.244L7.56 26.2l.132 2.339-5.72-4.393zm9.012 6.978l-.15-2.472 6.998 5.134v2.567l-6.848-5.23z" />
      <path d="M17.833 36.355l-6.848-5.229-.15-2.472 6.998 5.134v2.567zM1.973 24.147l-.189-2.243L7.561 26.2l.131 2.339-5.72-4.393zm37.944-3.555l-.104 2.938 3.495 2.063-1.693.722-2.973-1.673-3.514 1.35 2.865 1.878-1.693.784-3.034-2.01-4.055 1.649 3.25 2.058-1.777.799-3.384-2.167-4.307 1.497 3.613 2.481-1.891.841L3.94 19.26 0 20.57l.336 3.768L20.392 40l4.29-2v-2.382l7.753-3.499-.067 2.368 3.814-1.768.07-2.31 6.873-3.09-.127 2.233 3.363-1.535.306-3.922-6.75-3.503z" />
    </svg>
  );
}

const SVGBoxPalletFacingRight = React.memo(BoxPalletFacingRight);
export { SVGBoxPalletFacingRight };