import React from "react"
import { SVGIcon } from "lib/util/SVGIcon"

function Text(props) {
  return (
    <SVGIcon height={48} viewBox="0 0 48 48" width={48} {...props}>
      <path d="M8 9h2v30H8v3h8v-3h-2V9h2V6H8zM4 16h3.021v-4H0v24h7.042v-4H4zM16.979 12v4H44v16H16.958v4H48V12z" />
    </SVGIcon>
  )
}

const SVGText = React.memo(Text);
export { SVGText };
