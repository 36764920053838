import { PATHS } from "core/config/urls";
import { useHistory as reactUseHistory } from "react-router";
import { getLogger } from "./Logger";
import { buildQueryString } from "./UrlUtil";

let storedHistory;

const log = getLogger("lib.util.Navigation");

export function setHistory(value) {
  storedHistory = value;
}

export function getHistory(value) {
  return storedHistory;
}

export function HistoryProvider() {
  useHistory();
  return null;
}

export function useHistory() {
  storedHistory = reactUseHistory();
  return storedHistory;
}

/**
 * This function is used to navigate the user's browser to another page.
 *
 * @param {*} pathOrComponent  Either a string path or a component from which the path will be calculated.  This calculation allows us to not hard code paths throughout the
 *                             application-level code.
 * @param {*} queryStringOrArrayOrObject A query string, array, or object to append to the URL.  See UrlUtil.buildQueryString() for more details on what is acceptable to send.
 * @param {*} hardRefresh Whether we want to perform a hard refresh of the page or to mount the target component in the current context of the single-page app
 */
export function navigateTo(pathOrComponent, queryStringOrArrayOrObject, hardRefresh = false, state) {
  log.info("navigateTo %o  query %o", pathOrComponent, queryStringOrArrayOrObject);
  if (storedHistory == null) {
    log.info("Attempt to navigate, but storedHistory was not set in main page context.");
    return;
  }
  let path = pathOrComponent;
  let queryString = buildQueryString(queryStringOrArrayOrObject);
  if (hardRefresh) {
    let fullPath = path;
    if (queryString != null) {
      fullPath += "?" + queryString;
    }
    window.location.href = fullPath;
  }
  else {
    storedHistory.push({
      pathname: pathOrComponent,
      search: queryString,
      state: state
    });
  }
}

export function navigateToRoot() { navigateTo(PATHS.ROOT); }
