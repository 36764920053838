import React from "react";
import { CircularProgress } from '@material-ui/core';
import { Component } from ".";
import { withStyles } from '@material-ui/core/styles';
import { getTheme } from "lib/util/Theme";

export function Spinner(props) {
	let size = 18;
	if (props.size)
		size = props.size;
	else if (props.width)
		size = props.width;
	else if (props.height)
    size = props.height;
  
  let color = "primary";
  if (props.color != null)
  { color = props.color; }
  else if (props.parentLook != null)
  {
    const parentLook = props.parentLook;
    let baseOrReverse;
    if (parentLook.toLowerCase().indexOf('filled') < 0)
    { baseOrReverse = 'base'; }
    else
    { baseOrReverse = 'reverse'; }
    
    const looks = getTheme().palette;
    for (const lookName in looks)
    {
      if (parentLook.toLowerCase().indexOf(lookName.toLowerCase()) == 0)
      {
        color = looks[lookName][baseOrReverse];
        break;
      }
    }
  }
  
  const CustomCircularProgress = withStyles((theme) => ({
    colorPrimary: {
      color: color
    }
  }))(CircularProgress);

  return (
		<Component {...props} >
			<CustomCircularProgress size={size}/>
		</Component>
	);
}
