import React from "react";
import { Panel, useComponentContext } from "lib/components";
import { OrderConfirmationHeader } from "portal/customer/OrderConfirmationHeader";
import { PanelAdditionalDetails } from "./QuoteDetails";
import { QuoteSummaryPanel } from "portal/customer/QuoteSummaryPanel";
import { FreightItemsTable } from "../FreightItemsTable";
import { HandlingReqsTable } from "../HandlingReqsTable";
import { AuthType } from "lib/util/AuthType";
import { getLogger } from "lib/util";
import { CustomerType, PanelMode } from "../ControlConstants";
import { StopTable } from "portal/customer/StopTable";

const log = getLogger("portal.customer.brokerage-ltl-quote.Confirmation");

export function Confirmation(props) {
  log.info("Confirmation %o  %o", props, useComponentContext());
  const parentType = "brokerage-ltl-quote";
  const stopTableProps = {
    parentType: parentType,
    tableCaption: "Pickup and Delivery",
    showDatesTimes: (context, rowProps, rowIndex) => showDatesTimes(rowIndex)
  }
  return (
    <Panel data={props.data} fillRow modelName="dispatch/ltl-order">
      <OrderConfirmationHeader />
      <Panel fillRow>
        <QuoteSummaryPanel data={props.data} type="Order" idField="order_id" stopsVisible={false} otherChargesVisible={false} disclaimerVisible={false} transitInfoPanelProps={{marginTop: 25, padding: 0}} buttonLook="primary1" noRowBreak />
        <Panel fillRow>
          <StopTable {...stopTableProps} fillRow />
          <PanelAdditionalDetails usePrintableVersion />
          {FreightItemsTable(CustomerType.BROKERAGE, PanelMode.DISPLAY)}
          {HandlingReqsTable(3)}
        </Panel>
      </Panel>
    </Panel>

    )
}

function showDatesTimes(rowIndex)
{
  return rowIndex == 0;
}

export function getPageOptions() {
  return {
    auth: AuthType.CUSTOMER,
    title: "Order Confirmation"
  };
}
