import React, { useState } from "react";
import { IconButton, CircularProgress, makeStyles } from "@material-ui/core";
import { SVGDownload } from "images/SVGDownload";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: -1,
    left: -1,
    zIndex: 1
  }
}));

export function DownloadActionButton({ onClick = () => undefined, ...restProps }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const handleClick = async (event) => {
    event.stopPropagation();
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <IconButton className={classes.root} onClick={handleClick} {...restProps}>
      <SVGDownload />
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </IconButton>
  );
}
