import React from "react";
import { ListItem } from "@material-ui/core";
import { Label, showDialog } from "./";
import { getLogger } from "lib/util";
import { showSlideout } from "./Slideout";
import { getFunctionForIdentifier } from "lib/util/DynamicLoader";

const log = getLogger("lib.components.MainMenuItem");

export function MainMenuItem(props) {
	log.debug("Menu item %o", props);
	let onClick = () => openMenuItem(props.id, props.caption, props.type, props.onClose, props.url);
	return (
		<ListItem button onClick={onClick} variant="outlined" color="primary">
			<Label image={{image:props.image, fill: "3E3E3E", width:18, height:18, marginRight:16}} caption={props.caption} />
		</ListItem>
	);
}

function openMenuItem(id, caption, type, onClose, url) {
  if (type === 'U') {  // open external url
    log.debug("Opening url: %s", url);
    window.open(url,"_blank");
    return;
  }
  onClose();
  let component = getFunctionForIdentifier(id, true);
  if (component == null)
    log.info("Menu component not found " + id);
  log.debug("Opening menu component for %s   %o", id, component);
  component = component({fillHeight: true}); // should probably just pass the function and not actually call the function
	log.debug("Open menu item %o", component);
	if (type === "D") // dialog
		showDialog(caption, component);
	else if (type === "S") // slideout from side
		showSlideout(caption, component);
	else {
		// make the Router navigate to this component - do we need URL piece?  Should we have made it a Link in the ListItem?
	}
}
