import { makeStyles } from "lib/util";

let styles;

export function useImageStyles() {
  if (styles == null)
    styles = makeStyles("img", {
      image: { display: "flex", alignItems: "center", padding: "0px" },
      svg: { width: "100%", height: "100%" }
    });
  return styles;
}
