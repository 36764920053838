import * as React from "react";

function PhoneWithChatBubble({gradientColor1="#4bb119", gradientColor2="#458719", stroke="#458719", fill="#FFF", ...props}) {
  return (
    <svg width="1em" height="1em" {...props}>
      <defs>
        <linearGradient
          x1="82.976%"
          y1="10.458%"
          x2="5.059%"
          y2="96.35%"
          id="prefix__a"
        >
          <stop stopColor={gradientColor1} offset="0%" />
          <stop stopColor={gradientColor2} offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" strokeWidth={1.25}>
        <path
          d="M11.17 20.34c5.4 0 9.78-4.33 9.78-9.67S16.57 1 11.17 1 1.4 5.33 1.4 10.67c0 1.78.5 3.46 1.34 4.9l.08.13-1.64 4.75c-.05.13.02.27.15.32.05.02.1.02.16 0l4.98-1.6.16.08a9.8 9.8 0 004.55 1.11z"
          stroke="url(#prefix__a)"
          fill={fill}
        />
        <path
          d="M6.04 6.24c.4-.6.93-1.3 1.64-1.3.2 0 .52.06.82.36.28.28.68.7 1 1.17.38.58.5 1.06.37 1.47-.08.27-.34.53-.8.98l-.01.01-.5.52c-.08.1.02.4.25.76a11 11 0 001.27 1.5l1.27 1.1c.53.38.8.44.92.44.06 0 .08-.02.1-.03.1-.08.32-.28.53-.5.47-.46.73-.7 1-.78.1-.03.2-.05.33-.05.37 0 .8.16 1.28.5a7.9 7.9 0 011.06.89 1.1 1.1 0 01.36.92c-.07.65-.73 1.15-1.3 1.5a4.1 4.1 0 01-2.26.64c-1.74 0-3.7-.95-5.37-2.6a10.7 10.7 0 01-1.49-1.83A7.7 7.7 0 015.62 10c-.4-1.4-.27-2.7.42-3.76z"
          stroke={stroke}
        />
      </g>
    </svg>
  );
}

const SVGPhoneWithChatBubble = React.memo(PhoneWithChatBubble);
export { SVGPhoneWithChatBubble };