import React from "react";
import { appendComponentToHierarchy, getChildArray } from "./ComponentUtil";
import { Button, ButtonActions, Component, Row, useComponentContext } from ".";
import { HierarchyContext } from "./Context";
import { DataContainer, getDataContainerProps } from "./DataContainer";
import { getLogger, useMountEffect } from "lib/util";
import { PropTypes, getBasicPropTypes, ComponentPropTypes } from "lib/components/PropTypes";
import { usePanelStyles } from "./PanelStyles";

const log = getLogger("lib.components.Panel");

export function Panel({ setLoading, ...props }) {
  let context = useComponentContext();
  let classes = usePanelStyles();
  log.debug("Panel props %o", props);
  let style = undefined;
  const rows = getRows(props.children, props);
  if (props.submitCaption != null) {
    rows.push(getSubmitRow(props, classes));
    style = {marginBottom: 60};
  }
  let content = getRowDivs(props, classes, rows);
  if (context.designer != null) {
    if (props.border == null)
      style = { border: "1px solid #E5E5FF" };
    if (props.children == null || props.children.length === 0) {
      content = <div style={{color:"#E5E5FF", textAlign:"center", padding: 8}}>No components on {props.name}<br></br>Place components here</div>
    }
  }
  const hierarchy = appendComponentToHierarchy(context, props);
  if (props.modelName || props.data != null || props.field != null && props.noDataContainer) { // but not if ancestor data is just passing through to children
    return (
      <DataContainer {...getDataContainerProps(props)} >
        <PanelGuts style={style} className={classes.panel} hierarchy={hierarchy} content={content} {...props} />
      </DataContainer>
    );
  }
  return <PanelGuts style={style} className={classes.panel} hierarchy={hierarchy} content={content} {...props} />;
}

function PanelGuts(props)
{
  const context = useComponentContext();
  useMountEffect(() => {
    loadInitialData(context, props);
  });
  return (
    <Component style={props.style} className={props.className} {...props}>
      <HierarchyContext.Provider value={props.hierarchy}>
        {props.content}
      </HierarchyContext.Provider>
    </Component>
  );
}

function loadInitialData(context, props) {
  if (props.loadInitialData != null) {
    props.loadInitialData(context);
  }
}

function shouldRowBreak(rowBreakDefault, childProps) {
  if (rowBreakDefault === false)
    return childProps.rowBreak === true;
  else
    return !childProps.noRowBreak && childProps.rowBreak !== false;
}

function getRows(children, props) {
  var currentRow = [];
  var result = [];
  children = getChildArray(children);
  for (let i = 0; children != null && i < children.length; i++) {
    var child = children[i];
    currentRow.push(child);
    if (child.type === Row) {
      result.push(child);
      currentRow = [];
    }
    else if (child.type === Row || (child.props != null && shouldRowBreak(props.rowBreakDefault, child.props))) {
      result.push(currentRow);
      currentRow = [];
    }
  }
  if (currentRow.length > 0)
    result.push(currentRow);
  return result;
}

function getRowHeight(row) {
  let result;
  for (let i = 0; i < row.length; i++) {
    const comp = row[i].props;
    let height = comp.height;
    if (height && height > result)
      result = height;
  }
  return result;
}

function rowHasFillComponent(row) {
  for (let i = 0; i < row.length; i++)
    if (row[i].props.fillHeight)
      return true;
  return false;
}

function getRowDivs(props, classes, rows) {
  const result = [];
  for (let i = 0; rows != null && i < rows.length; i++) {
    let row = rows[i];
    if (row != null && row.type === Row)
      result.push(row)
    else {
      const height = getRowHeight(row);
      let rowStyle = {};
      let hAlign;
      if (row.length > 0 && row[0].props != null)
        hAlign = row[0].props.hAlign;
      if (hAlign == null)
        hAlign = props.contentHAlign;
        // we need to do two things to make this work as envisioned:
        // 1) consider hAlign of every component, not just the first component.  I think we would
        //       collect all the "left," "center," and "right" components and stick them in their own divs.
        //       If there are only "left" components then don't add any interior divs
        // 2) consider the vAlign of components.  This complicates the collection described in item 1.
      if (hAlign === "right")
        rowStyle.justifyContent = "flex-end";
      else if (hAlign === "center")
        rowStyle.justifyContent = "center";
      let className = classes.panelRow;
      if (props.verticalAlign === "center")
        className += " " + classes.centerVertical;
      else if (props.verticalAlign === "bottom")
        className += " " + classes.bottomVertical;

      if (height != null)
        rowStyle.height = height;
      if (rowHasFillComponent(row))
        className += " " + classes.rowFillHeight;
      //		console.log("Panel row class %o %o  %o", row, className, rowStyle);
      result.push(
        <div key={"row" + i} style={rowStyle} className={className}>
          {row}
        </div>
      );
    }
  }
  return result;
};

function getSubmitRow(props, classes) {
  let cancelCaption = props.cancelCaption ? props.cancelCaption : "Cancel";
  const isSubmitEnabled = props.submitEnabled !== false;
  let submitAction = ButtonActions.SUBMIT;
  let onClickAction = null;
  if (props.onSubmit != null)
  {
    submitAction = null;
    onClickAction = (event, context, setLoading) => props.onSubmit(event, context, setLoading);
  }
  let submitButtonWidth = 210;
  if (props.submitButtonWidth != null) {
    submitButtonWidth = props.submitButtonWidth;
  }
  return (
    <Row key="submitRow" className={props.undockSubmitRow !== true ? classes.submitRow : classes.undockSubmitRow} >
      <Button key="cancelButton" caption={cancelCaption} look="subtleLight2" onClick={props.onCancel} width={104} height={44} style={{paddingRight:"16px"}}/>
      <Button key="submitButton" caption={props.submitCaption} look="primaryFilledHoverDarker2" onClick={onClickAction} action={submitAction} enabled={isSubmitEnabled} onComplete={props.onComplete} height={44} width={submitButtonWidth} />
    </Row>
  );
}

Panel.extPropTypes = {
  ...ComponentPropTypes,
  caption: { type: PropTypes.string },
  field: { type: PropTypes.string },
  modelName: { type: PropTypes.string },
  submitButtonWidth: { type: PropTypes.number },
}

Panel.propTypes = {
  ...getBasicPropTypes(Panel.extPropTypes)
}
