import { makeStyles } from "lib/util";

let styles;

export function useWindowStyles() {
  if (styles == null)
    styles = makeStyles("wnd", {
      window: {
        position: "fixed",
        zIndex: 2000,
        backgroundColor: "#f1f1f1",
        border: "1px solid rgba(0,0,0,.25)",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      }
    });
  return styles;
}

