import { Container } from "@material-ui/core";
import classNames from "core/utils/classNames";
import { SEO } from "core";
import { Nav } from "features/Nav";
import React from "react";
import { getDrawerOpen } from "core/store/selectors/UISelectors";
import { connect } from "react-redux";
import { useStyles } from "./DefaultPageContainer.styles";
import { useHistory } from "lib/util/Navigation";

function DefaultPageContainerPresentation({
  pageTitle = null,
  nav = true,
  children = [],
  navDrawerOpen = false
}) {
  useHistory();
  const classes = useStyles();
  return (
    <main
      data-test-selector="main"
      className={classNames(classes.root, {
        [classes.rootShift]: navDrawerOpen
      })}
    >
      <SEO title={pageTitle} />
      {nav && <Nav />}
      <Container
        maxWidth={false}
        data-test-selector="content"
        className={classes.content}
      >
        {children}
      </Container>
    </main>
  );
}

const mapStateToProps = (state) => ({
  navDrawerOpen: getDrawerOpen(state)
});

export const DefaultPageContainer = connect(mapStateToProps)(
  DefaultPageContainerPresentation
);
