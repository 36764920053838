import React from "react";

export class ALKMap extends React.Component {
  static defaultProps = {
    center: { lat: 39.8283, lng: -98.5795 },
    zoom: 5,
  };

  createMarkers = (locations) => {
    if (this.map != null) {
      // var htmlContent = '<div id="container"> ' +
      //     '<div id="text">GREEN</div>' +
      //     '<div id="image" style="background: url(' + ALKMaps.IMAGE.TRUCK_GREEN + ') no-repeat; height: 35px; width: 35px;"/>' +
      // '</div>';

      this.markers.clearMarkers();
      for (let i = 0; i < locations.length; i++) {
        var lonLat = new window.ALKMaps.LonLat(locations[i].lng, locations[i].lat)
          .transform(new window.ALKMaps.Projection("EPSG:4326"), this.map.getProjectionObject());
        var marker = new window.ALKMaps.Marker2("marker", lonLat,
          // new window.ALKMaps.Size(60, 50), htmlContent, false, null);
          new window.ALKMaps.Size(30, 30), null, false, null);
        this.markers.addMarker(marker);
      }
    }
  }

  componentDidMount = () => {
    const script = document.createElement("script");
    script.src = "https://maps.alk.com/api/1.2/alkmaps.js";
    script.async = true;
    script.onload = () => this.loadMap();
    document.body.appendChild(script);
  }

  loadMap = () => {
    window.ALKMaps.APIKey = "79EB69949301114AB1911E7E07AA394A";
    this.map = new window.ALKMaps.Map({ div: "map" });
    this.markers = new window.ALKMaps.Layer.Markers("markers", {});
    this.map.addLayer(this.markers);

    var center = new window.ALKMaps.LonLat(this.props.center.lng, this.props.center.lat);
    center.transform(new window.ALKMaps.Projection("EPSG:4326"), this.map.getProjectionObject()); //transform to mercator
    this.map.setCenter(center, this.props.zoom);

    this.createMarkers(this.props.pins);
  }

  render = () => {
    if (this.props.pins != null) {
      for (let i = 0; i < this.props.pins.length; i++) {
        if (this.props.pins[i].lng > 0)
          this.props.pins[i].lng *= -1;
        console.log("ALK Map pin: %o", this.props.pins[i]);
      }
    }
    this.createMarkers(this.props.pins);
    const mapSize = {
      width: "100%",
      height: "100%"
    }
    return (
      <div id="map" style={mapSize} />
    )
  }
}
