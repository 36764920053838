import { copyTextToClipboard } from "lib/util/Clipboard";
import React, { useState } from "react";
import { Label, Panel, Tree, Row } from ".";
import { showSnackbar } from "./Snackbar";
import { Switch } from "./Switch";

export function JsonView(props) {
  let [viewAsTree, setViewAsTree] = useState();
  let comp;
  if (viewAsTree) {
    let data = null;
    if (props.json == null)
      data = {};
    else
      data = JSON.parse(props.json);
    comp = <Tree data={data} fillRow fillHeight />
  }
  else
    comp = (
      <pre style={{overflow:"unset"}} >
        <Label caption={props.json} />
      </pre>
    );

  return (
    <Panel fillRow fillHeight {...props} >
      <Label caption="Copy to clipboard" image={{image: "Clipboard", color: "#137CDD"}} onClick={() => copy(props.json)}  variant="link" fillRow noRowBreak />
      <Switch leftLabel="Text" rightLabel="Tree" usePrintableVersion={false} onChange={setViewAsTree} />
      <Row style={{overflow: "auto"}}>
        {comp}
      </Row>
    </Panel>
  )
}

function copy(text) {
  copyTextToClipboard(text);
  showSnackbar("Text has been copied to clipboard.");
}
