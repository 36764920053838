import * as React from "react";
import { SVGIcon } from "lib/util/SVGIcon";

function CircleEmpty(props) {
  return (
    <SVGIcon viewBox="0 0 20 20" {...props}>
      <circle
        cx={10}
        cy={10}
        r={9}
        strokeWidth={2}
        fill="none"
        stroke={props.fill}
      />
    </SVGIcon>
  );
}

const SVGCircleEmpty = React.memo(CircleEmpty);
export { SVGCircleEmpty };
