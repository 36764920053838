import React from "react";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Component }   from ".";
import { getDataFromContext, getLogger } from "lib/util";
import { isObject } from "lib/util/JSUtil";
import { useComponentContext } from "./Context";
import { evaluateProp, getFirstSuppliedValue } from "./ComponentUtil";
import { useTreeStyles } from "./TreeStyles";

const log = getLogger("lib.components.Tree");

export function Tree({ nodes, onNodeSelect, ...props }) {
  const context = useComponentContext();
  const classes = useTreeStyles();
  log.info("Tree %o  %o", context, props);
  if (nodes != null)
    nodes = getNodes(nodes);
  else {
    const data = getFirstSuppliedValue(context, [
      () => evaluateProp(context, props.data),
      (context) => getDataFromContext(context, props.field)
    ]);
    log.debug("Data %o   %o", data, getDataFromContext(context));
    nodes = getDataNodes(props, data, []);
  }
  log.debug("Nodes %o", nodes);
  return (
    <Component {...props} >
      <TreeView
        className={classes.tree}
        onNodeSelect={onNodeSelect}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}>
        {nodes}
      </TreeView>
    </Component>
  );
}

function getDataNodes(props, data, path) {
  let result = [];
  for (const key in data) {
    const value = data[key];
    path = [...path];
    path.push(key);
    if (isObject(value))
      result.push(getObjectNode(props, path, key, value));
    else if (Array.isArray(value))
      result.push(getArrayNode(props, path, key, value));
    else
      result.push(getPrimitiveNode(props, path, key, value));
  }
  return result;
}

function getPrimitiveNode(props, path, key, value) {
  const elemKey = path.join(".");
  return (<TreeItem key={elemKey} nodeId={elemKey} label={key + ": " + value} />);
}

function getObjectNode(props, path, key, value) {
  const elemKey = path.join(".");
  return (
    <TreeItem key={elemKey} nodeId={elemKey} label={key} >
      {getDataNodes(props, value, path)}
    </TreeItem>
  );
}

function getArrayNode(props, path, key, value) {
  const elemKey = path.join(".");
  let arrayNodes = [];
  for (let i = 0; i < value.length; i++) {
    let arrPath = [...path];
    arrPath.push(i);
    if (isPrimitive(value[i]))
      arrayNodes.push(getPrimitiveNode(props, arrPath, i, value[i]));
    else
      arrayNodes.push(
        <TreeItem key={elemKey + i} nodeId={elemKey + i} label={"" + i} >
          {getDataNodes(props, value[i], arrPath)}
        </TreeItem>
      );
  }
  return (
    <TreeItem key={elemKey} nodeId={elemKey} label={key} >
      {arrayNodes}
    </TreeItem>
  );
}

function isPrimitive(value) {
  return typeof value === "string";
}

function getNodes(data) {
  if (data == null)
    return null;
  let result = [];
  for (let i = 0; i < data.length; i++) {
    let subNodes;
    if (data[i].nodes != null)
      subNodes = getNodes(data[i].nodes);
    result.push(
      <TreeItem key={data[i].key} nodeId={data[i].key} label={data[i].caption} >
        {subNodes}
      </TreeItem>
    );
  }
  return result;
}
