import * as React from "react";

function MapPinFilledActive(gradientColor1="#2C5DD1", gradientColor2="#109BE8", ...props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 63 70" {...props}>
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="prefix__c">
          <stop stopColor={gradientColor1} offset="0%" />
          <stop stopColor={gradientColor2} offset="100%" />
        </linearGradient>
        <filter
          x="-67.1%"
          y="-56.2%"
          width="234.3%"
          height="215.3%"
          filterUnits="objectBoundingBox"
          id="prefix__a"
        >
          <feMorphology
            radius={2.5}
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.0745098039 0 0 0 0 0.48627451 0 0 0 0 0.866666667 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M48.514 29.444c0 2.855-.71 5.827-2.111 8.83-1.203 2.595-2.94 5.243-5.156 7.874-3.46 4.11-7.335 7.252-9.488 8.852-2.152-1.606-6.027-4.742-9.487-8.852-2.216-2.631-3.954-5.284-5.161-7.878C15.709 35.266 15 32.294 15 29.44c0-1.872.43-3.701 1.286-5.429.846-1.712 2.053-3.264 3.597-4.614a17.597 17.597 0 015.387-3.211A18.352 18.352 0 0131.754 15h.01c2.216 0 4.4.399 6.48 1.186 2.01.76 3.822 1.839 5.392 3.21 1.549 1.35 2.756 2.903 3.591 4.615.856 1.744 1.287 3.567 1.287 5.433z"
          id="prefix__b"
        />
      </defs>
      <g fillRule="nonzero" fill="none">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="url(#prefix__c)" xlinkHref="#prefix__b" />
      </g>
    </svg>
  );
}

const SVGMapPinFilledActive = React.memo(MapPinFilledActive);
export { SVGMapPinFilledActive };